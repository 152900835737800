import { createSlice } from "@reduxjs/toolkit";
import { clearSession, getItem, setItem, removeItem } from "../../services/LocalStorage";

export const wineriesSlice = createSlice({
    name: 'wineries',
    initialState: () => {
        const wineriesSession = getItem('wineriesList') || [];
        const activeWinery = wineriesSession?.find((winery) => winery.id === getItem('activeWineryId'));
        return {
            list: wineriesSession,
            active: activeWinery || null
        }
    },
    reducers: {
        empty: state => {
            return {
                list: [],
                active: null
            }
        },
        set: (state, action) => {
            setItem('wineriesList', action.payload)
            if (action.payload?.length === 1) {
                setItem('activeWineryId', action.payload?.[0]?.id)
            }
            return {
                list: action.payload,
                active: action.payload?.length !== 1 ? null : action.payload[0]
            }
        },
        add: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        setActive: (state, action) => {
            setItem('activeWineryId', action.payload.id)
            return {
                list: state.list,
                active: action.payload
            }
        },
        resetActive: (state) => {
            removeItem('activeWineryId');
            return {
                list: state.list,
                active: null
            }
        },
        update: (state, action) => {
            const foundedIndex = state.list?.findIndex((winery) => winery.id === action?.payload?.id);
            if (foundedIndex !== -1)
                state.list[foundedIndex] = action.payload;

            state.active = (state.active.id === action.payload.id) ? action.payload : state.active;
            setItem('wineriesList', state.list)
            // TO DO: when client change wineriesList and activeWineryId remains the one of the previous logged client
            return state;
        },
        remove: (state, action) => {
            const foundedIndex = state?.list.findIndex((winery) => winery.id === action?.payload?.id);
            if (foundedIndex !== -1) {
                const removeActive = state.list[foundedIndex]?.id === state.active?.id;
                if (removeActive) {
                    clearSession('activeWineryId'); // TO DO: redirect to #wineriesList
                }

                state.list = state.list?.filter((w, index) => index !== foundedIndex);
                state.active = removeActive ? null : state.active;
                setItem('wineriesList', state.list);
                
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    set, 
    update,
    remove,
    empty,
    add,
    setActive,
    resetActive,
} = wineriesSlice.actions;

export default wineriesSlice.reducer