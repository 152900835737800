import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { deleteDrug, getDrugs } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { selectDrugs, selectExams } from "../../context/selectors";
import { Label } from "../FormComponents";
import SecondaryBar from "../SecondaryBar";
import { Button, ConfirmationModal, CTA } from "../Generic";

import "./styles/index.scss";
import { removeDrugs, setDrugs } from "../../context/substances/drugsSlice";
import { removeExams, setExams } from "../../context/substances/examsSlice";
import { toast } from "react-toastify";

const SubstanceInfo = ({ substance }) => {
  const [getLabel] = useLabels();

  return (
    <div className="substance_info_container">
      <div className="substance_info_container_row">
        <Label label={getLabel("drugName")}>{substance.name}</Label>
      </div>
      <div className="substance_info_container_row">
        <Label label={getLabel("drugType")}>
          {substance?.type
            ? getLabel("drugType_" + substance.type.toUpperCase())
            : ""}
        </Label>
      </div>
      <div className="substance_info_container_row">
        <Label label={getLabel("drugIdealQuantity")}>
          {substance?.quantity ? `${substance?.quantity} ${substance?.unit}`: ""}
        </Label>
        <Label label={getLabel("drugMinRange")}>
          {substance?.min_range ? substance?.min_range : ""}
        </Label>
        <Label label={getLabel("drugMaxRange")}>
          {substance?.max_range ? substance?.max_range : ""}
        </Label>
      </div>
      <div className="substance_info_container_row">
        <Label label={getLabel("drugMinTemperature")}>
          {substance?.min_temperature ? `${substance?.min_temperature} \u00b0C` : ""}
        </Label>
        <Label label={getLabel("drugMaxTemperature")}>
          {substance?.max_temperature ? `${substance?.max_temperature} \u00b0C` : ""}
        </Label>
      </div>
      <div className="substance_info_container_row">
        <Label width={400} height={150} label={getLabel("substanceNotes")} customClassName={"notes"}>
          {substance.notes}
        </Label>
      </div>
    </div>
  );
};

const SubstanceDetail = () => {
  const { id, type } = useParams();
  const substances = useSelector(
    type === "treatment" ? selectDrugs : selectExams
  );
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [substance, setSubstance] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const pageName = getLabel("drugsTitlePage", {
    name: substance?.substances_name || substance?.name || id || "",
  });

  useEffect(() => {
    if (!substances || substances.length === 0) {
      loadSubstances();
    }
  }, []);

  useEffect(() => {
    if (substances && substances.length > 0) {
      setSubstance(
        substances.find((dbSubstance) => {
          return Number(dbSubstance.id) === Number(id);
        }) || {}
      );
    }
  }, [substances, id]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await removeSubstance();
    handleCloseModal();
  };

  const loadSubstances = async () => {
    const currentSubstances = await getDrugs(axiosPrivate);
    dispatch(
      type === "treatment"
        ? setDrugs(currentSubstances)
        : setExams(currentSubstances)
    );
  };

  const removeSubstance = async () => {
    if (type !== "treatment") return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    const response = await deleteDrug(id, axiosPrivate);
    const requestPayload = {
      ...(id && { id: Number(id) }),
    };

    toast.update(toastId, {
      render: response?.error
        ? getLabel(response?.error)
        : getLabel(response?.success, { name: substance?.name || "" }),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && !response.error) {
      dispatch(removeDrugs(requestPayload));
      navigate(`/protocols/${type}`);
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate(`/protocols/${type}`);
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        <div className="primary_container_row">
          <NavLink to={`/substances/${type}/${id}/update`}>
            <CTA>{getLabel("updateDrug")}</CTA>
          </NavLink>

          <CTA onClick={handleOpenModal}>{getLabel("deleteDrug")}</CTA>
          <ConfirmationModal
            isOpen={isOpen}
            onConfirm={handleFormSubmit}
            onClose={handleCloseModal}
            description={getLabel("modalDeleteDrugDescription", {
              name: substance?.name || "",
            })}
          ></ConfirmationModal>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("drugsNavLink"),
          getLabel("drugTitlePage", { name: "" }),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      <SubstanceInfo substance={substance} />
    </div>
  );
};

export default SubstanceDetail;
