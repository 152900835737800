import { useDispatch, useSelector } from "react-redux";
import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import {
  selectActiveWinery,
  selectAnalysis,
  selectConfigs,
  selectLots,
  selectTanks,
  selectTreatments,
  selectUsers,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useMemo, useState } from "react";
import { addMonths, format } from "date-fns";
import { NavLink, useNavigate } from "react-router-dom";
import { BatchColumn, CTA, CellarmanColumn, TankColumn, Dropdown, BottlingColumn } from "../Generic";
import {
    getCompletedOperationsCount,
    getCompletedOperationsWithPaging,
  getLots,
  getPDF,
  getProtocols,
  getTanks,
  getUsers,
} from "../../services/utils";
import { setTanks } from "../../context/tanks/tanksSlice";
import { setUsers } from "../../context/users/usersSlice";
import { setOperations } from "../../context/operations/operationsSlice";
import { setLots } from "../../context/lots/lotsSlice";
import Table from "../Table";

import "./styles/index.scss";
import ReactDatePicker from "react-datepicker";
import TableEdit from "../TableEdit";
import TableCell from "../TableEdit/TableCell";
import { createColumnHelper } from "@tanstack/react-table";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMedia } from "react-use";

const OperationsHistory = () => {
  const isMobile = useMedia("(max-width: 480px)");
  const isTablet = useMedia("(min-width: 481px) and (max-width: 736px)");
  const [dates, setDates] = useState({
    startDate2: new Date(),
    startDate: addMonths(new Date(), -7),
    endDate: addMonths(new Date(), 3),
  });
  const [getLabel] = useLabels();
  const activeWinery = useSelector(selectActiveWinery);
  const users = useSelector(selectUsers);
  const tanks = useSelector(selectTanks);
  const analysis = useSelector(selectAnalysis);
  const treatments = useSelector(selectTreatments);
  const configs = useSelector(selectConfigs);
  const lots = useSelector(selectLots);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [completedOperations, setCompletedOperations] = useState([]);
  const [completedOperationsCount, setCompletedOperationsCount] = useState(1);
  const [offset, setOffset] = useState(0);
  
  const computeRecurrentString = (operation) => {
    if (!['ANALYSIS', 'TREATMENT'].includes(operation?.type)) return '';
    const arr = operation?.type === 'TREATMENT' ? treatments : analysis;
    const protocolName = arr?.find((p) => p?.protocols_id === operation?.protocol_id)?.protocols_name || '';
    const pName = protocolName?.length > 0 ? ` [${protocolName}]` : '';
  
    if (!operation?.repetitive) return ` #${operation?.id}${pName}`;
    
    const current = operation?.relative_id || 0;
    const total = operation?.total || 0;
    
    return (current > 0 && total > 0) ? ` #${operation?.main_id || operation?.id} (${current}/${total})${pName}` : '';
  }

  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const computeAdditionalTypeString = (operation) => {
    if (['CUT', 'DECANT', 'RACKING', 'ANALYSIS', 'TREATMENT', 'DESTEMMING', 'NEW_LOT'].includes(operation?.type)) return '';
    const protocolType = operation?.src_tanks?.[0]?.protocolType || null;
    const homogenizationType = protocolType === 'HOMOGENIZATION_REASSEMBLY' ? operation?.src_tanks?.[0]?.homogenizationType || null : null;
    
    // TO DO: aggiungi cappello o altro in omogenizzazione temperatura
    if (protocolType !== null && protocolType !== 'ADD') return ` (${getLabel(`protocolType_${protocolType}`)}${homogenizationType ? ` ${getLabel(`protocolHomogenizationMass_${homogenizationType}`)}` : ''}) `?.toLowerCase();
    return '';
  }

  useEffect(() => {
    if (!treatments || treatments.length === 0) {
      loadTreatments();
    }
     if (!analysis || analysis.length === 0) {
      loadAnalysis();
    }
  }, []);

  const loadAnalysis = async () => {
    const currentAnalysis = await getProtocols('analysis', axiosPrivate);
    dispatch(setAnalysis(currentAnalysis));
  };

  const loadTreatments = async () => {
    const currentTreatments = await getProtocols('treatment', axiosPrivate);
    dispatch(setTreatments(currentTreatments));
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) =>
        getLabel(`operationType${row.type}`) + computeAdditionalTypeString(row) +
        computeRecurrentString(row),
      {
        header: getLabel("operationType"),
        cell: TableCell,
      }
    ),
    columnHelper.accessor(
      (row) => getLabel(`operationPriorityLong${row.priority}`),
      {
        header: getLabel("priority"),
        cell: TableCell,
        sortingFn: 'PrioritySortingFn',
      }
    ),
    columnHelper.accessor("operationSrcTank", {
      header: getLabel("operationSrcTank"),
      cell: (props) =>
        props.row?.original?.type === 'NEW_LOT' ? (props.row?.original?.src_tanks?.[0]?.name || '')
         : (props.row?.original?.src_tanks?.find(
          (tank) => tank?.batch_id != null && ['ANALYSIS', 'TREATMENT'].includes(props.row?.original?.type) && !props.row?.original?.on_tank
        ) || props.row.original?.batch_id_in)? (
          <BatchColumn
            lots={lots}
            rowData={props.row?.original}
            type={props.row?.original?.type}
          />
        ) : (
          <TankColumn rowData={props.row?.original?.src_tanks} tanks={tanks} />
        ),
    }),
    columnHelper.accessor("operationDestTank", {
      header: getLabel("operationDestTank"),
      cell: (props) =>
          <TankColumn rowData={props.row?.original?.dest_tanks} tanks={tanks} />
    }),
    columnHelper.accessor(
      (row) =>
        row?.expire_date
          ? format(new Date(row.expire_date), sameDay(new Date(row?.expire_date), new Date()) ? configs.hourShortDateFormat : configs.shortDateFormat)
          : "",
      {
        // TO DO short date se repetitive nell stesso giorno
        header: getLabel("expiringDate"),
        cell: TableCell,
        meta: {
          isDate: true,
          iconType: 'fa-calendar-day',
        }
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.expire_date
          ? format(new Date(row.completion_date), configs.dateFormat)
          : "",
      {
        // TO DO short date se repetitive nell stesso giorno
        header: getLabel("operationCompletionDate"),
        cell: TableCell,
      }
    ),
    // {
    //   Header: getLabel("operationCompletionDate"),
    //   accessor: (row) =>
    //     row.completion_date
    //       ? format(new Date(row.completion_date), configs.shortDateFormat)
    //       : "-",
    // },
    // {
    //   Header: getLabel("operationNotes"),
    //   accessor: (row) => row.notes,
    // },
  ];

  const columns2 = useMemo(() => [
    {
      Header: getLabel("priority"),
      accessor: (row) => getLabel(`operationPriorityLong${row.priority}`),
    },
    {
      Header: getLabel("operationType"),
      accessor: (row) => getLabel(`operationType${row.type}`)+ (!row?.repetitive ? '' : computeRecurrentString(row))
    },
    {
      Header: getLabel("operationSrcTank"),
      accessor: (row) =>
        row?.type === "DESTEMMING" ? (
          <BatchColumn lots={lots} rowData={row} />
        ) : row?.type === 'BOTTLING' ? <BottlingColumn rowData={row?.src_tanks?.[0]} /> : (
          <TankColumn rowData={row?.src_tanks} tanks={tanks} />
        ),
    },
    {
      Header: getLabel("operationDestTank"),
      accessor: (row) => <TankColumn rowData={row?.dest_tanks} tanks={tanks} />,
    },
    {
      Header: getLabel("cellarman"),
      accessor: (row) => (
        <CellarmanColumn rowData={row?.cellarman_ids} users={users} />
      ),
    },
    {
      Header: getLabel("expiringDate"),
      accessor: (row) =>
        row.expire_date
          ? format(new Date(row.expire_date), configs.shortDateFormat)
          : "",
    },
    {
      Header: getLabel("operationCompletionDate"),
      accessor: (row) =>
        row.completion_date
          ? format(new Date(row.completion_date), configs.shortDateFormat)
          : "-",
    },
    // {
    //     Header: getLabel('operationNotes'),
    //     accessor: (row) => row.notes
    // }
  ]);

  const loadCompletedOperations = async () => {
    const completedOperationsRecordsCount = await getCompletedOperationsCount(axiosPrivate, dates.startDate, dates.endDate);
    setCompletedOperationsCount(completedOperationsRecordsCount);

    const completedOps = await getCompletedOperationsWithPaging(axiosPrivate, dates.startDate, dates.endDate, 50, offset);
    setCompletedOperations([...new Map([...completedOperations, ...completedOps]?.map(item =>
      [item['id'], item])).values()]);
  };

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers));
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const initData = async () => {
    const promiseArray = [];
    if (users?.length === 0) {
      promiseArray.push(loadUsers());
    }
    if (tanks?.length === 0) {
      promiseArray.push(loadTanks());
    }
    if (lots?.length === 0) {
      promiseArray.push(loadLots());
    }
    
    loadCompletedOperations();

    return Promise.all(promiseArray);
  };
  
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    loadCompletedOperations();
  }, [dates, offset]);

  const requestPDF = async (file_type = 'PDF') => {
    const startDate = new Date(dates?.startDate)?.setHours(0, 0, 0);
    const endDate = new Date(dates?.endDate)?.setHours(23, 59, 59);
    
    const data = {
      pdf_type: "history",
      type: "operations",
      title: getLabel("operations"),
      additionalData: {
        from_date: new Date(startDate).toISOString(),
        to_date: new Date(endDate).toISOString(),
      },
      rows: [],
      columns: [
        getLabel("priority"),
        getLabel("operationType"),
        getLabel("operationSrcTank"),
        getLabel("operationDestTank"),
        getLabel("cellarman"),
        getLabel("expiringDate"),
        // getLabel("operationNotes"),
      ],
      headers: [
        "priority",
        "type",
        "src_tanks",
        "dest_tanks",
        "cellarman_ids",
        "expire_date",
        // "note",
      ],
      winery_name: activeWinery?.name || "",
      order_by: "expire_date",
      order_sort: "desc",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language:
        navigator.languages?.length > 0
          ? navigator.languages[0]?.replace("_", "-")
          : "it-IT",
    };
    await getPDF(data, axiosPrivate, 'history', file_type);
  };

  const renderNavMenu = () => {
    return (
      <div className="primary_container_menu ctas">
        <div className="primary_container_row">
          {/* <CTA onClick={requestPDF}>{getLabel("print")}</CTA> */}
          <Dropdown
              label={isMobile ? <FontAwesomeIcon icon="fa-print" /> : getLabel("print")}
              options={[
                {
                  label: getLabel("operationPrintPdf"),
                  handler: () => requestPDF('PDF'),
                },
                {
                  label: getLabel("operationPrintExcel"),
                  handler: () => requestPDF('EXCEL'),
                }
              ]}
            />
        </div>
      </div>
    );
  };

  const renderInterval = () => {
    return (
      <div className="interval_row">
        <div className="interval_value">
          <span>{getLabel("dateFrom")}</span>
          <ReactDatePicker
            selected={dates.startDate}
            onChange={(date) =>
              setDates({
                ...dates,
                startDate: date.setHours(0, 0, 0),
              })
            }
            selectsStart
            startDate={dates.startDate}
            endDate={dates.endDate}
            dateFormat={configs.shortDateFormat}
          />
          <span>{getLabel("dateTo")}</span>
          <ReactDatePicker
            selected={dates.endDate}
            onChange={(date) =>
              setDates({
                ...dates,
                endDate: date.setHours(23, 59, 59),
              })
            }
            selectsEnd
            startDate={dates.startDate}
            endDate={dates.endDate}
            minDate={dates.startDate}
            dateFormat={configs.shortDateFormat}
          />
        </div>
      </div>
    );
  };

  const updateOffset = (offset) => {
    setOffset(offset);
  };

  const renderList = () => {
    if (completedOperations.length > 0) {
      return (
        <div className="operations_list_table">
          {/* <Table
            initialState={{
                pageSize: 10,       // must match lower value of [10, 20, 30, 40, 50]
                pageIndex: 0
            }}
            totalRows={completedOperationsCount}
            setPagination={true}
            data={completedOperations}
            prefix="operations"
            customColumns={columns2}
            rowClickHandler={(row) => {
              navigate(`/programming/${row.original.type}/${row.original.id}`);
            }}
            updateOffset={updateOffset}
          /> */}
          <TableEdit
              defaultData={completedOperations?.filter((o) => new Date(o?.completion_date) >= dates?.startDate && new Date(o?.completion_date) <= dates?.endDate)}
              columns={columns}
              // errors={errors}
              // control={control}
              isEditable={false}
              isSearchBar={false}
              isPagination={true}
              rowClickHandler={(row) => {
                navigate(`/programming/${row.original.type}/${row.original.id}`);
              }}
              // setValue={setValue}
              // type={"winemaking"}
              // modalHandler={handleOpenModal}
              // setModifyMode={setModifyMode}
            />
        </div>
      );
    }
  };

  return (
    <div className="primary_container">
      <SecondaryBar breadCrumb={[getLabel("programmingNavLink")]} />
      {renderNavMenu()}
      {renderInterval()}
      {renderList()}
    </div>
  );
};

export default OperationsHistory;
