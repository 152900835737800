import { format } from "date-fns";
import useLabels from "../../hooks/useLabels";
import { ChatArea, Input, Label } from "../FormComponents";
import { Player } from "@lottiefiles/react-lottie-player";
import json_arrow_black from "../../assets/lottieJson/black_arrow.json";
import { useEffect, useState } from "react";
import AnalysisEvolution from "../AnalysisEvolution/AnalysisEvolutions";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import { OperationRecap } from "../Generic";
import { empty } from "../../context/operations/operationsSlice";
import { toast } from "react-toastify";
import { addOperationNote } from "../../services/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { socket } from "../../socket";
import { type } from "@testing-library/user-event/dist/type";
// import QRcodeReader from "../QRcodeReader";

const getWineColor = (color) => {
  switch (color) {
    case "WHITE":
      return "white";
    case "ROSE":
      return "rose";
    default:
      return "red";
  }
};

const getRecapTanks = (tanks, operation, isSrc = true) => {
  const tankList = [];

  const isCompleted = operation?.completion_date !== null;
  const tmpTanks = (isSrc ? operation?.src_tanks : operation?.dest_tanks) || [];
          
  tmpTanks?.forEach((t) => {
  
      if (isCompleted) {
        if (isSrc)
          tankList.push({ tankData: {...t?.postOperation?.tank, lot: t?.postOperation?.tank?.batch_name, quantity: t?.quantity - t?.postOperation?.tank?.arrow_quantity }, new_quantity: String(t?.postOperation?.tank?.quantity), toRemove: t?.postOperation?.tank?.arrow_quantity, quantity: Math.abs(t?.postOperation?.tank?.arrow_quantity), capacity: t?.postOperation?.tank?.capacity, lot: t?.postOperation?.tank?.batch_name, name: t?.postOperation?.tank?.name, color: t?.postOperation?.tank?.color || t?.postOperation?.batch?.color })
        else {
          tankList.push({ tankData: {...t?.postOperation?.tank, quantity: t?.postOperation?.tank?.old_quantity, value: true }, quantity: t?.new_quantity - (operation?.type === 'RACKING' ? t?.postOperation?.tank?.drop : 0), batch_name_selector: { value: false, label: t?.new_batch_name}, drop: 0*t?.postOperation?.tank?.drop, ...(operation?.type === 'RACKING' && { batch_type: t?.batch_type }), lot: t?.postOperation?.tank?.batch_name, color: t?.postOperation?.tank?.color || t?.postOperation?.batch?.color })
        }
        if (isSrc) console.log(t);//{ quantity: t?.quantity - (operation?.type === 'RACKING' ? t?.postOperation?.tank?.drop : 0), drop: t?.postOperation?.tank?.drop })
        // TO DO RACKING con cali non funziona, forse non va mai quando ci sono cali
      } else {
        const tank = tanks?.find((s) => s?.id === t?.tank_id);
        if (isSrc) {
          if (operation?.type === 'CUT') tankList.push({ tankData: { ...tank, quantity: Number(t?.preOperation?.tank?.old_quantity), color: t?.preOperation?.batch?.color }, ...tank, quantity: Number(t?.quantity) })
          else tankList.push({ tankData: {...tank }, ...tank })
        } else {
          // TO DO fix batch_type in RACKING and what if src is not emptied by the operation?
          if (operation?.type === 'CUT') tankList.push({ tankData: {...tank, toAdd: t?.quantity, quantity: Number(tank?.quantity), value: true, color: operation?.src_tanks?.[0]?.preOperation?.batch?.color }, quantity: t?.quantity, batch_name_selector: { value: false, label: t?.new_batch_name}, lot: tank?.batch_name })
          else tankList.push({ tankData: {...tank, toAdd: t?.quantity, quantity: Number(tank?.quantity), value: true }, quantity: t?.quantity, batch_name_selector: { value: false, label: t?.new_batch_name}, ...(operation?.type === 'RACKING' && { batch_type: t?.batch_type }), lot: tank?.batch_name, color: tank?.color })
        }
      }
    })
    
  return tankList;
}

export const DestLotDetail = ({
  lot,
  operation,
  toRemove = null,
  isName = true,
  isRecap = false,
  isDestemming = false,
  isNewLot = false,
  isAdditional = false,
}) => {
  const [getLabel] = useLabels();
  
  const getAdditional = () => {
    const lot = operation?.src_tanks?.[0]?.preOperation?.batch;
    
    if (!lot) return <p></p>;
  
    const additional = [];
    for (let i = 0; i < lot?.varieties?.length; i++) {
      const v = lot?.varieties?.[i] ? getLabel(`lotVarieties_${lot?.varieties?.[i]}`) : '';
      const y = lot?.years?.[i] ? ` (${lot?.years?.[i]})` : '';
      const o = lot?.origins?.[i] ? `, ${lot?.origins?.[i]}` : '';
      additional.push(`${v} ${y}${o}`);
    }

    return (
      <div className="additional">{additional?.map((a, i) => { return <p key={`additional_${i}`}>{a}</p>})}</div>
    )
  }

  return (
    <>
      {isName && (
        <div className="tank_name">
          {lot?.name || ''}
        </div>
      )} 
      {!isDestemming && <><div className="init_status">
        <div className="tank_header">
          <p>{getLabel('operationDetailBefore')}</p>
        </div>
        <div className="lot_representation">
          <div
            className={
              "lot_progress_" + getWineColor(lot?.color)
            }
            style={{
              height: `${
                (Number(lot?.old_quantity) / Number(lot?.initial_quantity)) * 100
              }%`,
            }}
          ></div>
        </div>
        <div>
          {lot?.old_quantity || 0} / {lot?.initial_quantity}Kg (
          {Math.round(
            ((Number(lot?.old_quantity) || 0) * 100) / Number(lot?.initial_quantity)
          )}
          %)
        </div>
      </div>
      <div className="arrow">
        {`-${Number(
          lot?.arrow_quantity
        )} ${isNewLot ? 'L' : 'Kg'}`}
        <Player
          autoplay={false}
          controls={false}
          hover={false}
          src={json_arrow_black}
          style={{ width: "24px", height: "24px", transform: "rotate(180deg)" }}
        />
      </div></>}
      <div className={`end_status ${isRecap ? 'unset-width' : ''}`}>
        {!isRecap && <div className="tank_header">
            <p>{getLabel('operationDetailAfter')}</p>
        </div>}
      {isDestemming && <h6 className="bold centered">{lot?.label}</h6>}
        <div className="lot_representation">
          <div
            className={
              "lot_progress_" + getWineColor(lot?.color)
            }
            style={{
              height: `${
                ((Number(lot?.quantity) - (isRecap && toRemove > 0 ? Number(toRemove) : 0)) / Number(lot?.initial_quantity)) * 100
              }%`,
            }}
          ></div>
        </div>
        <div>
          {(Number(lot?.quantity) - (isRecap && toRemove > 0 ? Number(toRemove) : 0)) || 0} / {lot?.initial_quantity}
          {isNewLot ? "L" : "Kg"} (
          {Math.round(
            ((Number(lot?.quantity)  - (isRecap && toRemove > 0 ? Number(toRemove) : 0) || 0) * 100) / Number(lot?.initial_quantity)
          )}
          %)
          {isRecap && toRemove > 0 && <h6 className="bold">{`-${Number(toRemove) || 0} Kg`}</h6>}
        </div>
      </div>
      {isAdditional && getAdditional()} 
    </>
  );
};

export const DestBottlingDetail = ({
  bottleLot,
  capLot,
  batches = [],
  srcBatches = [],
}) => {
  const [getLabel] = useLabels();

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "rgb(249, 213, 115)";
      case "ROSE":
        return "rgb(199, 79, 89)";
      default:
        return "#64242e";
    }
  };

  return (
    <div className="bottling">
      <div className="box_icons">
        {batches?.map((batch, index) => {
          const srcBatch = srcBatches?.find((b) => b?.batch_id === batch?.batch_id)?.preOperation?.batch || {};
          return (
            <div key={`batch-${index}`} className="box_item">
              <p className="batch_name">{batch?.new_batch_name}</p>
              <FontAwesomeIcon icon="fa-wine-bottle" className="bottle_icon" style={{color: getWineColor(srcBatch?.color)}} />
              <FontAwesomeIcon icon="fa-box" style={{color: 'rgb(157, 126, 104)'}} size={'4x'}/>
            </div>
          );
        })}
      </div>
      <div className="icons">
        <div className="cap_icon">
          <FontAwesomeIcon icon="fa-bread-slice" style={{color: 'rgb(157, 126, 104)'}} size={'sm'}/>
          <p className="line"></p>
          <p className="bold-p">{getLabel('capLot')}<span className="value-p">{capLot || '-'}</span></p>
        </div>

        <div className="bottle_icon">
          <FontAwesomeIcon icon="fa-wine-bottle" className="icon_rotate" style={{color: getWineColor(srcBatches?.[0]?.preOperation?.batch?.color)}} size={'4x'}/>
          <p className="line"></p>
          <p className="bold-p">{getLabel('bottleLot')}<span className="value-p">{bottleLot || '-'}</span></p>
        </div>
      </div>
    </div>
  );
};

export const DestTankDetail = ({
  tank,
  color,
  operation,
  isName = true,
  isDrop = true,
  isFirst = false
}) => {
  const [getLabel] = useLabels();
  
  return (
    <div className="tanks_wrapper">
      {isName && (
        <div className="tank_name">
          {tank?.name}
          {/* {operation?.batch_name_out && <p className="batch_name">{operation?.batch_name_out}</p>} */}
          {/* {tank?.new_batch_name && (
            <p className="batch_name">{tank?.new_batch_name}</p>
          )} */}
        </div>
      )}
      <div className="init_status">
        <div className="tank_header">
          {isFirst && <p>{getLabel('operationDetailBefore')}</p>}
        </div>
          {/* {tank?.old_batch_name && (
            <p className="batch_name">{tank?.old_batch_name}</p>
          )} */}
          <p className="batch_name">{(Number(tank?.old_quantity) > 0 ? tank?.old_batch_name : getLabel("tankState_EMPTY")) || getLabel("tankState_EMPTY")}</p>
          <div className="tank_representation">
          <div
            className={
              "tank_tile_progress_" + getWineColor(color || tank?.color)
            }
            style={{
              height: `${
                (Number(tank?.old_quantity) / Number(tank?.capacity)) * 100
              }%`,
            }}
          ></div>
        </div>
        <div>
          {tank?.old_quantity || 0} / {tank?.capacity}L (
          {Math.round(
            ((Number(tank?.old_quantity) || 0) * 100) / Number(tank?.capacity)
          )}
          %)
        </div>
      </div>
      <div className="arrow">
        {`${Number(tank?.arrow_quantity) > 0 ? "+" : "-"}${Number(
          tank?.arrow_quantity
        )} L`}
         {/* {operation?.type === 'DESTEMMING' ? 'KG' : 'L'} */}
        <Player
          autoplay={false}
          controls={false}
          hover={false}
          src={json_arrow_black}
          style={{ width: "24px", height: "24px", transform: "rotate(180deg)" }}
        />
      </div>
      <div className="end_status">
        <div className="tank_header">
          {isFirst && <p>{getLabel('operationDetailAfter')}</p>}
        </div>
        {tank?.batch_name && (
            <p className="batch_name bold">{tank?.batch_name}</p>
          )}
        {String(tank?.batch_name)?.length === 0 && (
          <p className="no_name"></p>
        )}
        {operation?.type === 'RACKING' && tank?.batch_type && <span className="batch_name">{getLabel(`lotType_${tank?.batch_type}`)}</span>}
        <div className="tank_representation">
          <div
            className={
              "tank_tile_progress_" + getWineColor(color || tank?.color)
            }
            style={{
              height: `${
                (Number(tank?.quantity) / Number(tank?.capacity)) * 100
              }%`,
            }}
          ></div>
        </div>
        <div>
          {Number(tank?.quantity) || 0} / {tank?.capacity}
          {"L"} (
          {Math.round(
            ((Number(tank?.quantity) || 0) * 100) / Number(tank?.capacity)
          )}
          %)
        </div>
      </div>
      {isDrop && operation?.completion_date !== null && (
        <div className="operation_info_container_row">
          <p className="tank_name">
            {getLabel("operationDrop")}
            <span className="batch_name">
              {" "}
              {`${
                tank?.drop || 0
              }`} {operation?.type === 'DESTEMMING' ? 'Kg' : 'L'}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

const SrcTankDetail = ({ tank, lot, data, isCurrentStateOnly = false, totalDrug }) => {
  const [getLabel] = useLabels();
  let wineColor = "red";
  switch (lot?.color || tank?.color) {
    case "WHITE":
      wineColor = "white";
      break;
    case "ROSE":
      wineColor = "rose";
      break;
    default:
      wineColor = "red";
      break;
  }
  
  return (
    <div className="tanks_wrapper">
      <div className="tank_name">
        {tank?.name}
        {/* {tank?.batch_name && <p className="batch_name">{Number(tank?.quantity) > 0 ? tank?.batch_name || getLabel("tankState_EMPTY") : getLabel("tankState_EMPTY")}</p>} */}
        {/* <div>{operation?.batch_name_out && <div className="batch_name">{operation?.batch_name_out}</div>}</div> */}
      </div>
      <div className="init_wrapper">
        <div className={`init_status ${['TONNEAU', 'BARRIQUE'].includes(tank?.type) ? 'barrel' : ''} ${tank?.type === 'TONNEAU' ? 'tonneau' : ''}`}>
          {tank?.old_batch_name && <p className={`batch_name ${isCurrentStateOnly ? 'bold' : ''}`}>{Number(tank?.old_quantity) > 0 ? tank?.old_batch_name || getLabel("tankState_EMPTY") : getLabel("tankState_EMPTY")}</p>}
          <div className={`tank_representation ${['TONNEAU', 'BARRIQUE'].includes(tank?.type) ? 'barrel' : ''} ${tank?.type === 'TONNEAU' ? 'tonneau' : ''}`}>
            <div
              className={"tank_tile_progress_" + wineColor}
              style={{
                height: `${
                  (Number(tank?.old_quantity) / Number(tank?.capacity)) * 100
                }%`,
              }}
            ></div>
          </div>
          <div>
            {tank?.old_quantity || 0} / {tank?.capacity}L (
            {Math.round(
              ((Number(tank?.old_quantity) || 0) * 100) /
                Number(tank?.capacity)
            )}
            %)
          </div>
        </div>
          {totalDrug}
      </div>
      {!isCurrentStateOnly && (
        <>
          <div className="arrow">
            {`-${Number(tank?.arrow_quantity)} L`}
            <Player
              autoplay={false}
              controls={false}
              hover={false}
              src={json_arrow_black}
              style={{
                width: "24px",
                height: "24px",
                transform: "rotate(180deg)",
              }}
            />
          </div>
          <div className={`end_status ${['TONNEAU', 'BARRIQUE'].includes(tank?.type) ? 'barrel' : ''} ${tank?.type === 'TONNEAU' ? 'tonneau' : ''}`}>
            {tank?.batch_name && <p className="batch_name bold">{Number(tank?.quantity) || 0 + Number(tank?.toAdd) || 0  > 0 ? tank?.batch_name || getLabel("tankState_EMPTY") : getLabel("tankState_EMPTY")}</p>}
            <div className={`tank_representation ${['TONNEAU', 'BARRIQUE'].includes(tank?.type) ? 'barrel' :''} ${tank?.type === 'TONNEAU' ? 'tonneau' : ''}`}>
              <div
                className={"tank_tile_progress_" + wineColor}
                style={{
                  height: `${
                    ((Number(tank?.quantity) || 0) /
                      Number(tank?.capacity)) *
                    100
                  }%`,
                }}
              ></div>
            </div>
            <div>
              {Number(tank?.quantity) || 0}{" "}
              / {tank?.capacity}
              {"L"} (
              {Math.round(
                ((Number(tank?.quantity) || 0) *
                  100) /
                  Number(tank?.capacity)
              )}
              %)
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const BatchDetail = ({ lot }) => {
  const [getLabel] = useLabels();

  return (
    <>
      <Label label={getLabel("lot")}>{lot?.name}</Label>
    </>
  );
};

export const DestemmingDetail = ({
  operation,
  users,
  tanks,
  configs,
  // batchData,
  register,
  errors,
  getValues,
  setValue,
  axiosPrivate,
  dispatch,
  user_id,
  isNewLot = false,
  userSockets,
}) => {
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const [completedState, setCompletedState] = useState([]);
  
  const setCheckboxState = (tank_id) => {
    const newCompletedState = [...completedState];
    const index = newCompletedState.indexOf(tank_id);
    if (index === -1) {
      newCompletedState.push(tank_id);
    } else {
      newCompletedState.splice(index, 1);
    }
    setCompletedState(newCompletedState);
  };

  const getTankData = (tank) => {
    const tankData = ((operation?.completion_date === null) ? tank?.preOperation?.tank : tank?.postOperation?.tank) || null;
    const color = (((operation?.completion_date === null) ? tank?.preOperation?.batch : tank?.postOperation?.batch)?.color) || operation?.src_tanks?.[0]?.color || null;
    
    return {...tankData, color: color };
  };

  return (
    <>
      <h4>{getLabel('operationDetail')}</h4>
      <div className={`operation_info_container_row ${operation?.cellarman_ids?.length > 1 ? 'start' : ''}`}>
        <Label label={getLabel("insertionDate")}>
          {format(
            new Date(operation?.insertion_date || null),
            configs.shortDateFormat
          )}
        </Label>
        {operation?.completion_date === null && (
          <>
            <Label label={getLabel("expireDate")}>
              {format(
                new Date(operation?.expire_date || null),
                configs.shortDateFormat
              )}
            </Label>
            <Label label={getLabel("cellarman")}>
              {
                <div className="cellarman_container">
                  {operation?.cellarman_ids?.map((cellarman, index) => {
                    const cellarmanName =
                      users?.find?.(
                        (user) => Number(user.id) === Number(cellarman)
                      )?.username || "";
                    return (
                      <div key={`cellarman-${index}`}>
                        {cellarmanName +
                          (operation?.cellarman_ids?.length > 1 ? " " : "")}
                      </div>
                    );
                  })}
                </div>
              }
            </Label>
          </>
        )}
        {operation?.completion_date && (
          <>
            <Label label={getLabel("operationCompletionUser")}>
              <div>
                {users?.find?.(
                  (user) => Number(user.id) === Number(operation?.completed_by)
                )?.username || ""}
              </div>
            </Label>
            <Label label={getLabel("operationCompletionDate")}>
              {format(
                new Date(operation?.completion_date || null),
                configs.dateFormat
              )}
            </Label>
          </>
        )}
        <Label label={getLabel("operationPriority")}>
          {getLabel(`operationPriority${operation?.priority}`)}
        </Label>
        {/* <Label label={getLabel("lotType")}>
          {operation?.batch_type ? getLabel(`lotType${operation?.batch_type}`) : ''}
        </Label> */}
      </div>
      <h4>{getLabel(isNewLot ? "fromLotExternal" : "fromLot")}</h4>
      <div className="operation_info_container_row">
        <div className="tanks_wrapper">
          {!isNewLot && (
            <DestLotDetail
              lot={operation?.completion_date === null ? operation?.src_tanks?.[0]?.preOperation?.batch : operation?.src_tanks?.[0]?.postOperation?.batch}
              isAdditional={true}
              // data={destTank}
              operation={operation}
            />
          )}
          {isNewLot && (
            <>
              <Label label={getLabel("lotNameExternal")}>
                {operation?.src_tanks?.[0]?.name || ""}
              </Label>
              <Label label={getLabel("quantity")}>
                {operation?.src_tanks?.[0]?.quantity || 0} L
              </Label>
            </>
          )}
        </div>
      </div>
      
      {isNewLot && (
        <div className="operation_info_container_row">
          {operation?.src_tanks?.[0]?.label &&
            operation?.src_tanks?.[0]?.label?.length > 0 && (
              <Label label={getLabel("lotLabel")}>
                {operation?.src_tanks?.[0].label?.map((lab, i) => {
                  return (
                    <p key={`label-${i}`}>{getLabel(`lotLabel_${lab}`)}</p>
                  );
                }, "") || ""}
              </Label>
            )}
          <Label label={getLabel("lotVarieties")}>
            {operation?.src_tanks?.[0]?.varieties?.map((v, i) => {
              return (
                <p key={`variety-${i}`}>
                  {v ? getLabel(`lotVarieties_${v}`) : ""}
                </p>
              );
            }, "") || ""}
          </Label>
          <Label label={getLabel("lotYears")}>
            {operation?.src_tanks?.[0]?.years?.map((y, index) => {
              return (
                <p key={`year-${index}`}>
                  {y
                    ? y
                    : index > 0
                    ? operation?.src_tanks?.[0]?.years[index - 1]
                    : ""}
                </p>
              );
            }, "") || ""}
          </Label>
          <Label label={getLabel("lotOrigins")}>
            {operation?.src_tanks?.[0].origins?.map((o, i) => {
              return <p key={`origin-${i}`}>{o}</p>;
            }, "") || ""}
          </Label>
          <Label label={getLabel("lotQuality")}>
            {getLabel(`lotQuality_${operation?.src_tanks?.[0].quality}`)}
          </Label>
          {operation?.src_tanks?.[0]?.color && (
            <Label label={getLabel("lotColor")}>
              {getLabel(`lotColor_${operation?.src_tanks?.[0].color}`)}
            </Label>
          )}
          {operation?.src_tanks?.[0]?.state && (
            <Label label={getLabel("lotState")}>
              {getLabel(`lotState_${operation?.src_tanks?.[0].state}`)}
            </Label>
          )}
        </div>
      )}
      <h4>{getLabel("toTanks")} </h4>
      <ul>
        {operation?.dest_tanks?.map((destTank, idx) => {
          return (
            <li key={`dest-${idx}`}>
              <div className="operation_info_container_row">
                <DestTankDetail
                  tank={getTankData(destTank)}
                  // color={batchData?.color}
                  // data={destTank}
                  isDrop={operation?.completion_date !== null}
                  operation={operation}
                  isFirst={idx === 0}
                ></DestTankDetail>
                {operation?.completion_date === null &&
                  (role === 3 ||
                    (role === ROLES.CLIENT &&
                      users?.filter((u) => u?.type === "USER")?.length ===
                        0)) && (
                    <>
                      <Input
                        name={`completed.${destTank.tank_id}`}
                        register={register}
                        error={errors[`completed.${destTank.tank_id}`]}
                        required={getLabel("inputRequiredError")}
                        isLabel={false}
                        type={"checkbox"}
                        checked={completedState.includes(destTank.tank_id)}
                        onChange={() => setCheckboxState(destTank.tank_id)}
                        customClassName={'confirm'}
                      />
                      {getValues(`completed.${destTank.tank_id}`) && (
                        <Input
                          name={`drop.${destTank.tank_id}`}
                          label={getLabel("operationDrop")}
                          placeholder={getLabel("operationDropKgPlaceholder")}
                          register={register}
                          error={errors[`drop`]?.[destTank.tank_id]}
                          required={getLabel("inputRequiredError")}
                          type={"number"}
                          min={{
                            value: 0,
                            message: getLabel("errorMinValue", { value: 0 }),
                          }}
                          max={{
                            value: destTank.quantity - 1, // TO DO: aggiustare coefficiente
                            message: getLabel("errorMaxValue", {
                              value: destTank.quantity - 1,
                            }),
                          }}
                        />
                      )}
                    </>
                  )}
              </div>
            </li>
          );
        })}
      </ul>
      {/* <h4>{getLabel("operationPomaces")} </h4>
      <ul>
        <div>
          <ul>
            {operation?.pomaces?.map((pomace) => {
              return (
                <li>
                  <div>
                    {pomace.dest}: {pomace.quantity || 0}%
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </ul> */}
      {/* <div className="operation_info_container_row">
        <Label label={getLabel("operationNotes")} width={400} height={150} customClassName={"notes"}>
          {operation?.note}
        </Label>
      </div> */}
      <div className="operation_info_container_row">
        <ChatArea
          name="chat"
          register={register}
          label={getLabel("lotNotes")}
          placeholder={getLabel("lotNotesPlaceholder")}
          users={users}
          user_id={user_id}
          // tooltip={getLabel("lotNotesTooltip")}
          configs={configs}
          operation={operation}
          storeNote={() =>
            storeNote(
              axiosPrivate,
              dispatch,
              setValue,
              getValues,
              getLabel,
              operation,
              user_id,
              userSockets,
              users,
            )
          }
        />
      </div>
      <div className="operation_info_container_row additional">
        <Label label={getLabel("demandant")}>
          {users?.find?.(
            (user) => Number(user.id) === Number(operation?.user_id)
          )?.username || ""}
        </Label>
        <Label label={getLabel("updateDate")}>
          {format(new Date(operation?.update_date || null), configs.dateFormat)}
        </Label>
      </div>
    </>
  );
};

export const TreatmentDetails = ({
  operation,
  register,
  users,
  tanks,
  lots,
  errors,
  treatments,
  configs,
  user_id,
  dispatch,
  setValue,
  getValues,
  axiosPrivate,
  userSockets,
}) => {
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const [completedState, setCompletedState] = useState([]);

  const setCheckboxState = (tank_id) => {
    const newCompletedState = [...completedState];
    const index = newCompletedState.indexOf(tank_id);
    if (index === -1) {
      newCompletedState.push(tank_id);
    } else {
      newCompletedState.splice(index, 1);
    }
    setCompletedState(newCompletedState);
  };
  const isBatchTreatment = operation?.on_tank === false;
  const [drugsData, setDrugsData] = useState([]);

  const renderTotal = (tank_id) => {
    const mainSrc = operation?.src_tanks?.[0] || null;
    if (mainSrc === null) return '';
    const liters = operation?.src_tanks?.find((t) => t?.tank_id == tank_id)?.preOperation?.tank?.quantity || (tanks?.find((t) => Number(t.id) === Number(tank_id)))?.quantity || 0;
      
    const totalDrugs = [];
    operation?.treatments?.forEach((drug) => {
      const productName =  drug?.substance_name || null;
      const productQuantity =  Number(drug?.quantity) || 0;
      const unit_id = drug?.unit_id || null;
      if (liters === 0 || productQuantity === 0 || unit_id === null || productName === null) return false;
      let totalProductToAdd = 0;
      switch (unit_id) {
        case 1: // mg/L
          totalProductToAdd =
            parseFloat(liters * productQuantity).toFixed(2) + " mg";
          break;
        case 2: // g/hL
          totalProductToAdd =
            parseFloat((liters / 100) * productQuantity).toFixed(2) + " g";
          break;
        case 3: // L
          break;
        case 4: // Kg
          break;
        case 5: // " "
          break;
        default:
          break;
      }
      if (totalProductToAdd && !drug?.removed) totalDrugs.push(totalProductToAdd + ` ${productName}`);
    });
    
    return (
      <div className="totalDrugWrapper">
        {getLabel("drugTotal")}{" "}
        <ul>
          {totalDrugs?.map((totalDrug, index) => {return <li  key={`total_drug_${index}`} className="totalDrug">{totalDrug}{index === totalDrugs?.length - 1 ? '' : ', '}</li>})}
        </ul>
      </div>
    );
  };
  
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("substance_name", {
      header: getLabel("treatmentName"),
      cell: TableCell,
      meta: {
        isFilterDisabled: true,
      }
    }),
    columnHelper.accessor((row) => `${row?.quantity} ${row?.unit || ""}`, {
      header: getLabel("quantity"),
      cell: TableCell,
      meta: {
        isFilterDisabled: true,
      },
    }),
    // columnHelper.accessor("unit", {
    //   header: getLabel("unit"),
    //   cell: TableCell,
    // }),
    columnHelper.accessor(
      (row) => (row?.type ? getLabel(`drugType_${row.type?.toUpperCase()}`) : ""),
      {
        header: getLabel("drugType"),
        cell: TableCell,
        meta: {
          type: "text",
          isFilterDisabled: true,
          filterVariant: 'text',
        },
      }
    ),
    columnHelper.accessor(
      (row) =>
        parseInt(row?.min_range) >= 0 && parseInt(row?.max_range) >= 0
          ? `${String(row?.min_range) || "0"} - ${
              String(row?.max_range) || ""
            } ${row?.unit}`
          : "",
      {
        header: getLabel("substanceRange"),
        cell: TableCell,
        meta: {
          type: "number",
          isFilterDisabled: true,
        },
      }
    ),
    columnHelper.accessor(
      (row) =>
        parseInt(row?.min_temperature) && parseInt(row?.max_temperature)
          ? `${row?.min_temperature || ""} - ${
              row?.max_temperature || ""
            } \u00b0C`
          : "",
      {
        header: getLabel("substanceTemperature"),
        cell: TableCell,
        meta: {
          type: "number",
          isFilterDisabled: true,
        },
      }
    ),
  ];

  useEffect(() => {
    const tmp = [];
    treatments?.drugs?.forEach((treatment) => {
      const newQuantity = operation?.treatments?.find(
        (o) => o?.substance_id == treatment?.substance_id
      )?.quantity;
      tmp.push({ ...treatment, quantity: newQuantity || treatment?.quantity });
    });

    setDrugsData(tmp);
  }, [treatments, operation]);

  const getTankData = (tank) => {
    // const tankData = ((operation?.completion_date === null) ? tank?.preOperation?.tank : tank?.postOperation?.tank) || {};
    // const color = (((operation?.completion_date === null) ? tank?.preOperation?.batch : tank?.postOperation?.batch)?.color) || null;
    if (operation?.completion_date !== null) {
      const tankData = tank?.postOperation?.tank || {};
      const color = tank?.postOperation?.batch?.color || null;
      return {...tankData, color: color };
    } else {
      const t = tanks?.find((t) => t?.id === Number(tank?.tank_id));
      return {...t, old_batch_name: t?.batch_name, old_quantity: t?.quantity };
    }
  };

  return (
    <>
      <h4>{getLabel("treatmentProtocols")}</h4>
      <div className="operation_info_container_row">
        <Label label={operation?.completion_date === null ? getLabel("insertionDate") : getLabel("operationCompletionDate")}>
          {format(
            new Date(operation?.insertion_date || null),
            operation?.completion_date === null ? configs.shortDateFormat : configs.dateFormat
          )}
        </Label>
        <Label label={getLabel("expireDate")}>
          {format(
            new Date(operation?.expire_date || null),
            configs.shortDateFormat
          )}
        </Label>
        <Label label={getLabel("cellarman")}>
          {
            <div className="cellarman_container">
              {operation?.cellarman_ids?.map((cellarman, index) => {
                const cellarmanName =
                  users?.find?.((user) => Number(user.id) === Number(cellarman))
                    ?.username || "";
                return (
                  <div key={`cellarman-${index}`}>
                    {cellarmanName +
                      (operation?.cellarman_ids?.length > 1 ? " " : "")}
                  </div>
                );
              })}
            </div>
          }
        </Label>
        <Label label={getLabel("protocolName")}>
          {treatments?.protocols_name}
        </Label>
      </div>
      {/* <h3>{getLabel(`tanksInvolved`)}</h3>
        {operation?.src_tanks?.map((srcTanks) => {
            return <div className="operation_info_container_row">
                <tanksDetails
                    key={`src-${srcTanks.id}`}
                    tank={tanks.find((tank) => Number(tank.id) === Number(srcTanks?.tank_id))}
                    operation={operation}
                ></tanksDetails>
            </div>
        })} */}
      <div className="operation_info_container_row">
        <div className="analysis_table">
          {/* <Table data={drugsData} customColumns={columns} /> */}
          <TableEdit
            defaultData={drugsData}
            columns={columns}
            isSearchBar={false}
          />
        </div>
      </div>
      {!isBatchTreatment && (
        <>
          <h4>{getLabel("fromTanksAnalysis")}</h4>
          {operation?.src_tanks?.map((srcTank, index) => {
            return (
              <div
                className="operation_info_container_row"
                key={`tank-row-${index}`}
              >
                <SrcTankDetail
                    key={`src-${srcTank.id}`}
                    tank={getTankData(srcTank)}
                    isCurrentStateOnly={true}
                    totalDrug={renderTotal(srcTank?.tank_id)}
                  ></SrcTankDetail>
                {operation?.completion_date === null &&
                  (role === 3 ||
                    (role === ROLES.CLIENT &&
                      users?.filter((u) => u?.type === "USER")?.length ===
                        0)) && (
                    <>
                      <Input
                        name={`completed.${srcTank.tank_id}`}
                        register={register}
                        error={errors[`completed.${srcTank.tank_id}`]}
                        required={getLabel("inputRequiredError")}
                        isLabel={false}
                        type={"checkbox"}
                        checked={completedState.includes(srcTank.tank_id)}
                        onChange={() => setCheckboxState(srcTank.tank_id)}
                        customClassName={'confirm'}
                      />
                    </>
                  )}
              </div>
            );
          })}
        </>
      )}
      {isBatchTreatment && (
        <>
          <h3>{getLabel("fromLotsAnalysis")}</h3>
          {operation?.src_tanks?.map((srcTanks, index) => {
            return (
              <div
                className="operation_info_container_row"
                key={`tank-row-${index}`}
              >
                <BatchDetail
                  key={`src-${srcTanks.id}`}
                  lot={lots.find(
                    (lot) => Number(lot.id) === Number(srcTanks?.batch_id)
                  )}
                ></BatchDetail>
              </div>
            );
          })}
        </>
      )}
      {/* <div className="operation_info_container_row">
        <Label label={getLabel("operationNotes")} width={400} height={150} customClassName={"notes"}>
          {operation?.notes}
        </Label>
      </div> */}
      <div className="operation_info_container_row">
        <ChatArea
          name="chat"
          register={register}
          label={getLabel("lotNotes")}
          placeholder={getLabel("lotNotesPlaceholder")}
          users={users}
          user_id={user_id}
          // tooltip={getLabel("lotNotesTooltip")}
          configs={configs}
          operation={operation}
          storeNote={() =>
            storeNote(
              axiosPrivate,
              dispatch,
              setValue,
              getValues,
              getLabel,
              operation,
              user_id,
              userSockets,
              users,
            )
          }
        />
      </div>
      <div className="operation_info_container_row additional">
        <Label label={getLabel("demandant")}>
          {users?.find?.(
            (user) => Number(user.id) === Number(operation?.user_id)
          )?.username || ""}
        </Label>
        <Label label={getLabel("updateDate")}>
          {format(new Date(operation?.update_date || null), configs.dateFormat)}
        </Label>
      </div>
    </>
  );
};

export const CutDetail = ({
  operation,
  users,
  tanks,
  configs,
  getValues,
  register,
  errors,
  type,
  dispatch,
  setValue,
  user_id,
  axiosPrivate,
  userSockets,
}) => {
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const [completedState, setCompletedState] = useState([]);

  const setCheckboxState = (tank_id) => {
    const newCompletedState = [...completedState];
    const index = newCompletedState.indexOf(tank_id);
    if (index === -1) {
      newCompletedState.push(tank_id);
    } else {
      newCompletedState.splice(index, 1);
    }
    setCompletedState(newCompletedState);
  };

  const getTankData = (tank, isSrc = false) => {
    if (operation?.completion_date === null) {
      const t = tanks?.find((t) => t?.id === tank?.tank_id) || {};
      const tankList = (isSrc ? operation?.src_tanks : operation?.dest_tanks) || [];
      
      if (type !== 'CUT') {
        const destTank = isSrc ? {} : tankList?.find((tt) => tt?.tank_id === t?.id);
        const arrow_quantity = !isSrc ? destTank?.quantity :
        operation?.dest_tanks?.reduce((acc, curr) => {
          return acc + (Number(curr?.quantity) || 0);
        }, 0);
        const newQuantity = t?.quantity + (isSrc ? -arrow_quantity : arrow_quantity);

        return {...t, ...(!isSrc && type === 'RACKING' && { batch_type: destTank?.batch_type }), ...(!isSrc && { batch_name: destTank?.new_batch_name }), old_batch_name: t?.batch_name, old_quantity: t?.quantity, quantity: newQuantity, arrow_quantity: arrow_quantity };
      } else {
        const destTank = !isSrc ? {} : tankList?.find((tt) => tt?.tank_id === t?.id);
        const arrow_quantity = isSrc ? destTank?.quantity :
        operation?.src_tanks?.reduce((acc, curr) => {
          return acc + (Number(curr?.quantity) || 0);
        }, 0);
        const newQuantity = t?.quantity + (isSrc ? -destTank?.quantity : arrow_quantity);

        return {...t, ...(!isSrc && { batch_name: tankList?.find((tt) => tt?.tank_id === t?.id)?.new_batch_name, color: tanks?.find((t) => t?.id === operation?.src_tanks?.[0]?.tank_id)?.color }), old_batch_name: t?.batch_name, old_quantity: t?.quantity, quantity: newQuantity, arrow_quantity: arrow_quantity };  
      }
    } else {
      const tankData = tank?.postOperation?.tank || null;
      const color = tank?.color || tank?.postOperation?.batch?.color || null;
      const old_batch_name = tank?.postOperation?.batch?.name || '';
      
      return {...tankData, ...(!isSrc && type === 'RACKING' && { batch_type: tank?.batch_type }), color: color, old_batch_name: old_batch_name };
    }
  };
  
  const onNewScanResult = (decodedText, decodedResult) => {
    // handle decoded results here
    console.log('scanned', decodedText, decodedResult)
  };
  
  return (
    <>
      {/* <h3>{getLabel(`operationType${operation?.type}`)}</h3> */}
      <div className={`operation_info_container_row ${operation?.cellarman_ids?.length > 1 ? 'start' : ''}`}>
        <Label label={getLabel("insertionDate")}>
          {format(
            new Date(operation?.insertion_date || null),
            configs.shortDateFormat
          )}
        </Label>
        {operation?.completion_date === null && <Label label={getLabel("expireDate")}>
          {format(
            new Date(operation?.expire_date || null),
            configs.shortDateFormat
          )}
        </Label>}
        {operation?.completion_date && (
          <>
            <Label label={getLabel("operationCompletionUser")}>
              <div>
                {users?.find?.(
                  (user) => Number(user.id) === Number(operation?.completed_by)
                )?.username || ""}
              </div>
            </Label>
            <Label label={getLabel("operationCompletionDate")}>
              {format(
                new Date(operation?.completion_date || null),
                configs.dateFormat
              )}
            </Label>
          </>
        )}
        <Label label={getLabel("cellarman")}>
          {
            <div className="cellarman_container">
              {operation?.cellarman_ids?.map((cellarman) => {
                const cellarmanName =
                  users?.find?.((user) => Number(user.id) === Number(cellarman))
                    ?.username || "";
                return (
                  <div>
                    {cellarmanName +
                      (operation?.cellarman_ids?.length > 1 ? " " : "")}
                  </div>
                );
              })}
            </div>
          }
        </Label>
      </div>
      <div className={"confirmation-modal-container"}>
        <h4>{getLabel("operationDetail")}</h4>
        <OperationRecap
          type={type}
          isScale={false}
          src_tanks={getRecapTanks(tanks, operation, true)}
          dest_tanks={getRecapTanks(tanks, operation, false)}
        />
      </div>
      {/* <h3>{getLabel("fromTanks")}</h3> */}
      <h4>{type === "CUT" ? getLabel("fromTanks") : getLabel("fromTank")}</h4>
      {operation?.src_tanks?.map((srcTanks, index) => {
        return (
          <div className="operation_info_container_row" key={index}>
            <SrcTankDetail
              key={`src-${srcTanks.id || index}`}
              tank={getTankData(srcTanks, true)}
              operation={operation}
            ></SrcTankDetail>
          </div>
        );
      })}
      {/* <h3>{getLabel("toTank")}</h3> */}
      <h4>{type === "CUT" || operation?.dest_tanks?.length === 1 ? getLabel("toTank") : getLabel("toTanks")}</h4>
      {operation?.dest_tanks?.map((destTank, index) => {
        return (
          <div className="operation_info_container_row">
            <DestTankDetail
              key={`dest-${destTank?.id || index}`}
              tank={getTankData(destTank)}
              data={destTank}
              operation={operation}
            ></DestTankDetail>
            {role === 3 && operation.completion_date == null && (
              <>
                <Input
                  name={`completed.${destTank.tank_id}`}
                  register={register}
                  error={errors[`completed.${destTank.tank_id}`]}
                  required={getLabel("inputRequiredError")}
                  type={"checkbox"}
                  isLabel={false}
                  checked={completedState.includes(destTank.tank_id)}
                  onChange={() => setCheckboxState(destTank.tank_id)}
                  customClassName={'confirm'}
                />
                {/* <QRcodeReader
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />      */}
                {getValues(`completed.${destTank.tank_id}`) && (
                  <Input
                    name={`drop.${destTank.tank_id}`}
                    label={getLabel("operationDrop")}
                    placeholder={getLabel("operationDropPlaceholder")}
                    register={register}
                    error={errors[`drop`]?.[destTank.tank_id]}
                    // required={getLabel("inputRequiredError")}
                    type={"number"}
                    defaultValue={0}
                    min={{
                      value: 0,
                      message: getLabel("errorMinValue", { value: "0" }),
                    }}
                    max={{
                      value:
                        type === "CUT"
                          ? operation.src_tanks?.reduce(
                              (acc, cur) => Number(acc) + Number(cur.quantity),
                              0
                            ) - 1
                          : Number(destTank.quantity) - 1, // TO DO: aggiustare coefficiente
                      message: getLabel("errorMaxValue", {
                        value:
                          type === "CUT"
                            ? operation.src_tanks?.reduce(
                                (acc, cur) =>
                                  Number(acc) + Number(cur.quantity),
                                0
                              ) - 1
                            : Number(destTank.quantity) - 1,
                      }),
                    }}
                    // Math.max(0, Number(destTank.quantity)),
                  />
                )}
              </>
            )}
          </div>
        );
      })}
      {/* <div className="operation_info_container_row">
        <Label label={getLabel("operationNotes")} width={400} height={150} customClassName={"notes"}>
          {operation?.note}
        </Label>
      </div> */}
      <div className="operation_info_container_row">
        <ChatArea
          name="chat"
          register={register}
          label={getLabel("lotNotes")}
          placeholder={getLabel("lotNotesPlaceholder")}
          users={users}
          user_id={user_id}
          // tooltip={getLabel("lotNotesTooltip")}
          configs={configs}
          operation={operation}
          storeNote={() =>
            storeNote(
              axiosPrivate,
              dispatch,
              setValue,
              getValues,
              getLabel,
              operation,
              user_id,
              userSockets,
              users,
            )
          }
        />
      </div>
      <div className="operation_info_container_row additional">
        <Label label={getLabel("demandant")}>
          {users?.find?.(
            (user) => Number(user.id) === Number(operation?.user_id)
          )?.username || ""}
        </Label>
        <Label label={getLabel("updateDate")}>
          {format(new Date(operation?.update_date || null), configs.dateFormat)}
        </Label>
      </div>
    </>
  );
};

export const AnalysisDetails = ({
  operation,
  users,
  tanks,
  lots,
  analysis,
  results,
  configs,
  isCellarmanEnabled,
  registerComplete,
  errorsComplete,
  controlComplete,
  analysisState,
  getValues,
  dispatch,
  user_id,
  setValue,
  register,
  axiosPrivate,
  userSockets,
}) => {
  const [getLabel] = useLabels();
  const [data, setData] = useState([]);

  const isBatchAnalysis = !operation?.on_tank;
  const isCompleted =
    (!operation?.repetitive &&
      operation?.completion_date !== undefined &&
      operation?.completion_date !== null) ||
    (operation?.repetitive &&
      Number(operation?.completed) === Number(operation?.total));

  useEffect(() => {
    const tmpData = [];
    
    if (!isCompleted && operation?.repetitive) {
      if (Number(operation?.completed) > 0) {
        setCompletedAnalysisData(tmpData);
      }
      if (Number(operation?.completed) < Number(operation?.total))
        setUncompletedAnalysisData(tmpData);
    } else if (isCompleted) setCompletedAnalysisData(tmpData);
    else setUncompletedAnalysisData(tmpData);

    setData(tmpData);
  }, [results, analysis]);

  const setCompletedAnalysisData = (tmpData) => {
    if (!results || results?.length === 0 || !results?.[0]?.results) return;
    let isShowFirstLineData = true;
    const keys = analysis?.analysis?.map((an) => an?.substance_name) || []; // Object.keys(result?.results);
    const len = keys.length || 0;

    results?.forEach((result, i) => {
      // if (!result?.results) return true;
      for (let index = 0; index < len; index++) {
        const currentAnalysis = analysis?.analysis?.find(
          (a) => a.substance_name === keys[index]
        );
        const rowsNum = analysis?.analysis?.length * operation?.src_tanks?.length;
        const completedRowsNum = Number(operation?.completed) * rowsNum;
        
        const totalIndex = i * (len || 0) + index;
        tmpData.push({
          ...result,
          results: result?.results?.[keys[index]] || "",
          name: keys[index],
          isShowFirstLineData:
            totalIndex %
              (rowsNum) ===
            0,
          enableRange: i === 0 || totalIndex >= completedRowsNum && totalIndex < (analysis?.analysis?.length + completedRowsNum),
          min_range: currentAnalysis?.min_range,
          max_range: currentAnalysis?.max_range,
          src_name:
            (operation?.on_tank
              ? tanks?.find((t) => t.id == result?.tank_id)?.name
              : lots?.find((l) => l.id == result?.batch_id)?.name) || "",
          repetitive: operation?.repetitive,
          unit: currentAnalysis?.unit || "",
          endOfRepBorder: operation?.repetitive
            ? !isShowFirstLineData &&
              totalIndex %
                (analysis?.analysis?.length * operation?.src_tanks?.length) ===
                0
            : false,
          // i % operation?.src_tanks?.length === 0 && !isShowFirstLineData,
          // endOfSrcNameBorder: !isShowFirstLineData && totalIndex % operation?.src_tanks?.length === 0,
          endOfSrcNameBorder:
            !isShowFirstLineData &&
            totalIndex % analysis?.analysis?.length === 0,
          isTankFirst: index % analysis?.analysis?.length === 0,
          opType: "ANALYSIS",
        });
        isShowFirstLineData = false;
      }
    });
  };

  const populateBaseData = (tmp, result, an) => {
    const source = operation?.on_tank
      ? tanks?.find((t) => t.id == result?.tank_id)
      : lots?.find((l) => l.id == result?.batch_id);

    tmp.push({
      name: an?.substance_name,
      unit: an?.unit,
      enableRange: Number(operation?.completed) === 0,
      min_range: an?.min_range,
      max_range: an?.max_range,
      src_name: source?.name || "",
      src_id: source?.id || "",
      opType: "ANALYSIS",
    });
  };

  const setUncompletedAnalysisData = (tmpData) => {
    const tmp = [];
    if (results?.filter((an) => an?.result)?.length === 0 || tmpData?.length > 0) {
      operation?.src_tanks?.forEach((s) => {
        const result = operation?.on_tank
          ? { tank_id: s.tank_id }
          : { batch_id: s.batch_id };
        analysis?.analysis?.forEach((an) => {
          populateBaseData(tmp, result, an);
        });
      });
    }

    // console.log(analysis, results)
    const rowCount =
      analysis?.analysis?.length * operation?.src_tanks?.length || 1;
    const analysisCount = analysis?.analysis?.length;
    const sampledCount =  tmpData?.length > 0 ?
      Number(operation?.total) - Number(tmpData?.length / rowCount) || 0 : 0;
    const missingReplica = operation?.repetitive
      ? Number(operation?.total) -
        Number(operation?.completed) -
        sampledCount -
        1
      : 0;
    // console.log('rowCount', rowCount, 'analysisCount', analysisCount, 'sampledCount', sampledCount)

    if (missingReplica > 0) {
      const copy = [...tmp];
      for (let index = 0; index < missingReplica; index++) {
        tmp.push(...copy);
      }
    }
    const basicData = {
      total: operation?.total,
      repetitive: operation?.repetitive,
      // enableRange: false,
    };
    
    tmp?.forEach((t, i) => {
      const obj = { ...t, ...basicData };

      obj.isTankFirst = i % analysisCount === 0;
      // obj.isTankFirst =
      // i % rowCount === 0 ||
      // (operation?.src_tanks?.length > 1 &&
      //   i % operation?.src_tanks?.length === 0);
      obj.isShowFirstLineData = i % rowCount === 0;
      obj.relative_id = Math.floor(tmpData?.length / (operation?.src_tanks?.length * analysis?.analysis?.length)) + 1;
      obj.endOfRepBorder = i % rowCount === 0;
      obj.endOfSrcNameBorder = obj.isTankFirst;
      // obj.endOfSrcNameBorder =
      //   operation?.src_tanks?.length === 1
      //     ? false
      //     : i % operation?.src_tanks?.length === 0 && i > 0;
      obj.enableRange =
        i < analysis?.analysis?.length && Number(operation?.completed) === 0;
      obj.isEditable =
        Number(operation?.relative_id) === Number(obj?.relative_id);
      obj.opType = "ANALYSIS";
      
      
      if (obj.isShowFirstLineData && obj.isTankFirst && i === Number(operation?.completed) && results?.filter((r) => r?.results === null)?.length > 0) {
        // only sampled
        const sample = results?.[0];
        obj.collection_date = new Date(sample?.collection_date);
        obj.temperature = sample?.temperature;
        obj.user_id = sample?.user_id;
      }
  
      if (
        Number(obj.relative_id) <= Number(operation.total) ||
        !operation?.repetitive
      )
        tmpData.push(obj);
    });
  };

  const columnHelper = createColumnHelper();
  const columns = [
    // columnHelper.accessor((row) => `${row?.isTankFirst ? row.src_name : ""}`, {
    columnHelper.accessor("src_name", {
      header: operation?.on_tank ? getLabel("tank") : getLabel("lot"),
      cell: TableCell,
      meta: {
        filterVariant: 'text',
        customClassName: 'hide',
      },
    }),
    columnHelper.accessor((row) => getLabel(`substance_${row.name}`), {
      // + (row?.unit?.trim()?.length > 0 ? ` (${row.unit})` : ''), {
      header: getLabel("analysisName"),
      cell: TableCell,
      meta: {
        filterVariant: 'text',
      }
    }),
  ];

  if (operation?.repetitive) {
    columns.unshift(
      columnHelper.accessor(
        (row) =>
          `${row?.isShowFirstLineData ? row.relative_id : ""}${
            row?.isShowFirstLineData ? "/" + row?.total : ""
          }`,
        {
          header: getLabel("analysisRepetition"),
          cell: TableCell,
          meta: {
            isFilterDisabled: true,
          }
        }
      )
    );
  }

  // if (!isCompleted) {
  columns.push(
    columnHelper.accessor(
      (row) =>
        !row?.enableRange
          ? ""
          : `${row?.min_range ? String(row?.min_range) : 'n/a'} - ${row?.max_range ? String(row?.max_range) : 'n/a'} ${row?.unit}`,
          {
        header: getLabel("substanceRange"),
        cell: TableCell,
        meta: {
          isFilterDisabled: true,
        }
      }
    )
    // columnHelper.accessor("quantity", {
    //   header: getLabel("examResult"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //     editable: true,
    //     regExpPattern: INT_FLOAT_REGEX,
    //     register: registerComplete,
    //   },
    // })
  );
  // }
  // if (isCellarmanEnabled && Number(operation?.completed) < Number(operation?.total)) {
  // if (Number(operation?.completed) > 0) {
  
  if (operation?.sampled || Number(operation?.completed) <= Number(operation?.total)) {
    columns.push(
      columnHelper.accessor((row) => (row?.results ? row.results : ""), {
        header:
          operation.type === "ANALYSIS"
            ? getLabel("examResult")
            : getLabel("drugQuantity"),
        cell: TableCell,
        meta: {
          type: "number",
          editable: true,
          register: registerComplete,
          noStoreValue: true,
          isFilterDisabled: true,
        },
      })
    );
  }

  // TO DO: quando filtri per analisi o tank, la colonna # 1/4 scompare
  if (Number(operation?.completed) > 0) {
    columns.push(
      columnHelper.accessor(
        (row) =>
          !row?.isShowFirstLineData ? "" : row?.analyst ? row.analyst : "-",
        {
          header: getLabel("analysisAnalyst"),
          cell: TableCell,
          meta: {
            isFilterDisabled: true,
          }
        }
      ));
  }
  if (analysisState === 'NOT_INSERTED') {
    columns.push(          
      columnHelper.accessor(
        (row) =>
          !row?.isShowFirstLineData
            ? ""
            : users?.find((u) => u.id === row.user_id)?.username || "-",
        {
          header: getLabel("analysisCollectionUser"),
          cell: TableCell,
          meta: {
            isFilterDisabled: true,
          }
        }
      ),
      columnHelper.accessor(
        (row) => 
          !row?.isShowFirstLineData 
            ? ""
            : row?.collection_date
            ? format(new Date(row.collection_date || null), configs.dateFormat)
            : '-',
        {
          header: getLabel("analysisCollectionDate"),
          cell: TableCell,
          meta: {
            isFilterDisabled: true,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          `${
            row?.isTankFirst && row?.temperature
              ? row.temperature + " \u00b0C"
              : row?.isShowFirstLineData ? "-" : ""
          }`,
        {
          header: getLabel("analysisExamTemperature"),
          cell: TableCell,
          meta: {
            isFilterDisabled: true,
          },
        }
      )
    );
  }

  //   if (isCellarmanEnabled && Number(operation?.completed) < Number(operation?.total)) {
  //     columns.push(
  //       columnHelper.display({
  //         id: "edit",
  //         cell: EditCell,
  //         meta: {
  //           isEditable: true,
  //         },
  //       }),
  //     );
  // }

  // if (isCellarmanEnabled) {

  //   // if (!isCompleted && Number(operation?.completed) === 0) {
  //   //   columns.push(
  //   //     columnHelper.accessor((row) => (!row?.enableRange ? "" : row?.unit), {
  //   //       header: getLabel("unit"),
  //   //       cell: TableCell,
  //   //     }),
  //   //   )
  //   // }

  // } else {
  //   columns.push(
  //     columnHelper.accessor(
  //       (row) =>
  //         !row?.isFirst
  //           ? ""
  //           : row?.results
  //           ? row.results + " " + row?.unit
  //           : "-",
  //       {
  //         header: getLabel("substanceResult"),
  //         cell: TableCell,
  //       }
  //     ),

  //   );
  // }

  const renderAnalysisEvolution = () => {
    return (
      <div>
        <div className="primary_container_menu">
          {/* <h4>{getLabel('nextOperations')}</h4> */}
        </div>
        {results?.length > 0 && (
          <AnalysisEvolution
            configs={configs}
            analysisResults={results}
            lots={[
              ...new Set(
                results
                  ?.map((r) =>
                    lots?.find((l) => l.id == r.batch_id && r.tank_id === null)
                  )
                  .filter(Boolean)
              ),
            ]}
            tanks={[
              ...new Set(
                results
                  ?.map((r) => tanks?.find((t) => t.id == r.tank_id))
                  .filter(Boolean)
              ),
            ]}
            exams={[analysis]?.filter((exam) =>
              [operation]?.find(
                (an) => Number(exam.protocols_id) === Number(an.protocol_id)
              )
            )}
          />
        )}
      </div>
    );
  };

  const getTankData = (tank) => {
    // const tankData = ((operation?.completion_date === null) ? tank?.preOperation?.tank : tank?.postOperation?.tank) || {};
    // const color = (((operation?.completion_date === null) ? tank?.preOperation?.batch : tank?.postOperation?.batch)?.color) || null;
    if (operation?.completion_date !== null) {
      const tankData = tank?.postOperation?.tank || {};
      const color = tank?.postOperation?.batch?.color || null;
      return {...tankData, color: color };
    } else {
      const t = tanks?.find((t) => t?.id === Number(tank?.tank_id));
      return {...t, old_batch_name: t?.batch_name, old_quantity: t?.quantity };
    }
  };

  return (
    <>
      {/* <h4>{getLabel("analysisProtocols")}</h4> */}
      <div className="operation_info_container_row">
        <Label label={getLabel("expireDate")}>
          {format(new Date(operation?.expire_date || null), configs.dateFormat)}
        </Label>
        {/* </div>
      <div className="operation_info_container_row"> */}
        <Label label={getLabel("cellarman")}>
          {
            <div className="cellarman_container">
              {operation?.cellarman_ids?.map((cellarman, index) => {
                const cellarmanName =
                  users?.find?.((user) => Number(user.id) === Number(cellarman))
                    ?.username || "";
                return (
                  <div key={`cellarman-${index}`}>
                    {cellarmanName +
                      (operation?.cellarman_ids?.length > 1 ? " " : "")}
                  </div>
                );
              })}
            </div>
          }
        </Label>
        <Label label={getLabel("protocolFrequency")}>
          {getLabel(operation?.repetitive ? "repetitive" : "spot")}
        </Label>
        {/* <Label label={getLabel("protocolFrequency")}>
          <div className="arrow">
            <span>
              <Player
                autoplay={false}
                controls={false}
                hover={true}
                src={
                  operation.repetitive
                    ? json_recurrent_operation
                    : json_single_operation
                }
                style={{
                  width: "24px",
                  height: "24px",
                  transform: "rotate(180deg)",
                }}
              />
            </span>
          </div>
        </Label> */}
      </div>
      {!isBatchAnalysis && (
        <>
          <h4>{getLabel("fromTanksAnalysis")}</h4>
          <div className="operation_info_container analysis_tanks">
            {operation?.src_tanks?.map((srcTank, index) => {
              return (
                <div
                  className="operation_info_container_row"
                  key={`dest-${index}`}
                >
                  <SrcTankDetail
                    key={`src-${srcTank.id}`}
                    tank={getTankData(srcTank)}
                    // lot={lots.find((lot) =>
                    //   tanks.find(
                    //     (tank) => Number(lot.id) === Number(tank?.batch_id)
                    //   )
                    // )}
                    // data={srcTanks}
                    // operation={operation}
                    // outQuantity={operation.dest_tanks?.reduce(
                    //   (acc, cur) => acc + cur.quantity,
                    //   0
                    // )}
                    isCurrentStateOnly={true}
                  ></SrcTankDetail>
                  {/* <TankIcon
                  tank={operation?.completion_date !== null ? {  // TO DO: add ...(tanks.find())
                    name: tanks.find(
                      (tank) => Number(tank.id) === Number(srcTanks?.tank_id))?.name,
                    capacity: tanks.find(
                      (tank) => Number(tank.id) === Number(srcTanks?.tank_id))?.capacity,
                    quantity: tanks.find(
                      (tank) => Number(tank.id) === Number(srcTanks?.tank_id))?.quantity,
                    color: tanks.find(
                      (tank) => Number(tank.id) === Number(srcTanks?.tank_id))?.color
                  } : tanks.find(
                    (tank) => Number(tank.id) === Number(srcTanks?.tank_id)
                  )}
                  isText={false}
                  isReverse={true}
                  isRecap={false}
                  isName={true}
                  isBatchName={true}
                /> */}
                </div>
              );
            })}
          </div>
        </>
      )}
      {isBatchAnalysis && (
        <>
          <h4>{getLabel("fromLotsAnalysis")}</h4>
          <div className="operation_info_container_row">
            {operation?.src_tanks?.map((srcTanks, index) => {
              return (
                <BatchDetail
                  key={`src-${srcTanks.id}`}
                  lot={lots.find(
                    (lot) => Number(lot.id) === Number(srcTanks?.batch_id)
                  )}
                ></BatchDetail>
              );
            })}
          </div>
        </>
      )}
      {isCellarmanEnabled && (
        <div>
          {/* <Input
            name="temperature"
            register={registerComplete}
            type="number"
            label={getLabel('analysisExamTemperature')}
            placeholder={getLabel('analysisExamTemperaturePlaceholder') + " (\u00b0C)"}
            error={errorsComplete['temperature']}
            min={{value: 15, message: getLabel('errorMinValue', {value: 15})}}
            max={{value: 35, message: getLabel('errorMaxValue', {value: 35})}}
            // regExpPattern={{value: ALPHA_SPACE_APEX_REGEX, message: getLabel('errorAlphaSpaceApex')}}
        /> */}
        </div>
      )}
      <div className="analysis_table">
        <h4>{analysis?.protocols_name}</h4>
        <div>
          {analysis?.analysis?.length > 0 && (
            <TableEdit
              defaultData={data}
              columns={columns}
              errors={errorsComplete}
              control={controlComplete}
              isEditable={true}
              isSorting={false}
              isSearchBar={false}
              analysisState={analysisState}
              filterType={"analysis"}
              minEditableIndex={
                (Number(analysis?.analysis?.length) *
                  Number(operation?.src_tanks?.length) || 0) *
                Number(operation?.completed)
              }
              maxEditableIndex={
                (Number(analysis?.analysis?.length) *
                  Number(operation?.src_tanks?.length) || 0) *
                  Number(operation?.completed) +
                (Number(analysis?.analysis?.length) *
                  Number(operation?.src_tanks?.length) || 0)
              }
              newRow={{
                quantity: "",
              }}
              setValue={setValue}
              type={"analysis"}
            />
          )}
        </div>
      </div>
      <div className="operation_info_container_row">
        <ChatArea
          name="chat"
          register={register}
          label={getLabel("lotNotes")}
          placeholder={getLabel("lotNotesPlaceholder")}
          users={users}
          user_id={user_id}
          // tooltip={getLabel("lotNotesTooltip")}
          configs={configs}
          operation={operation}
          storeNote={() =>
            storeNote(
              axiosPrivate,
              dispatch,
              setValue,
              getValues,
              getLabel,
              operation,
              user_id,
              userSockets,
              users,
            )
          }
        />
      </div>
      {operation.id !== undefined && renderAnalysisEvolution()}
      <div className="operation_info_container_row additional">
        <Label label={getLabel("demandant")}>
          {users?.find?.(
            (user) => Number(user.id) === Number(operation?.user_id)
          )?.username || ""}
        </Label>
        <Label label={getLabel("updateDate")}>
          {format(new Date(operation?.update_date || null), configs.dateFormat)}
        </Label>
      </div>
    </>
  );
};

export const AutomaticDetail = ({
  type,
  operation,
  users,
  tanks,
  configs,
  lots,
  drugs,
  register,
  errors,
  getValues,
  dispatch,
  user_id,
  setValue,
  axiosPrivate,
  userSockets,
}) => {
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const [completedState, setCompletedState] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const tmp = [];
    if (operation?.src_tanks?.length > 0) tmp.push(operation?.src_tanks?.[0]);
    setTableData(tmp);
  }, [operation]);

  const setCheckboxState = (tank_id) => {
    const newCompletedState = [...completedState];
    const index = newCompletedState.indexOf(tank_id);
    if (index === -1) {
      newCompletedState.push(tank_id);
    } else {
      newCompletedState.splice(index, 1);
    }
    setCompletedState(newCompletedState);
  };

  const getTankData = (tank) => {
    const tankData = ((operation?.completion_date === null) ? tank?.preOperation?.tank : tank?.postOperation?.tank) || null;
    return tankData || {};
  
    // if (operation.completion_date === null) {
    //   const t = tanks?.find((t) => Number(t.id) === Number(tank?.tank_id)) || null;
    //   if (t === null) return {};
    //   return { ...t, old_quantity: t?.quantity};
    // } else {
    //   const t = {
    //     batch_name: tank?.batch_name,
    //     quantity: tank?.old_quantity,
    //     tank_id: tank?.tank_id,
    //     capacity: tank?.capacity,
    //   };
    //   return t;
    // }
  };

  const getDrugData = (row, type) => {
    const drug = drugs?.find((d) => d?.id == row?.product);
    if (drug === undefined) return "";
    if (type === "range") {
      return (
        `${String(drug?.min_range || "")} - ${String(drug?.max_range || "")} ${
          row?.product_unit || ""
        }` || ""
      );
    } else if (type === "temp") {
      return (
        `${String(drug?.min_temperature || "")} - ${
          drug?.max_temperature ? drug?.max_temperature + " \u00b0C" : ""
        }` || ""
      );
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) => getLabel(`protocolType_${row?.protocolType}`),
      {
        header: getLabel("protocolType"),
        cell: TableCell,
      }
    ),
    columnHelper.accessor("product_name", {
      header: getLabel("protocolProduct"),
      cell: TableCell,
    }),
    columnHelper.accessor(
      (row) => `${row?.quantity} ${row?.product_unit || ""}`,
      {
        header: getLabel("drugQuantity"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }
    ),
    // columnHelper.accessor('product_unit', {
    //   header: getLabel("unit"),
    //   cell: TableCell,
    // }),
    columnHelper.accessor((row) => getDrugData(row, "range"), {
      header: getLabel("substanceRange"),
      cell: TableCell,
      meta: {
        type: "number",
      },
    }),
    columnHelper.accessor((row) => getDrugData(row, "temp"), {
      header: getLabel("substanceTemperature"),
      cell: TableCell,
      meta: {
        type: "number",
      },
    }),
  ];

  const renderTotal = (tank_id) => {
    const mainSrc = operation?.src_tanks?.[0] || null;
    if (mainSrc === null) return '';
    
    const liters = (tanks?.find((t) => Number(t.id) === Number(tank_id)))?.quantity || 0;
    const productQuantity = Number(mainSrc?.quantity) || 0;
    const unit_id = mainSrc?.product_unit_id;
    if (liters === 0 || productQuantity === 0) return "";
    let totalProductToAdd = 0;
    switch (unit_id) {
      case 1: // mg/L
        totalProductToAdd =
          parseFloat(liters * productQuantity).toFixed(2) + " mg";
        break;
      case 2: // g/hL
        totalProductToAdd =
          parseFloat((liters / 100) * productQuantity).toFixed(2) + " g";
        break;
      case 3: // L
        break;
      case 4: // Kg
        break;
      case 5: // " "
        break;
      default:
        break;
    }
    return (
      <div className="totalDrugWrapper">
        {getLabel("drugTotal")}{" "}
        <span className="totalDrug">{totalProductToAdd}</span>
      </div>
    );
  };

  return (
    <>
      {/* <h4>{getLabel(`operationType${operation?.type}`)}</h4> */}
      <div className={`operation_info_container_row ${operation?.cellarman_ids?.length > 1 ? 'start' : ''}`}>
        <Label label={getLabel("insertionDate")}>
          {format(
            new Date(operation?.insertion_date || null),
            configs.shortDateFormat
          )}
        </Label>
        {operation?.completion_date === null && (
          <>
            <Label label={getLabel("toDoDate")}>
              {format(
                new Date(operation?.expire_date || null),
                configs.shortDateFormat
              )}
            </Label>
            <Label label={getLabel("cellarman")}>
              {
                <div className="cellarman_container">
                  {operation?.cellarman_ids?.map((cellarman, index) => {
                    const cellarmanName =
                      users?.find?.(
                        (user) => Number(user.id) === Number(cellarman)
                      )?.username || "";
                    return (
                      <div key={`cellarma-${index}`}>
                        {cellarmanName +
                          (operation?.cellarman_ids?.length > 1 ? " " : "")}
                      </div>
                    );
                  })}
                </div>
              }
            </Label>
          </>
        )}
        {operation?.completion_date && (
          <>
            <Label label={getLabel("operationCompletionUser")}>
              <div>
                {users?.find?.(
                  (user) => Number(user.id) === Number(operation?.completed_by)
                )?.username || ""}
              </div>
            </Label>
            <Label label={getLabel("operationCompletionDate")}>
              {format(
                new Date(operation?.completion_date || null),
                configs.dateFormat
              )}
            </Label>
          </>
        )}
        <Label label={getLabel("operationPriority")}>
          {getLabel(`operationPriority${operation?.priority}`)}
        </Label>
        {/* <Label label={getLabel("lotType")}>
          {operation?.batch_type ? getLabel(`lotType${operation?.batch_type}`) : ''}
        </Label> */}
      </div>
      <div className="operation_info_container_row">
        <Label label={getLabel("protocolPhase")}>
          {getLabel(`protocolPhase_${operation?.src_tanks?.[0]?.phase}`)}
        </Label>
        <Label label={getLabel("protocolNotes")}>
          {operation?.note || "-"}
        </Label>
        <Label label={getLabel("protocolGoals")}>
          {operation?.src_tanks?.[0]?.goal || "-"}
        </Label>
      </div>
      <h4>{getLabel("protocolOperation")} </h4>
      {["TENNISSAGE", "ENZYME", "SO2", "INOCULATION", "NITROGEN"].includes(
        type
      ) && (
        <>
          <div className="operation_info_container_row">
            <TableEdit
              defaultData={tableData}
              columns={columns}
              isSearchBar={false}
              // rowClickHandler={
              //   !isProtocolApplication
              //     ? (row) => {
              //         navigate(`/substances/${type}/${row.original.id}`);
              //       }
              //     : null
              // }
              isEditable={false}
            />
          </div>
        </>
      )}
      {type === "HOMOGENIZATION_MASS" && (
        <>
          <div className="operation_info_container_row">
            {operation?.src_tanks?.[0]?.protocolType ===
              "HOMOGENIZATION_TEMP" && (
              <>
                <Label
                  label={getLabel("protocolOperation_HOMOGENIZATION_TEMP")}
                >
                  {`${operation?.src_tanks?.[0]?.quantity} \u00b0C`}
                </Label>
              </>
            )}
            {operation?.src_tanks?.[0]?.protocolType ===
              "HOMOGENIZATION_REASSEMBLY" && (
              <>
                <Label label={getLabel("protocolHomogenizationType")}>
                  {getLabel(
                    `protocolHomogenizationMass_${operation?.src_tanks?.[0]?.homogenizationType}`
                  )}
                </Label>
                <Label label={getLabel("protocolHomogenizationWorkTime")}>
                  {operation?.src_tanks?.[0]?.workTime}
                </Label>
                <Label label={getLabel("protocolHomogenizationPauseTime")}>
                  {operation?.src_tanks?.[0]?.homogenizationPauseTime}
                </Label>
                <Label label={getLabel("protocolHomogenizationMotorSpeed")}>
                  {operation?.src_tanks?.[0]?.homogenizationMotorSpeed}
                </Label>
                <Label label={getLabel("protocolHomogenizationIterPerDay")}>
                  {operation?.src_tanks?.[0]?.iterPerDay}
                </Label>
              </>
            )}
          </div>
        </>
      )}
      {type === "OXYGENATION" && ['MICRO', 'MACRO', 'CUSTOM'].includes(operation?.src_tanks?.[0]?.protocolType) && (
        <>
          <div className="operation_info_container_row">
            <Label
              label={getLabel("protocolOxigenationTypology")}
            >
              {getLabel(`protocolOXYGENATION_${operation?.src_tanks?.[0]?.protocolType}`)}
            </Label>
            <Label
              label={'MICRO' === operation?.src_tanks?.[0]?.protocolType ? 
              getLabel("protocolOxygenationDose_MICRO") : 'MACRO' === operation?.src_tanks?.[0]?.protocolType ? 
              getLabel("protocolOxygenationDose_MACRO") : getLabel("protocolOxygenationDose_CUSTOM")}
            >
              {operation?.src_tanks?.[0]?.quantity || ''}
            </Label>
            {'CUSTOM' === operation?.src_tanks?.[0]?.protocolType &&
            <Label
              label={getLabel("protocolOxygenationDoseCustomTime")}
            >
              {`${operation?.src_tanks?.[0]?.oxygenationDoseHours || ''}h:${operation?.src_tanks?.[0]?.oxygenationDoseMinutes || ''}m`}
            </Label>
            }
          </div>
        </>
      )}
      <h4>{getLabel("toTanks")}</h4>
      <ul>
        {operation?.src_tanks?.map((srcTank, idx) => {
          return (
            <li key={`li-${idx}`}>
              <div className="operation_info_container_row">
              <div className="operation_info_container_row_total">
                <SrcTankDetail
                  key={`src-${srcTank.id}`}
                  tank={getTankData(srcTank)}
                  lot={lots.find((lot) =>
                    tanks.find(
                      (tank) => Number(lot.id) === Number(tank?.batch_id)
                    )
                  )}
                  data={srcTank}
                  operation={operation}
                  isCurrentStateOnly={true}
                  totalDrug={[
                    "TENNISSAGE",
                    "ENZYME",
                    "SO2",
                    "INOCULATION",
                    "NITROGEN",
                    'BOIS',
                  ].includes(type) ? renderTotal(srcTank?.tank_id) : null}
                >
                </SrcTankDetail>
                </div>
                {operation?.completion_date === null &&
                  (role === 3 ||
                    (role === ROLES.CLIENT &&
                      users?.filter((u) => u?.type === "USER")?.length ===
                        0)) && (
                    <>
                      <Input
                        name={`completed.${srcTank.tank_id}`}
                        register={register}
                        error={errors[`completed.${srcTank.tank_id}`]}
                        required={getLabel("inputRequiredError")}
                        isLabel={false}
                        type={"checkbox"}
                        checked={completedState.includes(srcTank.tank_id)}
                        onChange={() => setCheckboxState(srcTank.tank_id)}
                        customClassName={'confirm'}
                      />
                    </>
                  )}
              </div>
            </li>
          );
        })}
      </ul>
      {/* <h4>{getLabel("operationPomaces")} </h4>
      <ul>
        <div>
          <ul>
            {operation?.pomaces?.map((pomace) => {
              return (
                <li>
                  <div>
                    {pomace.dest}: {pomace.quantity || 0}%
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </ul> */}
      {/* <div className="operation_info_container_row">
        <Label label={getLabel("operationNotes")} width={400} height={150} customClassName={"notes"}>
          {operation?.note}
        </Label>
      </div> */}
      <div className="operation_info_container_row">
        <ChatArea
          name="chat"
          register={register}
          label={getLabel("lotNotes")}
          placeholder={getLabel("lotNotesPlaceholder")}
          users={users}
          user_id={user_id}
          // tooltip={getLabel("lotNotesTooltip")}
          configs={configs}
          operation={operation}
          storeNote={() =>
            storeNote(
              axiosPrivate,
              dispatch,
              setValue,
              getValues,
              getLabel,
              operation,
              user_id,
              userSockets,
              users,
            )
          }
        />
      </div>
      <div className="operation_info_container_row additional">
        <Label label={getLabel("demandant")}>
          {users?.find?.(
            (user) => Number(user.id) === Number(operation?.user_id)
          )?.username || ""}
        </Label>
        <Label label={getLabel("updateDate")}>
          {format(new Date(operation?.update_date || null), configs.dateFormat)}
        </Label>
      </div>
    </>
  );
};

export const BottlingDetail = ({
  operation,
  users,
  tanks,
  configs,
  getValues,
  register,
  errors,
  type,
  dispatch,
  setValue,
  user_id,
  axiosPrivate,
  userSockets,
}) => {
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const [completedState, setCompletedState] = useState([]);

  const setCheckboxState = (tank_id) => {
    const newCompletedState = [...completedState];
    const index = newCompletedState.indexOf(tank_id);
    if (index === -1) {
      newCompletedState.push(tank_id);
    } else {
      newCompletedState.splice(index, 1);
    }
    setCompletedState(newCompletedState);
  };

  const getTankData = (tank, isSrc = false) => {
    const t = tanks?.find((t) => t?.id === tank?.tank_id) || {};
    if (!isSrc) {
      return t;
    }
    if (operation?.completion_date === null) {
      // const tankList = (isSrc ? operation?.src_tanks : operation?.dest_tanks) || [];
      const arrow_quantity = Number(tank?.quantity) || 0;
      const newQuantity = (Number(t?.quantity) - Number(tank?.quantity)) || 0;
      
      return {...t, old_batch_name: t?.batch_name, batch_name: t?.batch_name, old_quantity: t?.quantity, quantity: newQuantity, arrow_quantity: arrow_quantity };
    } else {
      const tankData = tank?.postOperation?.tank || null;
      const color = tank?.color || tank?.postOperation?.batch?.color || null;
      const old_batch_name = tank?.postOperation?.batch?.old_batch_name || '';

      return {...tankData, color: color, old_batch_name: old_batch_name };
    }
  };

  const getLotInfo = (src) => {
    const tank = operation?.completion_date !== null ? operation?.dest_tanks?.find((d) => d?.postOperation?.batch?.old_batch_id == src?.postOperation?.batch?.batch_id) || null :
    operation?.dest_tanks?.find((d) => d?.tank_id == src?.tank_id) || null;
    if (tank === null) return;
    const drop = operation?.completion_date !== null ? Number(operation?.drops?.find((d) => d?.tank_id === src?.tank_id)?.quantity) || 0 : 0;
    
    return (<div className="tag-fix">
        <Label label={getLabel("lot")}>
        {tank?.new_batch_name || ''} {`(${(Number(tank?.quantity) - drop) || '0'} L)`}
      </Label>
      <p className="timeline-tag">
        {["NEW", "INHERITED"].includes(
          tank?.new_batch_type
        )
          ? getLabel("new")
          : ""}
      </p>
    </div>)
  }

  return (
    <>
      {/* <h3>{getLabel(`operationType${operation?.type}`)}</h3> */}
      <div className={`operation_info_container_row ${operation?.cellarman_ids?.length > 1 ? 'start' : ''}`}>
        <Label label={getLabel("insertionDate")}>
          {format(
            new Date(operation?.insertion_date || null),
            configs.shortDateFormat
          )}
        </Label>
        {operation?.completion_date === null && <Label label={getLabel("expireDate")}>
          {format(
            new Date(operation?.expire_date || null),
            configs.shortDateFormat
          )}
        </Label>}
        {operation?.completion_date && (
          <>
            <Label label={getLabel("operationCompletionUser")}>
              <div>
                {users?.find?.(
                  (user) => Number(user.id) === Number(operation?.completed_by)
                )?.username || ""}
              </div>
            </Label>
            <Label label={getLabel("operationCompletionDate")}>
              {format(
                new Date(operation?.completion_date || null),
                configs.dateFormat
              )}
            </Label>
          </>
        )}
        <Label label={getLabel("cellarman")}>
          {
            <div className="cellarman_container">
              {operation?.cellarman_ids?.map((cellarman) => {
                const cellarmanName =
                  users?.find?.((user) => Number(user.id) === Number(cellarman))
                    ?.username || "";
                return (
                  <div>
                    {cellarmanName +
                      (operation?.cellarman_ids?.length > 1 ? " " : "")}
                  </div>
                );
              })}
            </div>
          }
        </Label>
      </div>
      <div className={"confirmation-modal-container"}>
        <h4>{getLabel("operationDetail")}</h4>
        <OperationRecap
          type={type}
          isScale={false}
          src_tanks={getRecapTanks(tanks, operation, true)}
          dest_tanks={[]}
        />
      </div>
      {/* <h3>{getLabel("fromTanks")}</h3> */}
      <h4>{type === "CUT" ? getLabel("fromTanks") : getLabel("fromTank")}</h4>
      {operation?.src_tanks?.map((srcTanks, index) => {
        return (
          <div className="operation_info_container_row" key={index}>
            <SrcTankDetail
              key={`src-${srcTanks.id || index}`}
              tank={getTankData(srcTanks, true)}
              operation={operation}
            ></SrcTankDetail>
            {role === 3 && operation.completion_date == null && (
            <>
              <Input
                name={`completed.${operation?.src_tanks?.[index]?.tank_id}`}
                register={register}
                error={errors[`completed.${operation?.src_tanks?.[index].tank_id}`]}
                required={getLabel("inputRequiredError")}
                type={"checkbox"}
                isLabel={false}
                checked={completedState.includes(operation?.src_tanks?.[index].tank_id)}
                onChange={() => setCheckboxState(operation?.src_tanks?.[index].tank_id)}
                customClassName={'confirm'}
              />
              <Input
                name={`drop.${operation?.src_tanks?.[index].tank_id}`}
                label={getLabel("operationDrop")}
                placeholder={getLabel("operationDropPlaceholder")}
                register={register}
                error={errors[`drop`]?.[operation?.src_tanks?.[index].tank_id]}
                // required={getLabel("inputRequiredError")}
                type={"number"}
                defaultValue={0}
                min={{
                  value: 0,
                  message: getLabel("errorMinValue", { value: "0" }),
                }}
                max={{
                  value:
                    Number(operation?.src_tanks?.[index].quantity) - 1, // TO DO: aggiustare coefficiente
                  message: getLabel("errorMaxValue", {
                    value: Number(operation?.src_tanks?.[index].quantity) - 1,
                  }),
                }}
                // Math.max(0, Number(src_tanks?.[0].quantity)),
              />
              </>)
            }
            <div>
              {getLotInfo(operation?.src_tanks?.[index])}
            </div>
          </div>
        );
      })}
      {/* <h3>{getLabel("toTank")}</h3> */}
      <h4>{getLabel("toTanks")}</h4>
      <div className="operation_info_container_row">
        <DestBottlingDetail
          key={`dest-${operation?.src_tanks?.[0]?.tank_id}`}
          capLot={operation?.src_tanks?.[0]?.capLot}
          bottleLot={operation?.src_tanks?.[0]?.bottleLot}
          srcBatches={operation?.src_tanks}
          batches={operation?.dest_tanks}
        ></DestBottlingDetail>
      </div>
      {/* <div className="operation_info_container_row">
        <Label label={getLabel("operationNotes")} width={400} height={150} customClassName={"notes"}>
          {operation?.note}
        </Label>
      </div> */}
      <div className="operation_info_container_row">
        <ChatArea
          name="chat"
          register={register}
          label={getLabel("lotNotes")}
          placeholder={getLabel("lotNotesPlaceholder")}
          users={users}
          user_id={user_id}
          // tooltip={getLabel("lotNotesTooltip")}
          configs={configs}
          operation={operation}
          storeNote={() =>
            storeNote(
              axiosPrivate,
              dispatch,
              setValue,
              getValues,
              getLabel,
              operation,
              user_id,
              userSockets,
              users,
            )
          }
        />
      </div>
      <div className="operation_info_container_row additional">
        <Label label={getLabel("demandant")}>
          {users?.find?.(
            (user) => Number(user.id) === Number(operation?.user_id)
          )?.username || ""}
        </Label>
        <Label label={getLabel("updateDate")}>
          {format(new Date(operation?.update_date || null), configs.dateFormat)}
        </Label>
      </div>
    </>
  );
};

const storeNote = async (
  axiosPrivate,
  dispatch,
  setValue,
  getValues,
  getLabel,
  operation,
  user_id,
  userSockets,
  users,
) => {
  const newNote = getValues("new_note") || "";
  if (!newNote || newNote?.length === 0) return;
  const userSocketsTmp = [...userSockets];

  const toastId = toast.loading(getLabel("toast_inProgress"), {
    type: toast.TYPE.INFO,
    position: toast.POSITION.BOTTOM_RIGHT,
    exclude: true,
  });
  const requestPayload = { message: newNote };
  const response = await addOperationNote(
    operation?.id,
    requestPayload,
    axiosPrivate
  );

  toast.update(toastId, {
    render: response?.error
      ? getLabel(response?.error)
      : getLabel(response?.success, { name: "" || "" }),
    type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
    isLoading: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
  });
  
  if (response && !response.error) {
    const user_ids = new Set();
    user_ids.add(user_id);
    user_ids.add(...operation?.cellarman_ids);

    if (response.success) {
      dispatch(empty());
      setValue("new_note", "");

      const tmpNote = 
      {
        "message": newNote,
        "user_id": user_id,
        "timestamp": new Date(),
      };
      if (Array.isArray(operation?.chat_notes)) operation?.chat_notes.push(tmpNote);
      else operation.chat_notes = [tmpNote];

      const socket_message = {
        content: newNote,
        to: undefined,
        username: undefined,
        type: 'new note',
        data: {
          operation_type: operation?.type,
        }
      }
      console.log('res', response.success, userSockets)

      Array.from(user_ids)?.forEach((user) => {
        if (user_id === user) return true;
        socket_message.username = users?.find((u) => u?.id === user)?.username;
        socket_message.to = userSocketsTmp?.find((u) => u?.id === user)?.userId;
        if (socket_message?.username) {
          socket.emit('private notification', socket_message, () => {});
        }
      })
    }
  }
};