import { useEffect, useRef, useState } from "react";
import "./styles/index.scss";
import { Controller } from "react-hook-form";
import { default as ReactSelect } from "react-select";
import makeAnimated from "react-select/animated";
import ReactDatePicker from "react-datepicker";
import { addDays, format } from "date-fns";
import { InfoTooltip, Tooltip } from "../Generic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLabels from "../../hooks/useLabels";

const ChatItem = ({
  configs,
  users,
  note,
  index,
  length,
  getLabel,
}) => {
  const getChatTimestamp = (timestamp) => {
    const timestampDate = new Date(timestamp);
    const diff = Math.abs(new Date() - timestampDate);
    const minutes = Math.floor(diff / 1000 / 60);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    const weekAgo = new Date();
    weekAgo.setDate(yesterday.getDate() - 6);
    timestampDate.setHours(0, 0, 0, 0);

    if (minutes >= 0 && minutes <= 20 && (index + 1) === length) return getLabel("justNow");
    else if (
      (minutes >= 0 && minutes <= 20) ||
      (minutes > 20 && minutes < 60)
    )
      return getLabel(minutes === 1 ? "minuteAgo" : "minutesAgo", { value: minutes || 2 });
    else if (minutes > 60 && minutes < 60 * 2)
      return getLabel("hourAgo", { value: Math.floor(minutes / 60) });
    else if (minutes > 60 * 2 && minutes < 60 * 8)
      return getLabel("hoursAgo", { value: Math.floor(minutes / 60) });
    else if (timestampDate.getTime() == yesterday.getTime())
      return getLabel("yesterday");
    else if (
      timestampDate.getTime() < yesterday.getTime() &&
      timestampDate.getTime() > weekAgo.getTime()
    )
      return getLabel("weekAgo");
    
    if (timestamp !== undefined) return format(timestampDate, configs.dateFormat);
    return '';
  };
  
  return (
    <div className="chat-item" key={`chat-item-${index}`}>
      <div className="chat-item_avatar">
        <div className="chat-item_avatar_container circle large">
          {/* <img alt={users?.find((u) => u?.id === note?.user_id)?.username || ''} src="https://avatars.githubusercontent.com/u/41473129?v=4" className="chat-avatar"></img> */}
          <FontAwesomeIcon
            icon={
              users?.find((u) => u?.id === note?.user_id)?.role_id == 2
                ? "fa-user-gear"
                : "fa-user"
            }
            style={{
              color: `${
                users?.find((u) => u?.id === note?.user_id)?.role_id == 2
                  ? "grey"
                  : ""
              }`,
            }}
            className="chat-avatar"
          />
        </div>
      </div>
      <div className="chat-item_body">
        <div className="chat-item_body_top">
          <div className="chat-item_body_top_title">
            {users?.find((u) => u?.id === note?.user_id)?.username || ""}
          </div>
          <div className="chat-item_body_top_time">
            {getChatTimestamp(note?.timestamp)}
          </div>
        </div>
        <div className="chat-item_body_bottom">
          <div className="chat-item_body_bottom_title">
            {note?.message || ""}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatArea = ({
  label = "",
  register,
  users,
  name,
  tooltip,
  configs,
  operation,
  storeNote,
}) => {
  const [getLabel] = useLabels();
  const [isAddNewNoteClicked, setIsAddNewNoteClicked] = useState(false);

  const getRightLabel = () => {
    return label || name || "";
  };

  return (
    <div className={'chatarea_field'}>
      <h4>{getRightLabel()}</h4>
      {/* <label htmlFor={name}>
        {getRightLabel()}
        {<InfoTooltip html={tooltip} />}
      </label> */}
      <div className={"chat"}>
        {operation?.chat_notes?.map((note, index) => {
          return (
            <ChatItem
              getLabel={getLabel}
              length={operation?.chat_notes?.length}
              configs={configs}
              users={users}
              note={note}
              index={index}
              key={index}
            />
          );
        })}
        {(operation?.chat_notes === null || operation?.chat_notes?.length === 0) && 
        <h5 className="alert_no_info">{getLabel("noNotes")}</h5>}
      </div>
      {operation?.completion_date === null && (
          <div className="note-container">
            {!isAddNewNoteClicked && (
              <>{(operation?.chat_notes || [])?.length < 10 && <button
                onClick={() => setIsAddNewNoteClicked(true)}
                className="new-note-button"
              >
                {getLabel("addNewNote")}
              </button>}
              {(operation?.chat_notes || [])?.length >= 10 && 
              <div className="new-note-tooltip"><Tooltip
                variant={"info"}
                html={getLabel("errorMaxNotes")
                }
                place="right"
                events={["hover"]}
              >
                <button
                className="new-note-button disabled"
              >
                {getLabel("addNewNote")}
              </button>
              </Tooltip></div>
              }
              </>
            )}
            {isAddNewNoteClicked && (
              <div className="new-note-container">
                <TextArea
                  customClassName={"is_chat"}
                  name="new_note"
                  register={register}
                  label={getLabel("lotNotes")}
                  placeholder={getLabel("notesPlaceholder")}
                  // error={error["new_note"]}
                  tooltip={getLabel("notesTooltip")}
                />
                <div className="note-button-container">
                  <button onClick={() => {storeNote(); setIsAddNewNoteClicked(false);}} className="store-note-button complete">
                    {getLabel("storeNewNote")}
                  </button>
                  <button
                    onClick={() => setIsAddNewNoteClicked(false)}
                    className="store-note-button"
                  >
                    {getLabel("cancelNewNote")}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

const TextArea = ({
  label = "",
  register,
  name,
  placeholder,
  required,
  validate,
  isDisabled,
  onChange,
  onBlur,
  maxLength,
  onFocus,
  shouldUnregister,
  error,
  tooltip,
  customClassName,
}) => {
  const getRightLabel = () => {
    return label || name || "";
  };
  return (
    <div
      className={`textarea_field ${customClassName ? customClassName : ""} ${
        error?.message ? "is_error" : ""
      }`}
    >
      <label htmlFor={name}>
        {getRightLabel()}
        {<InfoTooltip html={tooltip} />}
      </label>
      <textarea
        id={name}
        placeholder={placeholder}
        {...register(name, {
          required,
          shouldUnregister,
          ...(validate && { validate: validate }),
          ...(isDisabled && { disabled: isDisabled }),
          ...(onChange && { onChange: onChange }),
          ...(onBlur && { onBlur: onBlur }),
          ...(maxLength && { maxLength: maxLength }),
        })}
      ></textarea>
      <div className="error_message">
        <span>{error?.message || ""}</span>
      </div>
    </div>
  );
};

const Input = ({
  label = "",
  register,
  name,
  type,
  placeholder = "",
  required,
  minLength,
  maxLength,
  max,
  min,
  regExpPattern,
  validate,
  isDisabled,
  onChange,
  onBlur,
  onFocus,
  shouldUnregister,
  error,
  isLabel = true,
  checked = false,
  isRequiredTooltip = true,
  defaultValue = "",
  style,
  tooltip,
  accept,
  customClassName = "",
}) => {
  const getRightLabel = () => {
    return label || name || "";
  };

  document.addEventListener("wheel", function (event) {
    if (
      document.activeElement.type === "number" &&
      document.activeElement.classList.contains("noscroll")
    ) {
      document.activeElement.blur();
    }
  });
  
  return (
    <div className={`input_field ${error?.message ? "is_error" : ""} ${type === "checkbox" ? "is_checkbox" : (type === "number" && customClassName === "quantity") ? "is_number" : ""}`}>
      {isLabel && (
        <label htmlFor={name} className={`${customClassName === 'winemaking' ? customClassName : ''} ${required?.length > 0 && isRequiredTooltip ? 'required' : ''}`}>
          {getRightLabel()}
          {<InfoTooltip html={tooltip} />}
        </label>
      )}
      <input
        className={`${
          type === "range" ? "is_slider" : customClassName
        } noscroll ${isDisabled ? "disabled" : ""}`}
        id={name}
        type={type} // TO DO when required show *
        placeholder={placeholder}
        checked={checked}
        defaultValue={defaultValue}
        style={style}
        {...register(name, {
          required,
          shouldUnregister,
          ...(minLength && { minLength: minLength }),
          ...(maxLength && { maxLength: maxLength }),
          ...(min && { min: min }),
          ...(max && { max: max }),
          ...(regExpPattern && { pattern: regExpPattern }),
          ...(validate && { validate: validate }),
          ...(isDisabled && { disabled: isDisabled }),
          ...(onChange && { onChange: onChange }),
          ...(onBlur && { onBlur: onBlur }),
          ...(accept && { accept: accept }),
        })}
      />
      <div className="error_message">
        <span>{error?.message || error?.name?.message || ""}</span>
      </div>
    </div>
  );
};

const Submit = ({ label, customClassName }) => {
  return (
    <div className={`submit_field ${customClassName ? customClassName : ''}`}>
      <input type="submit" value={label} />
    </div>
  );
};

const Switch = ({
  leftOption,
  rightOption,
  disabled = false,
  toggleHandler,
  status,
  negative = false,
}) => {
  return (
    <div
      onClick={disabled ? null : toggleHandler}
      className={`switch_field ${disabled ? "disabeld" : ""}`}
    >
      <div
        className={`switch_field_left ${!status && "active"} ${
          negative ? "negative" : ""
        }`}
      >
        {leftOption.label}
      </div>
      <label className="switch_field_switch">
        <input
          disabled={disabled}
          type="checkbox"
          checked={status}
          onClick={(e) => e.stopPropagation()}
        ></input>
        <span
          className={`switch_field_slider ${negative ? "negative" : ""}`}
        ></span>
      </label>
      <div className={`switch_field_right ${status && "active"}`}>
        {rightOption.label}
      </div>
    </div>
  );
};

const Label = ({
  label,
  children,
  width,
  height,
  tooltip,
  customClassName,
}) => (
  <div
    className="label_field"
    style={{
      ...(width && { width: `${width}px` }),
      ...(height && { height: `${height}px` }),
    }}
  >
    <label className="label_field_label">
      {label}
      <InfoTooltip html={tooltip} />
    </label>
    <div className={`label_field_value ${customClassName}`}>{children}</div>
  </div>
);

const SelectTemplate = ({
  value,
  onChange,
  options = [],
  placeholder,
  isTable = false,
  isDisabled = false,
  isSearchable = false,
  error,
}) => {
  const [val, setVal] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      openAndAnimate(false);
    }
  };

  const openAndAnimate = (value) => {
    if (value) {
      setIsOpen(value);
      setTimeout(() => {
        setIsAnimated(value);
      }, 10);
    } else {
      setIsAnimated(value);
      setTimeout(() => {
        setIsOpen(value);
      }, 10);
    }
  };

  const changeValue = (value) => {
    onChange(value);
    setVal(value);
    openAndAnimate(false);
  };

  const noop = () => {
    return null;
  };

  return (
    <div className="select_field_wrapper" ref={wrapperRef}>
      {!isSearchable && <div
        disabled={!options?.length}
        className={`select_field_wrapper_container ${isOpen ? "opened" : ""} ${
          error ? "is_error" : ""
        } ${isTable ? "is_table" : ""} ${isDisabled ? "disabled" : ""} ${
          (!value || !value?.value) && !value?.label ? "gray_filtered" : ""
        }`}
        onClick={(e) => {
          if (options?.length && !isDisabled) {
            openAndAnimate(!isOpen);
          } else {
            noop();
          }
          e.stopPropagation();
        }}
      >
        {!isTable && (value.label || placeholder)}
        {isTable && placeholder}
      </div>}
      {isSearchable && <input
        disabled={!options?.length}
        className={`select_field_wrapper_container ${isOpen ? "opened" : ""} ${
          error ? "is_error" : ""
        } ${isTable ? "is_table" : ""} ${isDisabled ? "disabled" : ""} ${
          (isOpen || (!value || !value?.value) && !value?.label) ? "gray_filtered" : ""
        }`}
        onClick={(e) => {
          if (options?.length && !isDisabled) {
            openAndAnimate(!isOpen);
          } else {
            noop();
          }
          e.stopPropagation();
        }}
        onChange={(e) => {
          setVal({ label: e.target.value, value: e.target.value?.toLowerCase?.()?.trim() });
          e.stopPropagation();
        }}
        onFocus={(e) => {
          onChange(value);
          setVal({ label: "", value: "" });
          e.stopPropagation();
        }}
        value={isOpen ? val?.label : value?.label}
        placeholder={!isTable ? placeholder : placeholder}
      />}
      {isOpen && (
        <div className="select_field_options_wrapper">
          {(isSearchable ? options?.filter((item) => !val?.label || ((item?.name || item?.label)?.toLowerCase?.().includes(val?.value))) : options)
          ?.map?.((option, index) => {
            return (
              <div
                key={index}
                className={`select_field_options_option ${option?.className}`}
                onClick={() => {
                  changeValue(option);
                }}
              >
                {option?.name || option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Select = ({
  control,
  defaultValue,
  label,
  name,
  required,
  pattern,
  placeholder,
  options,
  isTable = false,
  isDisabled = false,
  isSearchable = false,
  isLabel = true,
  error,
  tooltip,
  onTableChange = () => {
    return null;
  },
}) => {
  const getError = () => {
    const result = [name].map((s) =>
      s
        .split(".")
        .filter((t) => t !== "")
        .reduce((prev, cur) => prev && prev[cur], error)
    )[0];

    return result?.message || "";
  };

  return (
    <div
      className={`select_field_controlled_container ${
        isTable ? "is_table" : ""
      } ${error ? "is_error" : ""}`}
    >
      {!isTable && isLabel && (
        <label htmlFor={name} className={`${required ? 'required' : ''}`}>
          {label}
          {<InfoTooltip html={tooltip} />}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ required: required, pattern: pattern }}
        defaultValue={defaultValue || ""}
        render={({ field, fieldState: { error } }) => (
          <SelectTemplate
            placeholder={placeholder}
            onChange={(value) => {
              field.onChange(value);
              onTableChange(value);
            }}
            value={field.value}
            options={options}
            isTable={isTable}
            error={error}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
          />
        )}
      />
      {getError() && (
        <div className="error_message">
          <span>{getError()}</span>
        </div>
      )}
    </div>
  );
};

const DatePicker = ({
  name,
  label,
  required,
  pattern,
  validate,
  control,
  defaultValue,
  placeholder,
  error,
  dateFormat,
  datePickerData = {},
  isTime = false,
}) => {
  
  return (
    <div
      className={`datepicker_field_controlled_container  ${
        error?.message ? "is_error" : ""
      }`}
    >
      <label htmlFor={name}>{label}</label>
      <Controller
        control={control}
        name={name}
        rules={{ required: required, pattern: pattern, validate: validate }}
        defaultValue={defaultValue ? new Date(defaultValue) : null}
        render={({ field }) => (
          <>
            {!isTime && (
              <ReactDatePicker
                selected={field?.value ? new Date(field?.value) : null}
                onChange={(value) => field.onChange(value)}
                startDate={addDays(new Date(), 1)}
                // minDate={addDays(new Date(), 1)}
                className={"input_error"}
                minDate={new Date()}
                dateFormat={dateFormat || null}
                placeholderText={placeholder}
                {...datePickerData}
              />
            )}
            {isTime && (
              <ReactDatePicker
                selected={field?.value ? new Date(field?.value) : null}
                onChange={(value) => field.onChange(value)}
                dateFormat={dateFormat || null}
                minDate={new Date()}
                selectsStart
                // startDate={dates.startDate}
                // endDate={dates.endDate}
                showTimeInput
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption={'getLabel("analysisExamStartTime")'}
                placeholderText={placeholder}
                // timeCaption={getLabel("analysisExamStartTime")}
                {...datePickerData}
              />
            )}
            {isTime && (!field?.value || field?.value === null) && (
              <div className="error_message">
                <span>{error?.message}</span>
              </div>
            )}
          </>
        )}
      />
      {!isTime && (
        <div className="error_message">
          <span>{error?.message}</span>
        </div>
      )}
    </div>
  );
};

const MultipleSelect = ({
  error,
  control,
  defaultValue,
  label,
  name,
  required,
  pattern,
  placeholder,
  closeMenuOnSelect,
  options,
  tooltip,
}) => {
  const [selectedOptions, setSelectedOptions] = useState();
  const animatedComponents = makeAnimated();
  const colourStyles = {
    className: "multiple_select_field",
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        minHeight: "47px",
        borderRadius: "8px",
        border: isFocused
          ? "2px solid rgba(33, 37, 31, 0.32)"
          : "2px solid rgba(33, 37, 31, 0.32)",
        boxShadow: isFocused ? "0px 0px rgba(33, 37, 31, 0.32)" : "none",
        "&:hover": {
          border: "2px solid rgba(33, 37, 31, 0.32)",
        },
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "rgba(198, 227, 225, 0.2)",
        // cursor: isDisabled ? 'not-allowed' : 'default',
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "rgba(185, 184, 180, 0.5)"
          : isFocused
          ? "rgba(185, 184, 180, 0.35)"
          : undefined,
        color: isDisabled ? "#64242e" : "black",
        ":active": {
          ...styles[":active"],
        },
      };
    },

    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: "#64242e",
        color: "white",
      },
    }),
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  return (
    <div
      className="multiple_select_field_controlled_container"
      style={{ zIndex: 11 }}
    >
      <label htmlFor={name}>
        {label}
        {<InfoTooltip html={tooltip} />}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: required, pattern: pattern }}
        defaultValue={defaultValue || ""}
        render={({ field }) => (
          <ReactSelect
            components={animatedComponents}
            options={options}
            placeholder={placeholder}
            onChange={(value) => field.onChange(value)}
            value={field.value}
            // value={selectedOptions}
            // onChange={handleSelect}
            isSearchable={true}
            styles={colourStyles}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti
          />
        )}
      />
      <div className="error_message">
        <span>{error?.message}</span>
      </div>
    </div>
  );
};

const Slider = ({
  label = "",
  register,
  name,
  type,
  placeholder = "",
  required,
  minLength,
  maxLength,
  max,
  min,
  regExpPattern,
  validate,
  isDisabled,
  onChange,
  onBlur,
  onFocus,
  shouldUnregister,
  error,
  isLabel = true,
  checked = false,
  isText = false,
  defaultValue = "",
  tooltip,
  additional = {},
}) => {
  const MAX = 100;
  const [value, setValue] = useState(0);
  
  const getBackgroundSize = (isFixed = false) => {
    return isFixed ? {
      display: "block",
      width: `${defaultValue || 0}%`,
    } : 
    {
      backgroundSize: `${((value || defaultValue) * 100) / MAX}% 100%`,
      display: "block",
      marginTop: '15px',
      marginBottom: '5px',
    };;
  };

  return (
    <div className={'slider'}>
      {register && <Input
        name={name}
        register={register}
        type={"range"}
        error={error}
        required={required}
        max={max}
        min={min}
        label={label}
        onChange={(e) => setValue(e.target.value)}
        style={getBackgroundSize()}
        tooltip={tooltip}
      />}
      {!register && 
      <div className="is-flex">
        <div className="no-slide">
          <div className={`progress ${additional?.color || 'red'}`} style={getBackgroundSize(true)} ></div>
        </div>
      </div>}
      {isText && <p className="slider_text">{`${value || defaultValue}% ${!register ? ` (${additional?.quantity}L)` : ''}`}</p>}
    </div>
  );
};

export {
  Input,
  Submit,
  Switch,
  Label,
  Select,
  TextArea,
  ChatArea,
  MultipleSelect,
  DatePicker,
  Slider,
};
