import { useEffect, useState } from "react";

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  isAutofocus = false,
  forwardRef,
  setFilterOpened = () => null,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);

    if (!event.nativeEvent.inputType) {
      event.target.blur();
      onChange(event.target.value);
      setFilterOpened("");
    }
  };

  const clear = (event) => {
    event.target.value = "";
  };

  return (
    <input
      type="input"
      {...props}
      onChange={handleChange}
      value={value}
      autoFocus={isAutofocus}
      onKeyDown={(e) =>
        (e.key === "Enter" || e.key === "Escape") ? setFilterOpened("") : null
      }
    />
  );
};

export default DebouncedInput;
