import { useParams } from "react-router-dom"
import SecondaryBar from "../SecondaryBar"
import useLabels from "../../hooks/useLabels";
import { useEffect, useState } from "react";
import { Button } from "../Generic";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveWinery,  selectAnalysis,  selectConfigs,  selectDrugs,  selectExams,  selectLots,  selectOperations,  selectTanks,  selectTreatments,  selectUser,  selectUsers } from "../../context/selectors";
import DestemmingForm from "./DestemmingForm";
import AnalysisForm from "./AnalysisForm";
import CutForm from "./CutForm";
import { setOperations } from "../../context/operations/operationsSlice";
import { getDrugs, getExams, getLots, getOperations, getProtocols, getTanks, getUsers } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { setUsers } from "../../context/users/usersSlice";
import { setTanks } from "../../context/tanks/tanksSlice";
import { setLots } from "../../context/lots/lotsSlice";

import './styles/index.scss';
import TransferForm from "./TransferForm";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import WineMakingForm from "./WineMakingForm";
import LotForm from "../LotForm";
import AutomaticForm from "./AutomaticForm";
import BottlingForm from "./BottlingForm";
import { setDrugs } from "../../context/substances/drugsSlice";
import { setExams } from "../../context/substances/examsSlice";

const OperationsForm = ({isDuplicate}) => {

    const {id, type} = useParams();
    const [getLabel] = useLabels();
    const activeWinery = useSelector(selectActiveWinery);
    
    const user = useSelector(selectUser);
    const users = useSelector(selectUsers);
    const tanks = useSelector(selectTanks);
    const lots = useSelector(selectLots);
    const configs = useSelector(selectConfigs);
    const operations = useSelector(selectOperations);
    const protocols = useSelector(type === 'TREATMENT' ? selectTreatments : selectAnalysis);
    const substances = useSelector(type === 'TREATMENT' ? selectDrugs : selectExams);
    {console.log('substances', substances, type)}

    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const [operation, setOperation] = useState(null);

    const loadOperations = async () => {
        const currentOperations = await getOperations(axiosPrivate)
        dispatch(setOperations(currentOperations))
    }

    const loadUsers = async () => {
        const currentUsers = await getUsers(axiosPrivate);
        dispatch(setUsers(currentUsers))
    }

    const loadTanks = async () => {
        const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
        dispatch(setTanks(currentTanks))
    }

    const loadLots = async () => {
        const currentLots = await getLots(activeWinery.id, axiosPrivate);
        dispatch(setLots(currentLots))
    }

    const loadTreatments = async () => {
        const currentTreatments = await getProtocols('treatment', axiosPrivate);
        dispatch(setTreatments(currentTreatments))
    }

    const loadAnalysis = async () => {
        const currentAnalysis = await getProtocols('analysis', axiosPrivate);
        dispatch(setAnalysis(currentAnalysis))
    }

    const loadSubstances = async () => {
        const currentSubstances = await (type === "TREATMENT"
        ? getDrugs(axiosPrivate)
        : getExams(axiosPrivate));
        dispatch(
        type === "TREATMENT"
            ? setDrugs(currentSubstances)
            : setExams(currentSubstances)
        );
    };
    
    const initData = async () => {
        const promiseArray = [];
        if (operations?.length === 0) {
            promiseArray.push(loadOperations())
        }
        if (users?.length === 0) {
            promiseArray.push(loadUsers())
        }
        if (tanks?.length === 0) {
            promiseArray.push(loadTanks())
        }
        if (lots?.length === 0) {
            promiseArray.push(loadLots())
        }
        if (substances?.length === 0) {
            promiseArray.push(loadSubstances())
        }
        if (protocols?.length === 0) {
            promiseArray.push(loadAnalysis());
            promiseArray.push(loadTreatments());
        }
        return Promise.all(promiseArray);
    }

    useEffect(() => {
        initData();
    }, [])

    useEffect(() => {
        if (operations && operations?.length > 0) {
            setOperation(operations.find((operationsDb) => {
                return operationsDb.id === Number(id)
            }) || null)
        }
    }, [operations])


    const renderNavMenu = () => {
        const navClickHandler = () => {
            window.history.back()
        }
        return (
            <div className="primary_container_menu">
                <div className="primary_container_row">
                    <Button
                        arrowDirection="left"
                        onClick={navClickHandler}
                    />
                    <h2>{getLabel(id && !isDuplicate  ? 'programmingUpdatePage' : 'programmingNewPage')}</h2>
                </div>
            </div>
        )
    }

    const renderOperationCase = () => {
        switch (type) {
            case 'NEW_LOT':
                return <LotForm isOnlyForm={true} operation={operation} />
            case 'DESTEMMING':
                return <DestemmingForm
                    // lots={lots.filter((lot) => lot?.state === 'GRAPE')}
                    lots={lots}
                    // allLots={lots}
                    tanks={tanks}
                    // tanks={tanks.filter((tank) => tank?.quantity === null || tank?.quantity === 0)}
                    operation={operation}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    configs={configs}
                    activeWinery={activeWinery}
                    isNewLot={type === 'NEW_LOT'}
                />
            case 'TREATMENT':
            case 'ANALYSIS':
                return <AnalysisForm
                    type={type}
                    tanks={tanks}
                    lots={type === 'TREATMENT' ? lots?.filter((l) => l.state !== 'GRAPE') : lots}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    protocols={protocols}
                    allSubstances={substances}
                    activeWinery={activeWinery}
                    configs={configs}
                    operation={operation}
                    operations={operations}
                />
            case 'CUT':
                return <CutForm
                    lots={lots.filter((lot) => lot?.state !== 'GRAPE')}
                    tanks={tanks}
                    type={type}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    activeWinery={activeWinery}
                    configs={configs}
                />
            case 'DECANT':  // travaso
            case 'RACKING': // svinatura
                return <TransferForm
                    lots={lots}
                    tanks={tanks}
                    type={type}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    activeWinery={activeWinery}
                    configs={configs}
                    isDuplicate={isDuplicate}
                />
            case 'WINEMAKING':
            case 'WINEMAKINGPOST':
                return <WineMakingForm
                    lots={lots}
                    tanks={tanks}
                    type={type}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    activeWinery={activeWinery}
                    configs={configs}
                />
            case 'BOTTLING':
                return <BottlingForm
                    lots={lots?.filter((l) => l.state === 'WINE')}
                    tanks={tanks?.filter((t) => Number(t?.quantity) > 0 && t?.state === 'WINE')}
                    type={type}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    activeWinery={activeWinery}
                    configs={configs}
                />
            case "ENZYME":
            case "SO2":
            case "HOMOGENIZATION_MASS":
            case "NITROGEN": 
            case "INOCULATION":
            case "BOIS":
            case "TENNISSAGE":
            case "OXYGENATION":
                return (
                <AutomaticForm
                    type={type}
                    operation={operation}
                    users={user.role === 2 ? users?.filter((u) => u.username != user.userName && u.role_id === 3) : users}
                    tanks={tanks}
                    lots={lots}
                    // drugs={drugs}
                    configs={configs}
                    // axiosPrivate={axiosPrivate}
                    />
                );
            default: 
                console.error('error case')
                return null
        }
    }

    return (<div className="primary_container">
        <SecondaryBar
            breadCrumb={
                (id && !isDuplicate )? [
                    getLabel('programmingNavLink'),
                    getLabel(`operationType${type}`),
                    // getLabel('programmingTitlePage', {name: operation?.name}),
                    getLabel('programmingUpdatePage')
                ] : [
                    getLabel('programmingNavLink'),
                        getLabel(`operationType${type}`),
                    getLabel('programmingNewPage')
                ]
            }
        />
        {renderNavMenu()}
        {renderOperationCase()}
    </div>)
}

export default OperationsForm