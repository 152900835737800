import useLabels from "../../hooks/useLabels";
import Chart from "./Chart";
import "./styles/index.scss";

const DataPlot = ({ data = [] }) => {
  const [getLabel] = useLabels();
  
  return (
    <div className="plot_wrapper">
      <h4>{getLabel("analysisEvolution")}</h4>
      <div className="analysis_plot">
        {data?.length > 0 && <Chart
          data={data}
          scatterData={[]}
          dataKey={'value'}
        />}
        {data?.length === 0 && (
          <h5 className="alert_no_info">{getLabel("noAnalysisCompleted")}</h5>
        )}
      </div>
    </div>
  );
};

export default DataPlot;
