import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom"
import useLabels from "../../hooks/useLabels";
import { getClients } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { setClients } from "../../context/clients/clientsSlice";
import { selectActiveWinery, selectClients, selectConfigs } from "../../context/selectors";
import { Label } from "../FormComponents";
import SecondaryBar from "../SecondaryBar";
import { Button, CTA } from "../Generic";

import './styles/index.scss';
import { format } from "date-fns";

const ClientInfo = ({ client }) => {
    const [getLabel] = useLabels();
    const configs = useSelector(selectConfigs);

    return (<div className="client_info_container">
        <div className="client_info_container_row">
            <Label label={getLabel('username')}>{client.username}</Label>
            <Label label={getLabel('name')}>{client.name}</Label>
            <Label label={getLabel('surname')}>{client.surname}</Label>
            <Label label={getLabel('clientEmail')}>{client.client_email}</Label>
        </div>
        <div className="client_info_container_row">
            <Label label={getLabel('clientType')}>{getLabel(`user_type_${client.type}`)}</Label>
            <Label label={getLabel('clientAccountState')}>{client.deactivated ? getLabel('clientDeactivated') : getLabel('clientActivated')}</Label>
            <Label label={getLabel('passwordExpireDate')}>{client?.password_expire_date ? format(new Date(client.password_expire_date), configs.shortDateFormat) : ''}</Label>
        </div>
        <div className="client_info_container_row">
            <Label label={getLabel('usersNumber')}>{client.users_number}</Label>
            <Label label={getLabel('wineriesNumber')}>{client.wineries_number}</Label>
            <Label label={getLabel('licensePasswordExpireDate')}>{client?.expire_date ? format(new Date(client.expire_date), configs.shortDateFormat) : ''}</Label>
        </div>
        <div className="client_info_container_row">
            <Label width={400} height={150} label={getLabel('clientNotes')} customClassName={"notes"}>{client.notes}</Label>
        </div>
    </div>)
}

const ClientDetail = () => {

    const activeWinery = useSelector(selectActiveWinery)
    const { id } = useParams();
    const clients = useSelector(selectClients);
    const [getLabel] = useLabels();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const [client, setClient ] = useState({});
    const navigate = useNavigate();

    const pageName = getLabel('clientTitlePage', {username: client?.username || id})

    useEffect(() => {
        console.log('0', clients)
        if (!clients || clients.length === 0) {
            loadClients();
        }
        console.log('1', clients)
    }, [])

    useEffect(() => {
        if (clients && clients.length > 0) {
            setClient(clients.find((dbClient) => {
                return dbClient.id === Number(id)
            }) || {});
        }
    }, [id, clients])

    const loadClients = async () => {
        const currentClients = await getClients(null, axiosPrivate);
        dispatch(setClients(currentClients));
    }

    const renderNavMenu = () => {
        const navClickHandler = () => {
            navigate('/clients')
        }
        return (
            <div className="primary_container_menu">
                <div className="primary_container_row">
                <Button
                    arrowDirection="left"
                    onClick={navClickHandler}
                />
                <h2>{pageName}</h2>
                </div>
                <div className="primary_container_row">
                    <NavLink
                        to={`/clients/${id}/update`}
                    >
                        <CTA>
                            {getLabel('updateClient')}
                        </CTA>
                    </NavLink>
                    <CTA>{getLabel('deleteClient')}</CTA>
                </div>
            </div>
        )
    }



    const renderNextOperation = () => {
        return (<div>
            <div className="primary_container_menu">
                <h4>{getLabel('client_nextOperations')}</h4>
                <NavLink to={'/programming/new'}>
                    <CTA>{getLabel('programOperation')}</CTA>
                </NavLink>
            </div>
        </div>)
    }


    return (
        <div className="primary_container">
            <SecondaryBar
                breadCrumb={[
                    getLabel('clientsNavLink'),
                    getLabel('clientsUpdatePage')
                ]}
                isBasePathNeeded={false}
            />
            {renderNavMenu()}
            <ClientInfo client={client}/>
            {/* {renderNextOperation()} */}
        </div>
    )
}

export default ClientDetail