import { addDays } from "date-fns";
import { ToastContainer } from "react-toastify";
import { selectActiveWinery } from "../context/selectors";
import { useSelector } from "react-redux";
import useAuth from "../hooks/useAuth";
import useLabels from "../hooks/useLabels";
import { Toast } from "./Generic";

const ToastManager = ({
  
}) => {
  const { auth } = useAuth();
  const [getLabel] = useLabels();
  const activeWinery = useSelector(selectActiveWinery);

  const isPasswordExpiring = (auth?.password_expire_date && addDays(new Date(), 10) > new Date(auth?.password_expire_date)) || false;
  // TO DO: after password change, force user logout or update auth?.password_expire_date otherwise the popup keeps appearing
  return (
    <>
      <ToastContainer 
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={3} 
      />
      {activeWinery &&
      <>
        {isPasswordExpiring && 
          <Toast
            toastId={'toast_WarningIsPasswordExpiring'}
            message={
              getLabel("toast_WarningIsPasswordExpiring") ||
              getLabel("toast_NoMessage")}
            autoClose={false}
            type={'warning'}
            navigateTo={auth?.user_id ? `users/${auth?.user_id}/update` : null}
        />
        }
      </>
      }
    </>
  );
};

export default ToastManager;