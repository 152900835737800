import { createSlice } from '@reduxjs/toolkit';

export const labelsSlice = createSlice({
    name: 'labels',
    initialState: {},
    reducers: {
        empty: state => {
            return {}
        },
        setArea: (state, action) => {
            const { locale, area, payload } = action.payload
            state[locale] = {
                ...state[locale],
                [area]: payload
            }
            return state
        },
        setAll: (state, action) => {
            return action.payload;
        }
    }
});

export const { empty, setArea, setAll } = labelsSlice.actions;

export default labelsSlice.reducer