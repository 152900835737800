import { createSlice } from "@reduxjs/toolkit";

export const sensorsSlice = createSlice({
    name: 'sensors',
    initialState: [],
    reducers: {
        emptySensors: state => {
            return []
        },
        setSensors: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        add: (state, action) => {
            // return [...state, action.payload]
            return []
        },
        update: (state, action) => {
            // const foundedIndex = state.findIndex((sensor) => sensor.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return []
        },
        removeS: (state, action) => {
            const foundedIndex = state?.findIndex((sensor) => sensor.id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state?.filter((d, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setSensors, 
    emptySensors,
    update,
    removeS,
    add
} = sensorsSlice.actions;

export default sensorsSlice.reducer