import { useDispatch, useSelector } from "react-redux";
import { setAll, setArea } from "../context/labels/labelsSlice";
import { selectConfigs, selectLabels } from "../context/selectors";
import jsonLabels from '../assets/labels/labels.json'
import useAxiosPrivate from "./useAxiosPrivate";
import { getLabelByArea } from "../services/utils";


const useLabels = () => {
    const { area, locale } = useSelector(selectConfigs);
    const labels = useSelector(selectLabels);
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    const getLabel = (label, replacableTokens = {}) => {
        const storedLabel = getFromStore(label);
        if (storedLabel) {
            if (Object.keys(replacableTokens).length > 0) {
                let rawLabel = storedLabel;
                for (const token in replacableTokens) {
                    rawLabel = rawLabel.replace(`{{${token}}}`, replacableTokens[token] || '')
                }
                return rawLabel
            }
            return storedLabel
        }
        if (Object.keys(replacableTokens).length > 0) {
            let rawLabel = `#${locale}_${area}_${label}`;
            for (const token in replacableTokens) {
                rawLabel = `${rawLabel}:${replacableTokens[token]}`
            }
            return rawLabel
        }
        return `#${locale}_${area}_${label}`
    }

    const getArea = () => {
        const storedArea = getFromStoreArea(area);
        if (storedArea) {
            return storedArea
        }
        return {}
    }

    const getFromStoreArea = (interestedArea) => {
        return labels?.[interestedArea];
    }

    const getFromStore = (label) => {
        return labels?.[locale]?.[area]?.[label]
    }

    const checkSession = () => {
        return Object.keys(labels?.[locale]?.[area] || {}).length > 0;
    }

    const getLabelFromServer = async () => {
        const response = await getLabelByArea(area, axiosPrivate);
        
        const errorResponse = await getLabelByArea('error', axiosPrivate);
        const errors = {};
        if (errorResponse) {
            for (const [key, dict] of Object.entries(errorResponse)) {
                for (const [id, value] of Object.entries(dict)) {
                    errors[`${key}.${id}`] = value;
                }
            }
        }
        
        return { ...response, ...errors } || {};
    }

    const setLabels = async () => {
        if (checkSession()) {
            return;
        }
        const serverLabels = await getLabelFromServer();
        dispatch(setArea({ locale: locale, area: area, payload: serverLabels }))
        // dispatch(setAll(jsonLabels))
    }

    return [getLabel, getArea, setLabels]

}


export default useLabels