import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { COOKIE, UTILS } from "../../constants/apis";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLabels from "../../hooks/useLabels";
import { CTA } from "../Generic";
import TableEdit from "../TableEdit";
import TableCell from "../TableEdit/TableCell";
import "./styles/index.scss";

const CookieBar = ({ isBar = true }) => {
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const [isCookieAccepted, setIsCookieAccepted] = useState(true);

  const [isMandatory, setIsMandatory] = useState(true);

  useEffect(() => {
    const cookieAccepted = Cookies.get("viewed_cookie_policy");
    if (cookieAccepted === "yes") {
      setIsCookieAccepted(true);
      return;
    }
    setIsCookieAccepted(false);
  }, []);

  const acceptCookie = async () => {
    try {
      const response = await axiosPrivate.post(`${UTILS}/${COOKIE}`, {
        withCredentials: true,
      });
      if (response.status === 200) setIsCookieAccepted(true);
    } catch (err) {
      console.error(err);
    }
  };

  const denyCookie = async () => {
    try {
      const response = await axiosPrivate.delete(`${UTILS}/${COOKIE}`, {
        withCredentials: true,
      });
      if (response.status === 200) setIsCookieAccepted(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCookieAccepted(true);
    }
  };

  if (!isBar) {
    const columnHelper = createColumnHelper();
    const columns = [
      columnHelper.accessor("name", {
        header: getLabel("cookie_settings_cookie"),
        cell: TableCell,
        meta: {
          isFilterDisabled: true,
        }
      }),
      columnHelper.accessor((row) => getLabel(`cookie_settings_mandatory_${row?.name}_duration`), {
        header: getLabel("cookie_settings_duration"),
        cell: TableCell,
        meta: {
          isFilterDisabled: true,
        }
      }),
      columnHelper.accessor((row) => getLabel(`cookie_settings_mandatory_${row?.name}_description`), {
        header: getLabel("cookie_settings_description"),
        cell: TableCell,
        meta: {
          isFilterDisabled: true,
        }
      }),
      columnHelper.accessor((row) => row?.editable && Cookies.get("viewed_cookie_policy") === 'yes', {
        header: getLabel("cookie_settings_actions"),
        cell: TableCell,
        meta: {
            type: 'remove_cookie',
            onClickHandler: denyCookie,
            isFilterDisabled: true,
        }
      }),
    ];

    const cookies = [
      {
        name: 'jwt',
        duration: 'cookie_settings_mandatory_jwt_duration',
        description: 'cookie_settings_mandatory_jwt_duration',
        editable: false,
      },
      {
        name: 'viewed_cookie_policy',
        duration: 'cookie_settings_mandatory_viewed_cookie_policy_duration',
        description: 'cookie_settings_mandatory_viewed_cookie_policy_description',
        editable: true,
      }
    ]

    return (
      <div className="cookie_settings">
        <h4>{getLabel("cookie_settings_title")}</h4>
        <p>{getLabel("cookie_settings_text")}</p>
        <div className="cookie_settings_content">
          <div className="">
            <h5 onClick={() => setIsMandatory((prev) => !prev)}>
              {getLabel("cookie_settings_mandatory")}{" "}
              <FontAwesomeIcon
                className={"icon"}
                size={"xs"}
                icon={isMandatory ? "fa-angles-down" : "fa-angles-up"}
              />
            </h5>
            {isMandatory && (
              <div>
                <p className="subtitle">
                  {getLabel("cookie_settings_mandatory_description")}
                </p>
                <TableEdit
                  defaultData={cookies}
                  columns={columns}
                  isSearchBar={false}
                  isLegend={false}
                />

              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (isCookieAccepted) return null;

  return (
    <div className="cookie_wrapper">
      <div className="cookie_content">
        <p>{getLabel("cookie_text")}</p>
        <div className="buttons">
          <CTA customClassName={"dialog"} onClick={acceptCookie}>
            {getLabel("cookie_buttonAccept")}
          </CTA>
          <CTA customClassName={"dialog"} onClick={denyCookie}>
            {getLabel("cookie_buttonDeny")}
          </CTA>
          <CTA
            customClassName={"dialog"}
            onClick={() =>
              window.open("/cookie", "_blank", "rel=noopener noreferrer")
            }
          >
            {getLabel("cookie_buttonMore")}
          </CTA>
          <CTA customClassName={"dialog"}>
            {getLabel("cookie_buttonSettings")}
          </CTA>
        </div>
      </div>
    </div>
  );
};

export default CookieBar;
