import useLabels from "../../hooks/useLabels";
import { Select } from "../FormComponents";
import { useForm, useWatch } from "react-hook-form";
import Chart from "./Chart";
import { format } from "date-fns";
import { useEffect, useState } from "react";

const AnalysisEvolution = ({ analysisResults, exams, configs, tanks = [], lots = [], treatmentResults = [] }) => {
  const [getLabel] = useLabels();
  const [analysis, setAnalysis] = useState([]);
  const [analysisNames, setAnalysisNames] = useState(new Set());
  const [tankNames, setTankNames] = useState(new Set());
  const [lotNames, setLotNames] = useState(new Set());
  const [borders, setBorders] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      name: "",
    },
    mode: "onTouched",
  });
  
  useEffect(() => {
    const tmpAnalysis = exams?.map((e) =>
      e.analysis?.map((an) => ({
        value: an.substance_name,
        label: getLabel(`substance_${an.substance_name}`),
      }))
    )[0];
    setAnalysis(tmpAnalysis);
    setAnalysisNames(new Set(tmpAnalysis));
    
    setBorders(exams?.map((e) =>
      e.analysis?.map((an) => ({
        name: an.substance_name,
        min_range: an?.min_range,
        max_range: an?.max_range,
        unit: an?.unit,
      }))
    )[0]);

  }, [exams]);

  useEffect(() => {
    const tankNamesTmp = new Set();
    tanks?.reduce((_, item) => tankNamesTmp.add({label: item?.name, value : item?.id}), null);
    setTankNames(tankNamesTmp);
  }, [tanks]);

  useEffect(() => {
    const lotNamesTmp = new Set();
    lots?.reduce((_, item) => lotNamesTmp.add({label: item?.name, value : item?.id}), null);
    setLotNames(lotNamesTmp);
  }, [lots]);

  const { name, tank, lot } = useWatch({ control });
  
  const sameDay = (d1, d2) => {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  };

  const filterByTanks = () => {
    if (tankNames?.size === 1 || !tanks)
      return analysisResults?.map((a) => ({
        ...a,
        ...a?.results,
        // date: format(new Date(a?.completion_date) || null, analysisResults?.filter((aa) => sameDay(new Date(a?.completion_date), new Date(aa?.completion_date)))?.length > 0 ? configs.dateFormat : configs.shortDateFormat),
        fullDate: format(new Date(a?.collection_date) || null, configs.dateFormat),
        date: format(new Date(a?.collection_date) || null, configs.shortDateFormat),
      }));
    else {
      const tank_id = tanks?.find((t) => t.name == getValues("tank")?.label)?.id;  
      return analysisResults?.map((a) => ({
        ...a,
        ...a?.results,
        // date: format(new Date(a?.collection_date) || null, configs.shortDateFormat),
        fullDate: format(new Date(a?.collection_date) || null, configs.dateFormat),
        date: format(new Date(a?.collection_date) || null, configs.hourShortDateFormat),
      }))?.filter((a) => a.tank_id == tank_id);
    }
  }

  const filterByLots = () => {
    if (lotNames?.size === 1 || !lots)
      return analysisResults?.map((a) => ({
        ...a,
        ...a?.results,
        // date: format(new Date(a?.collection_date) || null, configs.shortDateFormat),
        fullDate: format(new Date(a?.collection_date) || null, configs.dateFormat),
        date: format(new Date(a?.collection_date) || null, configs.shortDateFormat),
      }));
    else {
      const lot_id = lots?.find((t) => t.name == getValues("lot")?.label)?.id;  
      return analysisResults?.map((a) => ({
        ...a,
        ...a?.results,
        // date: format(new Date(a?.collection_date) || null, configs.shortDateFormat),
        fullDate: format(new Date(a?.collection_date) || null, configs.dateFormat),
        date: format(new Date(a?.collection_date) || null, configs.shortDateFormat),
      }))?.filter((a) => a.batch_id == lot_id);
    }
  }

  const filterByType = (isTank) => {
    return isTank ? filterByTanks() : filterByLots();
  }
  
  return (
    <div className="primary_container_lower_info">
      <h4>{getLabel("analysisEvolution")}</h4>
      {analysisResults?.length > 0 && <>
      <div className="analysis_plot">
      {tankNames?.size > 1 && <Select
        name="tank"
        control={control}
        label={getLabel("tanks")}
        placeholder={getLabel("tanksPlaceholder")}
        error={errors["tank"]}
        required={getLabel("inputRequiredError")}
        options={[{ value: "", label: "" }, ...Array.from(tankNames)]}
        defaultValue={{
          value: "",
          label: "",
        }}
      />}
      {lotNames?.size > 1 && <Select
        name="lot"
        control={control}
        label={getLabel("lots")}
        placeholder={getLabel("lotsPlaceholder")}
        error={errors["lot"]}
        required={getLabel("inputRequiredError")}
        options={[{ value: "", label: "" }, ...Array.from(lotNames)]}
        defaultValue={{
          value: "",
          label: "",
        }}
      />}
      <Select
        name="name"
        control={control}
        label={getLabel("analysisSubstanceName")}
        placeholder={getLabel("analysisSubstanceNamePlaceholder")}
        error={errors["name"]}
        required={getLabel("inputRequiredError")}
        options={[{ value: "", label: "" }, ...Array.from(analysisNames)]}
        defaultValue={{
          value: "",
          label: "",
        }}
      />
    </div>
      {(tankNames?.size === 1 || (tankNames?.size === 0 || getValues("tank")?.value) || 
        (lotNames?.size === 0 || getValues("lot")?.value)) && 
        getValues("name")?.value && (tankNames?.size === 1 || (tankNames?.size > 1 && getValues("tank")?.value)) && 
        <Chart
          data={filterByType(tanks?.length > 0)}
          scatterData={treatmentResults}
          borders={borders}
          dataKey={getValues("name")?.value}
        />}
      </>}
      {analysisResults?.length === 0 && (
        <h5 className="alert_no_info">{getLabel("noAnalysisCompleted")}</h5>
      )}
    </div>
  );
};

export default AnalysisEvolution;
