import { NavLink } from "react-router-dom"
import useLabels from "../../hooks/useLabels"
import SecondaryBar from "../SecondaryBar"
import { CTA } from "../Generic"
import ClientList from "../ClientList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Clients = () => {

    const [getLabel] = useLabels()

    const renderNavMenu = () => {
        return (
            <div className="primary_container_menu">
                <h2>{getLabel('clients')}</h2>
                <NavLink to='/clients/new'>
                    <CTA>
                        {getLabel('createClient')}{" "}<FontAwesomeIcon icon="fa-plus" />
                    </CTA>
                </NavLink>
            </div>
        )
    }

    return (<div className="primary_container">
        <SecondaryBar
            breadCrumb={[
                getLabel('clientsNavLink')
            ]}
            isBasePathNeeded={false}
        />
        {renderNavMenu()}
        <ClientList/>
    </div>)
}

export default Clients