export const USER_REGEX = /^[A-z][.A-z0-9-_]{3,15}$/;
export const NOT_USER_REGEX = /[^.a-zA-Z0-9-_]/g;
export const PWD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^&*()+=]).{6,20}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX = /^[ A-Za-z0-9_.-]{2,30}$/;
export const ADDRESS_REGEX = /^[\/ .A-Za-z0-9_-]{5,30}$/;
export const CAP_REGEX = /^[0-9]{5}$/;
export const PROVINCE_REGEX = /^[A-Za-z]{2}$/;
export const ALPHA_SPACE_REGEX = /^[ A-Za-z]{2,30}$/;
export const MOBILE_PHONE_REGEX = /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[24-90]|36[680]|33[13-90]|32[890]|35[013]|37[0139]|39[23])\d{6,7}$/;
export const PHONE_REGEX = /^(\((00|\+)39\)|(00|\+)39)?(?:\d{8}(?:\d{2}(?:\d{2})?)?|\(\+?\d{2,3}\)\s?(?:\d{4}[\s*.-]?\d{4}|\d{3}[\s*.-]?\d{3}|\d{2}([\s*.-]?)\d{2}\1\d{2}(?:\1\d{2})?))$/;
export const ALPHA_SPACE_APEX_REGEX = /^[A-z][ 'A-Za-z]{2,30}$/;
export const ALPHA_SPACE_APEX_DOT_REGEX = /^[A-z][ '.A-Za-z]{2,30}$/;
export const NUM_ALPHA_SPACE_APEX_DOT_REGEX = /^[A-z][ '.A-Za-z0-9]{2,30}$/;
export const INT_FLOAT_REGEX = /^(?=.*\d)\d*(?:\.\d?\d)?$/;
export const INT_REGEX = /^[1-9]\d*$/;
export const INT_0_REGEX = /^[0-9]\d*$/;
export const YEAR_REGEX = /^(19|20)\d{2}$/;
export const NAME_REGEX = /^[a-zA-Z ]+$/;
