import axios from "axios";
import { BASE_URL } from "../services/constants";


export default axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

// 2 instances to attach here an interceptor (with the jwt token) used to retry when we get a failure (code 403)
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
