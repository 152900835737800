import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import "./styles/index.scss";

const AdditionalInfo = ({ type }) => {
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const isCookie = type === "cookie";
  const headers = isCookie ? [] : getLabel("privacy_header") || [];
  const [array, setArray] = useState([]);
  const privacy = isCookie ? [] : getLabel("privacy_items") || [];
  const cookie = isCookie ? getLabel("cookie") || [] : [];

  useEffect(() => {
    const tmp = [];
    if (array?.length === 0) {
      if (Array.isArray(cookie) && cookie?.length > 0) tmp.push(...cookie);
      if (Array.isArray(privacy) && privacy?.length > 0) tmp.push(...privacy);

      if (tmp?.length > 0) setArray(tmp);
    }
    console.log(tmp, privacy, cookie)
  }, [privacy, cookie]);

  const renderP = (pp) => {
    const { v, to } = pp;

    const isLink = String(to)?.length > 0;
    if (typeof v === "string")
      return (
        <p>
          {v}
          {isLink && to ? (
            <FontAwesomeIcon
              onClick={() =>
                pp?.title === "Cookie Policy" ? navigate(to) : null
              }
              size={"1x"}
              icon={"fa-link"}
              className="link"
            />
          ) : null}
        </p>
      );

    return (
      <div className="paragraphs">
        {v?.map?.((h, i) => {
          return (
            <div key={`par-${i}`}>
              {" "}
              {!Array.isArray(h) && <p>{h}</p>}
              {Array.isArray(h) && (
                <ul className="inner_ul">
                  {h?.map?.((hh, index) => {
                    return <li key={`policy-hh-${i}-${index}`}>{hh}</li>;
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="privacy_wrapper">
      <div className="privacy_header">
        <h4>
          {isCookie ? getLabel("cookie_title") : getLabel("privacy_title")}
        </h4>
        {Array.isArray(headers) && !isCookie && (
          <>
            <h5>{getLabel("privacy_subtitle", { name: "Wine2Trace" })}</h5>
            {headers?.map?.((h, i) => {
              return <p key={`policy-h-${i}`}>{h}</p>;
            })}
          </>
        )}
      </div>

      {array?.length > 0 && (
        <div className="privacy_content">
          {array?.map((item, index) => (
            <div className="column" key={`priv-${index}`}>
              <h5>{item?.title}</h5>
              <ul className="content">
                {item?.p?.map?.((pp, i) => {
                  return (
                    <li key={`policy-P-${i}`}>
                      <div
                        className={`li ${
                          pp?.title?.length === 0 ? "no_margin" : ""
                        }`}
                      >
                        <span>{pp?.title}</span>
                        {renderP(pp)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdditionalInfo;
