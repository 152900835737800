import { createSlice } from "@reduxjs/toolkit";

export const UserSocketsSlice = createSlice({
    name: 'UserSockets',
    initialState: [],
    reducers: {
        emptyUserSockets: state => {
            return []
        },
        setUserSockets: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        add: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        update: (state, action) => {
            // const foundedIndex = state.findIndex((User) => User.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return []
        },
        removeUserSockets: (state, action) => {
            const foundedIndex = state?.findIndex((user) => user.id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state.splice(foundedIndex, 1)
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setUserSockets, 
    emptyUserSockets,
    update,
    removeUserSockets,
    add
} = UserSocketsSlice.actions;

export default UserSocketsSlice.reducer
