import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { deleteLot, getLots, getOperations, getTanks } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { removeL, setLots } from "../../context/lots/lotsSlice";
import { setTanks } from "../../context/tanks/tanksSlice";
import {
  selectActiveWinery,
  selectConfigs,
  selectLots,
  selectOperations,
  selectTanks,
  selectUsers,
} from "../../context/selectors";
import { Label } from "../FormComponents";
import {
  Button,
  BatchColumn,
  CTA,
  CellarmanColumn,
  TankColumn,
  ConfirmationModal,
  Dropdown,
  TankIcon,
  RecapTankIcon,
} from "../Generic";

import "./styles/index.scss";
import AnalysisGrid from "../Home/AnalysisGrid";
import { ROLES } from "../../constants/base";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import SecondaryBar from "../SecondaryBar";
import NextOperations from "../TankDetail/NextOperations";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setOperations } from "../../context/operations/operationsSlice";

export const LotInfo = ({ lot, tankList, isDetail = true }) => {
  const [getLabel] = useLabels();
  const configs = useSelector(selectConfigs);

  let wineColor = "red";
  switch (lot?.color) {
    case "WHITE":
      wineColor = "white";
      break;
    case "ROSE":
      wineColor = "rose";
      break;
    default:
      wineColor = "red";
      break;
  }

  return (
    <div className="lot_info_container">
      {isDetail && <h4>{getLabel('lotDetails')}</h4>}
      {lot?.label && lot?.label?.length > 0 && <div className="lot_info_container_row">
        <Label label={getLabel("lotLabel")}>
          {lot.label?.map((lab, index) => {
            return <p key={`label-${index}`}>{getLabel(`lotLabel_${lab}`)}</p>;
          }, "") || ""}
        </Label>
      </div>}
      <div className="lot_info_container_row no_wrap">
        <Label label={getLabel("lotVarieties")}>
          {lot?.varieties?.map((v, index) => {
            // return <p>{v ? getLabel(`lotVarieties_${v}`) : ''}</p>;
            return <p key={`varieties-${index}`}>{v ? v : (index > 0 ? getLabel(`lotVarieties_${lot?.varieties[index - 1]}`): '')}</p>;
          }, "") || ""}
        </Label>
        <Label label={getLabel("lotYears")}>
          {lot?.years?.map((y, index) => {
            return <p key={`years-${index}`}>{y ? y : (index > 0 ? lot?.years[index - 1]: '')}</p>;
          }, "") || ""}
        </Label>
        <Label label={getLabel("lotOrigins")}>
          {lot.origins?.map((o, index) => {
            // return <p>{o}</p>;
            return <p key={`origins-${index}`}>{o ? o : (index > 0 ? lot?.origins[index - 1]: '')}</p>;
          }, "") || ""}
        </Label>
        {lot?.doco_id && lot?.doco_date && (
            <Label label={getLabel("lotDoco")}>
              {lot?.doco_id} - {format(new Date(lot?.doco_date), configs.shortDateFormat)}
            </Label>
          )}
      </div>
      <div className="lot_info_container_row">
        <Label label={getLabel("lotQuality")}>
          {getLabel(`lotQuality_${lot.quality}`)}
        </Label>
        {isDetail && <Label label={getLabel("lotQuantity")}>{`${lot?.actual_quantity} ${lot?.state === 'GRAPE' ? 'Kg' : 'L'}`}</Label>}
        {lot?.state === "WINE" && lot?.batch_type >= 0 && (
          <Label label={getLabel("lotType")}>{getLabel(`lotType${lot?.batch_type}`)}</Label>
        )}
        {lot?.color && (
          <Label label={getLabel("lotColor")}>
            {getLabel(`lotColor_${lot.color}`)}
          </Label>
        )}
        {lot?.state && (
          <Label label={getLabel("lotState")}>
            {getLabel(`lotState_${lot.state}`)}
          </Label>
        )}
      </div>
      {!isDetail && tankList?.length > 0 && <p className="tanks">{getLabel("lotTanks")}</p>}
          
      {lot.state !== "GRAPE" && (
        <>
          {tankList?.length > 0 && isDetail && <h4>{getLabel('lotComposition')}</h4>}
          <div className={`lot_info_container_row ${!isDetail ? 'space_evenly' : ''}`}>
            {tankList?.map((tank, index) => {
              return (
                <div className="tank" key={`tank-detail-${index}`}>
                  <RecapTankIcon
                    tank={tank}
                    wineColor={wineColor}
                  />
                </div>
              );
            })}
          </div>
          {/* <div className="lot_info_container_row">
            {tankList?.map((tank, index) => {
              return (
                <div className="lot_info_container_row" key={index}>
                  <div className="end_status">
                    <div className="tank_representation">
                      <div
                        className={"tank_tile_progress_" + wineColor}
                        style={{
                          height: `${
                            (Number(tank?.quantity) / Number(tank?.capacity)) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <div>
                      <p className="tank_name">{tank?.name}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
        </>
      )}

      {isDetail && <div className="lot_info_container_row">
        <Label width={400} height={150} label={getLabel("lotNotes")} customClassName={"notes"}>
          {lot?.note}
        </Label>
      </div>}
    </div>
  );
};

const LotDetail = () => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams();
  const operations = useSelector(selectOperations);
  const lots = useSelector(selectLots);
  const tanks = useSelector(selectTanks);
  const users = useSelector(selectUsers);
  const configs = useSelector(selectConfigs);
  const [getLabel] = useLabels();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [lot, setLot] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [tankList, setTankList] = useState([]);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const pageName = getLabel("lotTitlePage", { name: lot?.name || id });

  const columns = useMemo(() => [
    {
      Header: getLabel("operationType"),
      accessor: (row) => getLabel(`operationType${row.type}`),
    },
    {
      Header: getLabel("priority"),
      accessor: (row) => getLabel(`operationPriorityLong${row.priority}`),
    },
    {
      Header: getLabel("expiringDate"),
      accessor: (row) =>
        row.expire_date
          ? format(new Date(row.expire_date), configs.shortDateFormat)
          : "",
    },
    {
      Header: getLabel("operationSrcTank"),
      accessor: (row) =>
        row?.type === "DESTEMMING" ||
        row?.src_tanks?.find((tank) => tank?.batch_id) ? (
          <BatchColumn lots={lots} rowData={row?.src_tanks} type={row?.type} />
        ) : (
          <TankColumn rowData={row?.src_tanks} tanks={tanks} />
        ),
    },
    {
      Header: getLabel("operationDestTank"),
      accessor: (row) =>
        row?.dest_tanks?.find((tank) => tank?.batch_id) ? (
          <BatchColumn lots={lots?.dest_tanks} rowData={row} />
        ) : (
          <TankColumn rowData={row?.dest_tanks} tanks={tanks} />
        ),
    },
    //  {
    //     Header: getLabel('operationCompletionDate'),
    //     accessor: (row) => row.completion_date ? format(new Date(row.completion_date), configs.shortDateFormat) : '-'
    // }, {
    //     Header: getLabel('operationNotes'),
    //     accessor: (row) => row.notes
    // }
  ]);

  if (role === ROLES.CLIENT)
    columns.push({
      Header: getLabel("cellarman"),
      accessor: (row) => (
        <CellarmanColumn rowData={row?.cellarman_ids} users={users} />
      ),
    });

  useEffect(() => {
    if (!lots || lots.length === 0) {
      loadLots();
    }
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
    if (!operations || operations.length === 0) {
      loadOperations();
    }
  }, []);

  useEffect(() => {
    if (lots && lots.length > 0) {
      setLot(
        lots.find((dbLot) => {
          return dbLot.id === Number(id);
        }) || {}
      );
    }
  }, [lots]);

  useEffect(() => {
    const filteredSet = lot?.tanks?.map((tank) =>
      tanks.find((t) => t.id === tank.tank_id)
    );
    setTankList(filteredSet);
  }, [lot]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await removeLot();
    handleCloseModal();
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    // TO DO prendi tutte le operazioni e poi dividi tramite i selectors quelle expired e quelle ok
    // dispatch(setOperations(loadedOperations?.filter((o) => new Date(o?.expire_date) > new Date())));
    dispatch(setOperations(loadedOperations));
  };

  const removeLot = async () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    const response = await deleteLot(id, axiosPrivate);
    const requestPayload = {
      ...(id && { id: Number(id) }),
    };

    toast.update(toastId, {
      render: response?.error
        ? getLabel(response?.error)
        : getLabel(response?.success, { name: lot?.name || "" }),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && !response.error) {
      dispatch(removeL(requestPayload));
      navigate(`/lots/`);
    }
  };

  const renderLastAnalysis = () => {
    return (
      <>
        <AnalysisGrid id={id} isLot={true} />
        <NavLink to={"/programming/ANALYSIS/new"}>
          <CTA>{getLabel("programOperationANALYSIS")}</CTA>
        </NavLink>
      </>
    );
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate("/lots");
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        <div className="primary_container_row ctas">
          <NavLink to={`/lots/${id}/update`}>
            <CTA>{getLabel("updateLot")}{" "}<FontAwesomeIcon icon="fa-pen" /></CTA>
          </NavLink>
          <NavLink to={`/lots/${id}/tracking`}>
            <CTA>{getLabel("showTrackData")}{" "}<FontAwesomeIcon icon="fa-timeline" /></CTA>
          </NavLink>

          <CTA onClick={handleOpenModal}>{getLabel("deleteLot")}{" "}<FontAwesomeIcon icon="fa-remove" /></CTA>
          <ConfirmationModal
            isOpen={isOpen}
            onConfirm={handleFormSubmit}
            onClose={handleCloseModal}
            description={getLabel("modalDeleteLotDescription", {
              name: lot?.name || "",
            })}
          ></ConfirmationModal>
        </div>
      </div>
    );
  };

  const renderNextOperation = () => {
    return (
      <div>
        <NextOperations id={id} type={"LOT"} />
        <div className="button-margin">
          <Dropdown
            label={getLabel("programOperation")}
            options={[
              {
                label: getLabel("operationTypeANALYSIS"),
                handler: () => navigate("/programming/ANALYSIS/new"),
              },
              {
                label: getLabel("operationTypeCUT"),
                handler: () => navigate("/programming/CUT/new"),
              },
              {
                label: getLabel("operationTypeDECANT"),
                handler: () => navigate("/programming/DECANT/new"),
              },
              {
                label: getLabel("operationTypeDESTEMMING"),
                handler: () => navigate("/programming/DESTEMMING/new"),
              },
              {
                label: getLabel("operationTypeRACKING"),
                handler: () => navigate("/programming/RACKING/new"),
              },
              {
                label: getLabel("operationTypeTREATMENT"),
                handler: () => navigate("/programming/TREATMENT/new"),
              },
              {
                label: getLabel("operationTypeWINEMAKING"),
                handler: () => navigate("/programming/WINEMAKING/new"),
              },
              {
                label: getLabel("operationTypeWINEMAKINGPOST"),
                handler: () => navigate("/programming/WINEMAKINGPOST/new"),
              },
              {
                label: getLabel("operationTypeBOTTLING"),
                handler: () => navigate("/programming/BOTTLING/new"),
              },
            ]}
          ></Dropdown>
          </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("lotsNavLink"),
          getLabel("lotTitlePage", { name: "" }),
        ]}
      />
      {renderNavMenu()}
      <LotInfo lot={lot} tankList={tankList} />
      {renderNextOperation()}
      {renderLastAnalysis()}
    </div>
  );
};

export default LotDetail;
