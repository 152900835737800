import { createSlice } from "@reduxjs/toolkit";

export const lotsSlice = createSlice({
    name: 'lots',
    initialState: [],
    reducers: {
        emptyLots: state => {
            return []
        },
        setLots: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        add: (state, action) => {
            // TO DO: to be faster, add just the new element, but there are missing data (id, actual_quantity) that must be given by the backend
            // action.payload.actual_quantity = action.payload.quantity;
            // return [...state, action.payload]    
            return []
        },
        update: (state, action) => {
            const foundedIndex = state.findIndex((lot) => lot.id === action.payload?.id);
            if (foundedIndex !== -1) {
                state[foundedIndex] = action.payload
            }
            return state
        },
        removeL: (state, action) => {
            const foundedIndex = state?.findIndex((lot) => lot.id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state?.filter((d, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setLots, 
    emptyLots,
    update,
    removeL,
    add
} = lotsSlice.actions;

export default lotsSlice.reducer