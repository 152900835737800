import { useEffect, useState } from "react";
import useLabels from "../../hooks/useLabels";

const MultipleRowsCell = ({ getValue, row, column, table }) => {
  const columnMeta = column.columnDef.meta;
  const selector = columnMeta?.selector || "";
  const [data, setData] = useState([]);
  const [getLabel] = useLabels();

  useEffect(() => {
    let tmp = [];
    if (selector) {
      
      switch (selector) {
        case "client_names":
          const clientNamesArray = row?.original?.[columnMeta?.array];
          if (clientNamesArray) {
            tmp = clientNamesArray?.map((v) => {
              return {
                [selector]: v,
              };
            }); 
            break;
          }
          break;
        case "unit":
        case "substance_name":
          let array = row?.original[columnMeta?.array];
            
          if (selector === 'substance_name' && columnMeta?.array === 'analysis') {
            array = array?.map((t) => { return {...t, substance_name: getLabel("substance_" + t?.substance_name)}})
          }
          if (array) {
            tmp = array;
            break;
          }
        case "usernames":
          const usernameArray = row?.original[columnMeta?.array];
          if (usernameArray) {
            tmp = usernameArray;
            break;
          }
        case "varieties":
        case "origins":
        case "years":
          const varArray = row?.original[columnMeta?.array];
          if (varArray) {
            tmp = varArray?.map((v) => {
              return {
                [selector]: v,
              };
            });
            break;
          }
        case "aggregate":
          const additional = [];
          const lot = row?.original;
          for (let i = 0; i < lot?.varieties?.length; i++) {
            const v = lot?.varieties?.[i] ? getLabel(`lotVarieties_${lot?.varieties?.[i]}`) : '';
            const y = lot?.years?.[i] ? ` (${lot?.years?.[i]})` : '';
            const o = lot?.origins?.[i] ? `, ${lot?.origins?.[i]}` : '';
            additional.push(`${v} ${y}${o}`);
          }
          if (additional) {
            tmp = additional?.map((v) => {
              return {
                [selector]: v,
              };
            });
            break;
          }
        case "tank_name":
          const tanks = row?.original[columnMeta?.array];
          if (tanks) {
            tmp = tanks?.map((v) => {
              return {
                [selector]: v?.[selector],
              };
            });
            break;
          }
        default:
          break;
      }
    }

    setData([...tmp]);
  }, [selector]);

  const getValueByParam = (r) => {
    if (
      !r ||
      Object.keys(r)?.length === 0 ||
      !selector ||
      !r.hasOwnProperty(selector)
    )
      return <span></span>;
    return <span>{r[selector] || ""}</span>;
  };

  return (
    <div>
      {data?.map?.((r, index) => {
        return (
          <div
            key={`multiple_row_${r?.index || r?.substance_id || r[selector] || index}_${Math.floor(Math.random() * 10000)}`}
          >
            {getValueByParam(r, "")}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleRowsCell;
