import { NavLink } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import { CTA, InfoCard } from "../Generic";
import LotList from "../LotList";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
import { selectActiveWinery, selectLots } from "../../context/selectors";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getLots } from "../../services/utils";
import { setLots } from "../../context/lots/lotsSlice";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import grape from "../../assets/icons/grape.png";
import bottled from "../../assets/icons/wine-bottles.png";
import must from "../../assets/icons/must.png";
import wine from "../../assets/icons/wine.png";

import "./styles/index.scss";


const Lots = ({ isMobile }) => {
  const activeWinery = useSelector(selectActiveWinery);
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const lots = useSelector(selectLots);
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [lotsLen, setLotsLen] = useState({});

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  useEffect(() => {
    if (!lots || lots?.length === 0) {
      loadLots();
    }
  }, []);

  useEffect(() => {
    if (!lots || lots?.length === 0) return;
    const tmp = {};
    tmp.tot = lots?.length || 0;
    const wine = lots?.filter((t) => t?.state === "WINE");
    const must = lots?.filter((t) => t?.state === "MUST");
    const grape = lots?.filter((t) => t?.state === "GRAPE");
    const bottled = lots?.filter((t) => t?.state === "BOTTLED");

    tmp.wine = wine?.length;
    tmp.must = must?.length;
    tmp.grape = grape?.length;
    tmp.bottled = bottled?.length;

    tmp.wines = {
      red: wine?.filter((t) => t?.color === "RED")?.length,
      white: wine?.filter((t) => t?.color === "WHITE")?.length,
      rose: wine?.filter((t) => t?.color === "ROSE")?.length
    };
    tmp.musts = {
      red: must?.filter((t) => t?.color === "RED")?.length,
      white: must?.filter((t) => t?.color === "WHITE")?.length,
      rose: must?.filter((t) => t?.color === "ROSE")?.length
    };
    tmp.grapes = {
      red: grape?.filter((t) => t?.color === "RED")?.length,
      white: grape?.filter((t) => t?.color === "WHITE")?.length,
      rose: grape?.filter((t) => t?.color === "ROSE")?.length
    };
    tmp.bottleds = {
      red: bottled?.filter((t) => t?.color === "RED")?.length,
      white: bottled?.filter((t) => t?.color === "WHITE")?.length,
      rose: bottled?.filter((t) => t?.color === "ROSE")?.length
    };

    setLotsLen(tmp);
  }, [lots]);

  const getTooltipContent = (obj = {}) => {
    return (
    <ul>
      {Object.keys(obj)?.map((o, i) =>(<li key={`lot_tooltip_${i}`} className="tooltip_label">{`${getLabel(`lotColor_${o?.toUpperCase()}`)}: ${obj?.[o]}`}</li>))}
    </ul>);
  }

  const renderNavMenu = () => {
    return (
      <div className="primary_container_menu">
        <h2>{getLabel("lots")}</h2>
        <div>
          <h5 className="tanks_title">{getLabel("numTotLots")}
          <span className="tanks_span">{lotsLen?.tot || "0"}</span></h5>
        </div>
        <div className="tanks_wrapper">
          {role === ROLES.CLIENT && (
            <NavLink to="/lots/new" className="cta_wrapper">
              <CTA customClassName={'create'}>{getLabel("createLot")}{" "}<FontAwesomeIcon icon="fa-plus" /></CTA>
            </NavLink>
          )}
          <div className="tanks_recap">
            
            <div className="tanks_recap_wrapper">
              <InfoCard label={getLabel("numTotGrapeLots")} number={lotsLen?.grape} img={isMobile || true ? grape : null} isGrey={lotsLen?.grape === 0}
                content={getTooltipContent(lotsLen?.grapes)} />
              <InfoCard label={getLabel("numTotMustLots")} number={lotsLen?.must} img={isMobile || true ? must : null} isGrey={lotsLen?.must === 0}
                content={getTooltipContent(lotsLen?.musts)} />
              <InfoCard label={getLabel("numTotWineLots")} number={lotsLen?.wine} img={isMobile || true ? wine : null} isGrey={lotsLen?.wine === 0}
                content={getTooltipContent(lotsLen?.wines)} />
              <InfoCard label={getLabel("numTotBottledLots")} number={lotsLen?.bottled} img={isMobile || true ? bottled : null} isGrey={lotsLen?.bottled === 0}
                content={getTooltipContent(lotsLen?.bottleds)} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar breadCrumb={[getLabel("lotsNavLink")]} />
      {renderNavMenu()}
      <LotList isMobile={isMobile} />
    </div>
  );
};

export default Lots;
