import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const decoded = auth?.accessToken ? jwt_decode(auth.accessToken) : undefined;
  const rolesArray = [decoded?.UserInfo?.role] || [];

  // TO DO: use subtype to hide routes for USER (ANALYST, CELLAR_MANAGER, USER, ...)
  return false || rolesArray.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet /> // any child component of RequireAuth
  ) : (
      auth?.username
        ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  ); // ELSE --> the user isn't asking to be redirect to the login, so we force to that path
};

export default RequireAuth;
