import { createSlice } from "@reduxjs/toolkit";

export const unitsSlice = createSlice({
    name: 'units',
    initialState: [],
    reducers: {
        empty: state => {
            return []
        },
        setUnits: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        add: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        update: (state, action) => {
            // const foundedIndex = state.findIndex((unit) => unit.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return []
        },
        remove: (state, action) => {
            const foundedIndex = state.findIndex((unit) => unit.id === action.payload?.id);
            if (foundedIndex) {
                return state.splice(foundedIndex, 1)
            }
            return state
        }
    }
});

export const {
    setUnits, 
    empty,
    update,
    remove,
    add
} = unitsSlice.actions;

export default unitsSlice.reducer