import { Player } from "@lottiefiles/react-lottie-player";
import json from '../../assets/lottieJson/red_wine_loader.json'

import './styles/index.scss';

const Loader = () => {
    return <div className="loader_container">
        <Player
            autoplay={true}
            loop={true}
            controls={false}
            src={json}
            style={{ height: '120px', width: '120px' }}
        />
    </div>
}

export default Loader