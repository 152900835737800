// import { toast } from "react-toastify";
import useLabels from "../../hooks/useLabels";
import { Select } from "../FormComponents";

const FooterCell = ({ table }) => {
  const [getLabel] = useLabels();
  const meta = table.options.meta;
  const data = table.options.data;
  const selectedRows = table.getSelectedRowModel().rows;

  const removeRows = () => {
    meta.removeSelectedRows(
      table.getSelectedRowModel().rows.map((row) => row.index)
    );
    table.resetRowSelection();
  };

  const customIdInvalidColor =  "custom-id-invalid-color";
  const customIdInvalidState =  "custom-id-invalid-state";

  const onChange = (newRow) => {
    // if (meta?.type === 'newAnalysisProtocol') {
    //   // checks on new analysis protocol creation
    //   const sameSubstance = (newRow?.colors || [])?.length > 0 ? (data?.find((d) => d?.substance_name === newRow?.substance_name) || null) : null;
    //   let sameSubstanceDiffColor = false;
    //   if (sameSubstance !== null && sameSubstance?.colors?.length > 0) { 
    //     sameSubstance?.colors?.forEach(color => {
    //       if (!newRow?.colors?.includes(color)) {
    //         sameSubstanceDiffColor = true;
    //       return false;
    //     }
    //     });
    //   }
    //   if (!sameSubstanceDiffColor) {
    //     const allColors = new Set();
    //     data?.reduce((_, s) => allColors.add(s?.colors), null);
    //     const allColorsArray = Array.from(allColors);
    //     let sameSubstanceDiffColor = false;
    //     if (allColorsArray?.length > 0 && newRow?.colors?.length > 0) { 
    //       if (newRow?.colors?.every(item => !allColorsArray.includes(item)) && newRow?.colors?.length === 1) {
    //         sameSubstanceDiffColor = true;
    //       }
    //     }
    
    //   }

    //   console.log(data, sameSubstance, newRow?.colors)
    //   console.log('-----------------------')
    //   const allStates = new Set();
    //   data?.reduce((_, s) => allStates.add(s?.states), null);
    //   const allStatesArray = Array.from(allStates);
    //   let diffState = false;
    //   if (allStatesArray?.length > 0 && newRow?.states?.length > 0) { 
    //     if (newRow?.states?.every(item => !allStatesArray.includes(item)) && newRow?.states?.length === 1) {
    //       diffState = true;
    //     }
    //   }
  
    //   if (sameSubstanceDiffColor || diffState) {
    //     const name = getLabel('substance_' + newRow?.substance_name);
    //     const msg = sameSubstanceDiffColor ? getLabel("toast_analysisProtocolInvalidColor", { name: name }) :
    //     getLabel("toast_analysisProtocolInvalidState", { name: name })
    //     toast.warning(msg, {
    //       toastId: sameSubstanceDiffColor ? customIdInvalidColor : customIdInvalidState,
    //       type: toast.TYPE.ERROR,
    //       isLoading: false,
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       autoClose: 4000,
    //     });
    //     return;
    //   }
    // }

    meta?.addRow(newRow);
  }
  
  const filterOptions = () => {
    const availableIds = table.getRowModel()?.rows?.map((r) => r.original?.id || r.original?.value) || [];
    if (meta?.type === 'newAnalysisProtocol') {
      // console.log(meta?.getAdditional(), availableIds, data?.[0])
      return meta?.getAdditional()?.filter((f) => !availableIds.includes(f.value) && !f?.colors?.includes(data?.[0]?.color))?.map((o) => { return {...o, name: o?.label }});
    }
    return meta?.getAdditional()?.filter((f) => !availableIds.includes(f.value))?.map((o) => { return {...o, name: o?.label }});
  }
  
  return (
    <div className="footer-buttons">
      {selectedRows.length > 0 ? (
        <button className="remove-button" onClick={removeRows}>
          {`${getLabel('removeRow')} x`}
        </button>
      ) : null}
      {meta?.type !== "winemaking" && filterOptions()?.length > 0 && 
      <Select
        isTable={true}
        placeholder={getLabel('addNewRow')}
        // errors={meta.getErrors()[`column.${row.original.id || row.id}.value`]}
        // name={`column.${row.original.id || row.id}.value`}
        // errors={meta.getErrors()[`column.6`]}
        name={`dummy`}
        control={meta?.getControl()}
        options={filterOptions()}
        onTableChange={onChange}
      />}
      {["newAnalysisProtocol"].includes(meta?.type) && filterOptions()?.length === 0 && <p className="remove-button">{getLabel("addNewRowUnavailable")}</p>}
       {meta?.type === "winemaking" && <button className="add-button" 
       onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        meta.modalHandler();
      }}
      >{getLabel('addNewRow')}
      </button>}
    </div>
  );
};

export default FooterCell;