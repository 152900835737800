import { createSlice } from "@reduxjs/toolkit";

const sortByDateFn = (a, b) => (new Date(b.expire_date) - new Date(a.expire_date))

export const expOperationsSlice = createSlice({
    name: 'expOperations',
    initialState: [],
    reducers: {
        emptyExp: state => {
            return []
        },
        setExpOperations: (state, action) => {
            if (Array.isArray(action?.payload)) {
                const sorted = action?.payload?.sort(sortByDateFn);
                return sorted
            }
        },
        add: (state, action) => {
            const sorted = [...state, ...action]?.sort(sortByDateFn);
            return sorted
        },
        update: (state, action) => {
            // const foundedIndex = state.findIndex((operation) => operation.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return [];
        },
        updateNote: (state, action) => {
            const foundedIndex = state.findIndex((operation) => operation.id === action.payload?.id);
            console.log(foundedIndex, action.payload, state)
            if (foundedIndex !== -1) {
                // console.log(current(state[foundedIndex]), action.payload)
                // state[foundedIndex] = action.payload
            }
            return state;
        },
        remove: (state, action) => {
            // const foundedIndex = state.findIndex((operation) => operation.id === action.payload?.id);
            // if (foundedIndex) {
            //     return state.splice(foundedIndex, 1)
            // }
            // return state
            return []
        }
    }
});

export const {
    emptyExp, 
    setExpOperations,
    update,
    updateNote,
    add, 
    remove
} = expOperationsSlice.actions;

export default expOperationsSlice.reducer;