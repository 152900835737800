import { useNavigate } from "react-router-dom"
import useLabels from "../../hooks/useLabels";

const LotTile = ({
    name,
    varieties,
    years,
    tanks,
    quantity,
    state,
    type,
    color,
    id
}) => {

    const navigate = useNavigate();
    const [getLabel] = useLabels();
    
    return (
    <div className="lot_tile_wrapper">
    <p className={`state_tag color_${color?.toLowerCase() || 'red'}`}>{getLabel(`lotState_${state}`)}</p>
    <div className={`lot_tile lot_${(color || 'red')?.toLowerCase()}`}
        onClick={() => {navigate(`/lots/${id}`)}}
    >
        <div className="lot_tile_content">
            <div className="lot_tile_content_name">
            <div className="lot_tile_content_name_wrapper">
                <span>{name}</span>
                {/* <span className="lot_tile_content_name_wrapper_icon">
                </span> */}
            </div>
            <div className="lot_tile_content_name_wrapper">
                <span className="light-span">{`${quantity ? quantity : ''}${quantity && state === 'GRAPE' ? ' Kg' : ' L'}`}</span>
                <span className="light-span lot-type-span">{state === 'WINE' && type ? (' ' + getLabel(`lotType_${type}`)) : ''}</span>
            </div>
        </div>
        {state !== 'BOTTLED' && 
        <div className="lot_tile_content_tanks">
            {tanks?.map((t, index) => index < 5 ? t : index === 5 ? {name: '...'} : {})?.map((tank, index) => {return <p key={`tank-${index}-${index}`}>{tank?.name}</p>})}
        </div>}
        {state === 'BOTTLED' && 
        <div className="lot_tile_content_tanks">
            {/* TO DO add cap and bottle lot in batches SQL */}
            {/* {tanks?.map((t, index) => index < 5 ? t : index === 5 ? {name: '...'} : {})?.map((tank, index) => {return <p key={`tank-${index}`}>{tank?.name}</p>})} */}
        </div>}
        </div>
    </div>
    </div>)
}

export default LotTile