import { useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/apis";
import { PWD_REGEX, USER_REGEX } from "../../constants/regex"
import useLabels from "../../hooks/useLabels";
import { Input, Submit } from "../FormComponents"
import useAuth from "../../hooks/useAuth";
import { getItem, removeItem, setItem } from "../../services/LocalStorage";
import './styles/index.scss';
import axios from "../../api/axios";
import { useEffect, useState } from "react";
import useError from "../../hooks/useError";
import { getWineries } from "../../services/utils";
import { useDispatch } from "react-redux";
import { set } from "../../context/wineries/wineriesSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import jwt from 'jwt-decode'

export default function Login () {

    const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm({
        defaultValues: {
            username: '',
            password: ''
        },
        mode: "onTouched"
    });
    const dispatch = useDispatch()
    const { auth, setAuth } = useAuth();
    const [getLabel] = useLabels();
    const [errorSubmit, setErrorSubmit] = useState({});
    const location = useLocation();
    const { getError } = useError();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        if (Object.keys(auth)?.length > 0 && auth?.accessToken) {
            const decoded = jwt(auth?.accessToken);

            if (decoded?.exp && parseInt(Date.now() / 1000) < decoded.exp)
                navigate('/programming');
        }
    }, []);

    const handleLogin = async (payload) => {
        try {
            const response = await axios.post(
                LOGIN,
                JSON.stringify(payload),
                {
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            
            if (accessToken) {
                const decoded = jwt(accessToken);
                setAuth({
                    username: payload.username,
                    accessToken,
                    role: decoded?.UserInfo?.role,
                    client_name: decoded?.UserInfo?.client_name,
                    user_id: decoded?.UserInfo?.id || null,
                    password_expire_date: decoded?.UserInfo?.password_expire_date
                });

                const wineries = await getWineries(axiosPrivate, accessToken);
                
                if (!decoded?.UserInfo?.client_name?.some(w => wineries?.includes(w))) {    // if another client logs in, reset the data
                    removeItem('activeWineryId');
                    removeItem('wineriesList');
                    dispatch(set(wineries));
                }
                if (decoded?.UserInfo?.role === 1) {            // ADMIN
                    navigate('/clients');
                } else if (decoded?.UserInfo?.role === 2) {     // CLIENT
                    if (!wineries || wineries?.length === 0 ) {
                        navigate('/wineries/new');
                        return;
                    };
                    dispatch(set(wineries));
                    
                    // if (location?.state?.from) {
                    //     navigate(location?.state?.from || '/');
                    //     return;    
                    // }
                    if (wineries?.length === 1 && getItem("activeWineryId")) {
                        navigate(location?.state?.from?.pathname || '/');
                        return;
                    }
                    navigate('/#wineriesList');
                } else if (decoded?.UserInfo?.role === 3) {     // USER
                    // if (location?.state?.from) {
                    //     navigate(location?.state?.from || '/');
                    //     return;    
                    // }
                    if (wineries?.length === 1 && getItem("activeWineryId")) {
                        navigate(location?.state?.from?.pathname || '/');
                        return;
                    } else if (!getItem("activeWineryId")) {
                        navigate('/#wineriesList');
                        return;
                    }
                    navigate('/programming');
                } else if (decoded?.UserInfo?.role === 5) {     // TANK
                    const currentMonitor = Number(getItem("currentMonitor"));
                    console.log(currentMonitor, wineries, '-=-=-=')
                    if (wineries?.length === 1) {
                        setItem("activeWineryId", wineries?.[0]?.id)
                        navigate(location?.state?.from?.pathname || '/');
                        return;
                    } else if (currentMonitor > 0) {
                        const foundWinery = wineries?.find((w) => w?.tanks?.includes(currentMonitor));
                        if (foundWinery) {
                            dispatch(set([foundWinery]));
                            setItem("activeWineryId", foundWinery?.id)
                            navigate(location?.state?.from?.pathname || '/');
                            return;
                        }
                    } else if (!getItem("activeWineryId")) {
                        navigate('/#wineriesList');
                        return;
                    }
                    navigate('/programming');
                }
            }
        } catch (err) {
            reset();
            // setErrorSubmit(getError(err?.response))
            setErrorSubmit({error: err?.response?.data?.error_message});
        }
    }

    const resetError = () => {
        setErrorSubmit(null)
    }


    return (
        <div className="login login_wrapper red_card">
            <h1>{getLabel('title')}</h1>
            <form
                onSubmit={handleSubmit(handleLogin)}
                autoComplete="off"
                noValidate
            >
                <Input
                    name='username'
                    register={register}
                    regExpPattern={{
                        value: USER_REGEX,
                        message: getLabel('inputPatternError')
                    }}
                    type="text"
                    label={getLabel('username')}
                    placeholder={getLabel('usernamePlaceholder')}
                    error={errors['username']}
                    required={getLabel('inputRequiredError')}
                    isRequiredTooltip={false}
                    onChange={resetError}
                />
                <Input
                    name='password'
                    register={register}
                    regExpPattern={{
                        value: PWD_REGEX,
                        message: getLabel('inputPatternError')
                    }}
                    type="password"
                    label={getLabel('password')}
                    placeholder={getLabel('passwordPlaceholder')}
                    error={errors['password']}
                    required={getLabel('inputRequiredError')}
                    isRequiredTooltip={false}
                    onChange={resetError}
                />
                <Submit
                    label={getLabel('loginCTA')}
                />
                {errorSubmit?.error && <div className="error_submit">{errorSubmit.error}</div>}
            </form>
            {/* <Link to={'/register'} className="login_link">{getLabel('linkRegister')}</Link> */}
        </div>
    )

}
