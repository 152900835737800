import { useDispatch, useSelector } from "react-redux";
import useLabels from "../../hooks/useLabels";
import Table from "../Table";
import {
  selectActiveWinery,
  selectConfigs,
  selectExpiredOperations,
  selectLots,
  selectNextOperationsByLot,
  selectNextOperationsByTank,
  selectNextOperationsByType,
  selectTanks,
  selectUsers,
} from "../../context/selectors";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { BatchColumn, CellarmanColumn, TankColumn } from "../Generic";
import { getExpiredOperations, getLots } from "../../services/utils";
import { setLots } from "../../context/lots/lotsSlice";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { addMonths, format } from "date-fns";
import { setExpOperations } from "../../context/expOperations/expOperationsSlice";

const NextOperations = ({ id, type = "TANK" }) => {
  const [getLabel] = useLabels();
  const activeWinery = useSelector(selectActiveWinery);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const expiredOperations = useSelector(selectExpiredOperations);
  const operations = useSelector((state) =>
    selectNextOperationsByType(
      state,
      { startDate: new Date(), endDate: addMonths(new Date(), 3) },
      Number(id),
      type
    )
  );
  
  const lots = useSelector(selectLots);
  const tanks = useSelector(selectTanks);
  const users = useSelector(selectUsers);
  const configs = useSelector(selectConfigs);
  const navigate = useNavigate();
  const columns = useMemo(() => [
    {
      Header: getLabel("priority"),
      accessor: (row) => getLabel(`operationPriorityLong${row.priority}`),
    },
    {
      Header: getLabel("operationType"),
      accessor: (row) => getLabel(`operationType${row.type}`),
    },
    {
      Header: getLabel("operationSrcTank"),
      accessor: (row) =>
      row?.type === "DESTEMMING" ? (
        <BatchColumn lots={lots} rowData={row} type={row?.type} />
      ) : (
        <TankColumn rowData={row?.src_tanks} tanks={tanks} />
      ),
    //   row?.type === "DESTEMMING" ||
    //     row?.src_tanks?.find((tank) => tank?.batch_id != null) ? (
    //       <BatchColumn lots={lots} rowData={row} type={row?.type} />
    //     ) : (
    //       <TankColumn rowData={row?.src_tanks} tanks={tanks} />
    //     ),
    },
    {
      Header: getLabel("operationDestTank"),
      accessor: (row) =>
        // row?.dest_tanks?.find((tank) => tank?.batch_id) ? (
        //   <BatchColumn lots={lots} rowData={row} type={row?.type} />
        // ) : (
          <TankColumn rowData={row?.dest_tanks} tanks={tanks} />
        // ),
    },
    {
      Header: getLabel("cellarman"),
      accessor: (row) => (
        <CellarmanColumn rowData={row?.cellarman_ids} users={users} />
      ),
    },
    {
      Header: getLabel("expiringDate"),
      accessor: (row) =>
        row.expire_date
          ? format(new Date(row.expire_date), configs.shortDateFormat)
          : "",
    },
    // {
    //     Header: getLabel('operationCompletionDate'),
    //     accessor: (row) => row.completion_date ? format(new Date(row.completion_date), configs.shortDateFormat) : '-'
    // },
    // {
    //   Header: getLabel("operationNotes"),
    //   accessor: (row) => row.notes,
    // },
  ]);

  useEffect(() => {
    if (!lots || lots.length === 0) {
        loadLots();
    }
    if (!expiredOperations || expiredOperations.length === 0) {
        loadExpiredOperations();
    }
  }, [activeWinery]);

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };
  
  const loadExpiredOperations = async () => {
    const currentExpiredOperations = await getExpiredOperations(activeWinery?.id, axiosPrivate);
    // setExpiredOperations([...currentExpiredOperations]);
    dispatch(setExpOperations(currentExpiredOperations));
  };

  if (operations?.length > 0)
        return (
        <div>
          <h4>
            {getLabel(
              type === "LOT" ? "lot_nextOperations" : "tank_nextOperations"
            )}
          </h4>
          <Table
            data={operations}
            prefix="operations"
            customColumns={columns}
            rowClickHandler={(row) => {
              navigate(`/programming/${row.original.type}/${row.original.id}`);
            }}
          ></Table>
        </div>
      );
  else
    return (
      <div>
        <div className="primary_container_menu">
          <h4>
            {getLabel(
              type === "LOT" ? "lot_nextOperations" : "tank_nextOperations"
            )}
          </h4>
          <h5 className="alert_no_info">{getLabel("noOperationsFound")}</h5>
        </div>
      </div>
    );
};

export default NextOperations;
