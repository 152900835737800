import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels"
import { Button } from "../Generic";
import SecondaryBar from "../SecondaryBar"
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveWinery, selectLots, selectSensors, selectTanks } from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { getLots, getSensors, getTanks, newTank, updateTank } from "../../services/utils";
import { add, emptyTanks, setTanks, update } from "../../context/tanks/tanksSlice";
import { Input, Label, MultipleSelect, Select, Submit, TextArea } from "../FormComponents";
import { setLots } from "../../context/lots/lotsSlice";
import { setSensors } from "../../context/sensors/sensorsSlice";
import { toast } from "react-toastify";
import { ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX, INT_REGEX } from "../../constants/regex";

import './styles/index.scss';


const TankForm = ({
    
}) => {
    const activeWinery = useSelector(selectActiveWinery)
    const { id } = useParams();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const tanks = useSelector(selectTanks);
    const lots = useSelector(selectLots);
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const [tank, setTank] = useState(tanks?.length > 0 ? tanks.find((dbTank) => (dbTank.id === Number(id))) || {} : {});
    const [isBulk, setIsBulk] = useState(false);
    const sensors = useSelector(selectSensors);

    const { register, handleSubmit, formState: {errors, isValid}, reset, setValue, getValues, clearErrors, control } = useForm({
        defaultValues: {
            name: tank?.name || '',
            id_code: tank?.id_code || '',
            manufacturer: tank?.manufacturer || '',
            model: tank?.model || '',
            material: tank?.material || '',
            capacity: tank?.capacity || 0,
            plant: tank?.plant ? { value: tank?.plant, label: getLabel(`tankPlant${tank?.plant}`) } : '',
            notes: tank?.notes || '',
            type: tank?.type ? {label: getLabel(`tankType${tank?.type}`), value: tank?.type} : '',
            barrels_num: tank?.barrels_num || 0,
            lot: lots?.find((lot) => tank?.batch_id === lot.id)?.name || ''
        },
        mode: 'onTouched',
        shouldUnregister: true
    });
    const { type, barrels_num } = useWatch({ control })

    useEffect(() => {
        if (id && (!tanks || tanks.length === 0)) {
            loadTanks()
        }
        if (!lots || lots.length === 0) {
            loadLots()
        }
        if (!sensors || sensors.length === 0) {
            loadSensors()
        }
    }, [])

    useEffect(() => {
        // if (id && tanks.length > 0 && Object.keys(tank).length === 0) {
        if (id && tanks.length > 0) {
                const singleTank = tanks.find((dbTank) => (dbTank.id === Number(id))) || null;
            if (singleTank) {
                setTank(singleTank);
                setValue('barrels_num', singleTank?.barrels_num || '');
                setValue('capacity', singleTank?.capacity || '');
                setValue('id_code', singleTank?.id_code || '');
                setValue('manufacturer', singleTank?.manufacturer || '');
                setValue('material', singleTank?.material || '');
                setValue('model', singleTank?.model || '');
                setValue('name', singleTank?.name || '');
                setValue('notes', singleTank?.notes || '');
                setValue('plant', singleTank?.plant ? { value: singleTank?.plant, label: getLabel(`tankPlant${singleTank?.plant}`) } : '');
                setValue('type', singleTank?.type ? {value: singleTank?.type, label: getLabel(`tankType${singleTank?.type}`)} : '');
                setValue('lot', lots?.find((lot) => singleTank?.batch_id === lot.id)?.name || '');
                setValue('sensors', sensors?.filter((s) => singleTank?.sensors?.enabled
                    ?.some((ss) => s?.id === ss?.id))?.map((ss) => { return {...ss, type: ss?.value, label: getLabel(`sensor_${ss?.value}`)}}));
                }
        }
    }, [tanks])

    const loadTanks = async () => {
        const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
        dispatch(setTanks(currentTanks));
    }
    
    const loadLots = async () => {
        const currentLots = await getLots(activeWinery.id, axiosPrivate);
        dispatch(setLots(currentLots));
    }

    const loadSensors = async () => {
        const currentSensors = await getSensors(activeWinery.id, axiosPrivate);
        dispatch(setSensors(currentSensors));
    }

    const submitForm = async (data) => {
        const sensors = ['TANK', 'AUTOCLAVE'].includes(data?.type?.value) ? { enabled: data?.sensors?.map((s) => {return { value: s?.type, id: s?.id}})} : {};
        const requestPayload = {
            ...data,
            plant: data?.plant?.value,
            type: data?.type?.value,
            winery_id: activeWinery.id,
            bulk_start: Number(data?.bulk_start),
            bulk_end: Number(data?.bulk_end),
            sensors: sensors,
            ...(id && {id: id})
        }
        
        if (['BARRIQUE', 'TONNEAU'].includes(data?.type?.value))
            requestPayload.capacity = (data?.type?.value === 'TONNEAU' ? 500 : 225) * Number(data?.barrels_num);
        
        const toastId = toast.loading(getLabel("toast_inProgress"), { 
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT,
            exclude: true,
            autoClose: 2000
        });
        let response = null;
        // console.log('requestPayload', requestPayload);
        // return;

        if (id) {
            response = await updateTank(id, requestPayload, axiosPrivate);
        } else {
            response = await newTank(requestPayload, axiosPrivate)
        }
        toast.update(toastId, { 
            render: response?.error ? 
                getLabel(response?.error) :
                getLabel(response?.success, { name: data?.name || '' }),
            type: response?.error ? 
                toast.TYPE.ERROR : 
                toast.TYPE.SUCCESS, isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4000,
          }
        );
        if (response && !response.error) {
            updateStore(requestPayload);
            navClickHandler();
        }

    }

    const updateStore = (payload) => {
        if (payload.id) {
            dispatch(update(payload))
        } else {
            // dispatch(add(payload))
            dispatch(emptyTanks())
        }
    }

    const navClickHandler = () => {
        if (id) {
            navigate(`/tanks/${id}`)
        } else {
            navigate('/tanks')
        }

    }

    const renderNavMenu = () => {
        return (
            <div className="primary_container_menu">
                <div className="primary_container_row">
                    <Button
                        arrowDirection="left"
                        onClick={navClickHandler}
                    />
                    <h2>{getLabel(id ? 'tanksUpdatePage' : 'tanksNewPage')}</h2>
                </div>
            </div>
        )
    }

    const calcCapacity = () => {
        switch (type?.value) {
            case 'BARRIQUE':
                return (barrels_num || 0) * 225
            case 'TONNEAU':
                return (barrels_num || 0) * 500
            case 'TANK':
            default:
                return 0
        }
    }

    const renderBulkExample = () => {
        const start = Number(getValues('bulk_start'));
        const end = Number(getValues('bulk_end'));
        
        if (end - start > 0)
            return (<div className="bulk_div">
                <h5>{getLabel('bulkTitle')}</h5>
                <p className="bulk_names">{`${getValues('name')} ${start}, ${end - start > 1 ? '...,' : ''} ${getValues('name')} ${end}`}</p>
            </div>)
    }

    const renderForm = () => {
        return (<div className="tank_form_wrapper">
            <form
                onSubmit={handleSubmit(submitForm)}
                autoComplete="off"
                noValidate
            >
                <div className="tank_form_wrapper_row">
                    <Input
                        name="name"
                        register={register}
                        type="text"
                        label={getLabel('tankName')}
                        placeholder={getLabel('tankNamePlaceholder')}
                        error={errors['name']}
                        required={getLabel('inputRequiredError')}
                        minLength={{
                            value: 3,
                            message: getLabel("errorMinLength", { value: 3 }),
                          }}
                          maxLength={{
                            value: 24,
                            message: getLabel("errorMaxLength", { value: 24 }),
                          }}
                          regExpPattern={{
                            value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                            message: getLabel("errorAlphaNumSpace_-"),
                          }}
                          tooltip={getLabel("tankNameTooltip")}
                    />
                    {id && <Label label={getLabel('tankLot')} 
                        tooltip={getLabel("tankLotTooltip")}
                        >{tank?.batch_name}</Label>
                    //     <Input
                    //     name="lot"
                    //     register={register}
                    //     type="text"
                    //     label={getLabel('tankLot')}
                    //     placeholder={getLabel('tankLotPlaceholder')}
                    //     error={errors['lot']}
                    //     isDisabled={true}
                    //     tooltip={getLabel("tankNameTooltip")}
                    // />
                    }
                    <Input
                        name="id_code"
                        register={register}
                        type="text"
                        label={getLabel('tankGlobalId')}
                        placeholder={getLabel('tankGlobalIdPlaceholder')}
                        error={errors['id_code']}
                        isDisabled={isBulk}
                        // required={getLabel('inputRequiredError')}
                        minLength={{
                            value: 3,
                            message: getLabel("errorMinLength", { value: 3 }),
                          }}
                          maxLength={{
                            value: 24,
                            message: getLabel("errorMaxLength", { value: 24 }),
                          }}
                          regExpPattern={{
                            value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                            message: getLabel("errorAlphaNumSpace_-"),
                          }}
                          tooltip={getLabel("tankGlobalIdTooltip")}
                    />
                    <Select
                        control={control}
                        name="type"
                        label={getLabel('tankType')}
                        defaultValue={{
                            value: 'TANK',
                            label: getLabel('tankTypeTANK')
                        }}
                        required={getLabel('inputRequiredError')}
                        errors={errors['type']}
                        placeholder={getLabel('tankTypePlaceholder')}
                        options={[{
                            value: 'TANK',
                            label: getLabel('tankTypeTANK')
                        },{
                            value: 'BARRIQUE',
                            label: getLabel('tankTypeBARRIQUE')
                        },{
                            value: 'TONNEAU',
                            label: getLabel('tankTypeTONNEAU')
                        },{
                            value: 'AUTOCLAVE',
                            label: getLabel('tankTypeAUTOCLAVE')
                        }]}
                        tooltip={getLabel("tankTypeTooltip")}
                    />
                    {['TANK', 'AUTOCLAVE'].includes(type?.value) && !tank?.quantity && (<Input
                        name="capacity"
                        register={register}
                        type="number"
                        label={getLabel('tankCapacity')}
                        placeholder={getLabel('tankCapacityPlaceholder')}
                        error={errors['capacity']}
                        required={getLabel('inputRequiredError')}
                        min={{
                            value: 1,
                            message: getLabel("errorMinValue", { value: 1 }),
                        }}
                        max={{
                            value: 500000,
                            message: getLabel("errorMaxValue", { value: 500000 }),
                        }}
                        regExpPattern={{
                            value: INT_REGEX,
                            message: getLabel("errorInt"),
                        }}
                        // tooltip={getLabel("tankCapacityTooltip")}
                    />)}
                    {tank?.quantity > 0 && <Label
                        label={getLabel('tankCapacity')}
                        tooltip={getLabel('tankCapacityTooltip')}
                    >{tank?.capacity}
                    </Label>}
                    {type?.value && !['TANK', 'AUTOCLAVE'].includes(type?.value) && (
                        <>
                            <Input
                                name="barrels_num"
                                register={register}
                                type="number"
                                label={getLabel('tankBarrelsNum')}
                                placeholder={getLabel('tankBarrelsNumPlaceholder')}
                                error={errors['barrels_num']}
                                required={getLabel('inputRequiredError')}
                                min={{
                                    value: 1,
                                    message: getLabel("errorMinValue", { value: 1 }),
                                }}
                                max={{
                                    value: 30,
                                    message: getLabel("errorMaxValue", { value: 30, }),
                                }}
                                regExpPattern={{
                                    value: INT_REGEX,
                                    message: getLabel("errorInt"),
                                }}
                                tooltip={getLabel("tankBarrelsNumTooltip")}
                            />
                            <Label
                                label={getLabel('tankCapacity')}
                            >
                                {calcCapacity()}
                            </Label>
                        </>
                    )}
                </div>
                <div className="tank_form_wrapper_row">
                    <Input
                        name="model"
                        register={register}
                        type="text"
                        label={getLabel('tankModel')}
                        placeholder={getLabel('tankModelPlaceholder')}
                        error={errors['model']}
                        // required={getLabel('inputRequiredError')}
                        minLength={{
                            value: 3,
                            message: getLabel("errorMinLength", { value: 3 }),
                          }}
                        maxLength={{
                        value: 20,
                        message: getLabel("errorMaxLength", { value: 20 }),
                        }}
                        regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        // tooltip={getLabel("tankModelTooltip")}
                        
                    />
                    <Input
                        name="manufacturer"
                        register={register}
                        type="text"
                        label={getLabel('tankManufacturer')}
                        placeholder={getLabel('tankManufacturerPlaceholder')}
                        error={errors['manufacturer']}
                        // required={getLabel('inputRequiredError')}
                        minLength={{
                            value: 3,
                            message: getLabel("errorMinLength", { value: 3 }),
                          }}
                        maxLength={{
                        value: 20,
                        message: getLabel("errorMaxLength", { value: 20 }),
                        }}
                        regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        // tooltip={getLabel("tankManufacturerTooltip")}
                    />
                    <Input
                        name="material"
                        register={register}
                        type="text"
                        label={getLabel('tankMaterial')}
                        placeholder={getLabel('tankMaterialPlaceholder')}
                        error={errors['material']}
                        required={getLabel('inputRequiredError')}
                        minLength={{
                            value: 3,
                            message: getLabel("errorMinLength", { value: 3 }),
                          }}
                        maxLength={{
                        value: 20,
                        message: getLabel("errorMaxLength", { value: 20 }),
                        }}
                        regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        // tooltip={getLabel("tankMaterialTooltip")}
                    />
                    {!['BARRIQUE', 'TONNEAU'].includes(getValues('type')?.value) && <Select
                        name="plant"
                        control={control}
                        label={getLabel('tankPlant')}
                        placeholder={getLabel('tankPlantPlaceholder')}
                        error={errors['plant']}
                        required={getLabel('inputRequiredError')}
                        options={[{
                            value: '',
                            label: ''
                        },{
                            value: '2-TUBES',
                            label: getLabel('tankPlant2-TUBES')
                        },{
                            value: '4-TUBES',
                            label: getLabel('tankPlant4-TUBES')
                        }]}
                        defaultValue={{
                            value: '',
                            label: ''
                        }}
                        tooltip={getLabel("tankPlantTooltip")}
                    />}
                </div>
                {!id && <div className="tank_form_wrapper_row">
                    <Input
                        name={'is_bulk'}
                        register={register}
                        label={getLabel('tankBulkCheckbox')}
                        tooltip={getLabel('tankBulkCheckboxTooltip')}
                        type={"checkbox"}
                        checked={isBulk}
                        onChange={() => setIsBulk(prev => !prev)}
                    />
                    {isBulk && (<>
                        <Input
                            name={'bulk_start'}
                            label={getLabel("bulkStart")}
                            placeholder={getLabel("bulkStartPlaceholder")}
                            tooltip={getLabel("bulkStartTooltip")}
                            register={register}
                            error={errors['bulk_start']}
                            required={getLabel("inputRequiredError")}
                            type="number"
                            min={{
                                value: 1,
                                message: getLabel("errorMinValue", { value: 1 }),
                            }}
                            max={{
                                value: 299,
                                message: getLabel("errorMaxValue", { value: 299 }),
                            }}
                            regExpPattern={{
                                value: INT_REGEX,
                                message: getLabel("errorInt"),
                            }}
                            validate={(value) => {
                                if (getValues('bulk_end') && 
                                  Number(getValues('bulk_end')) <= Number(value)) {
                                    return getLabel("errorBulkStart");
                                } else {
                                    clearErrors('bulk_end');
                                    clearErrors('bulk_start');
                                }
                            }}
                        />
                        <Input
                            name={'bulk_end'}
                            label={getLabel("bulkEnd")}
                            placeholder={getLabel("bulkEndPlaceholder")}
                            tooltip={getLabel("bulkEndTooltip")}
                            register={register}
                            error={errors['bulk_end']}
                            required={getLabel("inputRequiredError")}
                            type="number"
                            min={{
                                value: 2,
                                message: getLabel("errorMinValue", { value: 2 }),
                            }}
                            max={{
                                value: 300,
                                message: getLabel("errorMaxValue", { value: 300 }),
                            }}
                            regExpPattern={{
                                value: INT_REGEX,
                                message: getLabel("errorInt"),
                            }}
                            validate={(value) => {
                                if (getValues('bulk_start') && 
                                  Number(getValues('bulk_start')) >= Number(value)) {
                                  return getLabel("errorBulkEnd");
                                } else {
                                    clearErrors('bulk_end');
                                    clearErrors('bulk_start');
                                }
                            }}
                        />
                        {getValues('name') && getValues('bulk_start') && getValues('bulk_end') && <>
                            {renderBulkExample()}
                        </>}
                    </>)}
                </div>}
                <div className="tank_form_wrapper_row">
                {type?.value && ["TANK", "AUTOCLAVE"].includes(type?.value) && (
                    <MultipleSelect
                        name={`sensors`}
                        control={control}
                        label={getLabel("tankSensors")}
                        placeholder={getLabel("tankSensorsPlaceholder")}
                        error={errors["sensors"]}
                        // required={getLabel("inputRequiredError")}
                        tooltip={getLabel("tankSensorsTooltip")}
                        options={sensors?.map?.((sensor) => {
                        return {
                            id: sensor?.id,
                            value: sensor?.id,
                            type: sensor?.value,
                            label: getLabel(`sensor_${sensor?.value}`),
                        };
                        })}
                    />
                    )}
                </div>
                <div className="tank_form_wrapper_row">
                    <TextArea
                        name="notes"
                        register={register}
                        label={getLabel('tankNotes')}
                        placeholder={getLabel('tankNotesPlaceholder')}
                        error={errors['notes']}
                        // tooltip={getLabel("tankNotesTooltip")}
                    />
                </div>
                <div className="tank_form_wrapper_row">
                    <Submit
                        label={getLabel('submitForm')}
                    />
                </div>
            </form>
        </div>)
    }

    return (
        <div className="primary_container">
            <SecondaryBar
                breadCrumb={
                    id ? [
                        getLabel('tanksNavLink'),
                        getLabel('tanksUpdatePage')
                    ] : [
                            getLabel('tanksNavLink'),
                            getLabel('tanksNewPage')

                    ]
                }
            />
            {renderNavMenu()}
            {renderForm()}
        </div>
    )
}

export default TankForm