import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectUserSockets } from "../../context/selectors";
import Clock from "./Clock";
import useLabels from "../../hooks/useLabels";
import "./styles/index.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import json from "../../assets/lottieJson/avatar_outline.json";
import json_tool from "../../assets/lottieJson/tool_outline.json";
import json_bell from "../../assets/lottieJson/bell.json";
import json_logout from "../../assets/lottieJson/logout.json";
import NotificationsCenter from "./Notifications/Notifications";
import { useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { ConfirmationModal } from "../Generic";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import Settings from "./Settings/Settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ isMobile, isConnected }) => {
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const logout = useLogout();
  // const dispatch = useDispatch();
  const user = useSelector(selectUser);
  // const userSockets = useSelector(selectUserSockets);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const { auth } = useAuth();

  const logoutWrapperRef = useRef();
  const settingsWrapperRef = useRef();
  const notificationWrapperRef = useRef();

  useClickOutside(logoutWrapperRef, () => {
    if (isLogoutOpen) {
      setIsNotificationOpen(false);
    }
  });

  useClickOutside(notificationWrapperRef, () => {
    if (isNotificationOpen) {
      setIsNotificationOpen(false);
    }
  });

  useClickOutside(settingsWrapperRef, () => {
    if (isSettingsOpen) {
      setIsSettingsOpen(false);
    }
  });

  if (isMobile) {
    return null;
  }

  const setNotificationOpen = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const setSettingsOpen = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleOpenModal = () => {
    setIsLogoutOpen(true);
  };

  const updateCounter = (value) => {
    setCounter(value);
  };

  const callLogout = () => {
    logout();
  };

  const handleCloseModal = () => {
    setIsLogoutOpen(false);
  };

  return (
    <div className="header">
      <div className="header_wrapper">
        <div className="header_wrapper_clock">
          <Clock />
        </div>
        <div className="header_wrapper_user">
          <div className="header_wrapper_user_badge">
            {getLabel(user?.type)}
          </div>
          {user?.type !== 'TANK' && <div className="header_wrapper_user_label">
            {getLabel("welcome", { username: user?.userName }) ||
              getLabel("welcome")}
          </div>}
          <div
            className="header_wrapper_user"
            role="button"
            onClick={() =>
              navigate(auth?.user_id ? `/users/${auth.user_id}` : "")
            }
          >
            <Player
              autoplay={false}
              controls={false}
              hover={true}
              src={json}
              style={{ width: "24px", height: "24px" }}
            />
          </div>
          <div
            className="header_wrapper_user_setting"
            role="button"
            onClick={setSettingsOpen}
            ref={settingsWrapperRef}
          >
            <Player
              autoplay={false}
              controls={false}
              hover={true}
              src={json_tool}
              style={{ width: "24px", height: "24px" }}
            />
            <Settings
              isOpen={isSettingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          </div>
          <div
            className="header_wrapper_bell"
            role="button"
            onClick={setNotificationOpen}
            ref={notificationWrapperRef}
          >
            <Player
              autoplay={false}
              controls={false}
              hover={true}
              src={json_bell}
              style={{ width: "24px", height: "24px" }}
            />
            {counter > 0 && <span className="trigger-span">{counter}</span>}
            <NotificationsCenter
              isOpen={isNotificationOpen}
              setCounter={updateCounter}
            />
            <FontAwesomeIcon icon="fa-circle" size={"2xs"} className={`socket ${isConnected ? "connected" : ""} ${counter > 0 ? 'fix' : ''}`} />
          </div>
          <div
            className="header_wrapper_user_setting"
            role="button"
            onClick={handleOpenModal}
          >
            <Player
              autoplay={false}
              controls={false}
              hover={true}
              src={json_logout}
              style={{ width: "24px", height: "24px" }}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isLogoutOpen}
        onConfirm={callLogout}
        onClose={handleCloseModal}
        description={getLabel("modalLogout")}
      ></ConfirmationModal>
    </div>
  );
};

export default Header;
