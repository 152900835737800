import { createSlice } from "@reduxjs/toolkit";

export const historySlice = createSlice({
    name: 'history',
    initialState: {
        index: -1,
        history: []
    },
    reducers: {
        append: (state, action) => {
            const { path } = action.payload;
            return {
                index: state.index++,
                history: [...state.history, path]
            }
        },
        clear: (state) => {
            return {
                index: -1,
                history: []
            }
        },
        goBack: (state) => {
            return {
                index: state.index--
            }
        },
        goForward: (state) => {
            return {
                index: state.index++
            }
        }
    }
});

export const {append, clear, goBack, goForward} = historySlice.actions;

export default historySlice.reducer