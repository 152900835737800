import { useNavigate } from "react-router-dom"

const ClientTile = ({
    name,
    type,
    barrels_num,
    capacity,
    quantity,
    id_code,
    id
}) => {

    const navigate = useNavigate();

    const percentageLoad = (quantity * 100)/capacity

    return (<div className="client_tile"
        onClick={() => {navigate(`/clients/${id}`)}}
    >
        <div className="client_tile_content">
            <div className="client_tile_content_name">{name}</div>
            <div className="client_tile_content_code">{id_code}</div>
        </div>
        <div className="client_tile_progress" style={{
            height: `${percentageLoad}%`}}></div>
    </div>)
}

export default ClientTile