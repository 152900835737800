import { format, addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  selectActiveWinery,
  selectAnalysis,
  selectAnalysisResults,
  selectConfigs,
  selectLots,
  selectTanks,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useLabels from "../../hooks/useLabels";
import {
  getLots,
  getLotTracking,
  getOperations,
  getProtocols,
  getTanks,
  // getAnalysisResults,
  getTankTracking,
} from "../../services/utils";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { setTanks } from "../../context/tanks/tanksSlice";
import { setLots } from "../../context/lots/lotsSlice";
// import { addAnalysisResults } from "../../context/substances/analysisResultsSlice";
// import AnalysisEvolution from "../AnalysisEvolution/AnalysisEvolutions";

import { Player } from "@lottiefiles/react-lottie-player";
import json_recurrent_operation from "../../assets/lottieJson/recurrent_operation.json";
import json_single_operation from "../../assets/lottieJson/single_operation.json";
import { BatchIcon } from "../Generic";

const AnalysisTile = ({
  relative_id,
  total,
  completion_date,
  lots,
  exam,
  tanks,
  configs,
  id,
  main_id,
  repetitive,
}) => {
  const [getLabel] = useLabels();
  const navigate = useNavigate();

  let wineColor = "red";
  switch (lots?.find((l) => l.id === id)?.color) {
    case "WHITE":
      wineColor = "white";
      break;
    case "ROSE":
      wineColor = "rose";
      break;
    default:
      wineColor = "red";
      break;
  }

  return (
    <div
      className="analysis_tile"
      onClick={() => {
        navigate(`/programming/ANALYSIS/${id}`);
      }}
    >
      <div className="analysis_tile_content">
        <div className="analysis_tile_content_name">
          <div className="analysis_tile_content_name_wrapper">
            <span>{exam?.protocols_name} {repetitive ? main_id || id : id || ''} {repetitive ? `${relative_id}/${total}` : ''}</span>
            <span className="analysis_tile_content_name_wrapper_icon">
              <Player
                autoplay={false}
                controls={false}
                hover={true}
                src={
                  repetitive ? json_recurrent_operation : json_single_operation
                }
                style={{
                  width: "24px",
                  height: "24px",
                  transform: "rotate(180deg)",
                }}
              />
            </span>
          </div>
        </div>
        <div className="analysis_tile_content_date">
          {format(new Date(completion_date || null), configs.dateFormat)}
        </div>
        <div className="analysis_tile_content_lots">
        {tanks?.length === 0 &&
          lots?.map((lot, index) => {
            return (
              <BatchIcon
                lot={lot}
                isText={false}
                isRecap={false}
                isName={true}
              />
              // <div key={index} className="analysis_tile_content_batch">
              //   {lot?.name}
              // </div>
            );
          })}
        </div>
        <div className="analysis_tile_content_tanks_container">
            {tanks?.slice(0, 2)?.map((tank, index) => {
              return (
                <div className="analysis_tile_content_tanks" key={index}>
                    <p className="tank_name">{tank?.batch_name}</p>
                    <div className="tank_representation">
                    <div
                      className={"tank_tile_progress_" + wineColor}
                      style={{
                        height: `${
                          (Number(tank?.quantity) / Number(tank?.capacity)) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div>
                    <p className="tank_name">{tank?.name}</p>
                    {/* <p className="tank_quantity">{tank?.quantity || 0} L</p> */}
                    <p className="tank_quantity">{`${tank?.quantity || 0} L (${Math.round((tank?.quantity * 100) / (Number(tank?.capacity) || 1))}%)`}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {tanks?.length > 2 && <p className="p-long-tanks">{getLabel('tankOthers')}</p>}
      </div>
    </div>
  );
};

const AnalysisGrid = ({ isLot = false, isTank = false, id }) => {
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const activeWinery = useSelector(selectActiveWinery);
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const configs = useSelector(selectConfigs);

  const [completedAnalysis, setCompletedAnalysis] = useState([]);
  const [completedOperations, setCompletedOperations] = useState([]);
  // const [analysisValues, setAnalysisValues] = useState([]);
  const analysis = useSelector(selectAnalysis);

  useEffect(() => {
    if (!analysis || analysis.length === 0) {
      loadAnalysis();
    }
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
    if (!lots || lots.length === 0) {
      loadLots();
    }
    loadCompletedOperations();
    // const filteredAnalysisValues = filterAnalysisValues();
    // setAnalysisValues(filteredAnalysisValues);
    // console.log('filteredAnalysisValues', filteredAnalysisValues)
  }, [activeWinery]);

  useEffect(() => {
    loadCompletedOperations();
  }, [id]);

  useEffect(() => {
    loadTrackingById();
  }, [completedOperations]);

  const loadCompletedOperations = async () => {
    const recentlyCompletedDate = addDays(new Date(), -14).toISOString(); // TO DO: configurable
    const cOps =
      await getOperations(axiosPrivate, true, null, recentlyCompletedDate) || [];
    if (!cOps || cOps?.length === 0 || !Array.isArray(cOps)) return;
    const completedOps = cOps?.filter((o) => o.type === "ANALYSIS") || [];

    setCompletedOperations(completedOps);
    if (id === undefined) {
      const completedAns = completedOps?.filter(
        (operation) =>
          operation.type === "ANALYSIS" && operation?.completion_date !== null
      );
    
      setCompletedAnalysis(completedAns);
    }
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery?.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery?.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadTrackingById = async () => {
    if (id !== undefined) {
      const loadedTracking = isLot
        ? await getLotTracking(axiosPrivate, id)
        : isTank
        ? await getTankTracking(axiosPrivate, id)
        : [];
      const trackingOpId = new Set();

      if (loadedTracking?.length > 0) {
        if (!isTank) {
          loadedTracking?.reduce(
            (_, item) =>
              item?.operation_ids?.forEach((i) => trackingOpId.add(i)),
            null
          );
        } else {
          loadedTracking?.reduce((_, item) => trackingOpId.add(item.id), null);
        }
        // console.log('trackingOpId', trackingOpId)
        // console.log('loadedTracking', loadedTracking)
        // console.log('completedOperations', completedOperations)

        setCompletedAnalysis(
          completedOperations?.filter(
            (operation) =>
              operation.type === "ANALYSIS" &&
              operation?.src_tanks?.some?.(
                (tank) =>
                  // ((isTank && trackingOpId?.has(Number(tank?.tank_id))) ||
                  (isTank &&
                    Number(id) == Number(tank?.tank_id) &&
                    trackingOpId?.has(Number(operation?.id))) ||
                  (isLot &&
                    Number(id) === Number(tank?.batch_id) &&
                    trackingOpId?.has(Number(operation?.id)))
                // (isLot && trackingOpId?.has(Number(tank?.batch_id))))
              )
          )
        );
      }
    }
  };

  const loadAnalysis = async () => {
    const currentAnalysis = await getProtocols("analysis", axiosPrivate);
    dispatch(setAnalysis(currentAnalysis));
  };
  
  return (
    <>
      <div className="primary_container_lower_info">
        <h4>
          {getLabel("lastAnalysis" + (isLot ? "LOT" : isTank ? "TANK" : ""))}
        </h4>
        {completedAnalysis?.length === 0 && (
          <h5 className="alert_no_info">{getLabel("noAnalysisCompleted")}</h5>
        )}
        {completedAnalysis?.length > 0 && (
          <div className="analysis_grid">
            {completedAnalysis?.sort((b, a) => new Date(b?.completion_date) - new Date(a?.completion_date))?.map((an, index) => {
              // if (!an.repetitive || (an.repetitive))  
                return (
                  <AnalysisTile
                    {...an}
                    key={`analysis-tile-${index}`}
                    configs={configs}
                    exam={analysis?.find(
                      (exam) => an?.protocol_id == exam.protocols_id
                    )}
                    // tanks={(isTank
                    //   ? tanks.find((t) => t.id === id)
                    //   : tanks
                    // )?.filter((t) =>
                    //   an?.src_tanks?.some(
                    //     (tank) => Number(tank?.tank_id) === t.id
                    //   )
                    // )}
                    // TO DO check parte commentata sopra, sostituita con quella sotto
                    tanks={an?.src_tanks?.map((t) => { return { ...t?.postOperation?.tank }})}
                    lots={
                      id !== undefined
                        ? lots?.filter((l) => Number(l.id) === Number(id))
                        : lots?.filter((l) =>
                            an?.src_tanks?.some(
                              (tank) => Number(tank?.batch_id) === l.id
                            )
                          )
                    }
                  />
                );
              }
            )}
          </div>
        )}
      </div>
      {/* {id !== undefined && renderAnalysisEvolution()} */}
    </>
  );
};

export default AnalysisGrid;
