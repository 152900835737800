import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  DatePicker,
  Input,
  MultipleSelect,
  Select,
  Submit,
  TextArea,
} from "../FormComponents";
import useLabels from "../../hooks/useLabels";
import { empty, setOperations } from "../../context/operations/operationsSlice";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getOperations,
  getWineLabels,
  newOperation,
  updateOperation,
} from "../../services/utils";
import { ConfirmationModal, OperationRecap } from "../Generic";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_REGEX,
} from "../../constants/regex";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  selectAllLotNames,
  selectOperations,
  selectVirtualTanks,
  selectWineLabels,
} from "../../context/selectors";
import { setWineLabels } from "../../context/wineLabels/wineLabelsSlice";
import { DestLotDetail, DestTankDetail } from "../OperationsDetail/Details";

const DestemmingForm = ({
  operation,
  lots,
  activeWinery,
  tanks,
  users,
  configs,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const wineLabels = useSelector(selectWineLabels);
  const [isOpen, setIsOpen] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  // const [newBatchNames, setNewBatchNames] = useState(new Set());
  const operations = useSelector(selectOperations);
  const lotNames = useSelector(selectAllLotNames);
  // const virtualTanks = useSelector(selectVirtualTanks);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    resetField,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      src_tanks: operations?.src_tanks,
      dest_tanks: operation?.dest_tanks || [
        {
          tankData: { value: null, label: null },
          quantity: 0,
          new_batch_name: "",
          batch_name_selector: false,
        },
      ],
      // batchType: operation?.batchType || "",
      expire_date: operation?.expire_date || null,
      type: "DESTEMMING",
      batch_id_in: operation?.batch_id_in || "",
      priority: operation?.priority || "",
      cellarman_ids: operation?.cellarman_ids || "",
      new_batch_name: operation?.new_batch_name || "",
    },
    mode: "onTouched",
  });

  const { dest_tanks } = useWatch({ control });

  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "dest_tanks",
  });

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  const loadWineLabels = async () => {
    const currentWineLabels = await getWineLabels(axiosPrivate);
    dispatch(setWineLabels(currentWineLabels));
  };

  useEffect(() => {
    if (!wineLabels || wineLabels.length === 0) {
      loadWineLabels();
    }

    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      const lot = lots?.find((l) => l.id === operation?.batch_id_in);
      setValue("batch_id_in", {
        value: lot?.id,
        label: lot?.name,
        color: lot?.color,
        initial_quantity: lot?.quantity,
        quantity: lot?.actual_quantity,
      });

      // const chosenQuantity = operation?.dest_tanks?.reduce((acc, curr) => {
      //   return acc + (Number(curr?.quantity) || 0);
      // }, 0);
      // setValue("batch_id_in_quantity", chosenQuantity);

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      // setValue("batchType", {
      //   label: getLabel(`lotType${operation?.batch_type}`),
      //   value: operation?.batch_type,
      // });

      const dests = [];
      operation?.dest_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === s.tank_id);
        dests.push({
          quantity: s?.quantity,
          tankData: {
            value: tank?.id,
            name: tank?.name,
            label: tank?.name,
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            availability: Number(tank?.capacity) - Number(tank?.quantity),
            color: tank?.color,
            lot:
              lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
              getLabel("tankState_EMPTY"),
          },
          new_batch_name: s?.new_batch_type === "NEW" && s?.is_new ? s?.new_batch_name : "",
          batch_name_selector:
            s?.new_batch_type === "NEW" && s?.is_new
              ? {
                  value: true,
                  label: getLabel("lotNewName"),
                }
              : {
                  value: false,
                  label: s?.new_batch_name,
                },
        });
      });
      replace(dests);

      // setNewBatchNames(computeLotNames());

      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      setValue("expire_date", operation?.expire_date);

      setValue("note", operation?.note);
    }
  }, [operations, operation, lots]);

  const computeLotNames = (isValidate = false) => {
    const names = new Set();
    if (getValues("batch_id_in")?.label)
      names.add(getValues("batch_id_in")?.label);

    // shows only 6 months old must batches. TO DO: what if a batch of the previous year has the same name?? I hope it is deactivated
    // lots?.filter((l) => l?.state === 'MUST' && (new Date(l?.insertion_date) >= addMonths(new Date(), -6)))?.reduce((_, l) => names.add(l?.name), null);
    lotNames
      ?.filter((l) => l?.state === "MUST")
      ?.reduce((_, l) => names.add(l?.name), null);

    if (dest_tanks?.length === 1 && dest_tanks?.[0]?.tankData?.value === null)
      return names;
    dest_tanks
      ?.filter((tank) => tank?.tankData?.quantity > 0)
      ?.reduce((_, item) => names.add(item?.tankData?.lot), null);

    if (!isValidate) {
      for (const tank of dest_tanks) {
        if (tank?.new_batch_name?.length > 0) names.add(tank?.new_batch_name);
      }
      let i = 0;
      for (const tank of dest_tanks?.filter(
        (t) => t?.tankData?.value && "batch_name_selector" in t
      )) {
        if (
          tank?.batch_name_selector?.value === false &&
          !Array.from(names.values())?.includes(
            tank?.batch_name_selector?.label
          )
        ) {
          setValue(`dest_tanks.${i}.batch_name_selector`, {
            value: "",
            label: "",
          });
        }
        i += 1;
      }
    }

    const nameObjects = [];
    names?.forEach((s) => {
      if (s) nameObjects.push({ value: false, label: s });
    });

    return nameObjects?.sort((a, b) =>
      a?.label > b?.label ? 1 : b?.label > a?.label ? -1 : 0
    );
  };

  const customIdMissingDests = "custom-id-missing-dests";
  const sendData = async () => {
    if (Object.keys(errors)?.length > 0) return;
    const data = getValues();
    // if (!data?.batch_name_selector?.value) {
    //   data.new_batch_name = data?.batch_name_selector?.label;
    // }
    // return;

    const dests = [];
    // let isSRCFound = false;
    // const newBatchIds = {};
    // // const order = { NEW: 1, INHERITED: 2, OLD: 3 };
    // //   const sorted_dest_tanks = data?.dest_tanks?.sort(function (a, b) {
    // //       return order[a.new_batch_type] - order[b.new_batch_type];
    // //   });
    // data?.dest_tanks
    //   ?.filter((d) => d?.tankData?.value && d?.quantity &&
    //   ((d?.batch_name_selector?.value === true && d?.new_batch_name) ||
    //     (d?.batch_name_selector?.value === false && d?.batch_name_selector?.label)))
    //   ?.forEach((destT) => {
    //     const type = (data?.batch_id_in?.label === destT?.batch_name_selector?.label && !isSRCFound) ? 'NEW' :
    //     (data?.batch_id_in?.label === destT?.batch_name_selector?.label && isSRCFound) ? 'INHERITED' : (destT?.batch_name_selector?.value === true && destT?.new_batch_name) ? 'NEW' :
    //     (destT?.batch_name_selector?.value === false && destT?.batch_name_selector?.label) ? 'INHERITED' : 'OLD';
    //     if (type === 'NEW' && !isSRCFound && data?.batch_id_in?.label === destT?.batch_name_selector?.label) isSRCFound = true;

    //     const dest = {
    //       tank_id: destT?.tankData?.value,
    //       quantity: Number(destT.quantity),
    //       new_batch_name: (destT?.batch_name_selector?.value === true ? destT?.new_batch_name : destT?.batch_name_selector?.label)?.trim(),
    //       new_batch_type: type,
    //       old_batch_id:
    //         tanks?.find((t) => t.id === destT?.tankData?.value)?.batch_id ||
    //         "",
    //       old_quantity:
    //         tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity ||
    //         0,
    //       new_quantity:
    //         (Number(
    //           tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity ||
    //             0
    //         ) || 0) + (Number(destT.quantity) || 0),
    //     };
    //     dests.push(dest);
    //   });

    const newBatchIds = [];
    const sorted_dest_tanks = data?.dest_tanks?.sort(function (a, b) {
      return b?.batch_name_selector?.value - a?.batch_name_selector?.value;
    })?.filter(
      (d) =>
        d?.tankData?.value &&
        d?.quantity &&
        ((d?.batch_name_selector?.value === true && d?.new_batch_name) ||
          (d?.batch_name_selector?.value === false &&
            d?.batch_name_selector?.label))
    );
    // console.log(sorted_dest_tanks)
    for (const destT of sorted_dest_tanks) {
        const type =
          ((destT?.batch_name_selector?.value === true &&
          destT?.new_batch_name?.length > 0 &&
          !newBatchIds?.includes(destT?.new_batch_name) &&
          lots?.find(
            (l) =>
              (l?.name === destT?.new_batch_name &&
                destT?.batch_name_selector?.value === true) ||
              (l?.name === destT?.batch_name_selector?.label &&
                destT?.batch_name_selector?.value === false)
          ) === undefined) || 
          (data?.batch_id_in?.label === destT?.batch_name_selector?.label && 
            destT?.batch_name_selector?.value === false && 
            !destT?.new_batch_name &&
            !newBatchIds?.includes(destT?.batch_name_selector?.label)))
            ? "NEW"
            : (destT?.batch_name_selector?.value === false &&
                destT?.new_batch_name?.length === 0 &&
                newBatchIds?.includes(destT?.batch_name_selector?.label)) ||
              newBatchIds?.includes(destT?.batch_name_selector?.label)
            ? "INHERITED"
            : (lots?.find(
                (l) =>
                  (l?.name === destT?.new_batch_name &&
                    destT?.batch_name_selector?.value === true) ||
                  (l?.name === destT?.batch_name_selector?.label &&
                    destT?.batch_name_selector?.value === false)
              ) ===
                undefined) !==
              undefined
            ? destT?.new_batch_name && lots?.find((l) => l?.name === destT?.new_batch_name) ===
                undefined &&
              !destT?.batch_name_selector?.value &&
              destT?.batch_name === undefined
              ? "NEW" // lot not existing yet but created in another operation
              : "OLD"
            : null;
        // console.log('lotNames', lotNames) // TO DO va messo il check nel 2o "NEW"

        if (type === null) {
          toast.error(getLabel("toast_invalidType"), {
            toastId: customIdInvalidLotName,
            position: toast.POSITION.BOTTOM_RIGHT,
            exclude: true,
            autoClose: 5000,
          });
          return;
        }
        
        // if (
        //   destT?.batch_name_selector?.value === true &&
        //   destT?.new_batch_name?.length > 0 &&
        //   !newBatchIds?.includes(destT?.new_batch_name)
        // )
        const newName = destT?.batch_name_selector?.value === true ? destT?.new_batch_name : destT?.batch_name_selector?.label;
        if (type === "NEW" && !newBatchIds?.includes(newName)) {
          newBatchIds.push(newName);
          // TO DO check all cases
        }

        const dest = {
          tank_id: destT?.tankData?.value,
          quantity: Number(destT.quantity),
          is_new: (destT?.batch_name_selector?.value === true && destT?.new_batch_name?.length > 0),
          new_batch_name: (destT?.batch_name_selector?.value === true
            ? destT?.new_batch_name
            : destT?.batch_name_selector?.label
          )?.trim(),
          new_batch_type: type,
          old_batch_id:
            tanks?.find((t) => t.id === destT?.tankData?.value)?.batch_id || "",
          old_quantity:
            tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity || 0,
          new_quantity:
            (Number(
              tanks?.find((t) => t.id === destT?.tankData?.value)?.quantity || 0
            ) || 0) + (Number(destT.quantity) || 0),
        };
        dests.push(dest);
      };

    if (dests?.length === 0) {
      toast.error(getLabel("toast_missingDestTanks"), {
        toastId: customIdInvalidLotName,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
        autoClose: 5000,
      });
      return;
    }
    const tmpDate = new Date(data?.expire_date)?.setHours(23, 59, 59);

    const requestPayload = {

      src_tanks: [
        {
          color: data?.batch_id_in?.color,
          old_quantity: data?.batch_id_in?.quantity,
          name: data?.batch_id_in?.label,
          batch_id: data?.batch_id_in?.value,
        },
      ],
      dest_tanks: dests,
      priority: data?.priority.value,
      // batch_type: data?.batchType?.value,
      // expire_date: addMinutes(
      //   new Date(data?.expire_date)?.setHours(23, 59, 59),
      //   -new Date(data?.expire_date).getTimezoneOffset()
      // ).toISOString(),
      is_exact_date: isExactDate,
      expire_date: new Date(tmpDate)?.toISOString(),
      type: data?.type,
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      // new_batch_name: data?.new_batch_name,
      batch_id_in: data?.batch_id_in?.value,
      note: data?.note,
    };
    console.log("requestPayload", requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    console.log(response);
    toast.update(toastId, {
      render: response?.error
        ? getLabel(response?.error)
        : getLabel(response?.success, { type: operation?.type || "" }),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && !response.error) {
      dispatch(empty());
      navClickHandler();
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/DESTEMMING/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const getError = (idx, data, key) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const getTotalQuantity = () => {
    return (
      dest_tanks?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0) || 0
    );
  };

  const getWineLabelsOptions = () => {
    if (!wineLabels || wineLabels?.length === 0) return [];
    if (
      getValues("label") &&
      getValues("label")?.filter((l) => l?.type !== "label")?.length > 0
    )
      return wineLabels?.labels;
    return [...wineLabels?.wine, ...wineLabels?.labels] || [];
  };

  const customIdInvalidColor = "custom-id-invalid-color";
  const customIdInvalidLotName = "custom-id-invalid-lot-name";
  const customIdInvalidQuantities = "custom-id-invalid-quantities";
  const openFormModal = () => {
    // console.log('errors', errors)
    // trigger();

    const expDate = getValues('expire_date');
    const tmpDate = new Date(expDate)?.setHours(23, 59, 59);
    if (!expDate || !tmpDate || new Date(tmpDate) < new Date()) {
      setError('expire_date', {
        type: "manual",
        message: getLabel("errorDateInThePast"),
      });
      return;
    }
    
    if (dest_tanks?.filter((t) => t?.tankData?.value)?.length === 0) {
      toast(getLabel("toast_destemmingMissingDests"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    if (
      dest_tanks?.filter(
        (t) =>
          (!t?.batch_name_selector?.value &&
            t?.batch_name_selector?.label?.length === 0) ||
          (t?.batch_name_selector?.value === true &&
            t?.new_batch_name?.length === 0)
      )?.length > 0
    ) {
      toast(getLabel("toast_destemmingMissingLotSelectors"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    // const filteredTanks = dest_tanks?.filter((d) => d?.tankData?.value);
    // if (filteredTanks?.length === 1)
    //   setValue(
    //     "dest_tanks.0.quantity",
    //     getTotalQuantity()
    //   );

    const destemmingLiters = getTotalQuantity();
    // return;

    const totalKgAvailable = Number(getValues("batch_id_in")?.quantity);
    if (destemmingLiters <= totalKgAvailable) {
      toast.dismiss(customIdInvalidQuantities);
      // setIsOpen(true);
    } else {
      toast(
        getLabel("toast_destemmingInvalidQuantities", {
          sum: destemmingLiters || "0",
          total: totalKgAvailable,
        }),
        {
          toastId: customIdInvalidQuantities,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );

      dest_tanks?.forEach((_, index) =>
        setError(`dest_tanks.${index}.quantity`, {
          type: "manual",
          message: getLabel("toast_invalidQuantity"),
        })
      );
      return;
    }

    dest_tanks?.forEach((tank) => {
      if (
        tank?.tankData?.color &&
        tank?.tankData?.color !== getValues("batch_id_in")?.color
      ) {
        toast.warn(getLabel("toast_WarningDiffentWineColor"), {
          toastId: customIdInvalidColor,
          position: toast.POSITION.BOTTOM_RIGHT,
          exclude: true,
          autoClose: 5000,
        });
        return false;
      }
    });

    setIsOpen(true);
  };

  const getLotData = (l) => {
    const lot = { ...getValues("batch_id_in") };
    const totalQuantity = Math.min(lot?.quantity, getTotalQuantity());

    const lotData = {};
    lotData.name = lot.name;
    lotData.old_quantity = lot.quantity;
    lotData.quantity = lot.quantity - totalQuantity;
    lotData.color = lot.color;
    lotData.initial_quantity = lot.initial_quantity;
    lotData.arrow_quantity = totalQuantity;

    return lotData;
  };

  const getTankData = (t) => {
    const tank = t?.tankData;
    const quantity =
      tank?.availability >= Number(t?.quantity) && Number(t?.quantity) >= 0
        ? Number(t?.quantity)
        : 0;
    const new_batch_name =
      t?.batch_name_selector?.value === true && t?.new_batch_name
        ? t.new_batch_name
        : t?.batch_name_selector?.value === false
        ? t?.batch_name_selector?.label
        : "";

    const tankData = {};
    tankData.arrow_quantity = quantity;
    tankData.capacity = tank.capacity;
    tankData.old_quantity = tank.quantity;
    tankData.old_batch_name = tank.lot || "";
    tankData.quantity = tank.quantity + quantity;
    tankData.color =
      tank.quantity > 0 ? tank?.color : getValues("batch_id_in")?.color;
    tankData.batch_name = new_batch_name;

    return tankData;
  };

  return (
    <div className="operation_form_wrapper">
      <form
        onSubmit={handleSubmit(openFormModal)}
        autoComplete="off"
        noValidate
      >
        <h6>{getLabel("fromLot")}</h6>
        <div className="operation_form_wrapper_row">
          <Select
            control={control}
            defaultValue={operation?.batch_id_in}
            name="batch_id_in"
            label={getLabel("operationDestemmingLot")}
            placeholder={getLabel("operationDestemmingLotPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={lots
              ?.filter((lot) => lot?.state === "GRAPE")
              ?.map?.((lot) => {
                return {
                  value: lot.id,
                  label: lot.name,
                  color: lot.color,
                  initial_quantity: lot.quantity,
                  quantity: lot.actual_quantity,
                };
              })}
            tooltip={getLabel("destemmingBatchInTooltip")}
          />
          {getValues("batch_id_in") && (
            <>
              <DestLotDetail
                lot={getLotData(getValues("batch_id_in"))}
                isRecap={false}
                // isDestemming={true}
              />
            </>
          )}
        </div>
        <div className="operation_form_wrapper_row vertical">
          <h6>{getLabel("toTanks")}</h6>
          {fields?.map?.((field, idx) => {
            return (
              <div key={field.id} className="operation_form_wrapper_row">
                <Select
                  label={getLabel("operationDestTankName")}
                  placeholder={getLabel("operationDestTanksPlaceholder")}
                  error={errors}
                  required={getLabel("inputRequiredError")}
                  name={`dest_tanks.${idx}.tankData`}
                  control={control}
                  options={tanks
                    ?.filter(
                      (tank) =>
                        (tank.quantity === 0 || tank.state === "MUST") &&
                        !["BARRIQUE", "TONNEAU"].includes(tank?.type) && // TO DO: in autoclave ci va il pigiadiraspato??
                        !dest_tanks?.some?.(
                          (field) => field.tankData.value == tank.id
                        )
                    )
                    ?.sort((a, b) =>
                      (a?.name).localeCompare(b?.name, undefined, {
                        numeric: true,
                      })
                    )
                    ?.map?.((tank) => {
                      return {
                        value: tank?.id,
                        label: tank?.name,
                        name:
                          tank?.name +
                          ` (${
                            lots?.find((lot) => lot?.id === tank?.batch_id)
                              ?.name || getLabel("tankState_EMPTY")
                          })`,
                        capacity: Number(tank?.capacity),
                        quantity: Number(tank?.quantity),
                        availability:
                          Number(tank?.capacity) - Number(tank?.quantity),
                        color: tank?.color,
                        lot:
                          lots?.find((lot) => lot?.id === tank?.batch_id)
                            ?.name || getLabel("tankState_EMPTY"),
                      };
                    })}
                  // tooltip={(idx === 0) ? getLabel("destemmingTankDestTooltip") : ''}
                />
                {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                  <DestTankDetail
                    tank={getTankData(dest_tanks?.[idx])}
                    isDrop={false}
                    isName={false}
                    isFirst={idx === 0}
                  ></DestTankDetail>
                )}
                {fields?.length > 0 &&
                  getValues(`dest_tanks.${idx}.tankData`)?.value && (
                    <Input
                      name={`dest_tanks.${idx}.quantity`}
                      register={register}
                      type="number"
                      label={getLabel(`operationSrcTankQuantity`)}
                      placeholder={getLabel(
                        "operationDestTanksQuantityPlaceholder"
                      )}
                      error={getError(idx, errors?.dest_tanks, "quantity")}
                      required={getLabel("inputRequiredError")}
                      min={{
                        value: 1,
                        message: getLabel("errorMinValue", { value: 1 }),
                      }}
                      max={{
                        value: Math.min(
                          dest_tanks[idx]?.tankData?.availability,
                          Number(getValues("batch_id_in")?.quantity)
                        ),
                        message: getLabel("errorMaxValue", {
                          value: Math.min(
                            dest_tanks[idx]?.tankData?.availability,
                            Number(getValues("batch_id_in")?.quantity)
                          ),
                        }),
                      }}
                      regExpPattern={{
                        value: INT_REGEX,
                        message: getLabel("errorInt"),
                      }}
                      tooltip={
                        idx === 0
                          ? getLabel("destemmingTankDestQuantityTooltip")
                          : ""
                      }
                      validate={() => {
                        if (
                          getTotalQuantity() <=
                          getValues("batch_id_in")?.quantity
                        )
                          dest_tanks?.forEach((_, index) =>
                            clearErrors(`dest_tanks.${index}.quantity`)
                          );
                        else
                          return getLabel("toast_invalidQuantity");
                      }}
                    />
                  )}
                {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                  <div className="new_batch_name">
                    <Select
                      control={control}
                      defaultValue={{
                        value: "",
                        label: "",
                      }}
                      name={`dest_tanks.${idx}.batch_name_selector`}
                      // name={`batch_name_selector.${idx}`}
                      // name="batch_name_selector"
                      label={getLabel("operationNewLotName")}
                      placeholder={getLabel("operationNewLotNamePlaceholder")}
                      error={errors}
                      required={getLabel("inputRequiredError")}
                      options={[
                        {
                          value: true,
                          label: getLabel("lotNewName"),
                          className: "bold",
                        },
                        ...computeLotNames(),
                      ]}
                      tooltip={
                        idx === 0
                          ? getLabel(
                              "destemmingTankDestBatchNameSelectorTooltip"
                            )
                          : ""
                      }
                    />
                    {getValues(`dest_tanks.${idx}.batch_name_selector`)
                      ?.value && (
                      <Input
                        name={`dest_tanks.${idx}.new_batch_name`}
                        error={getError(
                          idx,
                          errors?.dest_tanks,
                          "new_batch_name"
                        )}
                        // name="new_batch_name"
                        register={register}
                        type="text"
                        label={getLabel("operationNewBatchName")}
                        placeholder={getLabel(
                          "operationNewBatchNamePlaceholder"
                        )}
                        // error={errors["new_batch_name"]}
                        required={getLabel("inputRequiredError")}
                        isReverse={true}
                        minLength={{
                          value: 3,
                          message: getLabel("errorMinLength", { value: 3 }),
                        }}
                        maxLength={{
                          value: 24,
                          message: getLabel("errorMaxLength", {
                            value: 24,
                          }),
                        }}
                        regExpPattern={{
                          value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                          message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        validate={(value) => {
                          if (
                            getValues(`dest_tanks.${idx}.batch_name_selector`)
                              ?.value &&
                            lots?.map((l) => l?.name)?.includes(value?.trim()) && false
                          ) {
                            // toast.error(
                            //   getLabel("toast_lotNameAlreadyPresent"),
                            //   {
                            //     toastId: customIdInvalidLotName,
                            //     position: toast.POSITION.BOTTOM_RIGHT,
                            //     exclude: true,
                            //     autoClose: 5000,
                            //   }
                            // );
                            // return false;
                            return getLabel("errorNewBatchNameAlreadyTaken", {
                              name: value,
                            });
                          }
                          // else {
                          // toast.dismiss(customIdInvalidLotName);
                          // return true;
                          // }
                        }}
                      />
                    )}
                  </div>
                )}
                {fields?.length > 1 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      remove(idx);
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            );
          })}
          <button
            id="tanksD"
            onClick={(e) => {
              e.preventDefault();
              append({
                tankData: {},
                quantity: 0,
                new_batch_name: "",
                batch_name_selector: "",
              });
            }}
          >
            +
          </button>
        </div>
        <h6>{getLabel("operationAdditional")}</h6>
        <div className="operation_form_wrapper_row">
          <MultipleSelect
            name={`cellarman_ids`}
            control={control}
            label={getLabel("operationUser")}
            placeholder={getLabel("operationUserPlaceholder")}
            error={errors["cellarman_ids"]}
            required={getLabel("inputRequiredError")}
            options={users?.map?.((user) => {
              return {
                value: user?.id,
                label: user?.username,
              };
            })}
            tooltip={getLabel("destemmingCellarmenTooltip")}
          />
          <Select
            name="priority"
            control={control}
            label={getLabel("operationPriority")}
            placeholder={getLabel("operationPriorityPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("lotQuality_HIGH"),
                value: "2",
              },
              {
                label: getLabel("lotQuality_MEDIUM"),
                value: "1",
              },
              {
                label: getLabel("lotQuality_LOW"),
                value: "0",
              },
            ]}
            // defaultValue={{
            //     value: "",
            //     label: "",
            //   }}
          />
          <DatePicker
            name="expire_date"
            control={control}
            label={getLabel("operationExpireDateForm")}
            error={errors["expire_date"]}
            required={getLabel("inputRequiredError")}
            dateFormat={configs.shortDateFormat}
            placeholder={getLabel("expiringDatePlaceholder")}
          />
          <Input
            name={"expire_date_exact"}
            register={register}
            label={getLabel("isExactDate")}
            error={errors["expire_date_exact"]}
            // isLabel={false}
            type={"checkbox"}
            checked={isExactDate}
            onChange={() => setIsExactDate((prev) => !prev)}
            customClassName={"confirm"}
            tooltip={getLabel("destemmingExactDate")}
          />
        </div>
        <div className="client_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div>
        <div className="operation_form_wrapper_row">
          <Submit label={getLabel("submitForm")}></Submit>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={sendData}
        onClose={handleCloseModal}
        description={getLabel("modalNewDestemmingDescription", {
          name: operation?.id || "",
        })}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
      >
        <OperationRecap
          type={"DESTEMMING"}
          control={control}
          register={register}
          getValues={getValues}
          // setError={setError}
          // clearErrors={clearErrors}
          errors={errors}
          lots={lots}
          // availableLotNames={computeLotNames()}
          lotNames={lots?.map((l) => l?.name)}
          // allLotNames={allLots?.map((l) => l?.name)}
          // totalStep={1}
          // step={step}
          dest_tanks={dest_tanks}
          src_tanks={[getValues("batch_id_in")]}
          // newBatchName={newBatchName}
        />
      </ConfirmationModal>
    </div>
  );
};

export default DestemmingForm;
