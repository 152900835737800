import { createSlice } from "@reduxjs/toolkit";

export const examsSlice = createSlice({
    name: 'exams',
    initialState: [],
    reducers: {
        emptyExams: state => {
            return []
        },
        setExams: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        addExams: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        updateExams: (state, action) => {
            // const foundedIndex = state.findIndex((lot) => lot.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return []
        },
        removeExams: (state, action) => {
            const foundedIndex = state.findIndex((lot) => lot.id === action.payload?.id);
            if (foundedIndex) {
                return state.splice(foundedIndex, 1)
            }
            return state
        }
    }
});

export const {
    setExams, 
    emptyExams,
    updateExams,
    removeExams,
    addExams
} = examsSlice.actions;

export default examsSlice.reducer