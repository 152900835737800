import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels"
import { Button, CTA } from "../Generic";
import SecondaryBar from "../SecondaryBar"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectConfigs, selectUser, selectUsers } from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { getUsers, updateUser, newUser, updateUserPassword, updateUserEmail } from "../../services/utils";
import { setUser, add, update, setUsers, emptyUsers } from "../../context/users/usersSlice";
import { Input, Label, Select, Submit } from "../FormComponents";
import { ALPHA_SPACE_APEX_REGEX, EMAIL_REGEX, NOT_USER_REGEX, PWD_REGEX, USER_REGEX } from "../../constants/regex";
import { getItem } from "../../services/LocalStorage";

import "./styles/index.scss";


const UserForm = ({
    
}) => {
    const { id } = useParams();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const users = useSelector(selectUsers);
    const currentUser = useSelector(selectUser);
    const wineriesSession = getItem('wineriesList') || [];
    const currentUserClientName = (wineriesSession?.find((winery) => winery.id === getItem('activeWineryId')))?.client_name || null;
    
    const configs = useSelector(selectConfigs);
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const [user, setUser] = useState(users?.length > 0 ? users.find((dbUser) => (dbUser.id === Number(id))) || {} : {})
    const { register, handleSubmit, formState: {errors, isValid}, reset, getValues, setValue, control } = useForm({
        defaultValues: {
            email: user?.client_email || '',
            name: user?.name || '',
            surname: user?.surname || '',
            role: user?.type ? {label: getLabel(user?.type), value: user?.type} : '',
        },
        mode: 'onTouched'
    })

    useEffect(() => {
        if (id && (!users || users.length === 0)) {
            loadUsers()
        }
        if (user) {
            setValue('email', user?.client_email || '');
            setValue('name', user?.name || '');
            setValue('surname', user?.surname || '');
            setValue('role', user?.type ? {label: getLabel(user?.type), value: user?.type} : '');
        }
    }, [])

    useEffect(() => {
        if (!users || users.length === 0) {
            loadUsers();
        }
        
        if (id && users.length > 0 && Object.keys(user).length === 0) {
            const singleUser = users.find((dbUser) => (dbUser.id === Number(id))) || null;
            if (singleUser) {
                setUser(singleUser);
                setValue('email', singleUser?.client_email || '');
                setValue('name', singleUser?.name || '');
                setValue('surname', singleUser?.surname || '');
                setValue('role', singleUser?.type ? {label: getLabel(singleUser?.type), value: singleUser?.type} : '');
            }
        }
    }, [users])

    useEffect(() => {
        const currentUserId = users?.find((u) => u.username === currentUser?.userName)?.id;
        if (!window.location?.pathname?.includes('/new') && currentUserId != id && currentUserId)
            navigate(`/users/${id}`)
    }, [user, users])

    const loadUsers = async () => {
        const currentUsers = await getUsers(axiosPrivate);
        dispatch(setUsers(currentUsers))
    }

    const updateEmail = async () => {
        const email = getValues('email');
        if (!email.match(EMAIL_REGEX)) {
            console.log("Invalid e-mail");
            return;
        }
        
        await updateUserEmail(email, axiosPrivate);
    }

    const updatePassword = async () => {
        const oldPassword = getValues('oldPassword');
        const password = getValues('password');
        
        if (!oldPassword.match(PWD_REGEX) || !password.match(PWD_REGEX)) {
            console.log("Invalid password");
            return;
        }

        const response = await updateUserPassword(oldPassword, password, axiosPrivate);
        if (response && !response.error) {
            setUser({});
            dispatch(emptyUsers());
            navClickHandler();
        }
    }

    const [defaultUsername, setDefaultUsername] = useState('');
    const updateDefaultUsername = () => {
        setDefaultUsername('');
        if (getValues('new_name') && getValues('new_surname') && 
        (getValues('new_name').charAt(0) + '.' + getValues('new_surname'))?.replace(NOT_USER_REGEX, '')?.replace(/\s+/g, '')?.length > 3) {
            setDefaultUsername((getValues('new_name').charAt(0) + '.' + getValues('new_surname'))?.replace(NOT_USER_REGEX, '')?.replace(/\s+/g, '')?.toLowerCase());
        }
    }

    const submitForm = async (data) => {
        if (id) {
            const requestPayload = {
                ...(data?.name !== user?.name && {name: data?.name}),
                ...(data?.surname !== user?.surname && {surname: data?.surname}),
                ...(id && {id: id})
            }
            console.log(requestPayload)
            const response = await updateUser(user?.username, requestPayload, axiosPrivate);
            if (response && !response.error) {
                updateStore(requestPayload);
                navClickHandler();
            }
        } else {
            const email = getValues('new_email');
            const name = getValues('new_name');
            const surname = getValues('new_surname');
            const username = getValues('new_username');
            const role = getValues('new_role');
            
            if (!email.match(EMAIL_REGEX)) {
                console.log("Invalid e-mail");
                return;
            }

            const requestPayload = {
                ...(name && {name: name}),
                ...(surname && {surname: surname}),
                ...(username && {username: username}),
                ...(email && {email: email}),
                ...(role && {role: Number(role)}),
                ...(id && {id: id})
            }

            const response = await newUser(username, requestPayload, axiosPrivate);
            console.log(response)
            // if (response && !response.error) {
            //     setUser({});
            //     // dispatch(emptyUsers());
            //     navClickHandler();
            // }
        }
    }

    const updateStore = (payload) => {
        if (payload.id) {
            dispatch(update(payload))
        } else {
            dispatch(add(payload))
        }
    }

    const navClickHandler = () => {
        if (id) {
            navigate(`/users/${id}`)
        } else {
            navigate('/users')
        }

    }

    const renderNavMenu = () => {
        const navClickHandler = () => {
            if (id) {
                navigate(`/users/${id}`)
            } else {
                navigate('/wineries')
            }
        }
        
        return (
            <div className="primary_container_menu">
                <div className="primary_container_row">
                    <Button
                        arrowDirection="left"
                        onClick={navClickHandler}
                    />
                    <h2>{getLabel(id ? 'usersUpdatePage': 'usersNewPage') + (id ? (' ' + user?.username) : '') }</h2>
                </div>
            </div>
        )
    }
    
    const renderForm = () => {
        return (<div className="user_form_wrapper">
            <form
                onSubmit={handleSubmit(submitForm)}
                autoComplete="off"
                noValidate
            >
                {currentUser?.userName === user?.username && <>
                <div className="user_form_wrapper_row">
                    <Input
                        name="name"
                        register={register}
                        type="text"
                        label={getLabel('clientName')}
                        placeholder={getLabel('clientNamePlaceholder')}
                        error={errors['name']}
                    />
                    <Input
                        name="surname"
                        register={register}
                        type="text"
                        label={getLabel('clientSurname')}
                        placeholder={getLabel('clientSurnamePlaceholder')}
                        error={errors['surname']}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Label label={getLabel('wineryClientName')}>
                        {user?.client_name}
                    </Label>
                    <Label label={getLabel('userRole')}>
                        {getLabel(`user_type_${user?.type}`)}
                    </Label>
                </div>
                <div className="user_form_wrapper_row">
                    <Input
                        name="email"
                        register={register}
                        type="text"
                        regExpPattern={{
                            value: EMAIL_REGEX,
                            message: getLabel('inputPatternError')
                        }}    
                        label={getLabel('userEmail')}
                        placeholder={getLabel('userEmailPlaceholder')}
                        error={errors['email']}
                    />
                    <CTA onClick={updateEmail}>{getLabel('updateClientEmail')}</CTA>
                </div>
                <div className="user_form_wrapper_row">
                    <Input
                        name='oldPassword'
                        register={register}
                        type="password"
                        label={getLabel('oldPassword')}
                        placeholder={getLabel('oldPasswordPlaceholder')}
                        error={errors['oldPassword']}
                    />
                    <Input
                        name='password'
                        register={register}
                        regExpPattern={{
                            value: PWD_REGEX,
                            message: getLabel('inputPatternError')
                        }}
                        type="password"
                        label={getLabel('newPassword')}
                        placeholder={getLabel('newPasswordPlaceholder')}
                        error={errors['password']}
                    />
                    <Label label={getLabel('passwordExpireDate')}>{format(
                            new Date(user?.password_expire_date || null),
                            configs.shortDateFormat
                        )}
                    </Label>
                    <CTA onClick={updatePassword}>{getLabel('updateClientPassword')}</CTA>
                </div>
                <div className="user_form_wrapper_row">
                    <Submit
                        label={getLabel('submitForm')}
                    />
                </div>
                </>}
                {currentUser?.userName !== user?.username && <>
                <div className="user_form_wrapper_row">
                    <Input
                        name="new_name"
                        register={register}
                        type="text"
                        label={getLabel('clientName')}
                        placeholder={getLabel('clientNamePlaceholder')}
                        error={errors['new_name']}
                        onChange={updateDefaultUsername}
                        minLength={{value: 4, message: getLabel('errorMinLength', {value: 4})}}
                        maxLength={{value: 25, message: getLabel('errorMaxLength', {value: 25})}}
                        regExpPattern={{value: ALPHA_SPACE_APEX_REGEX, message: getLabel('errorAlphaSpaceApex')}}
                    />
                    <Input
                        name="new_surname"
                        register={register}
                        type="text"
                        label={getLabel('clientSurname')}
                        placeholder={getLabel('clientSurnamePlaceholder')}
                        error={errors['new_surname']}
                        onChange={updateDefaultUsername}
                        minLength={{value: 4, message: getLabel('errorMinLength', {value: 4})}}
                        maxLength={{value: 25, message: getLabel('errorMaxLength', {value: 25})}}
                        regExpPattern={{value: ALPHA_SPACE_APEX_REGEX, message: getLabel('errorAlphaSpaceApex')}}
                    />
                    <Input
                        name="new_username"
                        register={register}
                        type="text"
                        label={getLabel('userName')}
                        placeholder={defaultUsername && defaultUsername?.length > 0 ? (getLabel('recommendedUserNamePlaceholder', {recommended: defaultUsername})): getLabel('userNamePlaceholder')}
                        error={errors['new_username']}
                        minLength={{value: 4, message: getLabel('errorMinLength', {value: 4})}}
                        maxLength={{value: 25, message: getLabel('errorMaxLength', {value: 25})}}
                        regExpPattern={{value: USER_REGEX, message: getLabel('errorUsername')}}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Label label={getLabel('wineryClientName')}>
                        {currentUserClientName}
                    </Label>
                    <Select
                        control={control}
                        name="new_role"
                        label={getLabel('userRole')}
                        defaultValue={{
                            value: 3,
                            label: getLabel('user_type_USER')
                        }}
                        required={getLabel('inputRequiredError')}
                        errors={errors['type']}
                        placeholder={getLabel('userRolePlaceholder')}
                        options={[{
                            value: 3,
                            label: getLabel('user_type_USER')
                        },
                        // {
                        //     value: 2,
                        //     label: getLabel('user_type_ANALYST'),
                        //     disabled: true
                        // }
                        ]}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Input
                        name="new_email"
                        register={register}
                        type="text"
                        label={getLabel('userEmail')}
                        placeholder={getLabel('userEmailPlaceholder')}
                        error={errors['new_email']}
                        minLength={{value: 5, message: getLabel('errorMinLength', {value: 5})}}
                        maxLength={{value: 35, message: getLabel('errorMaxLength', {value: 35})}}
                        regExpPattern={{value: EMAIL_REGEX, message: getLabel('errorEmail')}}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Submit
                        label={getLabel('submitForm')}
                    />
                </div>
                </>}
            </form>
        </div>)
    }

    return (
        <div className="primary_container">
            <SecondaryBar
                breadCrumb={[
                    getLabel('usersNavLink'),
                    getLabel(id ? 'usersUpdatePage' : 'usersNewPage')
                ]}
                isBasePathNeeded={false}
            />
            {renderNavMenu()}
            {renderForm()}
        </div>
    )
}

export default UserForm