import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? 'https://webapp.wine2trace.com/api' : 'http://localhost:3500';
const URL = process.env.NODE_ENV === 'production' ? 'http://localhost:3500' : 'http://localhost:3500';
// by default, the Socket.IO client opens a connection to the server right away.
// To prevent this behavior set autoConnect: false

export const socket = io(URL, {
  // path: "/socket.io",
  autoConnect: false,
  reconnectionDelay: 5000, // defaults to 1000
  reconnectionDelayMax: 10000, // defaults to 5000
  ackTimeout: 10000,
  // retries: 3,
  // auth: {
  //   token: "123"
  // },
});