import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { Button, CTA } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalysis,
  selectDrugs,
  selectExams,
  selectTreatments,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  getProtocols,
  getDrugs,
  getExams,
  updateProtocol,
  newProtocol,
} from "../../services/utils";
import {
  emptyTreatments,
  setTreatments,
} from "../../context/protocols/treatmentsSlice";
import {
  emptyAnalysis,
  setAnalysis,
} from "../../context/protocols/analysisSlice";
import { Input, Select, Submit, TextArea } from "../FormComponents";
import { setDrugs } from "../../context/substances/drugsSlice";
import { setExams } from "../../context/substances/examsSlice";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_FLOAT_REGEX,
} from "../../constants/regex";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import EditCell from "../TableEdit/EditCell";
import TableCell from "../TableEdit/TableCell";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { getItem } from "../../services/LocalStorage";

const ProtocolForm = ({}) => {
  const { id, type } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const substances = useSelector(
    type === "treatment" ? selectDrugs : selectExams
  );
  const protocols = useSelector(
    type === "treatment" ? selectTreatments : selectAnalysis
  );
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [protocol, setProtocol] = useState(
    protocols?.length > 0
      ? protocols.find((dbProtocol) => dbProtocol.id === Number(id)) || {}
      : {}
  );
  const [file, setFile] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [oldAnalysisSelects, setOldAnalysisSelects] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      name: protocol?.name || "",
      substance_ids: protocol?.substance_ids || [],
      notes: protocol?.notes || "",
    },
    mode: "onTouched",
  });
  const { state, color } = useWatch({ control });

  const columnHelper = createColumnHelper();
  const temperatureColumns = type === 'treatment' ? [columnHelper.accessor(
    (row) => `${row?.min_temperature ? String(row?.min_temperature) : 'n/a'} - ${row?.max_temperature ? String(row?.max_temperature) : 'n/a'}`,
    {
      header: getLabel("substanceTemperature") + ' (\u00b0C)',
      cell: TableCell,
      meta: {
        type: "number",
        isFilterDisabled: true,
      },
    }
  )] : [];
  
  const analysisColumns =
    type === "analysis"
      ? [
        columnHelper.accessor(
          (row) => (row?.colors?.length > 0 ? getLabel(`substance_color_${row?.colors?.toString()}`) : ""),
          {
            header: getLabel("substanceColors"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            }
          }
        ),
        columnHelper.accessor(
          (row) => (row?.states?.length > 0 ? getLabel(`substance_state_${row?.states?.toString()}`) : ""),
          {
            header: getLabel("substanceStates"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            }
          }
        ),] : []

  const treatmentsColumns =
    type === "treatment"
      ? [
          columnHelper.accessor(
            (row) => (row?.type ? getLabel(`drugType_${row.type?.toUpperCase()}`) : ""),
            {
              header: getLabel("drugType"),
              cell: TableCell,
              meta: {
                type: "text",
                isFilterDisabled: true,
              },
            }
          ),
          columnHelper.accessor("quantity", {
            header: getLabel("drugQuantity"),
            cell: TableCell,
            meta: {
              type: "number",
              editable: true,
              regExpPattern: INT_FLOAT_REGEX,
              register: register,
              isFilterDisabled: true,
            },
          }),
        ]
      : [];

  const columns = [
    columnHelper.accessor((row) => type === 'analysis' ? getLabel("substance_" + row?.substance_name) : row?.substance_name, {
      header: type === 'analysis' ? getLabel("analysisName") : getLabel("drugName"),
      cell: TableCell,
      meta: {
        isFilterDisabled: true,
      }
    }),
    ...treatmentsColumns,
    columnHelper.accessor(
      (row) => `${row?.min_range ? String(row?.min_range) : 'n/a'} - ${row?.max_range ? String(row?.max_range) : 'n/a'} ${row?.unit}`,
      {
        header: getLabel("substanceRange"),
        cell: TableCell,
        meta: {
          type: "number",
          isFilterDisabled: true,
        },
      }
    ),
    ...analysisColumns,
    ...temperatureColumns,
    // columnHelper.accessor(
    //   (row) => row?.min_range >= 0 ? `${row?.min_range} ${row?.unit}` : "",
    //   {
    //     header: getLabel("drugMinRange"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    // columnHelper.accessor(
    //   (row) => row?.max_range >= 0 ? `${row?.max_range} ${row?.unit}` : "",
    //   {
    //     header: getLabel("drugMaxRange"),
    //     cell: TableCell,
    //     meta: {
    //       type: "number",
    //     },
    //   }
    // ),
    columnHelper.display({
      id: "edit",
      cell: EditCell,
      meta: {
        isEditable: true,
      },
    }),
  ];

  useEffect(() => {
    if (!protocols || protocols.length === 0) {
      loadProtocols();
    }
    if (!substances || substances.length === 0) {
      loadSubstances();
    }
  }, [type]);

  useEffect(() => {
    if (type === 'analysis' && isReset === false && oldAnalysisSelects?.state && oldAnalysisSelects?.color &&
      (state?.value !== oldAnalysisSelects?.state?.value || color?.value !== oldAnalysisSelects?.color?.value)) {
        const filteredAnalysis = protocol?.analysis?.filter((a) => a?.colors?.includes(color?.value) && a?.states?.includes(state?.value));
        setProtocol((prev) => { return {
          ...prev,
          analysis: filteredAnalysis,
        }});
        setIsReset(true);
    }
    setOldAnalysisSelects({color: color, state: state})
  }, [state, color])
  

  useEffect(() => {
    if (id && protocols.length > 0 && Object.keys(protocol).length === 0) {
      const singleProtocol =
        protocols.find(
          (dbProtocol) => dbProtocol.protocols_id === Number(id)
        ) || null;
        
        if (singleProtocol) {
          setProtocol(singleProtocol);
          setValue("name", singleProtocol?.protocols_name || "");
          setValue("substance_ids", singleProtocol?.substance_ids || []);
          setValue("notes", singleProtocol?.notes || "");
          if (type === 'analysis') {
            setValue("color", {value: singleProtocol?.color, label: getLabel(`lotColor_${singleProtocol?.color}`)});
            setValue("state", {value: singleProtocol?.state, label: getLabel(`lotState_${singleProtocol?.state}`)});
            setOldAnalysisSelects({color: {value: singleProtocol?.color}, state: {value: singleProtocol?.state}})
          }
        }
    }
  }, [protocols, type]);

  const loadProtocols = async () => {
    const currentProtocols = await getProtocols(type, axiosPrivate);
    
    dispatch(
      type === "treatment"
        ? setTreatments(currentProtocols)
        : setAnalysis(currentProtocols)
    );
  };

  const getRightQuantity = (substance) => {
    const index = protocol?.substance_ids?.indexOf(substance?.substance_id || substance?.id);
    const protocolQuantity =
      protocol?.quantities?.length > index ? protocol?.quantities[index] : 0;

    return (protocolQuantity > 0 ? protocolQuantity : substance?.quantity) || 0;
  };

  const loadSubstances = async () => {
    const currentSubstances = await (type === "treatment"
      ? getDrugs(axiosPrivate)
      : getExams(axiosPrivate));
    dispatch(
      type === "treatment"
        ? setDrugs(currentSubstances)
        : setExams(currentSubstances)
    );
  };

  const submitForm = async (data) => {
    // console.log(data);
    // return;
    let toastId = null;
    if (
      !data.hasOwnProperty("removed") &&
      (!data?.column || data?.column?.length === 0) &&
      data?.notes === protocol?.notes &&
      data?.name === protocol?.protocols_name
    ) {
      toastId = toast.info(getLabel("toast_NoModifications"), {
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
      });
      return;
    }
    const quantities = [];
    const substance_ids = [];
    data?.column?.forEach((s, id) => {
      if (
        !data?.removed?.hasOwnProperty(id) ||
        (data?.removed?.hasOwnProperty(id) && !data?.removed[id])
      ) {
        substance_ids.push(id);
        quantities.push(Number(s?.value) || 0);
      }
    });

    substances
      ?.map((s, i) => {
        return {
          ...s,
          quantity: getRightQuantity(s),
        };
      })
      ?.forEach((s) => {
        if (
          !substance_ids.includes(s.id) &&
          protocol?.substance_ids?.includes(s.id) &&
          (!data?.removed?.hasOwnProperty(s.id) ||
            (data?.removed?.hasOwnProperty(s.id) && !data?.removed[s.id]))
        ) {
          substance_ids.push(s?.id);
          quantities.push(s?.quantity);
        }
      });

    const requestPayload = {
      name: data?.name,
      substance_ids: substance_ids,
      quantities: quantities,
      notes: data?.notes,
      color: color?.value,
      state: state?.value,
      ...(id && { id: Number(id) }),
    };

    toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;
    console.log(requestPayload);
    // return;
    if (id) {
      response = await updateProtocol(id, type, requestPayload, axiosPrivate);
    } else {
      response = await newProtocol(type, requestPayload, axiosPrivate);
    }

    toast.update(toastId, {
      render: response?.error
        ? getLabel(response?.error)
        : getLabel(response?.success, { name: data?.name || "" }),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    // console.log(response);
    if (response && !response.error) {
      updateStore();
      navClickHandler();
    }
  };


  const buildJson = (json, fileName) => {
    const data = [];
    let error = null;
    const extractParams = fileName.match(/(?<=__\[)(.*)(?=\].xlsx)/gs)?.[0];
    const [state, color] = extractParams?.split('-')
    if (color) setValue("color", {value: color, label: getLabel(`lotColor_${color}`)});
    if (state) setValue("state", {value: state, label: getLabel(`lotState_${state}`)});

    json?.forEach((row) => {
      const rowArr = Object.values(row) || [];
      // const tmp2 = type === 'treatment' ? {
      //   day: rowArr?.[0] || 0, //row?.['GIORNO'] || row?.['DAY'] || 0,
      //   quantity: rowArr?.[5], //row?.["QUANTITA'"] || row?.['QUANTITY'] || row?.['DOSE (mg/lt)'],
      //   note: rowArr?.[17], //row?.['NOTE'] || row?.['NOTE'],
      //   goal: rowArr?.[18], //row?.['OBIETTIVI'] || row?.['GOAL'],
      //   // type: row?.['TIPO'] || row?.['TYPE'],
      // } : {
      // }
      const product_name = rowArr?.[0] || ''; //row?.['NOME ANALISI'] || row?.['ANALYSIS NAME'] || '',
      const tmp = {};
      const foundDrug = substances
        ?.filter((s) => s?.colors?.includes(color) && s?.states?.includes(state))
        ?.map((s) => { return {...s, substance_name: s?.name, translatedName: getLabel(`substance_${s?.name}`)}})
        ?.find((s) => (type === 'treatment' ? s?.name : s?.translatedName) === product_name) || null;
      
      if (foundDrug !== null) {
      
        Object.assign(tmp, foundDrug);
        // tmp.unit = foundDrug?.unit_id;
        // tmp.label = foundDrug?.name;
        // tmp.substance_name = foundDrug?.name;
        // tmp.id = foundDrug?.id;
        // tmp.value = foundDrug?.id;
        // tmp.substance_id = foundDrug?.id;
        // tmp.deactivated = foundDrug?.deactivated
        // tmp.unit = foundDrug?.unit;
        // tmp.unit_id = foundDrug?.unit_id;
      } else {
        error = {
          product_name: tmp?.name,
          type: 'ADD'
        };
      }
      // console.log('tmp:', tmp, foundDrug)
      // phase
      // const phase = rowArr?.[1];
      // if (['Fermentazione', 'Fermentation'].includes(phase)) tmp.phase = 'FERM';
      // if (['Pre-fermentazione', 'Pre-fermentation'].includes(phase)) tmp.phase = 'PREFERM';
      // if (['Post-fermentazione', 'Post-fermentation'].includes(phase)) tmp.phase = 'POSTFERM';
      // if (['Inoculo', 'Inoculation'].includes(phase)) tmp.phase = 'INOCULATE';
      // if (['Inizio', 'Start'].includes(phase)) tmp.phase = 'START';
      // if (['Pre-fermentazione', 'Pre-fermentation'].includes(phase)) tmp.phase = 'PREFERM';
      // if (['Fermentazione alcolica', 'Alcoholic fermentation'].includes(phase)) tmp.phase = 'FERMAL';
      // if (['Fermentazione malolattica', 'Malo-lactic fermentation'].includes(phase)) tmp.phase = 'FERMML';
      // if (['Fine fermentazione malolattica', 'Malo-lactic fermentation end'].includes(phase)) tmp.phase = 'ENDFERMML';
      
      // TO DO manca sicuramente roba
      // TO DO aggiungi check correttezza dati
      
      data.push(tmp);
    });
    
    return error?.type ? error : data;
  }

  const handleExport = () => {
    const data = [];
    const language = getItem('i18LanguageLocale') || 'it';
    const headers = [];
    switch (language) {
      // TO DO falle diventare labels
      case "en":
        headers.push(...['ANALYSIS NAME', 'UNIT']);
        // else
        if (type === 'treatment') {
          headers.splice(1, 0, 'QUANTITY');
          headers.push(...['RECOM. MIN', "RECOM. MAX", getLabel("drugType")?.toUpperCase()]);
        }
        break;
      default:
        headers.push(...[type === 'treatment' ? getLabel('treatmentName')?.toUpperCase() : getLabel('analysisName')?.toUpperCase(), "UNITA'"])
        // if (type === 'analysis') headers.push(...['MINIMO RACCOM.', "MASSIMO RACCOM."])
        // else
        if (type === 'treatment') {
          headers.splice(1, 0, "QUANTITA'");
          headers.push(getLabel("drugType")?.toUpperCase());
          headers.push(...['MINIMO RACCOM.', "MASSIMO RACCOM.", getLabel("drugMinTemp")?.toUpperCase(), getLabel("drugMaxTemp")?.toUpperCase()]);
        }
      }

      // return
      const worksheet = XLSX.utils.json_to_sheet(data);
    // var worksheet = XLSX.utils.aoa_to_sheet([
    //   [ '' ], // A1
    //   [ {t: "n", f: "A2+A3"}, {t: "s", f: 'CHOOSE("","red","blue","green")'} ] // A2
    // ]);
    
    const wscols = [
      {wch: 15},
      {wch: 10},
    ];
    if (type === 'analysis') wscols.push(...[{ wch: 18 }, { wch: 18 }]);
    else if (type === 'treatment'){
      wscols[0] = {wch: 18};
      wscols.push(...[{ wch: 10 }, { wch: 18 }, { wch: 18 }, { wch: 10 }, { wch: 18 }, { wch: 18 }]);
    };

    worksheet['!cols'] = wscols;
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const protocolName = protocol?.protocols_name || 'data';

    XLSX.writeFile(
      workbook,
      `${protocolName}.xlsx`,
      { compression: true }
    );
  };

  const handleConvert = () => {
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
      isLoading: true,
    });
    // TO DO quando si blocca perche' file é nullo, devo togliere il toast (pure in protocolDetail e in winemakingProtocol form e detail)
    if (file) {
      const fileName = file?.name;
      
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        
        const protocolJson = buildJson(json, fileName);
        // console.log('protocolJson', protocolJson)
        if (!Array.isArray(protocolJson)) {
          toast.update(toastId, {
            render: getLabel(`toast_importProtocolErrorType_${protocolJson?.type}`, {value: protocolJson?.product_name}),
            type: toast.TYPE.ERROR,
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4000,
          });
          return;
        }

        if (protocolJson !== null) {
          const newSubstances = { ...protocol };
          newSubstances[type === "treatment" ? "drugs" : "analysis"] = protocolJson;
          Object.assign({}, protocol, newSubstances);
          setProtocol((prev) => { return {
            ...prev, 
            ...newSubstances
          }});
          setIsReset(true);
        }
        // generic error
        toast.update(toastId, {
          render: protocolJson === null
            ? getLabel('toast_importProtocolError')
            : getLabel('toast_importProtocolSuccess'),
          type: protocolJson === null ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4000,
        });
      };
      reader.readAsBinaryString(file);
    } else {
      toast.dismiss(toastId)
    }
  };

  const updateStore = () => {
    if (type === "treatment") dispatch(emptyTreatments());
    else if (type === "analysis") dispatch(emptyAnalysis());
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/protocols/${type}/${id}`);
    } else {
      navigate(`/protocols/${type}`);
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      if (id) {
        navigate(`/protocols/${type}/${id}`);
      } else {
        navigate(`/protocols/${type}`);
      }
    };

    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>
            {getLabel(id ? "protocolsUpdatePage" : "protocolsNewPage", {
              type: getLabel(type) || "",
              name: protocol?.protocols_name || "",
            })}
          </h2>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="protocol_form_wrapper">
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
          <div className="protocol_form_wrapper_row">
            <Input
              name="name"
              register={register}
              type="text"
              label={getLabel("protocolName")}
              placeholder={getLabel("protocolNamePlaceholder")}
              error={errors["name"]}
              required={getLabel("inputRequiredError")}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", { value: 24 }),
              }}
              tooltip={getLabel("protocolNameTooltip")}
            />
            </div>
            <div className="protocol_form_wrapper_row">
              <Input
                name="file"
                register={register}
                type="file"
                label={getLabel("operationExcelImportFile")}
                // placeholder={getLabel("protocolNamePlaceholder")}
                error={errors["file"]}
                tooltip={getLabel("operationExcelImportTooltip")}
                accept=".xls,.xlsx"
                onChange={(e) => setFile(e.target.files[0])}
              />
              {<CTA disabled={file === null} onClick={handleConvert}>
                {getLabel("operationExcelImport")}{" "}<FontAwesomeIcon icon="fa-file-excel" />
              </CTA>}
              <CTA onClick={handleExport}>
                {getLabel("operationExcelTemplateExport")}{" "}<FontAwesomeIcon icon="fa-file-arrow-down" />
              </CTA>
            </div>
            <div className="protocol_form_wrapper_row vertical">
              <h4 className="">
                {type === "treatment"
                  ? getLabel("treatmentProtocolSubstances")
                  : getLabel("analysisProtocolSubstances")}
              </h4>
              {type === "analysis" && (
                <div className="analysis-filter">
                  <Select
                    name="state"
                    control={control}
                    label={getLabel("lotState")}
                    placeholder={getLabel("lotStatePlaceholder")}
                    error={errors["state"]}
                    required={getLabel("inputRequiredError")}
                    isDisabled={id ? true : false}
                    options={[
                      {
                        value: "GRAPE",
                        label: getLabel("lotState_GRAPE"),
                      },
                      {
                        value: "MUST",
                        label: getLabel("lotState_MUST"),
                      },
                      {
                        value: "WINE",
                        label: getLabel("lotState_WINE"),
                      },
                    ]}
                    // defaultValue={{
                    //   value: "MUST",
                    //   label: getLabel("lotState_MUST"),
                    // }}
                    tooltip={getLabel("substanceStatesTooltip")}
                    // onTableChange={(value) => setOldAnalysisSelects((prev) => {return {...prev, state: value?.value}})}
                  />
                  <Select
                    name="color"
                    control={control}
                    label={getLabel("lotColor")}
                    placeholder={getLabel("lotColorPlaceholder")}
                    error={errors["color"]}
                    required={getLabel("inputRequiredError")}
                    isDisabled={id ? true : false}
                    options={[
                      {
                        value: "ROSE",
                        label: getLabel("lotColor_ROSE"),
                      },
                      {
                        value: "WHITE",
                        label: getLabel("lotColor_WHITE"),
                      },
                      {
                        value: "RED",
                        label: getLabel("lotColor_RED"),
                      },
                    ]}
                    // defaultValue={{
                    //   value: "RED",
                    //   label: getLabel("lotColor_RED"),
                    // }}
                    tooltip={getLabel("substanceColorsTooltip")}
                    // onTableChange={(value) => setOldAnalysisSelects((prev) => {return {...prev, color: value?.value}})}
                  />
                </div>
              )}
              <div className="protocol-table-container">
                <TableEdit
                  isLegend={false}
                  additionalData={substances?.filter((s) => type === 'treatment' || (type === 'analysis' && s?.states?.includes(state?.value) && s?.colors?.includes(color?.value)))?.map((s, i) => {
                    return {
                      ...s,
                      substance_name: s?.name,
                      label: `${type === 'analysis' ? (getLabel("substance_" + s?.name) + " ") : s?.name}${(false && type === 'analysis' && s?.colors?.length > 0  && s?.colors?.length < 3 ? ` (${getLabel("substance_color_" + s?.colors?.toString())})` : '')}`,
                      id: s?.substance_id,
                      quantity:
                        (protocol?.quantities?.length > i &&
                        protocol?.quantities[i] > 0
                          ? protocol?.quantities[i]
                          : s?.quantity) || 0,
                          colors: type === 'analysis' ? s?.colors : '',
                          states: type === 'analysis' ? s?.states : '',
                          opType: 'PROTOCOL',
                    };
                  })?.sort((a, b) => a?.substance_name - b?.substance_name)}
                  defaultData={
                    (type === "treatment"
                      ? protocol?.drugs
                      : protocol?.analysis
                    )?.map((s, i) => {
                      return {
                        ...s,
                        id: s?.substance_id,
                        quantity:
                          (protocol?.quantities?.length > i &&
                          protocol?.quantities[i] > 0
                            ? protocol?.quantities[i]
                            : s?.quantity) || 0,
                        opType: 'PROTOCOL',
                      };
                    }) || []
                  }
                  columns={columns}
                  errors={errors}
                  control={control}
                  isEditable={((type === 'analysis' && state?.value && color?.value) || type === 'treatment')}
                  isSearchBar={false}
                  setValue={setValue}
                  getValues={getValues}
                  type={type === 'analysis' ? 'newAnalysisProtocol' : ''}
                  newRow={{
                    substance_name: "",
                    min_range: "",
                    max_range: "",
                    min_temperature: "",
                    max_temperature: "",
                    unit: "",
                    quantity: "",
                    type: "",
                  }}
                  reset={isReset}
                  setIsReset={setIsReset}
                  filterType={null}
                />
              </div>
            </div>
          <div className="protocol_form_wrapper_row">
            <TextArea
              name="notes"
              register={register}
              label={getLabel("protocolNotes")}
              placeholder={getLabel("protocolNotesPlaceholder")}
              error={errors["notes"]}
              // tooltip={getLabel("protocolNotesTooltip")}
            />
          </div>
          <div className="protocol_form_wrapper_row">
            <Submit label={getLabel("submitForm")} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("protocolsNavLink"),
          getLabel(id ? "protocolsUpdatePage" : "protocolsNewPage", {
            type: "",
            name: "",
          }),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>
  );
};

export default ProtocolForm;
