import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCode,
  faRemove,
  faSearch,
  faFlaskVial,
  faPrescriptionBottle,
  faCircleInfo,
  faTablets,
  faWineBottle,
  faCubesStacked,
  faGear,
  faSitemap,
  faAnglesDown,
  faAnglesUp,
  faCalendarCheck,
  faCalendar,
  faUserGear,
  faUser,
  faBars,
  faXmark,
  faArrowLeft,
  faBook,
  faPrint,
  faTentArrowDownToLine,
  faCalendarDay,
  faEyeDropper,
  faTimeline,
  faPen,
  faFileExcel,
  faFileArrowDown,
  faBreadSlice,
  faBox,
  faPlus,
  faCircle,
  faFilter,
  faFilterCircleXmark,
  faDroplet,
  faAnglesRight,
  faCalendarPlus,
  faLink,
  faSort,
  faSortUp,
  faSortDown,
  faGroupArrowsRotate,
  faQrcode,
  faDisplay,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCode,
  faRemove,
  faSearch,
  faFlaskVial,
  faPrescriptionBottle,
  faCircleInfo,
  faTablets,
  faWineBottle,
  faCubesStacked,
  faGear,
  faSitemap,
  faAnglesDown,
  faAnglesUp,
  faCalendar,
  faCalendarCheck,
  faUserGear,
  faUser,
  faBars,
  faXmark,
  faArrowLeft,
  faBook,
  faPrint,
  faTentArrowDownToLine,
  faCalendarDay,
  faEyeDropper,
  faTimeline,
  faPen,
  faFileExcel,
  faFileArrowDown,
  faBreadSlice,
  faBox,
  faPlus,
  faCircle,
  faFilter,
  faFilterCircleXmark,
  faDroplet,
  faAnglesRight,
  faCalendarPlus,
  faLink,
  faSort,
  faSortUp,
  faSortDown,
  faGroupArrowsRotate,
  faQrcode,
  faDisplay,
);
