import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import Loader from "./Loader";
import Header from "./Header";
import SideBar from "./SideBar";
import ToastManager from "./ToastManager";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveWinery,
  selectUserSockets,
  selectWineries,
} from "../context/selectors";
import { setUserSockets } from "../context/userSockets/userSocketsSlice";
import { toast } from "react-toastify";
import useLabels from "../hooks/useLabels";
import { getItem, setItem } from "../services/LocalStorage";
import Footer from "./Footer";
import CookieBar from "./CookieBar";
import { NotificationWithLink } from "./Generic";

const PersistentLogin = ({ isMobile, socket }) => {
  const [getLabel] = useLabels();
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const wineries = useSelector(selectWineries);
  const activeWinery = useSelector(selectActiveWinery);
  const userSockets = useSelector(selectUserSockets);
  const navigate = useNavigate();
  const location = useLocation();
  // socket.io
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        if (auth?.role === 1) {
          navigate("/clients");
          setIsLoading(false);
        } else {
          if (!activeWinery) {
            if (!wineries || wineries?.length === 0) {
              navigate("/");
              // navigate('/wineries/new');
              setIsLoading(false);
              return;
            }
            navigate("/#wineriesList");
          } else {
            navigate(location.pathname);
          }
        }
        isMounted && setIsLoading(false);
      }
    };

    if (!auth?.accessToken) {
      verifyRefreshToken();
    } else {
      if (auth?.role === 1) {
        navigate("/clients");
        setIsLoading(false);
      } else {
        if (!activeWinery && auth?.role === 2) {
          if (!wineries || wineries?.length === 0) {
            navigate("/wineries/new");
            setIsLoading(false);
            const customIdNoWineries = "custom-id-no-wineries";
            toast.info(getLabel("toast_missingWineries"), {
              toastId: customIdNoWineries,
              position: toast.POSITION.BOTTOM_RIGHT,
              exclude: true,
              autoClose: 5000,
            });
            // return;
          }
          navigate("/#wineriesList");
          setIsLoading(false);
        } else {
          navigate(location.pathname);
          setIsLoading(false);
        }
      }
    }

    // socket connection
    const username = auth.username;
    const client_name = auth.client_name;

    socket.auth = { username, id: auth?.user_id, client_name: client_name };
    // console.log('socket.auth', socket.auth)
    const sessionId = getItem("sessionId");
    if (sessionId) {
      socket.auth = { ...socket.auth, sessionId };
    }

    socket.connect();

    return () => (isMounted = false);
  }, []);

  // socket.io
  useEffect(() => {
    function onConnect() {
      console.log("connect");
      setIsConnected(true);
    }

    function onDisconnect() {
      console.log("disconnect");

      const index = userSockets?.findIndex((u) => u?.self);

      if (index === -1) {
        return;
      }
      dispatch(
        setUserSockets((prevState) => [
          ...prevState?.slice(0, index),
          {
            ...prevState[index],
            connected: false,
          },
          ...prevState?.slice(index + 1),
        ])
      );
      setIsConnected(false);
    }

    function onConnectError(err) {
      if (err.message === "invalid username") {
        console.log("usernameAlreadySelected", err);
      }
      // console.log(auth);

      // retry connection
      setTimeout(() => {
        // socket connection
        const username = auth.username;
        const client_name = auth.client_name;
        socket.auth = { username, id: auth?.user_id, client_name: client_name };
        const sessionId = getItem("sessionId");
        if (sessionId) {
          socket.auth = { ...socket.auth, sessionId };
        }

        socket.connect();
      }, 5000);
    }

    function onSession({ sessionId, userId }) {
      // attach the session ID to the next reconnection attempts
      socket.auth = { sessionId };
      // store it in the localStorage
      setItem("sessionId", sessionId);
      // save the ID of the user
      socket.userId = userId;
      // console.log("session =============================================");
    }

    function onUsers(users) {
      // console.log("users =============================================", users);
      const userSocketsTmp = [...userSockets];

      for (const user of users) {
        user.notifications?.forEach((notification) => {
          notification.fromSelf = notification.from === socket.userID;
          if (!notification.fromSelf) {
            onNotificationEvent(notification);
          }
        });

        const userIndex = userSocketsTmp?.findIndex((u) => u?.id === user?.id);
        if (userIndex === -1) {
          // add new connected user
          user.self = user.userID === socket.userID;
          userSocketsTmp.push(user);
        } else {
          // update userId
          const tmpUser = { ...userSocketsTmp[userIndex] };
          userSocketsTmp[userIndex] = tmpUser;
        }
      }

      // put the current user first, and sort by username
      userSocketsTmp?.sort((a, b) => {
        if (a.self) return -1;
        if (b.self) return 1;
        if (a.username < b.username) return -1;
        return a.username > b.username ? 1 : 0;
      });

      dispatch(setUserSockets(userSocketsTmp));
      // console.log(userSocketsTmp, "userSockets");
    }

    function onUserConnected(user) {
      const userSocketsTmp = [...userSockets];

      const userIndex = userSocketsTmp?.findIndex((u) => u?.id === user?.id);
      if (userIndex === -1) {
        // add new connected user
        user.self = user.userID === socket.userID;
        user.connected = true;
        userSocketsTmp.push(user);
      } else {
        // update userId
        const tmpUser = { ...userSocketsTmp[userIndex] };
        tmpUser.connected = true;
        userSocketsTmp[userIndex] = tmpUser;
      }
      // console.log("userSockets user connected");
    }

    function onUserDisonnected(id) {
      const userSocketsTmp = [...userSockets];

      const userIndex = userSocketsTmp?.findIndex((u) => u?.id === id);
      if (userIndex > -1) {
        // update userId
        const tmpUser = { ...userSocketsTmp[userIndex] };
        tmpUser.connected = false;
        userSocketsTmp[userIndex] = tmpUser;
      }
      // console.log("userSockets user disconnected");
    }

    function onNotificationEvent(notification) {
      const message = buildToastMessage(notification);
      console.log(message, notification);
      if (message) {
        const data = {
          link: `/tanks`,
          message: message,
          props: {
            toastId: `${
              notification?.type
            }-${notification?.message?.slice(0, 7) || 'gatto'}`,
            type: 'info',
            position: toast.POSITION.BOTTOM_RIGHT,
            data: {
              exclude: false,
            },
            autoClose: 12000,
            delay: 3000,
          },
        };
        
        // setTimeout(
        //   () =>
            toast(
              <NotificationWithLink content={data}
              />
            )
        //   , 1000
        // );
        // toast.info(message, {
        //   toastId: `${notification?.type}-${notification?.message?.slice(
        //     0,
        //     7
        //   )}`,
        //   position: toast.POSITION.BOTTOM_RIGHT,
        //   data: {
        //     exclude: false,
        //   },
        //   autoClose: 5000,
        // }), 5000);
        // setCounter((prev) => prev + 1);
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("session", onSession);
    // socket.on("connect", onConnect);
    socket.on("users", onUsers);
    socket.on("user connected", onUserConnected);
    socket.on("user disconnected", onUserDisonnected);
    socket.on("connect_error", onConnectError);
    socket.on("private notification", onNotificationEvent);
    // socket.on("notification", onNotificationEvent);

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("session");
      socket.off("users");
      socket.off("user connected");
      socket.off("user disconnected");
      socket.off("private notification");
      // socket.off("notification");
    };
  }, []);

  const buildToastMessage = (notification) => {
    switch (notification?.type) {
      case "new note":
        const opTypeNewNote =
          getLabel(`operationType${notification?.data?.operation_type}`) || "-";
        const opExtendedIdNewNote =
          notification?.data?.operation_id_extended || "";
        const headingNewNote = getLabel("newNoteFrom");
        return `${headingNewNote}: ${notification?.from_username} --> ${opTypeNewNote} ${opExtendedIdNewNote}`;

      case "new operation":
      case "update operation":
        const opTypeNewOp =
          getLabel(`operationType${notification?.data?.operation_type}`) || "-";
        const opExtendedIdNewOp =
          notification?.data?.operation_id_extended || "";
        const headingNewOp = getLabel(
          notification?.type === "new operation"
            ? "programmingNewOp"
            : "programmingUpdateOp"
        );
        return `${headingNewOp}: ${notification?.from_username} --> ${opTypeNewOp} ${opExtendedIdNewOp}`;

      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header isMobile={isMobile} isConnected={isConnected}></Header>
          <main className="container">
            <SideBar isMobile={isMobile}></SideBar>
            <ToastManager />
            <Outlet />
          </main>
          <Footer isMobile={isMobile}></Footer>
          <CookieBar isMobile={isMobile}></CookieBar>
        </>
      )}
    </>
  );
};

export default PersistentLogin;
