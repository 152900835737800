import { useDispatch, useSelector } from "react-redux";
import { selectSubstancesDisplayGrid } from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getDrugs, getProtocols } from "../../services/utils";
import { useEffect, useState } from "react";
import { Switch } from "../FormComponents";
import { useNavigate } from "react-router-dom";
import { toggleSubstancesDisplayGrid } from "../../context/configs/configsSlice";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { setDrugs } from "../../context/substances/drugsSlice";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import EditCell from "../TableEdit/EditCell";
import { INT_FLOAT_REGEX } from "../../constants/regex";
import "./styles/index.scss";
import ProtocolTile from "../ProtocolList/ProtocolTile";


const SubstanceList = ({
  isMobile,
  substanceType,
  substances = [],
  allSubstances = [],
  register,
  errors,
  control,
  quantities = [],
  isProtocolApplication = false,
  isSearchBar = true,
  setValue,
  getValues,
  isCheckbox = false,
  disabledIds = [],
}) => {
  
  const displayModeGrid = useSelector(selectSubstancesDisplayGrid);
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState(substances);

  const isTreatment = substanceType === "substance";

  const columnHelper = createColumnHelper();
  const quantity = isTreatment
    ? [
      columnHelper.accessor('quantity', {
        header: getLabel("drugQuantity"),
        cell: TableCell,
        meta: {
          type: "number",
          editable: true,
          regExpPattern: INT_FLOAT_REGEX,
          isFilterDisabled: true,
          register: register,
        },
      }),
      columnHelper.display({
        id: "edit",
        cell: EditCell,
        meta: {
          isEditable: true,
        },
      }),
      columnHelper.accessor('unit', {
        header: getLabel("unit"),
        cell: TableCell,
        meta: {
          isFilterDisabled: true,
        },
      }),
      ]
    : [];
  const additionalColumns =
    substanceType === "substance" || substanceType === "treatment"
      ? [
          columnHelper.accessor(
            (row) =>
              row?.type ? getLabel(`drugType_${row.type?.toUpperCase()}`) : "",
            {
              header: getLabel("drugType"),
              cell: TableCell,
              meta: {
                isFilterDisabled: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              quantities?.length > 0
                ? `${quantities[row?.index] || ""}` || ""
                : (row?.quantity || "") +
                  (row?.quantity ? " " : ""),
            {
              header: getLabel("drugQuantity"),
              cell: TableCell,
              meta: {
                isFilterDisabled: true,
              },
            }
          ),

          columnHelper.accessor(
            (row) => Number(row?.min_range || '0') && Number(row?.max_range) ? `${row?.min_range || ''} - ${row?.max_range || ''} ${row?.unit}` : '',
            {
              header: getLabel("substanceRange"),
              cell: TableCell,
              meta: {
                type: "number",
                isFilterDisabled: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) => Number(row?.min_temperature) && Number(row?.max_temperature) ? `${row?.min_temperature} - ${row?.max_temperature || ''} \u00b0C` : '',
            {
              header: getLabel("substanceTemperature"),
              cell: TableCell,
              meta: {
                type: "number",
                isFilterDisabled: true,
              },
            }
          ),

          // columnHelper.accessor(
          //   (row) =>
          //     (row?.min_temperature || "") +
          //     (row?.min_temperature ? " " + "\u00b0 C" : ""),
          //   {
          //     header: getLabel("drugMinTemp"),
          //     cell: TableCell,
          //   }
          // ),
          // columnHelper.accessor(
          //   (row) =>
          //     (row?.max_temperature || "") +
          //     (row?.max_temperature ? " " + "\u00b0 C" : ""),
          //   {
          //     header: getLabel("drugMaxTemp"),
          //     cell: TableCell,
          //   }
          // ),

          // columnHelper.accessor(
          //   (row) =>
          //     (row?.min_range || "") + (row?.min_range ? " " + row?.unit : ""),
          //   {
          //     header: getLabel("drugMinRange"),
          //     cell: TableCell,
          //   }
          // ),

          // columnHelper.accessor(
          //   (row) =>
          //     (row?.max_range || "") + (row?.max_range ? " " + row?.unit : ""),
          //   {
          //     header: getLabel("drugMaxRange"),
          //     cell: TableCell,
          //   }
          // ),
        ]
      : [];
      
  const columns = isProtocolApplication
    ? [
      columnHelper.accessor((row) => substanceType === "analysis"
      ? getLabel(`substance_${row?.substance_name}`)
      : row?.substance_name,
          {
            header:
              substanceType === "analysis"
                ? getLabel("analysisName")
                : getLabel("treatmentName"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            }
          }
        ),
        columnHelper.accessor(
          (row) => (row?.min_range >= 0 && row?.max_range >= 0) ? `${row?.min_range || '0'} - ${row?.max_range || ''} ${row?.unit}` : '',
          {
            header: getLabel("substanceRange"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            }
          }
        ),
        // columnHelper.accessor("unit", {
        //   header: getLabel("unit"),
        //   cell: TableCell,
        // }),
        ...quantity,
      ]
    : [
        columnHelper.accessor(
          substanceType === "substance" ? "name" : "substance_name",
          {
            header:
              substanceType === "analysis"
                ? getLabel("analysisName")
                : getLabel("treatmentName"),
            cell: TableCell,
          }
        ),
        ...additionalColumns,
      ];
  
  if (isCheckbox && isProtocolApplication) {
    columns.push(columnHelper.accessor('removed', {
      header: getLabel("drugRemove"),
      cell: TableCell,
      meta: {
        type: "checkbox",
        editable: true,
        isFilterDisabled: true,
        // register: register,
      },
    }));
  }

  const toggleDisplayMode = () => {
    dispatch(toggleSubstancesDisplayGrid());
  };

  const loadSubstances = async () => {
    const currentSubstances =
      substanceType === "substance"
        ? await getDrugs(axiosPrivate)
        : (await getProtocols(substanceType, axiosPrivate)) || [];
    dispatch(
      substanceType === "substance"
        ? setDrugs(currentSubstances)
        : substanceType === "treatment"
        ? setTreatments(currentSubstances)
        : setAnalysis(currentSubstances)
    );

    if (!isProtocolApplication) setTableData(substances);
  };

  useEffect(() => {
    // setTableData(substances?.filter((s) => disabledIds?.find((dis) => dis?.substance_id == s?.substance_id && dis?.removed === true) == undefined));
    const tmpSubstances = substances?.map((s) => { return {
      ...s,
      removed: disabledIds?.find((dis) => dis?.substance_id == s?.substance_id && dis?.removed === true) !== undefined
    }});
    setTableData(tmpSubstances);

    tmpSubstances?.forEach((s, i) => (s?.removed) ? setValue(`removed.${s?.substance_id}.value`, true) : null);
  }, [substances]);

  useEffect(() => {
    if (!substances || substances.length === 0) {
      loadSubstances();
    }
  }, [substanceType]);

  const renderList = () => {
    const type = substanceType === "substance" ? "treatment" : substanceType;
    if ((!isProtocolApplication
      ? substances : tableData)?.length > 0) {
      return (
        <div className="substances_list_table">
          {/* <Table
                        data={!isProtocolApplication ? substances?.map((s, i) => ({...s, index: i})) : tableData}
                        prefix='substances'
                        customColumns={columns}
                        isSearchBar={isSearchBar}
                        rowClickHandler={!isProtocolApplication ? (row) => {navigate(`/substances/${type}/${row.original.id}`)} : null}
                    /> */}
          <TableEdit
            defaultData={
              !isProtocolApplication
                ? substances?.map((s, i) => ({ ...s, index: i }))
                : tableData
            }
            columns={columns}
            isSearchBar={isSearchBar}
            rowClickHandler={
              !isProtocolApplication
                ? (row) => {
                    navigate(`/substances/${type}/${row.original.id}`);
                  }
                : null
            }
            additionalData={allSubstances?.filter(el => !substances.some(f => f.substance_id === el.id))?.map((s, i) => {
              return {
                ...s,
                substance_name: s?.name,
                label: `${type === 'analysis' ? (getLabel("substance_" + s?.name) + " ") : s?.name}${(false && type === 'analysis' && s?.colors?.length > 0  && s?.colors?.length < 3 ? ` (${getLabel("substance_color_" + s?.colors?.toString())})` : '')}`,
                id: s?.substance_id,
                quantity: 10,
                  // (protocol?.quantities?.length > i &&
                  // protocol?.quantities[i] > 0
                  //   ? protocol?.quantities[i]
                  //   : s?.quantity) || 0,
                // colors: type === 'analysis' ? s?.colors : '',
                // states: type === 'analysis' ? s?.states : '',
                opType: type?.toUpperCase(),
              };
            })?.sort((a, b) => a?.substance_name - b?.substance_name)}
            isEditable={true}
            isLegend={false}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            type={type}
          />
        </div>
      );
    }
  };

  const renderGrid = () => {
    if (substances?.length > 0) {
      return (
        <div className="substances_grid">
          {substances?.map((substance) => {
            return <ProtocolTile substance={substance} type={'SUBSTANCES'} />
          })}
        </div>
      );
    }
  };

  return (
    <div className="substances_list">
      {substances?.length > 0 && (
        <>
          {!isProtocolApplication && !isMobile && (
            <Switch
              leftOption={{
                label: getLabel("listView"),
              }}
              rightOption={{
                label: getLabel("listView"),
              }}
              toggleHandler={toggleDisplayMode}
              status={displayModeGrid}
            />
          )}
          {isMobile ? renderGrid() : displayModeGrid ? renderGrid() : renderList()}
        </>
      )}
      {!substances ||
        (substances?.length === 0 && (
          <>
            <div className="substances_alert">
              <h6 className="substances_alert_title">
                {getLabel("noSubstancesAlert", {
                  type: getLabel(substanceType),
                })}
              </h6>
            </div>
          </>
        ))}
    </div>
  );
};

export default SubstanceList;
