import { useDispatch, useSelector } from "react-redux"
import { selectWineries, selectWineryDisplayGrid } from "../../context/selectors"
import useLabels from "../../hooks/useLabels";
import { CTA } from "../Generic";
import { useNavigate } from "react-router-dom";
import { set, setActive } from "../../context/wineries/wineriesSlice";
import { useEffect, useMemo } from "react";
import { getWineries } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Switch } from "../FormComponents";
import { toggleWineriesDisplayGrid } from "../../context/configs/configsSlice";
import WineryTile from "./WineryTile";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import TableEdit from "../TableEdit";
import Table from "../Table";
import { emptyTanks } from "../../context/tanks/tanksSlice";
import { emptyLots } from "../../context/lots/lotsSlice";
import { emptyUsers } from "../../context/users/usersSlice";
import { emptyAnalysis } from "../../context/protocols/analysisSlice";
import { emptyExp } from "../../context/expOperations/expOperationsSlice";
import { empty } from "../../context/operations/operationsSlice";
import './styles/index.scss';

const WineriesSelector = ({
    eventCallback,
    isSelector = false
}) => {

    const wineries = useSelector(selectWineries);
    const displayModeGrid = useSelector(selectWineryDisplayGrid);
    const dispatch = useDispatch();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const columnsWinerySelector = useMemo(() => {
        
        let columnArray =  [
            {
                Header: getLabel('wineryName'),
                accessor: 'name'
            },
            {
                Header: getLabel('wineryClientName'),
                accessor: 'client_name'
            },
            {
                Header: getLabel('wineryAddress'),
                accessor: (row) => `${row?.address ? row.address : ''}, ${row?.cap ? row.cap : ''} ${row?.city ? row.city : ''}`
            }
        ]
        if (!isSelector) {
            columnArray = columnArray.concat([
                {
                    Header: getLabel('wineryProvince'),
                    accessor: (row) => `${row?.province ? row.province : ''}`
                },
                {
                    Header: getLabel('wineryCountry'),
                    accessor: (row) => `${row?.country ? row.country : ''}`
                },
                {
                    Header: getLabel('wineryMobile'),
                    accessor: (row) => `${row?.mobile_number ? row.mobile_number : ''}`
                },
                {
                    Header: getLabel('wineryPhone'),
                    accessor: (row) => `${row?.phone_number ? row.phone_number : ''}`
                }
            ])
        }

        return columnArray
    });

  const columnHelper = createColumnHelper();
  const additionalColumns = !isSelector ? [
    columnHelper.accessor((row) => `${row?.province ? row.province : ''}`, {
        header: getLabel("wineryProvince"),
        cell: TableCell,
      }),
      columnHelper.accessor((row) => `${row?.country ? row.country : ''}`, {
        header: getLabel("wineryCountry"),
        cell: TableCell,
        meta: {
          type: "number",
        },
      }),
      columnHelper.accessor((row) => `${row?.mobile_number ? row.mobile_number : ''}`, {
        header: getLabel("wineryMobile"),
        cell: TableCell,
      }),
      columnHelper.accessor((row) => `${row?.phone_number ? row.phone_number : ''}`, {
        header: getLabel("wineryPhone"),
        cell: TableCell,
      }),
  ] : [];

  const columns = [
    columnHelper.accessor('name', {
        header: getLabel("wineryName"),
        cell: TableCell,
      }
    ),
    columnHelper.accessor("client_name", {
      header: getLabel("wineryClientName"),
      cell: TableCell,
    }),
    columnHelper.accessor(
        (row) => `${row?.address ? row.address : ''}, ${row?.cap ? row.cap : ''} ${row?.city ? row.city : ''}`,
      {
        header: getLabel("wineryAddress"),
        cell: TableCell,
      }
    ),
    ...additionalColumns
  ];

    const toggleDisplayMode = () => {
        dispatch(toggleWineriesDisplayGrid())
    }
        
    const clickHandler = (data) => {
        dispatch(setActive(data?.original))
        if (eventCallback) {
            eventCallback();

            dispatch(empty());
            dispatch(emptyExp());
            dispatch(emptyAnalysis());
            dispatch(emptyUsers());
            dispatch(emptyTanks());
            dispatch(emptyLots());
            dispatch(emptyAnalysis());
        }
    }

    const loadWineries = async () => {
        const currentWineries = await getWineries(axiosPrivate);
        dispatch(set(currentWineries))
    }

    useEffect(() => {
        if (!wineries || wineries.length === 0) {
            loadWineries();
        }
    }, [])

    const renderWineriesList = () => {
        if (wineries?.length > 0) {
            return (
                <div className="wineries_list">
                    {!isSelector && <TableEdit
                        defaultData={wineries}
                        columns={columns}
                        isSearchBar={false}
                        rowClickHandler={!isSelector ? (row) => {navigate(`/wineries/${row.original.id}`)} : clickHandler}
                        isEditable={false}
                    />}
                    {isSelector && <Table
                        isSearchBar={!isSelector}
                        data={wineries} 
                        prefix={'winery'}
                        customColumns={columnsWinerySelector} 
                        rowClickHandler={!isSelector ? (row) => {navigate(`/wineries/${row.original.id}`)} : clickHandler}
                    ></Table>}
                </div>
            )
        }
        return (<div className="wineries_alert">
            <h6 className="wineries_alert_title">{getLabel('noWineriesAlert')}</h6>
            <CTA
                customClassName={'create'}
                label={getLabel('createWinery')}
                onClick={() => {navigate('/wineries/new')}}
            />
            {/* TO DO: add {" "}<FontAwesomeIcon icon="fa-plus" /> */}
        </div>)
    }

    const renderWineriesGrid = () => {
        if (wineries.length > 0) {
            return (
                <div className="wineries_grid">
                    {wineries.map((winery) => {
                        return <WineryTile {...winery}/>
                    })}
                </div>
            )
            
        }
    }

    return (
        <div className={isSelector ? "wineries" : "wineries_list"}>
        <Switch
            leftOption={{
                label: getLabel('listView')
            }}
            rightOption={{
                label: getLabel('gridView')
            }}
            toggleHandler={toggleDisplayMode}
            status={displayModeGrid}
        />
        {displayModeGrid ? renderWineriesGrid() : renderWineriesList()}
        </div>
    )
}

export {
    WineriesSelector
}