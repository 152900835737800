import useLabels from "../../hooks/useLabels";
import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Scatter,
  ComposedChart,
  Bar,
  Legend,
} from "recharts";
import "./styles/index.scss";
import { useEffect, useState } from "react";
import { addDays, format } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { selectConfigs } from "../../context/selectors";

const Chart = ({ dataKey = null, data, scatterData = [] }) => {
  const [getLabel] = useLabels();
  const configs = useSelector(selectConfigs);
  const [plotData, setPlotData] = useState([]);
  const [borders, setBorders] = useState([]);
  const [scatterPlotData, setScatterPlotData] = useState([]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  
  // const [chartType, setChartType] = useState(0);

  useEffect(() => {
    const allDates = new Set();
    data?.forEach((d) => d?.data?.forEach((dd) => allDates.add(dd?.date)));
    const allDatesArr = Array.from(allDates);
    const minDate = new Date(Math.min.apply(null, allDatesArr));
    const maxDate = new Date(Math.max.apply(null, allDatesArr));
    setDates({startDate: minDate, endDate: maxDate, minDate: minDate, maxDate: maxDate});

    setPlotData(data);
    // console.log('data', data, minDate, maxDate, allDates)
    setBorders(
      data?.map((d) => {
        return { min_range: d?.min || 0, max_range: d?.max };
      })
    );
    // console.log(data, "data");
    // console.log(
    //   data?.map((d) => {
    //     return { min_range: d?.min || 0, max_range: d?.max };
    //   }),
    //   "borders"
    // );
  }, [data]);


  useEffect(() => {
    const currentData = [];
    if (data?.length === 0 || dates?.startDate === null || dates?.endDate === null) return;
    
    for (const d of data) {
      const obj = {...d};
      obj.data = d?.data?.filter((dd) => new Date(dd?.date) >= dates?.startDate && new Date(dd?.date) <= dates?.endDate);
      currentData.push(obj);
    }
    
    setPlotData(currentData);
    setBorders(
      currentData?.map((d) => {
        return { min_range: d?.min || 0, max_range: d?.max };
      })
    );
  }, [dates]);

  useEffect(() => {
    const tmpScatterData = [];
    scatterData?.forEach((element) => {
      const obj = { ...element };
      obj["treatment"] = 5;
      obj["date"] = format(
        new Date(obj?.completion_date) || null,
        "dd/MM/yy HH:mm"
      );
      tmpScatterData.push(obj);
    });
    setScatterPlotData(tmpScatterData);
  }, [scatterData]);

  const renderInterval = () => {
    return (
      // <div className="primary_container_menu">
        <div className="hub_primary_container_row end">
          <div className="interval_row">
            <div className="interval_value">
            <div className="interval_vwrapper">
              <span>{getLabel("dateFrom")}</span>
              <ReactDatePicker
                selected={dates.startDate}
                onChange={(date) =>
                  setDates({
                    ...dates,
                    startDate: date.setHours(0, 0, 0),
                  })
                }
                selectsStart
                startDate={dates.startDate}
                endDate={dates.endDate}
                minDate={dates.minDate}
                dateFormat={configs.shortDateFormat}
              />
            </div>
            <div className="interval_vwrapper">
              <span>{getLabel("dateTo")}</span>
              <ReactDatePicker
                selected={dates.endDate}
                onChange={(date) =>
                  setDates({
                    ...dates,
                    endDate: date.setHours(23, 59, 59),
                  })
                }
                selectsEnd
                startDate={dates.startDate}
                endDate={dates.endDate}
                minDate={addDays(dates.startDate, 1)}
                maxDate={dates.endDate}
                dateFormat={configs.shortDateFormat}
              />
            </div>
            </div>
          </div>
        </div>
      // </div>
    );
  };

  const CustomTooltip = ({ payload, label, active }) => {
    // console.log(',-------------------', label, payload?.[0]?.payload?.date === label, payload?.[1]?.payload?.date === label)//, active)
    if (active && payload?.length > 0) {
      const unit =
        borders?.find((b) => b?.name === payload[0]?.name)?.unit || "";
      return (
        <div className="custom-tooltip">
          {payload?.[0] && <p className="custom-tooltip_label">{`${
            payload?.[0]?.name
              ? payload?.[0]?.name?.split(' (')?.[0]
              : getLabel("substanceDefault")
          }: ${payload[0].value} ${payload?.[0]?.payload?.unit || '' }`}</p>}
          {payload?.[1] && <p className="custom-tooltip_label">{`${
            payload?.[1]?.name
              ? payload?.[1]?.name?.split(' (')?.[0]
              : getLabel("substanceDefault")
          }: ${payload[1].value} ${payload?.[1]?.payload?.unit || '' }`}</p>}
          <p className="custom-tooltip_date">{`${
            payload[0]?.payload?.fullDate || ""
          }`}</p>
          {/* <p><span className="custom-tooltip_bold">{getLabel('dateCollection')}: </span>{payload[0]?.payload?.collection_date || '-'}</p> */}
          {/* <p className="temp">
            <span className="custom-tooltip_bold">
              {getLabel("analysisTemperature")}:{" "}
            </span>
            {`${payload[0]?.payload?.temperature || "-"} ${"\u00B0"}C`}
          </p> */}
          {/* <p className="analyst">
            <span className="custom-tooltip_bold">
              {getLabel("analysisAnalyst")}:{" "}
            </span>
            {`${payload[0]?.payload?.analyst || "-"}`}
          </p> */}
        </div>
      );
    }

    return null;
  };

  const getYLabel = () => {
    return borders?.find((b) => b?.name === dataKey)?.unit || "";
  };

  const formatXAxis = (tickFormat) => {
    return format(
      new Date(tickFormat) || null, 'dd MMM yy HH:mm'
      // configs.dateFormat
    )
  }

  const renderLineChart = () => (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <ComposedChart
          width={600}
          height={300}
          // data={[...plotData, ...scatterPlotData]}
          data={plotData?.[0]?.data}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
          {/* {plotData?.length > 0 &&
            plotData?.map((d, i) => {
              return (
                <XAxis 
                  dataKey={"date"}
                  domain={["auto", "auto"]}
                  padding={{ left: 20, right: 50 }}
                  xAxisId={i + 1}
                  hide={i > 0}
                />
              );
            })} */}
            <XAxis 
              dataKey={"date"}
              domain={["auto", "auto"]}
              scale="time"
              type="number"
              tickFormatter={formatXAxis} 
              padding={{ left: 20, right: 50 }}
              // tickCount={6}
              minTickGap={10}
              // domain={['dataMin', 'dataMax']}
              // interval={0}
            />
          {/* <XAxis hide={true} xAxisId={2} /> */}
          {plotData?.length > 0 &&
            plotData?.map((d, i) => {
              return (
                <YAxis
                  // dx={i === 0 ? -35 : 35}
                  yAxisId={i === 0 ? "left" : "right"}
                  orientation={i === 0 ? "left" : "right"}
                  // domain={["auto", "auto"]}
                >
                  <Label
                    style={{
                      textAnchor: "middle",
                      fontSize: "130%",
                    }}
                    // angle={270}
                    // value={d?.unit}
                  />
                </YAxis>
              );
            })}
          {/* {scatterPlotData?.length > 0 && <Scatter xAxisId={2} dataKey={'treatment'} fill="red" data={scatterPlotData} barSize={2} />} */}
          {plotData?.length > 0 &&
            plotData?.map((d, i) => {
              return (
                <Line
                  yAxisId={i === 0 ? "left" : "right"}
                  key={`x_axis_${i}`}
                  name={d?.translated_name + (d?.unit ? ` (${d?.unit})` : '')}
                  // xAxisId={i + 1}
                  type="monotone"
                  data={d?.data}
                  dataKey={dataKey}
                  stroke={d?.color || "#64242E"}
                  strokeWidth="2"
                  orientation="left"
                  activeDot={{ r: 8 }}
                  // activeDot={{
                  //   r: 8,
                  //   onMouseOver: () => setChartType(1),
                  //   onMouseLeave: () => setChartType(0)
                  // }}
                />
              );
            })}
          <Tooltip content={<CustomTooltip />} />
          {/* <Tooltip /> */}
          {borders?.length > 0 &&
            borders?.length === plotData?.length &&
            borders?.map((b, i) => {
              return (
                <ReferenceLine
                  key={`border_min_${i}`}
                  yAxisId={i === 0 ? "left" : "right"}
                  // xAxisId={i + 1}
                  y={b?.min_range}
                  stroke={plotData?.[i]?.color || "hsl(351, 47%, 50%)"}
                >
                  <Label
                    style={{ fill: plotData?.[i]?.color || "hsl(351, 47%, 50%)" }}
                    position={"insideBottomRight"}
                  >
                    {getLabel("sensorMin")}
                  </Label>
                </ReferenceLine>
              );
            })}
          {borders?.length > 0 &&
            borders?.length === plotData?.length &&
            borders?.map((b, i) => {
              return (
                <ReferenceLine
                  key={`border_max_${i}`}
                  yAxisId={i === 0 ? "left" : "right"}
                  // xAxisId={i + 1}
                  y={b?.max_range}
                  stroke={plotData?.[i]?.color || "hsl(351, 47%, 50%)"}
                >
                  <Label
                    style={{ fill: plotData?.[i]?.color || "hsl(351, 47%, 50%)" }}
                    position={"insideTopRight"}
                  >
                    {getLabel("sensorMax")}
                  </Label>
                </ReferenceLine>
              );
            })}
          {plotData?.length > 0 &&
            plotData?.map((b, i) => {
              return (
                <ReferenceLine
                  key={`reference_${i}`}
                  yAxisId={i === 0 ? "left" : "right"}
                  // xAxisId={i + 1}
                  y={b?.set_point}
                  stroke={plotData?.[i]?.color || "hsl(351, 47%, 50%)"}
                  strokeWidth="3"
                  strokeDasharray="3 3"
                  alwaysShow={true}
                >
                  <Label
                    style={{ fill: plotData?.[i]?.color || "hsl(351, 47%, 50%)" }}
                    position={"insideTop"}
                  >
                    {`${getLabel("sensorSetPoint")}: ${b?.set_point} ${b?.unit}`}
                  </Label>
                </ReferenceLine>
              );
            })}
          <Legend />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div className="chart_wrapper">
      <div className="dates_wrapper">{renderInterval()}</div>
      <div>
        {dataKey !== null && renderLineChart()}
      </div>
    </div>
  );
};

export default Chart;
