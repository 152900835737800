import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveWinery,
  selectLots,
  selectTankDisplayGrid,
  selectTanks,
} from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getTanks, getLots } from "../../services/utils";
import { setTanks } from "../../context/tanks/tanksSlice";
import { useEffect } from "react";
import TankTile from "./TankTile";
import { Switch } from "../FormComponents";
import { toggleTanksDisplayGrid } from "../../context/configs/configsSlice";
import { setLots } from "../../context/lots/lotsSlice";

import "./styles/index.scss";
import TableEdit from "../TableEdit";
import TableCell from "../TableEdit/TableCell";
import { createColumnHelper } from "@tanstack/react-table";
import TankStateCell from "../TableEdit/TankStateCell";

const TankState = ({ tank }) => {
  return (
    <div className="tank_info_container_row">
      <div className="end_status">
        <div className="tank_state_representation">
          {/* <div className={"tank_tile_progress_" + getWineColor(tank?.color)} style={{
                        height: `${Number(tank?.quantity) / Number(tank?.capacity) * 100}%`
                    }}>
                    </div> */}
        </div>
        <div className="tank_percentage">
          {(Number(tank?.quantity) * 100) / tank?.capacity}%
        </div>
      </div>
    </div>
  );
};

const TankList = ({ isMobile }) => {
  const activeWinery = useSelector(selectActiveWinery);
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const displayModeGrid = useSelector(selectTankDisplayGrid);
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: getLabel("tankName"),
      cell: TableCell,
      meta: {
        filterVariant: 'text',
      }
    }),
    columnHelper.accessor(
      (row) =>
        (Number(row?.quantity > 0) &&
          lots?.find((lot) => row?.batch_id === lot?.id)?.name) ||
        "-",
      {
        header: getLabel("tankLot"),
        cell: TableCell,
        meta: {
          filterVariant: 'text',
        }
      }
    ),
    columnHelper.accessor("type", {
      header: getLabel("tankState"),
      cell: TankStateCell,
      meta: {
        filterVariant: 'select',
        selector: 'tank_type',
      },
    }),
    columnHelper.accessor((row) => `${row?.capacity} L`, {
      header: getLabel("tankCapacity"),
      cell: TableCell,
      filterFn: 'QuantityInRange',
      meta: {
        filterVariant: 'range',
        selector: 'actual_quantity'
      }
    }),
    columnHelper.accessor(
      (row) =>
        getLabel(`tankType${row?.type}`) +
        (row?.barrels_num === 0 && row.type === "TANK"
          ? ""
          : ` (${row?.barrels_num})`),
      {
        header: getLabel("tankType"),
        cell: TableCell,
        meta: {
            filterVariant: 'select',
            selector: 'tankType',
            // iconType: 'tankType',
        },
      }
    ),
    columnHelper.accessor(
      (row) => (row?.state ? getLabel(`lotState_${row?.state}`) : "-"),
      {
        header: getLabel("lotState"),
        cell: TableCell,
        meta: {
            filterVariant: 'select',
            iconType: 'stateWithColor',
        },
      }
    ),
    // columnHelper.accessor((row) =>  row?.barrels_num === 0 && row.type === 'TANK' ? getLabel('tankNoBarrelsUndefined') : row?.barrels_num, {
    //     header: getLabel("tankBarrelsNum"),
    //     cell: TableCell,
    // }),
    // columnHelper.accessor((row) => Number(row?.quantity > 0) && row?.color ? getLabel(`tankColor${row?.color}`) : '-', {
    //     header: getLabel("tankColor"),
    //     cell: TableCell,
    // }),

    // , {
    //     Header: getLabel('tankModel'),
    //     accessor: 'model'
    // }, {
    //     Header: getLabel('tankPlant'),
    //     accessor: 'plant'
    // }, {
    //     Header: getLabel('tankManufacturer'),
    //     accessor: 'manufacturer'
    // }
  ];

  const toggleDisplayMode = () => {
    dispatch(toggleTanksDisplayGrid());
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  useEffect(() => {
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }

    if (!lots || lots.length === 0) {
      loadLots();
    }
  }, []);

  const renderList = () => {
    if (tanks.length > 0) {
      const sortedTanks = [...tanks]?.sort((a, b) =>
        (a?.name).localeCompare(b?.name, undefined, { numeric: true })
      );
      return (
        <div className="tanks_list_table">
          <TableEdit
            defaultData={sortedTanks}
            columns={columns}
            rowClickHandler={(row) => {
              navigate(`/tanks/${row.original.id}`);
            }}
            filterType={"tanks"}
          />
        </div>
      );
    }
  };

  const renderGrid = () => {
    if (tanks?.length > 0) {
      const sortedTanks = [...tanks]?.sort((a, b) =>
        (a?.name).localeCompare(b?.name, undefined, { numeric: true })
      );
      return (
        <div className="tanks_grid">
          {sortedTanks?.map((tank, index) => {
            return (
              <TankTile
                key={index}
                {...tank}
                state={lots?.find((lot) => lot.id === tank?.batch_id)?.state}
              />
            );
          })}
        </div>
      );
    }
  };
  
  return (
    <div className="tanks_list">
      {tanks?.length > 0 && (
        <>
          {!isMobile && (
            <Switch
              leftOption={{
                label: getLabel("listView"),
              }}
              rightOption={{
                label: getLabel("gridView"),
              }}
              toggleHandler={toggleDisplayMode}
              status={displayModeGrid}
            />
          )}
          {isMobile
            ? renderGrid()
            : displayModeGrid
            ? renderGrid()
            : renderList()}
        </>
      )}
      {tanks?.length === 0 && (
        <div className="protocols_alert">
          <h6 className="protocols_alert_title">{getLabel("noTanksAlert")}</h6>
        </div>
      )}
    </div>
  );
};

export default TankList;
