import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../Generic";
import "./styles/index.scss";
import white_check from "../../assets/lottieJson/white_check.json";
import json_home from "../../assets/lottieJson/home.json";
import useLabels from "../../hooks/useLabels";
import { selectUser, selectUsers } from "../../context/selectors";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { setUsers } from "../../context/users/usersSlice";
import { getUsers } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const NavigationButtons = ({}) => {
  const navigate = useNavigate();

  return (
    <div className="sidebar_container_navigation">
      <Button
        isRed={true}
        arrowDirection="left"
        customClassName="sidebar_container_navigation_action"
        disabled={!window.navigation.canGoBack}
        onClick={() => navigate(-1)}
      />
      <Button
        isRed={true}
        customClassName="sidebar_container_navigation_action"
        disabled={!window.navigation.canGoForward}
        onClick={() => navigate(1)}
      />
    </div>
  );
};

const BurgerMenu = ({ setIsOpen, isOpen, setIsSecondaryOpen, isSecondaryOpen }) => {
  const location = useLocation();
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const [permission, setPermissions] = useState({});
  
  useEffect(() => {
    if (!users || users.length === 0) {
        loadUsers();
    }
    const currentUser = user?.role < 3 ? 
      { settings: {permissions: {lots: true, tanks: true, protocols: true, wineries: true}} } :
      users?.find((u) => u?.username === user?.userName);
    setPermissions(currentUser?.settings?.permissions || {})
  }, [])

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers))
  }

  if (!isOpen) return;

  return (<>
    <div className="blocker" onClick={() => {if (!isSecondaryOpen) setIsOpen(false); setIsSecondaryOpen(false)}}></div>
    <div className={`burger_container ${isOpen ? 'open' : ''}`} onClick={() => isOpen && setIsOpen(false)}>
      <div className="burger_container_primary">
        {!isSecondaryOpen && <><NavLink to="/">
          <Button
            isRed={true}
            isFull={true}
            label={getLabel("homeNavLink")}
            customClassName="burger_container_action"
            isNavLink={true}
            isActive={location.pathname === "/"}
            disableIcon={true}
          />
        </NavLink>
        <NavLink to="/programming">
          <Button
            isRed={true}
            isFull={true}
            label={getLabel("programmingNavLink")}
            customClassName="burger_container_action"
            isNavLink={true}
            isActive={location.pathname === "/programming"}
            disableIcon={true}
          />
        </NavLink>
        {permission?.lots && <NavLink to="/lots">
          <Button
            isRed={true}
            isFull={true}
            label={getLabel("lotsNavLink")}
            customClassName="burger_container_action"
            isNavLink={true}
            isActive={location.pathname === "/lots"}
            disableIcon={true}
          />
        </NavLink>}
        {permission?.tanks && <NavLink to="/tanks">
          <Button
            isRed={true}
            isFull={true}
            label={getLabel("tanksNavLink")}
            customClassName="burger_container_action"
            disableIcon={true}
            isNavLink={true}
            isActive={location.pathname === "/tanks"}
          />
        </NavLink>}
        </>}
        {permission?.protocols && <div
          className="burger_container_secondary dropdown"
          onClick={(e) => { setIsSecondaryOpen(true); e.stopPropagation(); }}
          // style={{ marginTop: "32px" }}
        >
          {!isSecondaryOpen && <Button
            isRed={true}
            isFull={true}
            isNavLink={true}
            label={"Protocolli"}
            disableIcon={true}
            isActive={location.pathname.startsWith("/protocols")}
            customClassName='burger_container_action'
          />}
          {isSecondaryOpen && <div className="dropdown-secondary" onClick={() => isOpen && setIsOpen(false)}>
            <NavLink to="/protocols/analysis">
              <Button
                isRed={true}
                isFull={true}
                isNavLink={true}
                label={getLabel("protocolsAnalysisNavLink")}
                disableIcon={true}
                isActive={location.pathname === "/protocols/analysis"}
                customClassName="burger_container_action"
              />
            </NavLink>
            <NavLink to="/protocols/treatment">
              <Button
                isRed={true}
                isFull={true}
                isNavLink={true}
                label={getLabel("protocolsTreatmentNavLink")}
                disableIcon={true}
                isActive={location.pathname === "/protocols/treatment"}
                customClassName="burger_container_action"
              />
            </NavLink>
            <NavLink to="/protocols/winemaking/PRE">
              <Button
                isRed={true}
                isFull={true}
                isNavLink={true}
                label={getLabel("protocolsWinemakingNavLink")}
                disableIcon={true}
                isActive={location.pathname === "/protocols/winemaking/PRE"}
                customClassName="burger_container_action"
              />
            </NavLink>
            <NavLink to="/protocols/winemaking/POST">
              <Button
                isRed={true}
                isFull={true}
                isNavLink={true}
                label={getLabel("protocolsPostWinemakingNavLink")}
                disableIcon={true}
                isActive={location.pathname === "/protocols/winemaking/POST"}
                customClassName="burger_container_action"
              />
            </NavLink>
          </div>}
        </div>}
      </div>
    </div>
  </>);
};

const SideBar = ({ isMobile }) => {
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const [permission, setPermissions] = useState({});
  const [burgerMenu, toggleBurgerMenu] = useState(false);
  const [secondaryBurgerMenu, toggleSecondaryBurgerMenu] = useState(false);

  useEffect(() => {
    if (!users || users.length === 0) {
        loadUsers();
    }

    const currentUser = user?.role < 3 ? 
      { settings: {permissions: {lots: true, tanks: true, protocols: true, wineries: true}} } :
      users?.find((u) => u?.username === user?.userName);
    setPermissions(currentUser?.settings?.permissions || {})
  }, [user, users])

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers))
  }

  const setIsOpen = (value) => {
    toggleBurgerMenu(value);
  }

  const setIsSecondaryOpen = (value) => {
    toggleSecondaryBurgerMenu(value);
  }

  const renderPrimaryNav = () => (
    <div className="sidebar_container_primary">
      <NavLink to="/">
        <Button
          isRed={true}
          isFull={true}
          label={getLabel("homeNavLink")}
          customClassName="sidebar_container_action"
          isNavLink={true}
          isActive={location.pathname === "/"}
          // customIcon={json_home}
          disableIcon={true}
        />
      </NavLink>
      <NavLink to="/programming">
        <Button
          isRed={true}
          isFull={true}
          label={getLabel("programmingNavLink")}
          customClassName="sidebar_container_action"
          isNavLink={true}
          isActive={location.pathname === "/programming"}
          disableIcon={true}
        />
      </NavLink>
      {permission?.lots && <NavLink to="/lots">
        <Button
          isRed={true}
          isFull={true}
          label={getLabel("lotsNavLink")}
          customClassName="sidebar_container_action"
          isNavLink={true}
          isActive={location.pathname === "/lots"}
          disableIcon={true}
        />
      </NavLink>}
      {permission?.tanks && <NavLink to="/tanks">
        <Button
          isRed={true}
          isFull={true}
          label={getLabel("tanksNavLink")}
          customClassName="sidebar_container_action"
          disableIcon={true}
          isNavLink={true}
          isActive={location.pathname === "/tanks"}
        />
      </NavLink>}
      {permission?.protocols && <div
        className="sidebar_container_secondary dropdown"
        // style={{ marginTop: "32px" }}
      >
        <Button
          isRed={true}
          isFull={true}
          isNavLink={true}
          label={"Protocolli"}
          disableIcon={true}
          isActive={location.pathname === "/protocols/analysis"}
          customClassName="sidebar_container_action"
        />
        <div className="dropdown-content">
          <NavLink to="/protocols/analysis">
            <Button
              isRed={true}
              isFull={true}
              isNavLink={true}
              label={getLabel("protocolsAnalysisNavLink")}
              disableIcon={true}
              isActive={location.pathname === "/protocols/analysis"}
              customClassName="sidebar_container_action"
            />
          </NavLink>
          <NavLink to="/protocols/treatment">
            <Button
              isRed={true}
              isFull={true}
              isNavLink={true}
              label={getLabel("protocolsTreatmentNavLink")}
              disableIcon={true}
              isActive={location.pathname === "/protocols/treatment"}
              customClassName="sidebar_container_action"
            />
          </NavLink>
          <NavLink to="/protocols/winemaking/PRE">
            <Button
              isRed={true}
              isFull={true}
              isNavLink={true}
              label={getLabel("protocolsWinemakingNavLink")}
              disableIcon={true}
              isActive={location.pathname === "/protocols/winemaking/PRE"}
              customClassName="sidebar_container_action"
            />
          </NavLink>
          <NavLink to="/protocols/winemaking/POST">
            <Button
              isRed={true}
              isFull={true}
              isNavLink={true}
              label={getLabel("protocolsPostWinemakingNavLink")}
              disableIcon={true}
              isActive={location.pathname === "/protocols/winemaking/POST"}
              customClassName="sidebar_container_action"
            />
          </NavLink>
        </div>
      </div>}
    </div>
  );

  const renderPrimaryNavAdmin = () => (
    <div className="sidebar_container_primary">
      <NavLink to="/clients">
        <Button
          isRed={true}
          isFull={true}
          label={getLabel("clientsNavLink")}
          customClassName="sidebar_container_action"
          isNavLink={true}
          isActive={location.pathname === "/clients"}
          disableIcon={true}
        />
      </NavLink>
    </div>
  );

  const renderSecondary = () => (
    <>
      {permission?.wineries && <div
        className="sidebar_container_secondary"
        // style={{ marginBottom: "-8px" }}
      >
        <NavLink to="/wineries">
          <Button
            isRed={true}
            isFull={true}
            isNavLink={true}
            label={getLabel("wineriesNavLink")}
            disableIcon={true}
            isActive={location.pathname === "/wineries"}
            customClassName="sidebar_container_action"
          />
        </NavLink>
      </div>}
    </>
  );

  const handleBurgerMenu = () => {
    if (!burgerMenu && secondaryBurgerMenu) {
      toggleBurgerMenu(true);
      toggleSecondaryBurgerMenu(false);
      return;
    } else if (secondaryBurgerMenu) {
      toggleSecondaryBurgerMenu(false);
      return;
    }

    toggleBurgerMenu(true);
  }

  if (isMobile) {
    return (<>
      <div className="top_burger_bar" onClick={() => burgerMenu && toggleBurgerMenu(false)} >
      {/* onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} */}
        <div className="bars" onClick={() => handleBurgerMenu()}>
          <FontAwesomeIcon icon={(burgerMenu && secondaryBurgerMenu) ? "fa-arrow-left" : !burgerMenu ? "fa-bars" : "fa-xmark"} size={"2x"} />
        </div>
      </div>
        <BurgerMenu setIsOpen={setIsOpen} isOpen={burgerMenu} setIsSecondaryOpen={setIsSecondaryOpen} isSecondaryOpen={secondaryBurgerMenu} />
      </>
    );
  }

  return (
    <aside className="sidebar_container">
      {/* {window.navigation && <NavigationButtons />} */}
      {user?.role === 1 ? renderPrimaryNavAdmin() : renderPrimaryNav()}
      {user?.role > 1 && renderSecondary()}
    </aside>
  );
};

export default SideBar;
