import { createSlice } from "@reduxjs/toolkit";

export const wineMakingSlice = createSlice({
    name: 'wineMaking',
    initialState: [],
    reducers: {
        emptyWineMaking: state => {
            return []
        },
        setWineMaking: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
            // return action.payload
        },
        addWineMaking: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        updateWineMaking: (state, action) => {
            return [];
        },
        removeWineMaking: (state, action) => {
            const foundedIndex = state?.findIndex((treatment) => treatment.protocols_id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state.filter((t, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setWineMaking, 
    emptyWineMaking,
    updateWineMaking,
    removeWineMaking,
    addWineMaking
} = wineMakingSlice.actions;

export default wineMakingSlice.reducer