import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveWinery,
  selectAnalysis,
  selectConfigs,
  selectExpiredOperations,
  selectNextOperations,
  selectOperations,
  selectTreatments,
} from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getExpiredOperations, getOperations, getProtocols } from "../../services/utils";
import { useEffect } from "react";
import { setOperations } from "../../context/operations/operationsSlice";
import { addMonths, format } from "date-fns";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setExpOperations } from "../../context/expOperations/expOperationsSlice";

const NextOperations = ({ hoverColor }) => {
  const activeWinery = useSelector(selectActiveWinery);
  // const [expiredOperations, setExpiredOperations] = useState([]);
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const configs = useSelector(selectConfigs);
  const analysis = useSelector(selectAnalysis);
  const treatments = useSelector(selectTreatments);

  const expiredOperations = useSelector(selectExpiredOperations);
  const operations = useSelector((state) =>
    selectNextOperations(state, {
      startDate: new Date(),
      endDate: addMonths(new Date(), 1),
    })
  );
  // const operations = useSelector(selectOperations);
  
  
  // console.log('expiredOperations', expiredOperations)
  const computeRecurrentString = (operation) => {
    if (!["ANALYSIS", "TREATMENT"].includes(operation?.type)) return "";
    const arr = operation?.type === 'TREATMENT' ? treatments : analysis;
    const protocolName = arr?.find((p) => p?.protocols_id === operation?.protocol_id)?.protocols_name || '';
    const pName = protocolName?.length > 0 ? ` [${protocolName}]` : '';
  
    if (!operation?.repetitive) return ` #${operation?.id}${pName}`;
    
    const current = operation?.relative_id || 0;
    const total = operation?.total || 0;

    return current > 0 && total > 0
      ? ` #${operation?.main_id || operation?.id} (${current}/${total})${pName}`
      : "";
  };

  const computeAdditionalTypeString = (operation) => {
    if (['CUT', 'DECANT', 'RACKING', 'ANALYSIS', 'TREATMENT', 'DESTEMMING', 'NEW_LOT'].includes(operation?.type)) return '';
    const protocolType = operation?.src_tanks?.[0]?.protocolType || null;
    const homogenizationType = protocolType === 'HOMOGENIZATION_REASSEMBLY' ? operation?.src_tanks?.[0]?.homogenizationType || null : null;
    
    // TO DO: aggiungi cappello o altro in omogenizzazione temperatura
    if (protocolType !== null && protocolType !== 'ADD') return ` (${getLabel(`protocolType_${protocolType}`)}${homogenizationType ? ` ${getLabel(`protocolHomogenizationMass_${homogenizationType}`)}` : ''}) `?.toLowerCase();
    return '';
  }

  const initData = async () => {
    const promiseArray = [];
    if (operations?.length === 0) {
      promiseArray.push(loadOperations());
    }
    if (analysis?.length === 0) {
      promiseArray.push(loadAnalysis());
    }
    if (treatments?.length === 0) {
      promiseArray.push(loadTreatments());
    }
    if (expiredOperations?.length === 0) {
      promiseArray.push(loadExpiredOperations());
    }

    return Promise.all(promiseArray);
  };

  useEffect(() => {
    initData();
  }, [activeWinery]);

  const loadExpiredOperations = async () => {
    const currentExpiredOperations = await getExpiredOperations(
      activeWinery?.id,
      axiosPrivate
    );
    // setExpiredOperations([...currentExpiredOperations]);

    dispatch(setExpOperations(currentExpiredOperations))
  };

  const loadAnalysis = async () => {
    const currentAnalysis = await getProtocols('analysis', axiosPrivate);
    dispatch(setAnalysis(currentAnalysis));
  };

  const loadTreatments = async () => {
    const currentTreatments = await getProtocols('treatment', axiosPrivate);
    dispatch(setTreatments(currentTreatments));
  };

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  return (
    <div className="next_operations_wrapper">
      {[...expiredOperations, ...operations]
        // ?.sort((b, a) => new Date(b.expire_date) - new Date(a.expire_date))
        ?.sort(function(a, b) {
          let adate = new Date(a?.expire_date),
              bdate = new Date(b.expire_date),
              rv = adate - bdate;
          if (rv === 0) {
              rv = b?.priority - a?.priority;
          }
          return rv;
        })?.map?.((operation, index) => {
          return (
            <Link
              key={operation.id}
              to={`/programming/${operation.type}/${operation.id}/`}
              className={`next_operations_wrapper_operation ${hoverColor}`}
            >
              <div className="expand-width">
                {getLabel(`operationType${operation?.type}`) + computeAdditionalTypeString(operation) +
                  computeRecurrentString(operation)}
                {operation?.type === 'ANALYSIS' && <span>{" "}<FontAwesomeIcon size="xs" icon={!operation?.sampled ? "fa-eye-dropper" : "fa-flask-vial"} /></span>}
              </div>
              {/* <div>{getLabel(`operationInsertionDate`, { date: format(new Date(operation?.insertion_date), configs.shortDateFormat)})}</div> */}
              <span>
                {getLabel((expiredOperations?.length > 0 && index < expiredOperations?.length) ? `operationExpiredDate` : `operationExpireDate`, {
                  date: format(
                    new Date(operation?.expire_date),
                    configs.shortDateFormat
                  ),
                })}
                {operation?.is_exact_date && <span>{" "}<FontAwesomeIcon size="xs" icon="fa-calendar-day" /></span>}
                {(expiredOperations?.length > 0 && index < expiredOperations?.length) && <>  &#10071;</>}
              </span>
              <div className="priority">{getLabel(`operationPriority${operation?.priority}`)}</div>
            </Link>
          );
        })}
      {operations?.length === 0 && expiredOperations?.length === 0 && (
        <h5 className="alert_no_info">{getLabel("noOperationsFound")}</h5>
      )}
    </div>
  );
};

export default NextOperations;
