import { useDispatch, useSelector } from "react-redux"
import { selectActiveWinery, selectUsersDisplayGrid, selectUsers, selectUser } from "../../context/selectors"
import useLabels from "../../hooks/useLabels"
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getUsers } from "../../services/utils";
import { setUsers } from "../../context/users/usersSlice";
import { useEffect } from "react";
import UserTile from "./UserTile";
import { Switch } from "../FormComponents";

import './styles/index.scss'
import { toggleUsersDisplayGrid } from "../../context/configs/configsSlice";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import TableEdit from "../TableEdit";

const UserList = ({

}) => {
    const activeWinery = useSelector(selectActiveWinery)
    const users = useSelector(selectUsers);
    const user = useSelector(selectUser);
    const displayModeGrid = useSelector(selectUsersDisplayGrid);
    const dispatch = useDispatch();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('username', {
            header: getLabel("userName"),
            cell: TableCell,
        }),
        columnHelper.accessor("client_email", {
            header: getLabel("userEmail"),
            cell: TableCell,
        }),
        columnHelper.accessor('valid', {
            header: getLabel("userEmailConfirmed"),
            cell: TableCell,
            meta: {
                type: 'email_validity'
            }
        }),
        columnHelper.accessor((row) => row?.subtype && getLabel(`user_subtype_${row?.subtype}`), {
            header: getLabel("userPermission"),
            cell: TableCell,
        }),
    ];


    const toggleDisplayMode = () => {
        dispatch(toggleUsersDisplayGrid())
    }

    const loadUsers = async () => {
        const currentUsers = await getUsers(axiosPrivate);
        // const currentUsers = await getUsers(axiosPrivate, activeWinery.id, user.userName);
        dispatch(setUsers(currentUsers));
    }

    useEffect(() => {
        if (!users || users.length === 0) {
            loadUsers()
        }
    }, []);

    const renderList = () => {
        if (users?.length > 1) {
            return (
                <div className="users_list_table">
                    <TableEdit
                        defaultData={users?.filter((u) => u.username !== user.userName)}
                        columns={columns}
                        isSearchBar={false}
                        rowClickHandler={(row) => {navigate(`/users/${row.original.id}`)}}
                        isEditable={false}
                    />
                </div>
            )
        }
        return (<div className="users_alert">
            <h6 className="users_alert_title">{getLabel('noUsersAlert')}</h6>
        </div>)
    }

    const renderGrid = () => {
        if (users?.length > 1) {
            return (
                <div className="users_grid">
                    {users.map((user) => {
                        return <UserTile {...user}/>
                    })}
                </div>
            )
            
        }
        return (<div className="users_alert">
            <h6 className="users_alert_title">{getLabel('noUsersAlert')}</h6>
        </div>)
    }

    return <div className="users_list">
    {users?.length > 1 && <Switch
        leftOption={{
            label: getLabel('listView')
        }}
        rightOption={{
            label: getLabel('gridView')
        }}
        toggleHandler={toggleDisplayMode}
        status={displayModeGrid}
    />}
        {displayModeGrid ? renderGrid() : renderList()}
    </div>


}

export default UserList