import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { axiosPrivate } from "../api/axios";
// import { clearSession } from "../services/LocalStorage";
import { clearSession } from "../services/SessionStorage";
import { useSelector } from "react-redux";
import { selectActiveWinery } from "../context/selectors";
import { getItem } from "../services/LocalStorage";
import { useNavigate } from "react-router-dom";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const activeWinery = useSelector(selectActiveWinery);

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          // is the first attempt
          config.headers["Authorization"] = `Bearer ${auth.accessToken}`; // taken from initial login
        }
          config.headers["Accept-Language"] = getItem('i18LanguageLocale')?.split("_")?.[0] || "it";
        if (activeWinery) {
          config.headers["clientname"] = activeWinery?.client_name || "";
          config.headers["wineryid"] = String(activeWinery?.id) || "";
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response, // if the response is good, we return it
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          // token expired
          prevRequest.sent = true; // get rid of loops
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        } else if (error?.response?.status === 401 && !prevRequest?.sent) {
          // jwt expired
          prevRequest.sent = true; // get rid of loops
          navigate('/login');
          // const newAccessToken = await refresh();
          // prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          // return axiosPrivate(prevRequest);
        }
        clearSession("authToken");
        return Promise.reject(error);
      }
    );

    // clean up function
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
