import { createSlice } from "@reduxjs/toolkit";

export const storedAnalysisResultsSlice = createSlice({
    name: 'storedAnalysisResults',
    initialState: [],
    reducers: {
        empty: state => {
            return []
        },
        setStoredAnalysisResults: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        addStoredAnalysisResults: (state, action) => {
            action.payload?.forEach(element => {
                const foundedIndex = state.findIndex((exam) => exam.id == element.id);
                if (foundedIndex !== -1) {
                    state[foundedIndex] = element
                } else {
                    state.push(element);
                }
            })
            
            return state;
        },
        update: (state, action) => {
            // const foundedIndex = state.findIndex((lot) => lot.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return []
        },
        remove: (state, action) => {
            const foundedIndex = state.findIndex((analysis) => analysis.id === action.payload?.id);
            if (foundedIndex) {
                return state.splice(foundedIndex, 1)
            }
            return state
        }
    }
});

export const {
    setStoredAnalysisResults, 
    empty,
    update,
    remove,
    addStoredAnalysisResults,
} = storedAnalysisResultsSlice.actions;

export default storedAnalysisResultsSlice.reducer