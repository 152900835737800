import { useState } from "react";
import useLabels from "../../hooks/useLabels";
import DebouncedInput from "./DebouncedInput";
import "./styles/index.scss";

// import { useReactTable, usePagination, useGlobalFilter, useFlexLayout, useSortBy, useResizeColumns } from '@tanstack/react-table'

const {
  useTable,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useResizeColumns,
  usePagination,
} = require("react-table");

const Table = ({
  data,
  customColumns,
  prefix,
  rowClickHandler,
  isEditable=false,
  onEditHandler,
  onDeleteHandler,
  initialState,
  setPagination = false,
  updateOffset,
  totalRows = 0,
  isSearchBar = true,
  isHeader = true,
}) => {
  const [data2, setData] = useState(data);
  const [getLabel] = useLabels();
  
  const getColumnsFromData = () => {
    const setColumns = new Set();
    data?.forEach((item) => {
      for (let keyItem in item) {
        setColumns.add(keyItem);
      }
    });
    return Array.from(setColumns).map((col) => {
      return {
        Header: getLabel(`${prefix}_${col}`),
        accessor: col,
      };
    });
  };
  const columns = customColumns || getColumnsFromData();
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      autoResetSortBy: false,
      autoResetPage: false,
      // getCoreRowModel: getCoreRowModel,
      meta: {
        updateData: (rowIndex, columnId, value) => {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            })
          );
        },
      },
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    resetResizing,
    setGlobalFilter,
    
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter }
  } = tableInstance;

  const renderHeader = () => {
    return (
      <div className="table_base_header">
        {headerGroups.map((headerGroup) => renderHeaderGroup(headerGroup))}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="table_base_body" {...getTableBodyProps()}>
        {renderRows()}
      </div>
    );
  };

  const renderHeaderGroup = (headerGroup) => {
    return (
      <div
        className="table_base_header_group"
        {...headerGroup.getHeaderGroupProps()}
      >
        {headerGroup.headers.map((column) => renderHeaderColumn(column))}
      </div>
    );
  };

  const renderHeaderColumn = (column) => {
    return (
      <div
        className="table_base_header_column"
        {...column.getHeaderProps(column.getSortByToggleProps())}
      >
        <div className="table_base_header_column_text">
          {column.render("Header")}
        </div>
        <div
          className="table_base_header_resizer"
          {...column.getResizerProps()}
        />
      </div>
    );
  };

  const renderRows = () => {
    if (setPagination) {
        return page.map((row) => {
            prepareRow(row);
            return renderRow(row);
        });
    } else {
        return rows.map((row) => {
            prepareRow(row);
            return renderRow(row);
        });
    }
  };

  const renderRow = (row) => {
    return (
      <div
        className={`table_base_body_row ${
          row?.original?.highlight ? "highlight" : ""
        } ${rowClickHandler ? "clickable" : ""} ${prefix === "analysisForm" ? "analysis" : ""}`}
        {...row.getRowProps()}
        onClick={() => {
          if (rowClickHandler) {
            rowClickHandler(row);
          }
        }}
      >
        {row.cells.map((cell) => {
          return renderCell(cell);
        })}
        {isEditable && renderEditMode(row)}
      </div>
    );
  };

  const renderCell = (cell) => {
    return (
      <div className="table_base_body_cell" {...cell.getCellProps()}>
      {cell.render("Cell")}
      </div>
    );
  };

  const renderEditMode = (row) => {

    const handleClick = (event) => {
      event.stopPropagation();
      if (isEditable) {
        event.preventDefault();
        onEditHandler(event);
      }
    };
    return (
      <>
        <div
          className="table_base_cody_cell edit"
          onClick={handleClick}
        >
          Modifica
        </div>
        {/* <div
          className="table_base_cody_cell delete"
          onClick={() => {
            onDeleteHandler(row?.original?.id);
          }}
        >
          CANCELLA PH
        </div> */}
      </>
    );
  };

  const renderPaginationTools = () => {
    return (<div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => {
          nextPage();
          if (Number(pageSize) * (pageIndex + 2) > data?.length)
            updateOffset(data?.length)
          }} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          {getLabel('page')}{" "}
          <strong>
            {/* {pageIndex + 1} of {pageOptions.length} */}
            {pageIndex + 1} {getLabel('of')} {Math.ceil(totalRows / pageSize)}
          </strong>{" "}
        </span>
        <span>
          | {getLabel('goToPage')}:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
              if (Number(pageSize) * (page + 1) > data?.length)
                updateOffset(data?.length)
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            if (Number(e.target.value) * (pageIndex + 2) > data?.length)
              updateOffset(data?.length)
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => ( // max value must match getCompletedOperationsWithPaging(..., 50, ...)
            <option key={pageSize} value={pageSize}>
              {getLabel(`show`)} {pageSize}
            </option>
          ))}
        </select>
      </div>)
  }

  const renderSearchBar = () => {

    return (
      <div className="search-box">
      <button className="btn-search"><i className="fas fa-search"></i></button>
      <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          className="input-search"
          placeholder={getLabel('search_Placeholder')}
        />
    </div>  
    )
  }

  return (
    <div className="table_base" {...getTableProps()}>
      {isSearchBar && renderSearchBar()}
      {isHeader && renderHeader()}
      {renderBody()}
      {setPagination && renderPaginationTools()}
    </div>
  );
};

export default Table;
