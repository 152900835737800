import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom"
import useLabels from "../../hooks/useLabels";
import './styles/index.scss';

const ProtocolTile = ({
    protocols_name,
    analysis,
    drugs,
    protocols_id,
    substance,
    type
}) => {
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const getSubstanceName = (row) => {
        if (type === 'ANALYSIS') return getLabel(`substance_${row?.substance_name}`);
        return row?.substance_name;
    }

    return (
        <div className="protocol_tile"
            onClick={() => {navigate(`/protocols/${type.toLowerCase()}/${protocols_id}`)}}
        >
            <div className="protocol_tile_content">
                <div className="protocol_tile_content_name">
                <div className="protocol_tile_content_name_wrapper">
                    <span>{protocols_name || substance?.name}</span>
                    <span className="protocol_tile_content_name_wrapper_icon">
                    {type === 'ANALYSIS' && 
                        <FontAwesomeIcon icon="fa-flask-vial" />
                    }
                    {type === 'TREATMENT' && 
                        <FontAwesomeIcon icon="fa-prescription-bottle" />
                    }
                    {type === 'SUBSTANCES' && 
                        <FontAwesomeIcon icon="fa-tablets" />
                    }
                    </span>
                </div>
            </div>
        {type === 'ANALYSIS' && <div className="protocol_tile_content_subs">
            {analysis?.map((an, index) => {return <p key={`substance-name-an-${index}`}>{getSubstanceName(an)}</p>})}
        </div>}
        {type === 'TREATMENT' && <div className="protocol_tile_content_subs">
            {drugs?.map((dr, index) => {return <p key={`substance-name-dr-${index}`}>{getSubstanceName(dr)}</p>})}
        </div>}
        {type === 'SUBSTANCES' && 
        <div className="protocol_tile_content_subs">
            <p>{getLabel(`drugType_${substance?.type?.toUpperCase()}`)}</p>
            <p>{substance?.quantity} {substance?.unit}</p>
        </div>}
        </div>
    </div>)
}

export default ProtocolTile