import { useEffect, useState } from "react"
import { format } from 'date-fns'
import { useDispatch, useSelector } from "react-redux";
import { selectConfigs } from "../../context/selectors";

const Clock = () => {

    const [currentDate, setDate] = useState(new Date());
    const [hourRot, setHourRot] = useState(0);
    const [minRot, setMinRot] = useState(0);
    const [secRot, setSecRot] = useState(0);
    const configs = useSelector(selectConfigs);
    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000)
        return () => {
            clearInterval(timerId)
        }
    }, [])

    const refreshClock = () => {
        const now = new Date();
        setDate(now)
        const hr = now.getHours();
        const min = now.getMinutes();
        const sec = now.getSeconds();
        setHourRot(30 * hr + min / 2);
        setMinRot(6 * min);
        setSecRot(6 * sec)
    }

    return <div className="clock">
        <div className="clock_analog">
            <div className="clock_analog_circle">
                <div className="clock_analog_pin"></div>
                <div className="clock_analog_hour" style={{ transform: `rotate(${hourRot}deg)` }}></div>
                <div className="clock_analog_minute" style={{ transform: `rotate(${minRot}deg)` }}></div>
                <div className="clock_analog_second" style={{ transform: `rotate(${secRot}deg)` }}></div>
            </div>
        </div>
        <div className="clock_digital">
            {format(currentDate, configs.dateFormat)}
        </div>
    </div>

}

export default Clock