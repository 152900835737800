import { Link, useLocation, useNavigate } from "react-router-dom";
import "./styles/index.scss";
import useLabels from "../../hooks/useLabels";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Footer = ({ isMobile }) => {
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [getLabel] = useLabels();
  const links = getLabel("footer") || [];
  
  const openExternalUrl = (url) => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const renderFooter = () => {
    return (
      <div className="footer">
        {/* <div className="social-info">
          <div class="footer-change-country">
            <div class="footer-current-country">
              <img
                src="/content/dam/costa/costa-asset/home-page/svg/it.png"
                alt="Italia"
              />
              <span>
                <span class="current-country-label">Italia - </span>
                <a
                  href="https://www.costacruises.com/welcome.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn-custom change-country-link"
                >
                  <span>Choose your Country/Region</span>
                </a>
              </span>
            </div>
          </div>
          <div
            class="social-share-wrapper"
            role="list"
            aria-label="Social Media"
          >
            <div class="social-icon-container" role="listitem">
              <a
                href="https://www.instagram.com/costacruisesofficial/"
                target="_blank"
                rel="noopener noreferrer"
                class="instagram"
                aria-describedby="audioeye_new_window_message"
              >
                <span>instagram</span>
              </a>
            </div>
            <div class="social-icon-container" role="listitem">
              <a
                href="https://twitter.com/costacrociere"
                target="_blank"
                rel="noopener noreferrer"
                class="twitter"
                aria-describedby="audioeye_new_window_message"
              >
                <span>twitter</span>
              </a>
            </div>
            <div class="social-icon-container" role="listitem">
              <a
                href="https://www.facebook.com/CostaCrociere"
                target="_blank"
                rel="noopener noreferrer"
                class="facebook"
                aria-describedby="audioeye_new_window_message"
              >
                <span>facebook</span>
              </a>
            </div>
            <div class="social-icon-container" role="listitem">
              <a
                href="https://www.youtube.com/costacrociere"
                target="_blank"
                rel="noopener noreferrer"
                class="youtube"
                aria-describedby="audioeye_new_window_message"
              >
                <span>youtube</span>
              </a>
            </div>
          </div>
        </div> */}
        {Array.isArray(links) && <div className="top_content">
          {links.map((link, index) => (
            <div className="column" key={`footer-div-${index}`}>
              <h5>{getLabel(link.title)}</h5>
              <div className="content">
                {link?.items?.map?.((l, i) => {
                  return (

                    // <p
                    //   key={`footer-link-${index}-${i}`}
                    //   onClick={() => openExternalUrl(l?.link || "#")}
                    //   className="link"
                    // >
                    //   {getLabel(l.label)}
                    // </p>

                    
                    <Link
                      key={`footer-link-${index}-${i}`}
                      to={l?.link || "#"}
                      // target="_blank"
                      // rel="noopener noreferrer"
                      className="link"
                    >
                      {getLabel(l.label)}
                    </Link>
                  );
                })}
              </div>
            </div>
          ))}
        </div>}
      </div>
    );
  };

  // if (isMobile) {
  //   return (<>
  //     <div className="top_burger_bar" onClick={() => burgerMenu && toggleBurgerMenu(false)} >
  //     {/* onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} */}
  //       <div className="bars" onClick={() => handleBurgerMenu()}>
  //         <FontAwesomeIcon icon={(burgerMenu && secondaryBurgerMenu) ? "fa-arrow-left" : !burgerMenu ? "fa-bars" : "fa-xmark"} size={"2x"} />
  //       </div>
  //     </div>
  //       <BurgerMenu setIsOpen={setIsOpen} isOpen={burgerMenu} setIsSecondaryOpen={setIsSecondaryOpen} isSecondaryOpen={secondaryBurgerMenu} />
  //     </>
  //   );
  // }

  return <footer className="footer_container">{renderFooter()}</footer>;
};

export default Footer;
