const getItem = (item) => {
    try {
        const store = localStorage.getItem(item);
        if (store) {
            const json = JSON.parse(store);
            return json
        }
    } catch {
        return null
    }
    return null
}

const setItem = (key, item) => {
    try {
        const string = JSON.stringify(item);
        localStorage.setItem(key, string)
    } catch (e) {
        throw new Error(e)
    }
}

const removeItem = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        throw new Error(e)
    }
}

const clearSession = (item) => {
    try {
        localStorage.removeItem(item)
    } catch (e) {
        throw new Error(e)
    }
}

export {
    getItem,
    setItem,
    removeItem,
    clearSession
}