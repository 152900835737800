import { useNavigate } from "react-router-dom";
import useLabels from "../../hooks/useLabels";

const TankTile = ({
  name,
  type,
  barrels_num,
  capacity,
  quantity,
  id_code,
  batch_name,
  color = null,
  state,
  id,
}) => {
  const navigate = useNavigate();
  const [getLabel] = useLabels();
  const percentageLoad = (quantity * 100) / capacity;
  let wineColor = "red";

  switch (color) {
    case "WHITE":
      wineColor = "white";
      break;
    case "ROSE":
      wineColor = "rose";
      break;
    default:
      wineColor = "red";
      break;
  }

  return (
    <div className={`tank_tile_wrapper ${['TONNEAU', 'BARRIQUE'].includes(type) ? 'barrel' : ''} ${type === 'TONNEAU' ? 'tonneau' : ''}`}>
      {state && (
        <p className={`state_tag color_${color?.toLowerCase() || "red"} ${['TONNEAU', 'BARRIQUE'].includes(type) ? 'barrel' : ''}`}>
          {getLabel(`lotState_${state}`)}
        </p>
      )}
      {!state && <p className={`state_tagFix ${['TONNEAU', 'BARRIQUE'].includes(type) ? 'barrel' : ''}`}></p>}
      <div
        className={`tank_tile ${['TONNEAU', 'BARRIQUE'].includes(type) ? 'barrel' : ''} ${type === 'TONNEAU' ? 'tonneau' : ''}`}
        onClick={() => {
          navigate(`/tanks/${id}`);
        }}
      >
        <div className={`tank_tile_content ${['TONNEAU', 'BARRIQUE'].includes(type) ? 'barrel' : ''} ${type === 'TONNEAU' ? 'tonneau' : ''}`}>
          <div className="tank_tile_content_name">{name}</div>
          {Number(quantity) > 0 && (
            <p className="tank_tile_content_code">{batch_name}</p>
          )}
          {/* <div className="tank_tile_content_code">{id_code}</div> */}
          <div className="tank_tile_content_code">
            {quantity || 0}L ({Math.round(percentageLoad)}%)
          </div>
          {/* {state === 'MUST' && Number(quantity) > 0 && <FontAwesomeIcon icon="fa-cubes-stacked" />}
            {state === 'WINE' && Number(quantity) > 0 && <FontAwesomeIcon icon="fa-wine-bottle" />} */}
        </div>
        <div
          className={"tank_tile_progress_" + wineColor}
          style={{
            height: `${percentageLoad}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default TankTile;
