import { createSlice } from "@reduxjs/toolkit";

export const wineVarietiesSlice = createSlice({
    name: 'wineVarieties',
    initialState: [],
    reducers: {
        emptyWineVarieties: state => {
            return []
        },
        setWineVarieties: (state, action) => {
            return action.payload
        },
    }
});

export const {
    setWineVarieties, 
    emptyWineVarieties,
} = wineVarietiesSlice.actions;

export default wineVarietiesSlice.reducer