import { useNavigate } from "react-router-dom"
import winery_img from "../../assets/icons/winery.png";

const WineryTile = ({
    name,
    city,
    id
}) => {

    const navigate = useNavigate();


    return (<div className="winery_tile" key={`winery-tile-${id}`}
        onClick={() => {navigate(`/wineries/${id}`)}}
    >
        <div className="winery_tile_content">
        <div className="winery_tile_content_img_container">
          <img src={winery_img} alt="winery"/>
        </div>
            <div className="winery_tile_content_name">{name}</div>
            <div className="winery_tile_content_code">{city}</div>
        </div>
    </div>)
}

export default WineryTile