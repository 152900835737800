import { createSlice } from "@reduxjs/toolkit";

export const tanksSlice = createSlice({
    name: 'tanks',
    initialState: [],
    reducers: {
        emptyTanks: state => {
            return []
        },
        setTanks: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        add: (state, action) => {
            // return [...state, action.payload]
            return []
        },
        update: (state, action) => {
            // const foundedIndex = state.findIndex((tank) => tank.id === action.payload?.id);
            // if (foundedIndex !== -1) {
            //     state[foundedIndex] = action.payload
            // }
            // return state
            return []
        },
        removeT: (state, action) => {
            const foundedIndex = state?.findIndex((tank) => tank.id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state?.filter((d, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setTanks, 
    emptyTanks,
    update,
    removeT,
    add
} = tanksSlice.actions;

export default tanksSlice.reducer