import { NavLink, useParams } from "react-router-dom"
import useLabels from "../../hooks/useLabels"
import SecondaryBar from "../SecondaryBar"
import { CTA } from "../Generic"
import { selectConfigs, selectDrugs, selectUsers, selectWineMaking } from "../../context/selectors"
import { getDrugs, getProtocols, getUsers } from "../../services/utils"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { setDrugs } from "../../context/substances/drugsSlice";
import { ROLES } from "../../constants/base"
import useAuth from "../../hooks/useAuth"
import WineMakingProtocolList from "../WineMakingProtocolList"
import { setWineMaking } from "../../context/protocols/wineMakingSlice"
import { setUsers } from "../../context/users/usersSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const WineMakingProtocols = ({ isMobile }) => {
    const { type } = useParams();
    const [getLabel] = useLabels();
    const protocols = useSelector(selectWineMaking);
    const substances = useSelector(selectDrugs);
    const users = useSelector(selectUsers);
    const configs = useSelector(selectConfigs);
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const role = auth?.role || ROLES.GUEST;
    
    const loadUsers = async () => {
      const currentUsers = await getUsers(axiosPrivate);
      dispatch(setUsers(currentUsers));
    }
  
    const loadSubstances = async () => {
        const currentSubstances = await getDrugs(axiosPrivate);
        dispatch(setDrugs(currentSubstances));
    }

    useEffect(() => {
        if (!protocols || protocols.length === 0) {
            loadProtocols()
        }
        if (!substances || substances.length === 0) {
            loadSubstances()
        }
        if (!users || users.length === 0) {
            loadUsers();
          }
    }, []);

    const loadProtocols = async () => {
        const currentProtocols = await getProtocols('winemaking', axiosPrivate) || [];
        dispatch(setWineMaking(currentProtocols));
    }

    const renderNavMenu = () => {
        return (
            <div className="primary_container_menu">
                <h2>{getLabel('protocols')}</h2>
                {role === ROLES.CLIENT && <NavLink to={`/protocols/winemaking/${type}/new`}>
                    <CTA customClassName={'create'}>
                        {type === 'PRE' ? getLabel('createWineMakingProtocol') : getLabel('createWineMakingPostProtocol')}
                        {" "}<FontAwesomeIcon icon="fa-plus" />
                    </CTA>
                </NavLink>}
            </div>
        )
    }

    return (<div className="primary_container">
        <SecondaryBar
            breadCrumb={[
                type === 'PRE' ? getLabel('wineMakingProtocolsNavLink') : getLabel('wineMakingPostProtocolsNavLink')
            ]}
            isBasePathNeeded={false}
        />
        {renderNavMenu()}
        <WineMakingProtocolList type={type} protocols={protocols?.filter((p) => p?.protocol_type === type)} users={users} configs={configs} isMobile={isMobile} />
    </div>)
}

export default WineMakingProtocols