const getItem = (item) => {
    const store = sessionStorage.getItem(item);
    try {
        if (store) {
            const json = JSON.parse(store);
            return json
        }
    } catch {
        return null
    }
    return null
}

const setItem = (key, item) => {
    try {
        const string = JSON.stringify(item);
        sessionStorage.setItem(key, string)
    } catch (e) {
        throw new Error(e)
    }
}

const clearSession = (item) => {
    try {
        sessionStorage.removeItem(item)
    } catch (e) {
        throw new Error(e)
    }
}

export {
    getItem,
    setItem,
    clearSession
}