import { createSlice, current } from "@reduxjs/toolkit";

export const treatmentsSlice = createSlice({
    name: 'treatments',
    initialState: [],
    reducers: {
        emptyTreatments: state => {
            return []
        },
        setTreatments: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        addTreatments: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        updateTreatments: (state, action) => {
            return [];
        },
        removeTreatments: (state, action) => {
            const foundedIndex = state?.findIndex((treatment) => treatment.protocols_id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state.filter((t, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setTreatments, 
    emptyTreatments,
    updateTreatments,
    removeTreatments,
    addTreatments
} = treatmentsSlice.actions;

export default treatmentsSlice.reducer