import { createSlice } from "@reduxjs/toolkit";

export const drugsSlice = createSlice({
    name: 'drugs',
    initialState: [],
    reducers: {
        emptyDrugs: state => {
            return []
        },
        setDrugs: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        addDrugs: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        updateDrugs: (state, action) => {
            const foundedIndex = state?.findIndex((drug) => drug.id === Number(action?.payload?.id));
            if (foundedIndex !== -1)
                state[foundedIndex] = action.payload;

            return state;

            // return []
        },
        removeDrugs: (state, action) => {
            const foundedIndex = state?.findIndex((drug) => drug.id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state?.filter((d, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setDrugs, 
    emptyDrugs,
    updateDrugs,
    removeDrugs,
    addDrugs
} = drugsSlice.actions;

export default drugsSlice.reducer