import { useNavigate } from "react-router-dom"
import useLabels from "../../hooks/useLabels";

const WineMakingProtocolTile = ({
    type,
    name,
    id,
    operations,
}) => {
    const navigate = useNavigate();
    const [getLabel] = useLabels();

    const slicedOps = [ ...operations?.slice(0, 4) ];
    if (operations?.length > 4) slicedOps.push({dot: '...'});

    return (<div className="protocol_tile"
        onClick={() => {navigate(`/protocols/winemaking/${type}/${id}`)}}
    >
        <div className="protocol_tile_content_subs">
            <div className="protocol_tile_content_name">{name || ''}</div>
            <div className="protocol_tile_content_subs">
                <p>{id}</p>
            </div>
        <div className="lot_tile_content_tanks">{slicedOps?.map((o, index) => {return <p key={`op-${index}`}>{o?.dot ? o?.dot : getLabel(`operationType${o?.operation}`)}</p>})}</div>
        </div>
    </div>)
}

export default WineMakingProtocolTile