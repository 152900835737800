import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        firstName: '',
        email: '',
        role: 'public'
    },
    reducers: {
        empty: state => {
            return {
                userName: '',
                clientName: '',
                role: -1,
                type: 'anonymous'
            }
        },
        setUser: (state, action) => {
            const { UserInfo } = action.payload
            return {
                role: UserInfo?.role,
                type: UserInfo?.type,
                userName: UserInfo?.username,
                clientName: UserInfo?.client_name
            }
        }
    }
});

export const { empty, setUser } = userSlice.actions;

export default userSlice.reducer