import { NavLink, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import { CTA, InfoCard } from "../Generic";
import ProtocolList from "../ProtocolList";
import SubstanceList from "../SubstanceList";
import {
  selectDrugs,
  selectExams,
  selectTreatments,
  selectAnalysis,
} from "../../context/selectors";
import { getDrugs, getExams, getProtocols } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrugs } from "../../context/substances/drugsSlice";
import { setExams } from "../../context/substances/examsSlice";
import { setAnalysis } from "../../context/protocols/analysisSlice";
import { setTreatments } from "../../context/protocols/treatmentsSlice";
import { ROLES } from "../../constants/base";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import grape from "../../assets/icons/grape.png";
import must from "../../assets/icons/must.png";
import wine from "../../assets/icons/wine.png";
import additives from "../../assets/icons/additives.png";
import adjuvants from "../../assets/icons/adjuvants.png";

const Protocols = ({ isMobile }) => {
  const [getLabel] = useLabels();
  const { protocolType } = useParams();
  const availableTreatmentsName = getLabel("substanceTitlePage");
  const protocols = useSelector(
    protocolType === "treatment" ? selectTreatments : selectAnalysis
  );
  const substances = useSelector(
    protocolType === "treatment" ? selectDrugs : selectExams
  );
  const [protocolsLen, setProtocolsLen] = useState({});
  const [substancesLen, setSubstancesLen] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;

  const loadSubstances = async () => {
    const currentSubstances = await (protocolType === "treatment"
      ? getDrugs(axiosPrivate)
      : getExams(axiosPrivate));
    dispatch(
      protocolType === "treatment"
        ? setDrugs(currentSubstances)
        : setExams(currentSubstances)
    );
  };

  useEffect(() => {
    if (!substances || substances.length === 0) {
      loadSubstances();
    }
  }, [protocolType]);

  const loadProtocols = async () => {
    const currentProtocols =
      (await getProtocols(protocolType, axiosPrivate)) || [];
    dispatch(
      protocolType === "treatment"
        ? setTreatments(currentProtocols)
        : setAnalysis(currentProtocols)
    );
  };

  useEffect(() => {
    if (!protocols || protocols.length === 0) {
      loadProtocols();
    }
  }, [protocolType]);

  const renderHeader = () => {
    return (
      <div className="primary_container_menu">
        <h2>{getLabel("protocols")}</h2>
      </div>
    );
  };

  useEffect(() => {
    if (!protocols || protocols?.length === 0) return;
    const tmp = {};
    tmp.tot = protocols?.length || 0;
    const wine = protocols?.filter((t) => t?.state === "WINE");
    const must = protocols?.filter((t) => t?.state === "MUST");
    const grape = protocols?.filter((t) => t?.state === "GRAPE");

    tmp.wine = wine?.length;
    tmp.must = must?.length;
    tmp.grape = grape?.length;

    tmp.wines = {
      red: wine?.filter((t) => t?.color === "RED")?.length,
      white: wine?.filter((t) => t?.color === "WHITE")?.length,
      rose: wine?.filter((t) => t?.color === "ROSE")?.length,
    };
    tmp.musts = {
      red: must?.filter((t) => t?.color === "RED")?.length,
      white: must?.filter((t) => t?.color === "WHITE")?.length,
      rose: must?.filter((t) => t?.color === "ROSE")?.length,
    };
    tmp.grapes = {
      red: grape?.filter((t) => t?.color === "RED")?.length,
      white: grape?.filter((t) => t?.color === "WHITE")?.length,
      rose: grape?.filter((t) => t?.color === "ROSE")?.length,
    };

    setProtocolsLen(tmp);
  }, [protocols]);


  useEffect(() => {
    if (!substances || substances?.length === 0) return;
    const tmp = {};
    tmp.tot = substances?.length || 0;
    const additive = substances?.filter((t) => t?.type === "ADDITIVE");
    const adjuvant = substances?.filter((t) => t?.type === "ADJUVANT");
    console.log(adjuvant)
    tmp.additive = additive?.length;
    tmp.adjuvant = adjuvant?.length;

    setSubstancesLen(tmp);
  }, [substances]);

  const getTooltipContent = (obj = {}) => {
    return (
      <ul>
        {Object.keys(obj)?.map((o, index) => (
          <li className="tooltip_label" key={`tooltip_${index}`}>{`${getLabel(
            `lotColor_${o?.toUpperCase()}`
          )}: ${obj?.[o]}`}</li>
        ))}
      </ul>
    );
  };

  const renderNavMenu = () => {
    return (
      <div className="primary_container_menu">
        <div>
          <h5 className="tanks_title">
            {getLabel("numTotProtocols")}
            <span className="tanks_span">{protocolsLen?.tot || "0"}</span>
          </h5>
        </div>
        <div
          className={`tanks_wrapper ${
            protocolType === "analysis" ? "" : "no-center"
          }`}
        >
          {role === ROLES.CLIENT && (
            <NavLink
              className="cta_wrapper"
              to={`/protocols/${protocolType}/new`}
            >
              <CTA customClassName={"create"}>
                {protocolType === "treatment"
                  ? getLabel("createTreatmentProtocol")
                  : getLabel("createAnalysisProtocol")}{" "}
                <FontAwesomeIcon icon="fa-plus" />
              </CTA>
            </NavLink>
          )}
          {protocolType === "analysis" && (
            <div className="tanks_recap">
              <div className="tanks_recap_wrapper">
                <InfoCard
                  label={getLabel("numTotGrapeLots")}
                  number={protocolsLen?.grape}
                  img={isMobile || true ? grape : null}
                  isGrey={protocolsLen?.grape === 0}
                  content={getTooltipContent(protocolsLen?.grapes)}
                />
                <InfoCard
                  label={getLabel("numTotMustLots")}
                  number={protocolsLen?.must}
                  img={isMobile || true ? must : null}
                  isGrey={protocolsLen?.musts === 0}
                  content={getTooltipContent(protocolsLen?.musts)}
                />
                <InfoCard
                  label={getLabel("numTotWineLots")}
                  number={protocolsLen?.wine}
                  img={isMobile || true ? wine : null}
                  isGrey={protocolsLen?.wines === 0}
                  content={getTooltipContent(protocolsLen?.wines)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDrugsNavMenu = () => {
    return (
      <div className="primary_container_menu">
        <div>
          <h5 className="tanks_title">
            {getLabel("numTotSubstances")}
            <span className="tanks_span">{substancesLen?.tot || "0"}</span>
          </h5>
        </div>
        <div
          className={`tanks_wrapper ${
            protocolType === "analysis" ? "" : "no-center"
          }`}
        >
          {role === ROLES.CLIENT && (
            <NavLink
              className="cta_wrapper"
              to={`/substances/${protocolType}/new`}
            >
              <CTA customClassName={"create"}>
                {getLabel("createDrug")} <FontAwesomeIcon icon="fa-plus" />
              </CTA>
            </NavLink>
          )}
            <div className="tanks_recap">
              <div className="tanks_recap_wrapper">
                <InfoCard
                  label={getLabel("numTotAdjuvants")}
                  number={substancesLen?.adjuvant}
                  img={isMobile || true ? adjuvants : null}
                />
                <InfoCard
                  label={getLabel("numTotAdditives")}
                  number={substancesLen?.additive}
                  img={isMobile || true ? additives : null}
                />
              </div>
            </div>
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          protocolType === "treatment"
            ? getLabel("treatmentProtocolsNavLink")
            : getLabel("analysisProtocolsNavLink"),
        ]}
        isBasePathNeeded={false}
      />
      {renderHeader()}
      {renderNavMenu()}
      <ProtocolList
        protocolType={protocolType}
        protocols={protocols}
        isMobile={isMobile}
      />

      {protocolType === "treatment" && (
        <>
          <div style={{ marginTop: "50px" }}>
            <h4>{availableTreatmentsName}</h4>
            {renderDrugsNavMenu()}
            <SubstanceList
              substances={substances}
              substanceType={"substance"}
              isMobile={isMobile}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Protocols;
