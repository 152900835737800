import React, { useEffect, useState } from "react";

import { ROLES } from "./constants/base";

// import Register from "./components/Register";
import Login from "./components/Login";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import PersistentLogin from "./components/PersistentLogin";

import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import useLabels from "./hooks/useLabels";
import { useDispatch, useSelector } from "react-redux";
import { setLocale } from "./context/configs/configsSlice";
import Tanks from "./components/Tanks";
import TankDetail from "./components/TankDetail";
import TankForm from "./components/TankForm";
import OperationsHub from "./components/OperationsHub";
import Lots from "./components/Lots";
import LotDetail from "./components/LotDetail";
import LotForm from "./components/LotForm";
import OperationsForm from "./components/OperationsForm";
import OperationsDetail from "./components/OperationsDetail";
import Wineries from "./components/Wineries";
import Protocols from "./components/Protocols";
import ProtocolDetail from "./components/ProtocolDetail";
import WineMakingProtocols from "./components/WineMakingProtocols";
import UserDetail from "./components/UserDetail";
import WineryDetail from "./components/WineryDetail";
import WineryForm from "./components/WineryForm";
import ProtocolForm from "./components/ProtocolForm";
import Clients from "./components/Clients";
import ClientDetail from "./components/ClientDetail";
import ClientForm from "./components/ClientForm";
import UserForm from "./components/UserForm";
import SubstanceForm from "./components/SubstanceForm";
import SubstanceDetail from "./components/SubstanceDetail";

import "react-datepicker/dist/react-datepicker.css";
import { useMedia } from "react-use";
import OperationsHistory from "./components/OperationsHistory";
import "./assets/fontawesome";
import WineMakingProtocolForm from "./components/WineMakingProtocolForm";
import WineMakingProtocolDetail from "./components/WineMakingProtocolDetail";
import Tracking from "./components/Tracking/Tracking";
import { selectConfigs } from "./context/selectors";
import UserCustomzation from "./components/UserCustomization";
import { getItem } from "./services/LocalStorage";
import { socket } from './socket';
import AdditionalInfo from "./components/AdditionalInfo";
import CookieBar from "./components/CookieBar";

function App() {
  const isMobile = useMedia("(max-width: 1024px)");
  const { area, locale } = useSelector(selectConfigs);
  
  const [_getLabel, _getArea, setLabels] = useLabels();
  const dispatch = useDispatch();
  
  // const [touchStart, setTouchStart] = useState(null)
  // const [touchEnd, setTouchEnd] = useState(null)

  // // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 50 

  // const onTouchStart = (e) => {
  //   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX)
  // }

  // const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return
  //   const distance = touchStart - touchEnd
  //   const isLeftSwipe = distance > minSwipeDistance
  //   const isRightSwipe = distance < -minSwipeDistance
  //   if (isRightSwipe)  setIsOpen(true);
  //   if (isLeftSwipe)  setIsOpen(false);
    
  //   if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
  //   // add your conditional logic here
  // }

  useEffect(() => {
    const localStorageLanguage = getItem('language') || 'it_IT';
    dispatch(setLocale({ locale: localStorageLanguage?.split('_')?.[0] || 'it' }));
    setLabels();
  }, []);
  
  useEffect(() => {
    setLabels();
  }, [area, locale]);


  return (
    <Routes>
      <Route path="/">
        {/* public routes */}
        <Route element={<Layout />}>
          <Route path="/login" exact element={<Login />} />
          {/* <Route path="/register" exact element={<Register />} /> */}
          <Route path="/unauthorized" exact element={<Unauthorized />} />
          <Route path='/privacy' exact element={<AdditionalInfo type="privacy" />} />
          <Route path='/cookie' exact element={<AdditionalInfo type="cookie" />} />
          <Route path='/set-cookie' exact element={<CookieBar isBar={false} />} />
        </Route>
        <Route element={<PersistentLogin isMobile={isMobile} socket={socket} />}>
          
          {/* admin routes [ 1 ] */}
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path="/admin" element={<Home />} />
            <Route path="/clients" exact element={<Clients />} />
            <Route path="/clients/new" exact element={<ClientForm />} />
            <Route path="/clients/:id" exact element={<ClientDetail />} />
            <Route path="/clients/:id/update" exact element={<ClientForm />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.CLIENT]} />}>
            {/* <Route path="/" element={<Home isMobile={isMobile} />} /> */}
            {/* <Route path="/programming" exact element={<OperationsHub />} /> */}
            <Route
              path="/programming/history"
              exact
              element={<OperationsHistory />}
            />
            <Route
              path="/programming/:type/new"
              exact
              element={<OperationsForm />}
            />
            <Route
              path="/programming/:type/:id/duplicate"
              exact
              element={<OperationsForm isDuplicate={true}/>}
            />
            {/* <Route path="/programming/:type/:id" exact element={<OperationsDetail />} /> */}
            <Route
              path="/programming/:type/:id/update"
              exact
              element={<OperationsForm />}
            />
            {/* <Route path="/lots" exact element={<Lots />} /> */}
            <Route path="/lots/new" exact element={<LotForm />} />
            {/* <Route path="/lots/:id" exact element={<LotDetail/>} /> */}
            <Route path="/lots/:id/update" exact element={<LotForm />} />
            {/* <Route path="/tanks" exact element={<Tanks />} /> */}
            <Route path="/tanks/new" exact element={<TankForm />} />
            {/* <Route path="/tanks/:id" exact element={<TankDetail/>} /> */}
            <Route path="/tanks/:id/update" exact element={<TankForm />} />
            {/* <Route path="/substances/:type/:id" exact element={<SubstanceDetail/>}/> */}
            <Route
              path="/substances/:type/new"
              exact
              element={<SubstanceForm />}
            />
            <Route
              path="/substances/:type/:id/update"
              exact
              element={<SubstanceForm />}
            />
            {/* <Route path="/protocols/:protocolType" exact element={<Protocols/>} /> */}
            {/* <Route path="/protocols/treatment" exact element={<Protocols protocolType={'treatment'}/>} /> */}
            {/* <Route path="/protocols/analysis" exact element={<Protocols protocolType={'analysis'}/>} /> */}
            {/* <Route path="/protocols/:type/:id" exact element={<ProtocolDetail/>} /> */}
            <Route
              path="/protocols/winemaking/:type/new"
              exact
              element={<WineMakingProtocolForm />}
            />
            <Route
              path="/protocols/winemaking/:type/:id/update"
              exact
              element={<WineMakingProtocolForm />}
            />
            <Route
              path="/protocols/:type/new"
              exact
              element={<ProtocolForm />}
            />
            <Route
              path="/protocols/:type/:id/update"
              exact
              element={<ProtocolForm />}
            />
            {/* <Route path="/wineries" exact element={<Wineries />} /> */}
            {/* <Route path="/wineries/:id" exact element={<WineryDetail/>} /> */}
            <Route path="/wineries/new" exact element={<WineryForm />} />
            <Route path="/wineries/:id/update" exact element={<WineryForm />} />
            {/* <Route path="/users/:id" exact element={<UserDetail />} /> */}
            <Route path="/users/new" exact element={<UserForm />} />
          </Route>

          <Route
            element={<RequireAuth allowedRoles={[ROLES.CLIENT, ROLES.USER, ROLES.TANK]} />}
          >
            <Route path="/" element={<Home isMobile={isMobile} />} />
            <Route path="/programming" exact element={<OperationsHub />} />
            <Route
              path="/programming/history"
              exact
              element={<OperationsHub />}
            />
            <Route
              path="/programming/:type/:id"
              exact
              element={<OperationsDetail />}
            />
            <Route path="/lots" exact element={<Lots isMobile={isMobile} />} />
            <Route path="/lots/:id" exact element={<LotDetail />} />
            <Route path="/lots/:id/tracking" exact element={<Tracking type={'lot'}/>} />
            <Route path="/tanks" exact element={<Tanks isMobile={isMobile} />} />
            <Route path="/tanks/:id" exact element={<TankDetail />} />
            <Route path="/tanks/:id/tracking" exact element={<Tracking type={'tank'}/>} />
            <Route
              path="/substances/:type/:id"
              exact
              element={<SubstanceDetail />}
            />
            <Route
              path="/protocols/:protocolType"
              exact
              element={<Protocols isMobile={isMobile}/>}
            />
            <Route
              path="/protocols/winemaking/:type"
              exact
              element={<WineMakingProtocols isMobile={isMobile} />}
            />
            <Route
              path="/protocols/winemaking/:type/:id"
              exact
              element={<WineMakingProtocolDetail />}
            />
            <Route
              path="/protocols/:type/:id"
              exact
              element={<ProtocolDetail />}
            />
            <Route path="/wineries" exact element={<Wineries />} />
            <Route path="/wineries/:id" exact element={<WineryDetail />} />
            <Route path="/users/:id" exact element={<UserDetail />} />
            <Route path="/users/:id/update" exact element={<UserForm />} />
            <Route path="/users/:id/customize" exact element={<UserCustomzation />} />
            </Route>

          {/* admin and client routes [ 1, 2 ]*/}
          {/* <Route
            element={<RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.CLIENT]} />}
          >
          </Route> */}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
