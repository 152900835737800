import { useSelector } from 'react-redux';
import './styles/index.scss';
import { selectActiveWinery } from '../../context/selectors';
import useLabels from '../../hooks/useLabels';

const SecondaryBar = ({
    breadCrumb,
    isBasePathNeeded = true
}) => {
    const activeWinery = useSelector(selectActiveWinery);
    const [getLabel] = useLabels();
    return (<div className='secondary_bar_container'>
        <div className='secondary_bar_title'>
        {isBasePathNeeded && <span>
                {getLabel('winery', { activeWinery: activeWinery?.name })}
        </span>}
            {breadCrumb?.map?.((page, index) => {
                return <span key={index}>{page}</span>
            })}
        </div>

    </div>)
}

export default SecondaryBar