import useLabels from "../../hooks/useLabels";
import humidity_sensor from "../../assets/icons/humidity-sensor.png";
import temperature_sensor from "../../assets/icons/temperature-sensor.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectConfigs } from "../../context/selectors";
import "./styles/index.scss";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationModal, OperationRecap, Tooltip } from "../Generic";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { ROLES } from "../../constants/base";
import { toast } from "react-toastify";
import { setTankSensorData } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { emptyTanks } from "../../context/tanks/tanksSlice";

const Sensor = ({ data, tank_id,  showPlotData = () => null, }) => {
  const [getLabel] = useLabels();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const role = auth?.role || ROLES.GUEST;
  const configs = useSelector(selectConfigs);
  const [isShowInPlot, setIsShowInPlot] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      new_set_point: "",
    },
    mode: "onTouched",
  });

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleFormSubmit = async () => {
    await setNewSetPoint();
    handleCloseModal();
  };

  const enablePlot = () => {
    if (!data?.last_point?.value) return;

    const isEnabled = !isShowInPlot;
    setIsShowInPlot(isEnabled);
    showPlotData(data?.type, !isEnabled);
  };

  const getIcon = () => {
    switch (data?.type) {
      case "TEMPERATURE":
        return temperature_sensor;
      case "HUMIDITY":
        return humidity_sensor;
      default:
        return null;
    }
  };

  const customNewSetPointErrorToastId = "custom-new-set-point-error";

  const setNewSetPoint = async () => {
    const newSetPoint = getValues()?.new_set_point;
    if (!newSetPoint) {
      toast.error(getLabel("toast_newSetPointError", { name: data?.translated_name || "" }), {
        toastId: customNewSetPointErrorToastId,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 4000,
      });
      return;
    } else {
      toast.dismiss(customNewSetPointErrorToastId);
    }

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await setTankSensorData(data?.type, tank_id, newSetPoint, axiosPrivate);

    toast.update(toastId, {
      render: response?.error
        ? getLabel(response?.error)
        : getLabel(response?.success, { name: data?.type || "" }),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && !response.error) {
      dispatch(emptyTanks());
      setIsShowInPlot(false);
    }
  };

  if (!data) return;

  return (
    <>
      {role === ROLES.CLIENT && (
        <ConfirmationModal
          isOpen={isOpen}
          onConfirm={handleFormSubmit}
          onClose={handleCloseModal}
          description={getLabel("modalNewSetPointDescription", {
            name: getLabel(`sensor_${data?.type}`) || "",
          })}
        >
          <OperationRecap
            type={"SENSOR_SETPOINT"}
            control={control}
            register={register}
            errors={errors}
            setPointBoundaries={{ min: data?.min, max: data?.max, default: data?.set_point }}
          />
        </ConfirmationModal>
      )}
      <div
        className={`sensor ${isShowInPlot ? "selected" : ""} ${!data?.last_point?.value ? "no_hover" : ""}`}
        onClick={enablePlot}
      >
        <h5 className="sensor_title">{data?.translated_name}</h5>
        <div className="sensor_data">
          <div className="icon_wrapper">
            {getIcon() && (
              <img
                className="icon"
                src={getIcon()}
                alt={data?.value?.toLowerCase()}
              />
            )}
          </div>
          <div className="sensor_info">
            {!data?.last_point?.value && <div className="values_wrapper">
              <div className="current">
                <p className="actual_time">
                  {getLabel("sensorNoData")}
                </p>
              </div>
            </div>}
            {data?.last_point?.value && <div className="values_wrapper">
              <div className="current">
                <p className="actual_value">
                  {data?.last_point?.value || "-"}{" "}
                  <span className="unit">{data?.unit || ""}</span>
                </p>
                <p className="actual_time">
                  {data?.last_point?.value && data?.last_point?.date
                    ? format(
                        data?.last_point?.date || null,
                        configs.hourMediumDateFormat
                      )
                    : "-"}
                </p>
              </div>
              <Tooltip
                variant={"info"}
                html={(data?.by_user && data?.by_user_time) ? getLabel("sensorModifiedSetPoint", {name: data?.by_user, date: data?.by_user_time}) : ''}
                place="right"
                events={["hover"]}
              >
                <div className="set_point">
                  <p className="set_point_title">
                    {getLabel("sensorSetPoint")}
                  </p>
                  <p className="set_point_value">
                    {data?.set_point || "-"}{" "}
                    <span className="unit">{data?.unit || ""}</span>
                  </p>
                </div>
              </Tooltip>
            </div>}
            {/* <p className="min_max">{getLabel("sensorMinMax")}</p>
          <p>
            {String(data?.min) || "n/a"} - {data?.max || "n/a"} {data?.unit || ""}
          </p> */}
          </div>
          <div className="modify_set_point">
            {role === ROLES.CLIENT && (
              <button
                onClick={(e) => {
                  setIsOpen(true);
                  e.stopPropagation();
                }}
              >
                {getLabel("sensorModifySetPoint")}{" "}
                <FontAwesomeIcon icon="fa-gear" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sensor;
