import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { selectProtocolDisplayGrid } from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import { useNavigate } from "react-router-dom";
import { Switch } from "../FormComponents";
import TableEdit from "../TableEdit";
import "./styles/index.scss";
import { toggleProtocolsDisplayGrid } from "../../context/configs/configsSlice";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import WineMakingProtocolTile from "./WineMakingProtocolTile";

const WineMakingProtocolList = (props) => {
  const { type, protocols, users, configs, isMobile } = props;
  const displayModeGrid = useSelector(selectProtocolDisplayGrid);
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      header: getLabel("protocolCode"),
      cell: TableCell,
      meta: {
        type: "text",
        isFilterDisabled: true,
      },
    }),
    columnHelper.accessor("name", {
      header: getLabel("protocolName"),
      cell: TableCell,
      meta: {
        type: "text",
        filterVariant: "text",
      },
    }),
    columnHelper.accessor(
      (row) =>
        row?.user_id ? users?.find((u) => u.id == row?.user_id)?.username : "",
      {
        header: getLabel("CLIENT"),
        cell: TableCell,
        meta: {
          type: "text",
          filterVariant: "select",
        },
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.insertion_date
          ? format(new Date(row.insertion_date), configs.shortDateFormat)
          : "",
      {
        header: getLabel("protocolCreationDate"),
        cell: TableCell,
        meta: {
          type: "text",
          isFilterDisabled: true,
        },
      }
    ),
    columnHelper.accessor(
        (row) =>
          row?.update_date
            ? format(new Date(row.update_date), configs.shortDateFormat)
            : "",
        {
          header: getLabel("protocolUpdateDate"),
          cell: TableCell,
          meta: {
            type: "text",
            isFilterDisabled: true,
          },
        }
      ),
      columnHelper.accessor("notes", {
        header: getLabel("protocolNotes"),
        cell: TableCell,
        meta: {
          type: "text",
          isFilterDisabled: true,
        },
      }),
  ];

  const toggleDisplayMode = () => {
    dispatch(toggleProtocolsDisplayGrid());
  };

  const renderList = () => {
    if (protocols?.length > 0) {
      return (
        <div className="protocols_list_table">
          <TableEdit
            defaultData={protocols}
            columns={columns}
            rowClickHandler={(row) => {
              navigate(`/protocols/winemaking/${type}/${row.original.id}`);
            }}
            type={type}
            isEditable={false}
          />
        </div>
      );
    }
  };

  const renderGrid = () => {
    if (protocols?.length > 0) {
      return (
        <div className="protocols_grid">
          {protocols?.filter((p) => p?.protocol_type === type)?.map((protocol) => {
            return <WineMakingProtocolTile {...protocol} configs={configs} type={type}/>;
          })}
        </div>
      );
    }
  };

  return (
    <div className="protocols_list">
      {!isMobile && protocols?.filter((p) => p?.protocol_type === type)?.length > 0 && <Switch
        leftOption={{
          label: getLabel("listView"),
        }}
        rightOption={{
          label: getLabel("gridView"),
        }}
        toggleHandler={toggleDisplayMode}
        status={displayModeGrid}
      />}
      {isMobile ? renderList() : displayModeGrid ? renderGrid() : renderList()}
      {!protocols ||
        (protocols?.filter((p) => p?.protocol_type === type)?.length === 0 && (
          <>
            <div className="protocols_alert">
              <h6 className="protocols_alert_title">
                {getLabel("noProtocolsAlert", { type: getLabel(type === 'PRE' ? 'winemaking' : 'postWinemaking') })}
              </h6>
            </div>
          </>
        ))}
    </div>
  );
};

export default WineMakingProtocolList;
