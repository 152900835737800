import useLabels from "../../hooks/useLabels";
import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Scatter,
  ComposedChart,
  Bar,
  Legend,
} from "recharts";
import './styles/index.scss';
import { useEffect, useState } from "react";
import { format } from "date-fns";

const Chart = ({ dataKey = null, data, borders=[], scatterData = []}) => {
  const [getLabel] = useLabels();
  
  const [plotData, setPlotData] = useState([]);
  const [scatterPlotData, setScatterPlotData] = useState([]);

  useEffect(() => {
    const tmpData = [];
    data?.forEach(element => {
      const obj = {...element};
      obj[dataKey] = parseFloat(element?.[dataKey]);
      if (parseFloat(element?.[dataKey]) >= 0) tmpData.push(obj);
    });
    setPlotData(tmpData);
  }, [data]);

  useEffect(() => {
    const tmpScatterData = [];
    scatterData?.forEach(element => {
      const obj = {...element};
      obj['treatment'] = 5;
      obj['date'] = format(new Date(obj?.completion_date) || null, 'dd/MM/yy HH:mm');
      tmpScatterData.push(obj);
    });
    setScatterPlotData(tmpScatterData);
  }, [scatterData]);
  
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload?.length > 0) {
      const unit = borders?.find((b) => b?.name === payload[0]?.name)?.unit || '';
      return (
        <div className="custom-tooltip">
          <p className="custom-tooltip_label">{`${payload[0]?.name ? getLabel('substance_' + payload[0]?.name) : getLabel('substanceDefault')}: ${payload[0].value} ${unit}`}</p>
          <p className="custom-tooltip_date">{`${payload[0]?.payload?.fullDate || ''}`}</p>
          {/* <p><span className="custom-tooltip_bold">{getLabel('dateCollection')}: </span>{payload[0]?.payload?.collection_date || '-'}</p> */}
          <p className="temp"><span className="custom-tooltip_bold">{getLabel('analysisTemperature')}: </span>{`${payload[0]?.payload?.temperature || '-'} ${'\u00B0'}C`}</p>
          <p className="analyst"><span className="custom-tooltip_bold">{getLabel('analysisAnalyst')}: </span>{`${payload[0]?.payload?.analyst || '-'}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const getYLabel = () => {
    return borders?.find((b) => b?.name === dataKey)?.unit || '';
  }
  
  const renderLineChart2 = () => (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
      <ComposedChart
          width={600}
          height={300}
          // data={[...plotData, ...scatterPlotData]}
          data={plotData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
          <XAxis dataKey={"date"} domain={['auto', 'auto']} padding={{ left:20, right:50 }} xAxisId={1} />
          <XAxis hide={true} xAxisId={2} />
          <YAxis dx={20}>
            <Label
              style={{
                  textAnchor: "middle",
                  fontSize: "130%",
              }}
              angle={270} 
              value={getYLabel()} />
          </YAxis>
          {/* {scatterPlotData?.length > 0 && <Scatter xAxisId={2} dataKey={'treatment'} fill="red" data={scatterPlotData} barSize={2} />} */}
          <Line xAxisId={1} type="monotone" data={plotData} dataKey={dataKey} stroke="#64242E" strokeWidth="2" orientation="left"/>
          <Tooltip content={<CustomTooltip />} />
          {/* <Tooltip /> */}
          {<ReferenceLine xAxisId={1} y={borders?.find((b) => b.name === dataKey)?.min_range} stroke="hsl(351, 47%, 50%)">
            <Label style={{ fill: 'hsl(351, 47%, 50%)' }} position={'insideBottomRight'}>{getLabel('substanceMinRange')}</Label>
          </ReferenceLine>}
          {<ReferenceLine xAxisId={1} y={borders?.find((b) => b.name === dataKey)?.max_range} stroke="hsl(351, 47%, 50%)">
            <Label style={{ fill: 'hsl(351, 47%, 50%)' }} position={'insideTopRight'}>{getLabel('substanceMaxRange')}</Label>
          </ReferenceLine>}
          {/* <Legend /> */}
      </ComposedChart>
      </ResponsiveContainer>
    </div>
  );

  const renderLineChart = () => (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
      <ComposedChart
          width={600}
          height={300}
          // data={[...plotData, ...scatterPlotData]}
          data={plotData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
          <XAxis dataKey={"date"} domain={['auto', 'auto']} padding={{ left:20, right:50 }} />
          {/* <XAxis hide={true} xAxisId={2} /> */}
          <YAxis dx={20}>
            <Label
              style={{
                  textAnchor: "middle",
                  fontSize: "130%",
              }}
              angle={270} 
              value={getYLabel()} />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          {scatterPlotData?.length > 0 && <Scatter dataKey={'treatment'} fill="red" data={scatterPlotData} barSize={2} />}
          <Line  type="monotone" data={plotData} dataKey={dataKey} stroke="#64242E" strokeWidth="2" orientation="left"/>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
  
  return <div>{dataKey !== null && renderLineChart()}</div>;
};

export default Chart;
