import { Outlet } from "react-router-dom"
import './styles/index.scss'

const Layout = () => {
  return (
    <div className="main_container">
      <Outlet />
    </div>
  )
}

export default Layout