import { createSlice } from "@reduxjs/toolkit";

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: [],
    reducers: {
        empty: state => {
            return []
        },
        setClients: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        update: (state, action) => {
            const foundedIndex = state.findIndex((client) => client.id === action.payload?.id);
            if (foundedIndex !== -1) {
                state[foundedIndex] = action.payload
            }
            return state
        },
        remove: (state, action) => {
            const foundedIndex = state.findIndex((client) => client.id === action.payload?.id);
            if (foundedIndex) {
                return state.splice(foundedIndex, 1)
            }
            return state
        }
    }
});

export const {
    setClients, 
    empty,
    update,
    remove
} = clientsSlice.actions;

export default clientsSlice.reducer