import { useSelector } from "react-redux"

const useError = (locale) => {

    const errorsData = useSelector(state => state.labels?.[locale]?.['errors']);

    const getErrorFromCode = (code) => {

    }

    const mapErrorFromStatus = (status) => {
        switch (status) {
            case 400:
                return 'CLT_400'
            case 401:
                return 'CLT_401'
            default:
                return 'COM_000'
        }
    }

    const getError = (response) => {
        if (response?.errorCode) {
            return {
                code: response.errorCode,
                error: errorsData?.[response.errorCode] || errorsData?.generic || ''
            }
        }
        if (response?.status) {
            const code = mapErrorFromStatus(response?.status)
            return {
                code: code,
                error: errorsData?.[code] || errorsData?.generic || ''
            }
        }
        return {
            code: 'COM_000',
            error: errorsData?.generic || 'GENERIC ERROR'
        }
    }

    return {
        getError
    }
}

export default useError