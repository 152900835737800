import { isBefore } from "date-fns";
import { WINERIES, TANKS, LOTS, OPERATIONS, USERS, USER, PROTOCOLS, DRUGS, ANALYSIS, CLIENTS, TRACKING, UTILS, UNITS, PDF, RECURRENT, RESULTS, HISTORY, COUNT, UPDATE, NEW_PASSWORD, SETTINGS, EMAIL, WINELABELS, LABELS, VARIETIES, EXPIRED, NOTE, DICTIONARY, WINEMAKING, APPLY, PERMISSIONS, EXCEL, QR, SENSORS } from "../constants/apis"

const getWineries = async (axiosInstance, jwtToken) => {
   try {
       const response = await axiosInstance.get(
       WINERIES,
       {
           withCredentials: true,
           headers: {
            ...(jwtToken && {Authorization: `Bearer ${jwtToken}`}) 
           }
       }
      );
      return response?.data || [] 
   } catch (e) {
    console.error(e)
    return []
   }
    
}

const getTanks = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${TANKS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        const filteredData = id ? response?.data?.filter?.((tank) => {
            return tank.winery_id === id
        }) : response?.data
        return filteredData
    } catch (e) {
        console.error(e);
        return []
    }
}

const getSensors = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${UTILS}${SENSORS}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || [];
    } catch (e) {
        console.error(e);
        return []
    }
}

const getTankQR = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${TANKS}${QR}/${id}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        return response?.data || [];
    } catch (e) {
        console.error(e);
        return []
    }
}

const getTankSensorData = async (type, id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${TANKS}${SENSORS}/${type}/${id}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        return response?.data || {};
    } catch (e) {
        console.error(e);
        return {}
    }
}

const setTankSensorData = async (type, id, new_set_point, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.put(
        `${TANKS}${SENSORS}/${type}/${id}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            },
            params: {
                new_set_point: new_set_point
            }
        }
        );
        return response || [];
    } catch (e) {
        console.error(e);
        return e.response?.data || null;
    }
}

const getWineLabels = async (axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${WINELABELS}${LABELS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        return response?.data;
    } catch (e) {
        console.error(e);
        return []
    }
}
const getWineVarieties = async (axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${WINELABELS}${VARIETIES}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        return response?.data;
    } catch (e) {
        console.error(e);
        return []
    }
}

const getOperations = async (axiosInstance, isCompleted = false, user_id = null, completion_date = new Date(), jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    ...(isCompleted === false ? { completion_date: '' } : { completion_date })
                },
            }
        );
            return (user_id === null) ? response?.data : response?.data.filter((op) => op.cellarman_ids.includes(user_id)) || []; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getExpiredOperations = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}${EXPIRED}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
            }
        );
            const filteredData = id ? response?.data?.filter?.((op) => {
                return op.winery_id === id
            }) : response?.data
            return filteredData || []; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getCompletedOperationsWithPaging = async (axiosInstance, start_completion_date = new Date(), end_completion_date = new Date(), limit = 50, offset = 0, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}${HISTORY}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    start_completion_date,
                    end_completion_date,
                    limit, 
                    offset,
                },
            }
        );
            return response?.data; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getCompletedOperationsCount = async (axiosInstance, start_completion_date = new Date(), end_completion_date = new Date(), jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}${HISTORY}${COUNT}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    start_completion_date,
                    end_completion_date,
                },
            }
        );
            return response?.data; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getOperationById = async (axiosInstance, id, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
            }
        );
            return response?.data; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getOperationsByMainId = async (axiosInstance, main_id, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}${RECURRENT}/${main_id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
            }
        );
            return response?.data; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getRepetitiveOperationsById = async (main_ids = [], axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${OPERATIONS}${RECURRENT}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    main_ids: main_ids
                }
            }
        );
            return response?.data || []; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getLotTracking = async (axiosInstance, id, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${LOTS}${TRACKING}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
            }
        );
            return response?.data || []; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getTankTracking = async (axiosInstance, id, jwtToken) => {
    try {
        const response = await axiosInstance.get(
            `${TANKS}${TRACKING}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
            }
        );
            return response?.data || []; 
        } catch (e) {
            console.error(e)
            return []
        }
}

const getLots = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${LOTS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        const filteredData = id ? response?.data?.filter?.((tank) => {
            return tank.winery_id === id
        }) : response?.data
        return filteredData
    } catch (e) {
        console.error(e);
        return []
    }
}

const getOperationByWineryId = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await getOperations(axiosInstance, jwtToken);
        const filteredByWineryData = id ? response?.filter?.((operation) => {
            return operation?.winery_id === id
        }) : response?.data;
        return filteredByWineryData
    } catch (e) {
        console.error(e)
        return []
    }
}

const getNextOperations = async (id, axiosInstance, jwtToken) => {
    try {
        const filteredByWineryData = await getOperationByWineryId(id, axiosInstance, jwtToken)
        const filteredByExpireDates = filteredByWineryData?.filter((operation) => {
            return isBefore(new Date(), new Date(operation?.expire_date)) && !operation.completaion_date 
        });
        return filteredByExpireDates
    } catch (e) {
        console.error(e);
        return []
    }
}

const getNextOperationsByTankId = async (wineryId, tankId, axiosInstance, jwtToken) => {
    try {
        const filteredByWineryData = await getOperationByWineryId(wineryId, axiosInstance, jwtToken);
        const filteredByTankId = filteredByWineryData?.filter((operation) => {
            return operation?.src_tanks?.some?.((tank) => (tank?.tank_id === tankId)) 
            || operation?.dest_tanks?.some?.((tank) => (tank?.tank_id === tankId))
        });
        return filteredByTankId
    } catch (e) {
        console.error(e)
        return []
    }
}

const newUser = async (username, data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${USERS}${USER}/${username}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const newTank = async (data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${TANKS}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateTank = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${TANKS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const newOperation = async (data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${OPERATIONS}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateOperation = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${OPERATIONS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const newWinery = async (data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${WINERIES}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateWinery = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.put(
            `${WINERIES}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const deleteWinery = async (id, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${WINERIES}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const getProtocols = async (type, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${PROTOCOLS}/${type}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        return response?.data;
    } catch (e) {
        console.error(e);
        return []
    }
}

const deleteProtocol = async (id, type, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${PROTOCOLS}/${type}/${id}`,
                {
                    id: id, 
                    type: type
                },
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const getUsers = async (axiosInstance, id, userName, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${USERS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        
        const filteredData = userName !== null ? response?.data?.filter?.((user) => {
            return user.username !== userName
        }) : id ? response?.data?.filter?.((user) => {
            return user.id === Number(id)
        }) : response?.data

        return filteredData
    } catch (e) {
        console.error(e);
        return []
    }
}

const deleteUser = async (username, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${USERS}${USER}/${username}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    }  catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const getDrugs = async (axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${DRUGS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        const filteredData = response?.data ? response?.data?.map?.((substance) => {
            return { value: substance.id, label: substance?.name, ...substance };
        }) : []
        return filteredData;
    } catch (e) {
        console.error(e);
        return []
    }
}

const deleteDrug = async (id, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${DRUGS}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const deleteTank = async (id, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${TANKS}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const deleteLot = async (id, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${LOTS}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const deleteOperation = async (id, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.delete(
            `${OPERATIONS}/${id}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const completeOperation = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.post(
            `${OPERATIONS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const addOperationNote = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.post(
            `${OPERATIONS}${NOTE}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const addAnalysisSample = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.post(
            `${PROTOCOLS}${ANALYSIS}${RESULTS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const getExams = async (axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${ANALYSIS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        const filteredData = response?.data ? response?.data?.map?.((substance) => {
            return { value: substance.id, label: substance.name, ...substance };
        }) : []
        return filteredData
    } catch (e) {
        console.error(e);
        return []
    }
}

const getUnits = async (axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${UTILS}${UNITS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        const filteredData = response?.data ? response?.data?.map?.((unit) => {
            return { value: unit.id, label: unit?.unit, ...unit };
        }) : []
        return filteredData;
    } catch (e) {
        console.error(e);
        return []
    }
}

const getClients = async (id, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${CLIENTS}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            }
        }
        );
        const filteredData = id ? response?.data?.filter?.((client) => {
            return client.id === id
        }) : response?.data

        return filteredData
    } catch (e) {
        console.error(e);
        return []
    }
}

const updateClient = async (username, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${CLIENTS}${CLIENTS}/${username}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const newClient = async (username, data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${CLIENTS}${CLIENTS}/${username}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}


const getAnalysisResults = async (operation_ids = [], axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${PROTOCOLS}${ANALYSIS}${RESULTS}`,
        
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
            },
            params: {
                operation_ids
            }
        }
        );
        
        return response?.data || [];
    } catch (e) {
        console.error(e);
        return []
    }
}

const newLot = async (data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${LOTS}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateLot = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${LOTS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const getPDF = async (data, axiosInstance, type, file_type, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${UTILS}${file_type === 'EXCEL' ? EXCEL : PDF}`,
            data,
            {            
                responseType: 'blob',
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        ).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${type || 'programming'}_${new Date().toLocaleDateString("it-IT")}.${file_type === 'EXCEL' ? 'xlsx' : 'pdf'}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });;
        return response?.data || null 
    } catch (e) {
        console.error(e)
        return null
    }
}

const newProtocol = async (type, data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${PROTOCOLS}/${type}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateProtocol = async (id, type, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${PROTOCOLS}/${type}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const newDrug = async (data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${DRUGS}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateDrug = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${DRUGS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateUserPassword = async (oldPassword, password, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${USERS}${UPDATE}${NEW_PASSWORD}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    ...(oldPassword ? { oldPassword } : { oldPassword: '' }),
                    ...(password ? { password } : { password: '' }),
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateUserEmail = async (email, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${USERS}${UPDATE}${EMAIL}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    ...(email ? { email } : { email: '' }),
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateUserSetting = async (key, value, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${USERS}${UPDATE}${SETTINGS}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    ...(key && value ? { key: key, value: value } : { key: '', value: '' }),
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateSubtypePermissions = async (subtype, prop, value, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${USERS}${UPDATE}${PERMISSIONS}`,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                },
                params: {
                    ...(subtype && { subtype: subtype, prop: prop, value: value }),
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateUser = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${USERS}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const getLabelByArea = async (area, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.get(
        `${DICTIONARY}/${area}`,
        {
            withCredentials: true,
            headers: {
                ...(jwtToken && {Authorization: `Bearer ${jwtToken}`}),
                'Accept-Language': 'it',//userLang
            },
        }
        );
        return response?.data || [];
    } catch (e) {
        console.error(e);
        return []
    }
}

const applyWorkingProtocolOperation = async (data, axiosInstance, jwtToken) => {
    try {
        const response = await axiosInstance.post(
            `${PROTOCOLS}${WINEMAKING}${APPLY}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && {Authorization: `Bearer ${jwtToken}`})
                }
            }
        );
        return response?.data || null 
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}

const updateWorkingProtocol = async (id, data, axiosInstance, jwtToken) => {
    try {
        const response  = await axiosInstance.put(
            `${PROTOCOLS}${WINEMAKING}/${id}`,
            data,
            {
                withCredentials: true,
                headers: {
                    ...(jwtToken && { Authorization: `Bearer ${jwtToken}` })
                }
            }
        );
        return response?.data || null
    } catch (e) {
        console.error(e);
        return e.response?.data || null
    }
}


export {
    getWineries,
    newWinery,
    updateWinery,
    deleteWinery,
    getTanks,
    getSensors,
    getTankQR,
    getTankSensorData,
    setTankSensorData,
    getWineLabels,
    getWineVarieties,
    newTank,
    updateTank,
    deleteTank,
    getOperations,
    getExpiredOperations,
    getOperationByWineryId,
    getNextOperationsByTankId,
    getNextOperations,
    addOperationNote,
    getUsers,
    newUser,
    deleteUser,
    getLots,
    newLot,
    updateLot,
    deleteLot,
    getProtocols,
    getDrugs,
    getExams,
    getClients,
    updateClient,
    newClient,
    newProtocol,
    updateProtocol,
    deleteProtocol,
    newDrug,
    deleteDrug,
    updateDrug,
    newOperation,
    updateOperation,
    deleteOperation,
    completeOperation,
    addAnalysisSample,
    getPDF,
    getUnits,
    getLotTracking,
    getAnalysisResults,
    getRepetitiveOperationsById,
    getOperationById,
    getOperationsByMainId,
    getTankTracking,
    getCompletedOperationsWithPaging,
    getCompletedOperationsCount,
    updateUserPassword,
    updateUserEmail,
    updateUser,
    updateSubtypePermissions,
    updateUserSetting,
    applyWorkingProtocolOperation,
    updateWorkingProtocol,
    getLabelByArea,
}