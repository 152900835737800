import { useSelector } from "react-redux";
import axios from "../api/axios";
// import { getItem } from "../services/LocalStorage";
import { getItem } from "../services/SessionStorage";
import useAuth from "./useAuth";
import { selectActiveWinery } from "../context/selectors";
import jwt_decode from "jwt-decode";

const useRefreshToken = () => {

  const { setAuth } = useAuth();
  const activeWinery = useSelector(selectActiveWinery);

  const refresh = async () => {
    const authData = getItem('authToken');
    // called when tour initial req failed due to expired access token, then we can retry the initial request
    const response = await axios.get("/refresh", {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${authData?.accessToken}`,
        ...(activeWinery && {clientname: activeWinery?.client_name})
      },
    });
    const decoded = response?.data?.accessToken ? jwt_decode(response.data.accessToken) : undefined;
    const data = {};
    if (Object.keys(decoded?.UserInfo).length > 0) {
      data.accessToken = response.data.accessToken || '';
      data.role = decoded?.UserInfo?.role || '';
      data.client_name = decoded?.UserInfo?.client_name || '';
      data.user_id = decoded?.UserInfo?.id || '';
      data.username = decoded?.UserInfo?.username || '';
      data.password_expire_date = decoded?.UserInfo?.password_expire_date || ''
    }
    setAuth(data);
    return response.data.accessToken; // override the old access token
  };
  return refresh;
};

export default useRefreshToken;
