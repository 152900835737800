import { Slider } from "../FormComponents";

const TankStateCell = ({ getValue, row, column, table }) => {
  return (
        <Slider
            isText={true}
            name={`slider.${row.id}.capacity`}
            label={'getLabel("destemmingQuantityPercent")'}
            defaultValue={`${Math.round(Number(row?.original?.quantity) * 100 / row?.original?.capacity)}`}
            additional={{quantity: Number(row?.original?.quantity), color: row?.original?.color?.toLowerCase() || 'red'}}
        />)

    {/* <div className="tank_percentage">{Math.round(Number(row?.original?.quantity) * 100 / row?.original?.capacity)}% ({row?.original?.quantity || 0}L)</div> */}
}

export default TankStateCell;