import { NavLink } from "react-router-dom"
import useLabels from "../../hooks/useLabels"
import SecondaryBar from "../SecondaryBar"
import { CTA } from "../Generic"
import { WineriesSelector } from "../WineriesList"
import UserList from "../UserList"
import useAuth from "../../hooks/useAuth"
import { ROLES } from "../../constants/base"
import PermissionList from "../PermissionList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Wineries = () => {

    const [getLabel] = useLabels()
    const { auth } = useAuth();
    const role = auth?.role || ROLES.GUEST;

    const renderWineryNavMenu = () => {
        return (
            <div className="primary_container_menu">
                <h2>{getLabel('wineries')}</h2>
                {role === ROLES.CLIENT && <NavLink to='/wineries/new'>
                    <CTA customClassName={'create'}>
                        {getLabel('createWinery')}
                        {" "}<FontAwesomeIcon icon="fa-plus" />
                    </CTA>
                </NavLink>}
            </div>
        )
    }

    const renderUserNavMenu = () => {
        return (
            <div className="primary_container_menu">
                <h2>{getLabel('users')}</h2>
                {role === ROLES.CLIENT && <NavLink to='/users/new'>
                    <CTA customClassName={'create'}>
                        {getLabel('createUser')}
                        {" "}<FontAwesomeIcon icon="fa-plus" />
                    </CTA>
                </NavLink>}
            </div>
        )
    }

    const renderPermissionNavMenu = () => {
        return (
            <div className="primary_container_menu">
                <h2>{getLabel('userPermissions')}</h2>
            </div>
        )
    }

    return (<div className="primary_container">
        <SecondaryBar
            breadCrumb={[
                getLabel('wineriesNavLink')
            ]}
            isBasePathNeeded={false}
        />
        {renderWineryNavMenu()}
        <WineriesSelector />
        <br></br>
        {renderUserNavMenu()}
        <UserList/>
        <br></br>
        {renderPermissionNavMenu()}
        <PermissionList/>
    </div>)
}

export default Wineries