import { createSlice } from "@reduxjs/toolkit";

export const wineLabelsSlice = createSlice({
    name: 'wineLabels',
    initialState: [],
    reducers: {
        emptyWineLabels: state => {
            return []
        },
        setWineLabels: (state, action) => {
            // if (Array.isArray(action?.payload)) {
                return action.payload
            // }
        },
    }
});

export const {
    setWineLabels, 
    emptyWineLabels,
} = wineLabelsSlice.actions;

export default wineLabelsSlice.reducer