import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  DatePicker,
  Input,
  MultipleSelect,
  Select,
  Slider,
  Submit,
  TextArea,
} from "../FormComponents";
import useLabels from "../../hooks/useLabels";
import { empty, setOperations } from "../../context/operations/operationsSlice";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  applyWorkingProtocolOperation,
  getOperations,
  getProtocols,
  newOperation,
  updateOperation,
  updateWorkingProtocol,
} from "../../services/utils";
import { TankIcon, WineMakingProtocolRowModal } from "../Generic";
import { INT_REGEX } from "../../constants/regex";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { selectOperations, selectWineMaking } from "../../context/selectors";
import { addMinutes } from "date-fns";
import { setWineMaking } from "../../context/protocols/wineMakingSlice";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import EditCell from "../TableEdit/EditCell";
import TransferForm from "./TransferForm";

const WineMakingForm = ({
  operation,
  lots,
  activeWinery,
  tanks,
  users,
  configs,
  type,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const operations = useSelector(selectOperations);
  const protocols = useSelector(selectWineMaking);
  const pType = type === 'WINEMAKINGPOST' ? 'POST' : 'PRE';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModifyMode, setIsModifyMode] = useState(-1);
  
  const setModifyMode = (row_id) => {
    setIsModifyMode(row_id);
  };
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const setManualOperation = () => {
    console.log(getValues());
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      dest_tanks: operation?.dest_tanks || [
        { tankData: { value: null, label: null } },
      ],
      priority: operation?.priority || "STANDARD",
      expire_date: operation?.expire_date || null,
      type: { type },
      tank_id_in: operation?.tank_id_in || "",
      cellarman_ids: operation?.cellarman_ids || "",
      new_batch_name: operation?.new_batch_name || "",
    },
    mode: "onTouched",
  });

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.day + (row?.toDay ? ` - ${row?.toDay}` : ""), {
      header: getLabel("protocolDay"),
      cell: TableCell,
      meta: {
        type: "number",
        register: register,
      },
    }),
    columnHelper.accessor(
      (row) => (row?.phase ? getLabel(`protocolPhase_${row.phase}`) : ""),
      {
        header: getLabel("protocolPhase"),
        cell: TableCell,
      }
    ),
    columnHelper.accessor(
      (row) =>
        row?.operation ? getLabel(`protocolOperation_${row.operation}`) : "",
      {
        header: getLabel("protocolOperation"),
        cell: TableCell,
        meta: {
          type: "text",
          register: register,
        },
      }
    ),
    columnHelper.accessor((row) => row?.product_name || "", {
      header: getLabel("protocolProduct"),
      cell: TableCell,
      meta: {
        type: "text",
        register: register,
      },
    }),
    columnHelper.accessor(
      (row) =>
        row?.quantity
          ? row?.protocolType === "HOMOGENIZATION_TEMP" ? `${row?.quantity} \u00b0C` : (row?.quantity || 0) +
            " " +
            (row?.product?.unit || row?.product_unit || "")
            + (row?.protocolType === 'MICRO' ? 
                getLabel('protocolOxygenationMonth') : row?.protocolType === 'MACRO' ? 
                getLabel('protocolOxygenationDay') : row?.protocolType === 'CUSTOM' ?
                `/${row?.oxygenationDoseHours}h:${row?.oxygenationDoseMinutes}m` : '')
          : "",
      {
        header: getLabel("protocolQuantity"),
        cell: TableCell,
        meta: {
          type: "number",
          register: register,
        },
      }
    ),
    // columnHelper.accessor("unit_id", {
    //   header: getLabel("unit"),
    //   cell: TableCell,
    //   meta: {
    //     type: "text",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor("cost", {
    //   header: getLabel("protocolCost"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //   },
    // }),
    columnHelper.accessor(
      (row) =>
        row?.protocolType ? getLabel(`protocolType_${row?.protocolType}`) : "",
      {
        header: getLabel("protocolType"),
        cell: TableCell,
        meta: {
          type: "text",
          register: register,
          // placeholder: getLabel("lotColorPlaceholder"),
        },
      }
    ),
    // columnHelper.accessor((row) => row?.workTime ? (row?.workTime + ' min') : '', {
    //   header: getLabel("protocolWorkTime"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor((row) => row?.homogenizationPauseTime ? (row?.homogenizationPauseTime + ' h') : '', {
    //   header: getLabel("protocolPauseTime"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor((row) => row?.homogenizationMotorSpeed ? (row?.homogenizationMotorSpeed + ' %') : '', {
    //   header: getLabel("protocolMotorSpeed"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor("iterPerDay", {
    //   header: getLabel("protocolIterationPerDay"),
    //   cell: TableCell,
    //   meta: {
    //     type: "number",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor("oxygenationType", {
    //   header: getLabel("protocolOxygenationType"),
    //   cell: TableCell,
    //   meta: {
    //     type: "text",
    //     register: register,
    //   },
    // }),
    // columnHelper.accessor("goal", {
    //   header: getLabel("protocolGoals"),
    //   cell: TableCell,
    // }),

    // columnHelper.accessor("note", {
    //   header: getLabel("protocolNotes"),
    //   cell: TableCell,
    // }),
    columnHelper.display({
      id: "edit",
      cell: EditCell,
      meta: {
        isEditable: true,
        isOnlyManual: true,
      },
    }),
  ];
  
  const { dest_tanks, protocol_id } = useWatch({ control });

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "dest_tanks",
    });

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  const loadProtocols = async () => {
    const loadedProtocols = await getProtocols("winemaking", axiosPrivate);
    dispatch(setWineMaking(loadedProtocols));
  };

  useEffect(() => {
    if (!protocols || protocols?.length === 0) loadProtocols();
    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });
      const dests = [];
      operation?.dest_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === s.tank_id);
        dests.push({
          tankData: {
            value: tank?.id,
            label: tank?.name,
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            availability: Number(tank?.capacity) - Number(tank?.quantity),
            color: tank?.color,
            lot:
              lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
              getLabel("tankState_EMPTY"),
          },
        });
      });
      replace(dests);

      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      setValue("expire_date", operation?.expire_date);

      setValue("note", operation?.note);

      if (lots?.find((l) => l.name === operation?.batch_name_out)) {
        setValue("batch_name_selector", {
          value: false,
          label: operation?.batch_name_out,
        });
        setValue("new_batch_name", "");
      } else {
        setValue("batch_name_selector", {
          value: true,
          label: getLabel("lotNewName"),
        });
      }
    }
  }, [operations, operation, lots]);

  const submitData = async () => {
    const data = getValues();
    if (!data?.batch_name_selector?.value) {
      data.new_batch_name = data?.batch_name_selector?.label;
    }

    const dests = data?.dest_tanks?.map((destT) => {
      return {
        tank_id: destT?.tankData?.value,
      };
    }) || [];

    const requestPayload = {
      tanks: dests,
      priority: data?.priority.value,
      // start_date: addMinutes(
      //   new Date(data?.expire_date)?.setHours(23, 59, 59),
      //   -new Date(data?.expire_date).getTimezoneOffset()
      // ).toISOString(),
      start_date: new Date(data?.expire_date?.setHours(23, 59, 59)).toISOString(),
      type: type,
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      // new_batch_name: data?.new_batch_name,
      // src_tanks: [],
      // note: data?.note,
      // destLen: dests?.length,
      working_protocol_id: data?.protocol_id?.value,
    };
    console.log(requestPayload);
    // return;
    localStorage.setItem('wineMakingData', JSON.stringify(requestPayload));
    // navigate('/programming/DECANT/new')
    // return;
    // const toastId = toast.loading(getLabel("toast_inProgress"), {
    //   type: toast.TYPE.INFO,
    //   position: toast.POSITION.BOTTOM_RIGHT,
    //   exclude: true,
    // });
    // let response = null;

    // if (id) {
      // response = await updateWorkingProtocol(id, requestPayload, axiosPrivate);
    // } else {
    const response = await applyWorkingProtocolOperation(requestPayload, axiosPrivate);
    // }
    console.log(response);
    // return;
    // toast.update(toastId, {
    //   render: response?.error
    //     ? getLabel(response?.error)
    //     : getLabel(response?.success, { type: operation?.type || "" }),
    //   type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
    //   isLoading: false,
    //   position: toast.POSITION.BOTTOM_RIGHT,
    //   autoClose: 4000,
    // });

    if (response && !response.error) {
      dispatch(empty());
      navClickHandler();
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/${type}/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const customId = "custom-id-different-color";
  const checkColors = () => {
    const colors = new Set();
    dest_tanks?.reduce(
      (_, item) =>
        item?.tankData?.color ? colors.add(item?.tankData?.color) : null,
      null
    );
    if (getValues("tank_id_in")?.color)
      colors.add(getValues("tank_id_in")?.color);

    if (colors?.size > 1)
      toast(getLabel("toast_WarningDiffentWineColor"), {
        toastId: customId,
        type: toast.TYPE.WARNING,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      });
    else toast.dismiss(customId);
  };

  return (
    <div className="operation_form_wrapper">
      <form onSubmit={handleSubmit(submitData)} autoComplete="off" noValidate>
        <h6>{getLabel(type === 'WINEMAKINGPOST' ? "wineMakingPostProtocols" : "wineMakingProtocols")}</h6>
        <div className="operation_form_wrapper_row">
          <Select
            label={getLabel("protocolName")}
            placeholder={getLabel("protocolNamePlaceholder")}
            errors={errors[`protocol_id`]}
            required={getLabel("protocolNameRequiredError")}
            name={`protocol_id`}
            control={control}
            options={protocols?.filter((p) => p?.protocol_type === pType)?.map?.((p) => {
              return {
                value: p?.id,
                label: p?.name,
              };
            })}
          />
        </div>
        {getValues("protocol_id")?.value && (
          <div className="operation_form_wrapper_row vertical">
            <TableEdit
              defaultData={
                protocols
                  ?.find((p) => p.id == getValues("protocol_id")?.value)
                  ?.operations?.map((s) => {
                    return {
                      ...s,
                      opType: "WINEMAKINGPROTOCOL",
                    };
                  }) || []
              }
              columns={columns}
              errors={errors}
              control={control}
              isEditable={false}
              isSearchBar={false}
              setValue={setValue}
              type={"winemaking"}
              modalHandler={handleOpenModal}
              setModifyMode={setModifyMode}
            />
          </div>
        )}
        <div className="operation_form_wrapper_row vertical">
          <h6>{getLabel("tanks")}</h6>
          {fields?.map?.((field, idx) => {
            return (
              <div key={field.id} className="operation_form_wrapper_row">
                <Select
                  label={getLabel("operationDestTankName")}
                  placeholder={getLabel("operationWineMakingDestTanksPlaceholder")}
                  error={errors}
                  required={getLabel("inputRequiredError")}
                  name={`dest_tanks.${idx}.tankData`}
                  control={control}
                  options={tanks
                    ?.filter(
                      (tank) => Number(tank?.quantity) > 0 &&
                        !dest_tanks?.some?.(
                          (field) => field.tankData.value == tank.id
                        )
                    )
                    ?.map?.((tank) => {
                      return {
                        value: tank?.id,
                        label: tank?.name,
                        name:
                          tank?.name +
                          ` (${
                            lots?.find((lot) => lot?.id === tank?.batch_id)
                              ?.name || getLabel("tankState_EMPTY")
                          })`,
                        capacity: Number(tank?.capacity),
                        quantity: Number(tank?.quantity),
                        availability:
                          Number(tank?.capacity) - Number(tank?.quantity),
                        color: tank?.color,
                        lot: tank.batch_name,
                      };
                    })}
                  onTableChange={checkColors(idx)}
                />
                {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                  <TankIcon
                    tank={getValues(`dest_tanks.${idx}.tankData`)}
                    isText={true}
                    total={null}
                    isDestemming={false}
                    isSrc={false}
                  />
                )}
                {fields?.length > 1 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      remove(idx);
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            );
          })}
          <button
            id="tanksD"
            onClick={(e) => {
              e.preventDefault();
              append({
                tankData: {},
              });
            }}
          >
            +
          </button>
        </div>
        <div className="operation_form_wrapper_row">
          <MultipleSelect
            name={`cellarman_ids`}
            control={control}
            label={getLabel("operationUser")}
            placeholder={getLabel("operationUserPlaceholder")}
            error={errors["cellarman_ids"]}
            required={getLabel("inputRequiredError")}
            options={users?.map?.((user) => {
              return {
                value: user?.id,
                label: user?.username,
              };
            })}
          />
          <Select
            name={"priority"}
            control={control}
            label={getLabel("operationPriority")}
            placeholder={getLabel("operationPriorityPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("lotQuality_HIGH"),
                value: 2,
              },
              {
                label: getLabel("lotQuality_MEDIUM"),
                value: 1,
              },
              {
                label: getLabel("lotQuality_LOW"),
                value: 0,
              },
            ]}
            // defaultValue={{
            //     value: "",
            //     label: "",
            //   }}
          />
          <DatePicker
            name="expire_date"
            control={control}
            label={getLabel("operationProtocolStartDateForm")}
            error={errors["expire_date"]}
            required={getLabel("inputRequiredError")}
            dateFormat={configs.shortDateFormat}
            placeholder={getLabel("expiringDatePlaceholder")}
          />
        </div>
        {/* <div className="operation_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div> */}
        <div className="operation_form_wrapper_row">
          <Submit label={getLabel("submitForm")}></Submit>
        </div>
      </form>
      <WineMakingProtocolRowModal
          isOpen={isModalOpen}
          onConfirm={setManualOperation}
          onClose={handleCloseModal}
          description={getLabel("modalLogout")}
        >
          <TransferForm
              lots={lots}
              tanks={tanks}
              type={type}
              activeWinery={activeWinery}
              configs={configs}
              isWineMakingApplication={true}
          />
      </WineMakingProtocolRowModal>
    </div>
  );
};

export default WineMakingForm;
