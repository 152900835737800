import { createSlice } from "@reduxjs/toolkit";

export const analysisSlice = createSlice({
    name: 'analysis',
    initialState: [],
    reducers: {
        emptyAnalysis: state => {
            return []
        },
        setAnalysis: (state, action) => {
            if (Array.isArray(action?.payload)) {
                return action.payload
            }
        },
        addAnalysis: (state, action) => {
            // return [...state, ...action.payload]
            return []
        },
        updateAnalysis: (state, action) => {
            return [];
        },
        removeAnalysis: (state, action) => {
            const foundedIndex = state?.findIndex((analysis) => analysis.protocols_id === action?.payload?.id);
            if (foundedIndex !== -1) {
                state = state.filter((a, index) => index !== foundedIndex);
                return state;
            }
            throw new Error('Index to remove not found')
        }
    }
});

export const {
    setAnalysis, 
    emptyAnalysis,
    updateAnalysis,
    removeAnalysis,
    addAnalysis
} = analysisSlice.actions;

export default analysisSlice.reducer