import axios from "../api/axios";
import { clearSession } from "../services/SessionStorage";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    try {
      const response = await axios.get("/auth/logout", {
        withCredentials: true,
      });
      setAuth({});
      clearSession('authToken');
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  return logout;
};
export default useLogout;
