import { useNavigate } from "react-router-dom"
import useLabels from "../../hooks/useLabels"
import user_img from "../../assets/icons/worker.png";

const UserTile = ({
    username,
    client_email,
    type,
    subtype,
    id
}) => {

    const navigate = useNavigate();
    const [getLabel] = useLabels();
    
    return (<div className="user_tile" key={`user-tile-${id}`}
        onClick={() => {navigate(`/users/${id}`)}}
    >
        <div className="user_tile_content">
            <div className="user_tile_content_img_container">
            <img src={user_img} alt="user"/>
            </div>
            <div className="user_tile_content_name">{username}</div>
            {/* <div className="user_tile_content_code">{client_email}</div> */}
        {/* </div> */}
        {/* <div className="user_tile_content"> */}
        {/* <div className="user_tile_content_code">{getLabel('user_type_' + type)}</div> */}
        <div className="user_tile_content_code">{subtype ? getLabel('user_subtype_' + subtype) : getLabel('user_type_' + type)}</div>
        </div>
    </div>)
}

export default UserTile