import { useDispatch, useSelector } from "react-redux"
import { selectClientDisplayGrid, selectClients, selectConfigs } from "../../context/selectors"
import useLabels from "../../hooks/useLabels"
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getClients } from "../../services/utils";
import { setClients } from "../../context/clients/clientsSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../Table";
import ClientTile from "./ClientTile";
import { Switch } from "../FormComponents";

import './styles/index.scss'
import { toggleClientsDisplayGrid } from "../../context/configs/configsSlice";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import TableEdit from "../TableEdit";
import { addMonths, format } from "date-fns";
import { toast } from "react-toastify";
import MultipleRowsCell from "../TableEdit/MultipleRowsCell";

const ClientsColumn = ({
    clients = []
}) => {
    return (<div>
        {clients?.map?.((row, index) => {
            return <div key={index}>{row}</div>
        })}
    </div>)
}

const ClientList = ({

}) => {
    const clients = useSelector(selectClients);
    const configs = useSelector(selectConfigs);
    const displayModeGrid = useSelector(selectClientDisplayGrid);
    const dispatch = useDispatch();
    const [getLabel] = useLabels();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const columns2 = useMemo(() => [
        {
            Header: getLabel('clientUsername'),
            accessor: 'username'
        }, {
            Header: getLabel('clientNames'),
            accessor: (row) => <ClientsColumn clients={row.client_names}/>
        }, {
            Header: getLabel('clientType'),
            accessor: (row) => getLabel(`user_type_${row.type}`)
        }, {
            Header: getLabel('clientEmail'),
            accessor: 'client_email'
        }, {
            Header: getLabel('clientAccountState'),
            accessor: (row) => row?.deactivated ? getLabel('clientDeactivated') : getLabel('clientActivated')
        },
        {
            Header: getLabel("userEmailConfirmed"),
            accessor: 'valid'
        },

    ])
    
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('username', {
            header: getLabel("userName"),
            cell: TableCell,
        }),
        columnHelper.accessor((row) => getLabel(row?.client_names), {
            header: getLabel('clientClientNames'),
            cell: MultipleRowsCell,
            meta: {
                array: "client_names",
                selector: "client_names",
            },
        }),
        columnHelper.accessor("client_email", {
            header: getLabel("userEmail"),
            cell: TableCell,
        }),
        columnHelper.accessor((row) => getLabel(row?.type), {
            header: getLabel('clientType'),
            cell: TableCell,
        }),
        columnHelper.accessor('valid', {
            header: getLabel("userEmailConfirmed"),
            cell: TableCell,
            meta: {
                type: 'email_validity'
            }
        }),
        columnHelper.accessor((row) => `${format(new Date(row?.expire_date), configs.shortDateFormat)} ${row?.role_id === 2 && (new Date(row?.expire_date) <= addMonths(new Date(), 1)) ? '❗' : ""}`, {
            header: getLabel("licensePasswordExpireDate"),
            cell: TableCell,
        }),
    ];

    const toggleDisplayMode = () => {
        dispatch(toggleClientsDisplayGrid())
    }

    const loadClients = async () => {
        const currentClients = await getClients(null, axiosPrivate);
        dispatch(setClients(currentClients))
    }

    useEffect(() => {
        if (!clients || clients.length === 0) {
            loadClients()
        }
    }, []);
    
    const customIdexpiringLicense = "custom-id-expiring-licence";
    useEffect(() => {
        const expiredLicenceClients = clients?.filter((c) => c?.role_id === 2 && new Date(c?.expire_date) <= addMonths(new Date(), 1)) || [];
        console.log(clients, expiredLicenceClients)
        expiredLicenceClients?.forEach((c) => {
            toast(
                getLabel(c?.client_names?.length > 1 ? "toast_clientsExpiringLicence" : "toast_clientExpiringLicence", { name: c?.client_names}),
                {
                toastId: `${customIdexpiringLicense}__${c?.id}`,
                type: toast.TYPE.WARNING,
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        });
    }, [clients]);

    const renderList = () => {
        const filteredClients = clients.reduce((accumulator, current) => {
            if (!accumulator.find((c) => c.username === current.username)) {
              accumulator.push(current);
            }
            return accumulator;
        }, []);
        
        if (filteredClients.length > 0) {
            return (
                <div className="clients_list_table">
                    {/* <Table
                        data={filteredClients}
                        prefix='clients'
                        customColumns={columns}
                        rowClickHandler={(row) => {navigate(`/clients/${row.original.id}`)}}
                    /> */}
                    <TableEdit
                        defaultData={clients}
                        columns={columns}
                        isSearchBar={false}
                        rowClickHandler={(row) => {navigate(`/clients/${row.original.id}`)}}
                        isEditable={false}
                    />
                </div>
            )
        }
    }

    const renderGrid = () => {
        const filteredClients = clients.reduce((accumulator, current) => {
            if (!accumulator.find((c) => c.username === current.username)) {
              accumulator.push(current);
            }
            return accumulator;
        }, []);

        if (filteredClients.length > 0) {
            return (
                <div className="clients_grid">
                    {clients.map((client) => {
                        return <ClientTile {...client}/>
                    })}
                </div>
            )
            
        }
    }

    return <div className="clients_list">
    <Switch
        leftOption={{
            label: getLabel('listView')
        }}
        rightOption={{
            label: getLabel('gridView')
        }}
        toggleHandler={toggleDisplayMode}
        status={displayModeGrid}
    />
        {displayModeGrid ? renderGrid() : renderList()}
    </div>


}

export default ClientList