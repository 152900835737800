import jwtDecode from "jwt-decode";
import { createContext, useEffect, useState } from "react";
// import { getItem, setItem } from '../services/LocalStorage';
// import { getItem } from '../services/LocalStorage';
import { getItem, setItem } from "../services/SessionStorage";
import { useDispatch } from "react-redux";
import { setUser } from "./user/userSlice";
import { setSettings, setArea } from "../context/configs/configsSlice";
import { isAfter } from "date-fns";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const getSessionToken = () => {
    try {
      const authData = getItem("authToken");
      const decoded = jwtDecode(authData?.accessToken || undefined);
      
      if (
        isAfter(new Date((decoded?.exp || 0) * 1000), new Date()) &&
        decoded?.UserInfo?.role
      ) {
        return { authData, decoded };
      }
      return {};
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  const dispatch = useDispatch();
  const [auth, setToken] = useState(getSessionToken()?.authData || {});

  useEffect(() => {
    const { authData, decoded } = getSessionToken();
    if (authData) {
      dispatch(setArea({ area: "private" }));
      setToken(authData);
      dispatch(setUser(decoded));
      dispatch(setSettings(decoded?.UserInfo?.settings || {}));
    }
  }, []);

  const setAuth = (value) => {
    setToken(value);
    const decoded = jwtDecode(value.accessToken);
    dispatch(setArea({ area: "private" }));
    dispatch(setUser(decoded));
    dispatch(setSettings(decoded?.UserInfo?.settings || {}));
    setItem("authToken", value);
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
