import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { Button } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectClients, selectConfigs } from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { getClients, newClient, updateClient } from "../../services/utils";
import { empty, setClients } from "../../context/clients/clientsSlice";
import { DatePicker, Input, Submit, Label } from "../FormComponents";
import { addMonths } from "date-fns";
import { toast } from "react-toastify";
import { EMAIL_REGEX, NAME_REGEX, USER_REGEX } from "../../constants/regex";

const ClientForm = ({}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const configs = useSelector(selectConfigs);
  const clients = useSelector(selectClients);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [client, setClient] = useState(
    clients?.length > 0
      ? clients.find((dbClient) => dbClient.id === Number(id)) || {}
      : {}
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      username: client?.username || "",
      name: client?.name || "",
      surname: client?.surname || "",
      clientEmail: client?.client_email || "",
      clientType: client?.clientType || "",
      clientAccountState: client?.deactivated || "",
      passwordExpireDate: client?.password_expire_date || "",
      usersNumber: client?.users_number || 0,
      wineriesNumber: client?.wineries_number || 0,
      licensePasswordExpireDate: client?.expire_date || "",
      clientNames: [{ name: "" }],
    },
    mode: "onTouched",
  });

  const { clientNames } = useWatch({ control });
  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "clientNames",
  });

  useEffect(() => {
    if (id && (!clients || clients.length === 0)) {
      loadClients();
    }
    if (id && clients.length > 0) {
      const singleClient =
        clients.find((dbClient) => dbClient.id === Number(id)) || null;
      if (singleClient) {
        setClient(singleClient);
        setValue("username", singleClient?.username || "");
        setValue("surname", singleClient?.surname || "");
        setValue("name", singleClient?.name || "");
        setValue("clientEmail", singleClient?.client_email || "");
        setValue("clientType", singleClient?.type || "");
        setValue("clientAccountState", singleClient?.deactivated);
        setValue(
          "passwordExpireDate",
          singleClient?.password_expire_date || ""
        );
        setValue("usersNumber", singleClient?.users_number || 0);
        setValue("wineriesNumber", singleClient?.wineries_number || 0);
        setValue("licensePasswordExpireDate", singleClient?.expire_date || "");

        const cl_names = [];
        singleClient?.client_names?.map((c) => {
          cl_names.push({ name: c });
        });
        replace(cl_names);
      }
    }
  }, []);

  const loadClients = async () => {
    const currentClients = await getClients(null, axiosPrivate);
    dispatch(setClients(currentClients));
  };

  const submitForm = async (data) => {
    let toastId = null;
    const defaultDate = addMonths(new Date(), 12);
    defaultDate.setHours(23, 59, 59);

    const requestPayload = {
      username: data?.username,
      name: data?.name,
      surname: data?.surname,
      email: data?.clientEmail,
      users_number: Number(data?.usersNumber),
      wineries_number: Number(data?.wineriesNumber),
      client_names: data?.clientNames?.map((c) => c?.name),
      expire_date:
        new Date(
          data?.license_expire_date?.setHours(23, 59, 59)
        ).toISOString() || defaultDate?.toISOString(),
    };

    // console.log("requestPayload", requestPayload);
    // return;
    toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id) {
      response = await updateClient(
        data?.username,
        requestPayload,
        axiosPrivate
      );
    } else {
      response = await newClient(data?.username, requestPayload, axiosPrivate);
    }
    // console.log(response);
    toast.update(toastId, {
      render: response?.error
        ? getLabel(response?.error)
        : getLabel(response?.success, { name: data?.name || "" }),
      type: response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && !response.error) {
      updateStore();
      navClickHandler();
    }
  };

  const updateStore = () => {
    dispatch(empty());
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/clients/${id}`);
    } else {
      navigate("/clients");
    }
  };

  const renderNavMenu = () => {
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{getLabel(id ? "clientsUpdatePage" : "clientsNewPage")}</h2>
        </div>
      </div>
    );
  };

  const getError = (idx, data, key) => {
    if (idx === null) return data?.[key] || null;

    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const renderForm = () => {
    return (
      <div className="client_form_wrapper">
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
          <div className="client_form_wrapper_row">
            {id && <Label label={getLabel('username')}>{client.username}</Label>}
            {!id && <Input
              name="username"
              register={register}
              type="text"
              label={getLabel("clientUsername")}
              placeholder={getLabel("clientUsernamePlaceholder")}
              error={errors["username"]}
              required={getLabel("inputRequiredError")}
              validate={(value) => {
                if (id) return true;
                const allClientUsernames = clients?.map((c) =>
                  c?.username?.toUpperCase()
                );
                if (
                  allClientUsernames?.includes(value?.trim()?.toUpperCase())
                ) {
                  return getLabel("errorNewClientNameAlreadyTaken", {
                    name: value,
                  });
                } else {
                  clearErrors("username");
                }
              }}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 15,
                message: getLabel("errorMaxLength", {
                  value: 15,
                }),
              }}
              regExpPattern={{
                value: USER_REGEX,
                message: getLabel("errorUsername"),
              }}
            />}
            <Input
              name="name"
              register={register}
              type="text"
              label={getLabel("clientName")}
              placeholder={getLabel("clientNamePlaceholder")}
              error={errors["name"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", {
                  value: 24,
                }),
              }}
              regExpPattern={{
                value: NAME_REGEX,
                message: getLabel("errorName"),
              }}
            />
            <Input
              name="surname"
              register={register}
              type="text"
              label={getLabel("clientSurname")}
              placeholder={getLabel("clientSurnamePlaceholder")}
              error={errors["surname"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", {
                  value: 24,
                }),
              }}
              regExpPattern={{
                value: NAME_REGEX,
                message: getLabel("errorName"),
              }}
            />
          </div>
          <div className="client_form_wrapper_row">
            <Input
              name="clientEmail"
              register={register}
              type="text"
              label={getLabel("clientEmail")}
              placeholder={getLabel("clientEmailPlaceholder")}
              error={errors["clientEmail"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 5,
                message: getLabel("errorMinLength", { value: 5 }),
              }}
              maxLength={{
                value: 32,
                message: getLabel("errorMaxLength", {
                  value: 32,
                }),
              }}
              regExpPattern={{
                value: EMAIL_REGEX,
                message: getLabel("errorEmail"),
              }}
              validate={(value) => {
                if (id) return true;
                const allClientUsernames = clients?.map((c) =>
                  c?.client_email?.toUpperCase()
                );
                if (
                  allClientUsernames?.includes(value?.trim()?.toUpperCase())
                ) {
                  return getLabel("errorNewClientEmailAlreadyTaken", {
                    name: value,
                  });
                } else {
                  clearErrors("clientEmail");
                }
              }}
            />
            {/* <Input
                        name="clientType"
                        register={register}
                        type="text"
                        label={getLabel('clientType')}
                        placeholder={getLabel('clientTypePlaceholder')}
                        error={errors['clientType']}
                        required={getLabel('inputRequiredError')}
                    /> */}
            {/* <Input
                        name="deactivated"
                        register={register}
                        type="text"
                        label={getLabel('clientAccountState')}
                        placeholder={getLabel('clientAccountStatePlaceholder')}
                        error={errors['clientAccountState']}
                        required={getLabel('inputRequiredError')}
                    /> */}
            {/* <Input
                        name="password_expire_date"
                        register={register}
                        type="text"
                        label={getLabel('passwordExpireDate')}
                        placeholder={getLabel('passwordExpireDatePlaceholder')}
                        error={errors['passwordExpireDate']}
                        required={getLabel('inputRequiredError')}
                    /> */}
          </div>
          <div className="client_form_wrapper_row">
            {fields?.map?.((field, idx) => {
              return (
                <div key={field.id} className="operation_form_wrapper_row">
                  <Input
                    name={`clientNames.${idx}.name`}
                    register={register}
                    type="text"
                    label={getLabel("wineryClientName")}
                    placeholder={getLabel("wineryClientNamePlaceholder")}
                    error={getError(idx, errors?.clientNames, "name")}
                    required={getLabel("inputRequiredError")}
                    minLength={{
                      value: 3,
                      message: getLabel("errorMinLength", { value: 3 }),
                    }}
                    maxLength={{
                      value: 24,
                      message: getLabel("errorMaxLength", {
                        value: 24,
                      }),
                    }}
                    regExpPattern={{
                      value: USER_REGEX,
                      message: getLabel("errorUsername"),
                    }}
                    validate={(value) => {
                      if (id) return true;
                      const allClientNames = [
                        ...new Set(
                          clients?.map((c) => c?.client_names).flat(1)
                        ),
                      ];
                      if (
                        allClientNames
                          ?.map((c) => c?.toUpperCase())
                          ?.includes(value?.trim()?.toUpperCase())
                      ) {
                        return getLabel("errorNewClientNameAlreadyTaken", {
                          name: value,
                        });
                      } else {
                        clearErrors(`clientNames.${idx}.name`);
                      }
                    }}
                  />
                  {fields?.length > 1 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        remove(idx);
                      }}
                    >
                      -
                    </button>
                  )}
                </div>
              );
            })}

            <button
              id="names"
              onClick={(e) => {
                e.preventDefault();
                append({
                  name: "",
                });
              }}
            >
              +
            </button>
          </div>
          <div className="client_form_wrapper_row">
            <Input
              name="usersNumber"
              register={register}
              type="text"
              label={getLabel("usersNumber")}
              placeholder={getLabel("usersNumberPlaceholder")}
              error={errors["usersNumber"]}
              required={getLabel("inputRequiredError")}
            />
            <Input
              name="wineriesNumber"
              register={register}
              type="text"
              label={getLabel("wineriesNumber")}
              placeholder={getLabel("wineriesNumberPlaceholder")}
              error={errors["wineriesNumber"]}
              required={getLabel("inputRequiredError")}
            />
            <DatePicker
              name="license_expire_date"
              control={control}
              error={errors["license_expire_date"]}
              required={getLabel("inputRequiredError")}
              dateFormat={configs.shortDateFormat}
              label={getLabel("licensePasswordExpireDate")}
              placeholder={getLabel("licensePasswordExpireDatePlaceholder")}
              defaultValue={addMonths(new Date(), 12)}
            />
          </div>
          {/* <div className="client_form_wrapper_row">
                    <TextArea
                        name="note"
                        register={register}
                        label={getLabel('clientNotes')}
                        placeholder={getLabel('clientNotesPlaceholder')}
                        error={errors['notes']}
                    />                
                </div> */}
          <div className="client_form_wrapper_row">
            <Submit label={getLabel("submitForm")} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("clientsNavLink"),
          getLabel(id ? "clientsUpdatePage" : "clientsNewPage"),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>
  );
};

export default ClientForm;
