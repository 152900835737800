import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom"
import useLabels from "../../hooks/useLabels";
import { deleteUser, getUsers } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { setUsers } from "../../context/users/usersSlice";
import { selectActiveWinery, selectUser, selectUsers } from "../../context/selectors";
import { Label } from "../FormComponents";
import SecondaryBar from "../SecondaryBar";
import { Button, CTA } from "../Generic";
import { removeUsers } from "../../context/users/usersSlice";

import './styles/index.scss';
import { getItem } from "../../services/LocalStorage";

const UserInfo = ({ user }) => {
    const [getLabel] = useLabels();
    const wineriesSession = getItem('wineriesList') || [];
    const currentUserClientName = (wineriesSession?.find((winery) => winery.id === getItem('activeWineryId')))?.client_name || null;
    
    return (<>
    <div className="user_info_container">
        <div className="user_info_container_row">
            <Label label={getLabel('clientName')}>{user.name}</Label>
            <Label label={getLabel('clientSurname')}>{user.surname}</Label>
            <Label label={getLabel('userName')}>{user.username}</Label>
        </div>
        <div className="user_info_container_row">
            <Label label={getLabel('wineryClientName')}>{currentUserClientName}</Label>
            <Label label={getLabel('userType')}>{getLabel(`user_type_${user.type}`)}</Label>
        </div>
        <div className="user_info_container_row">
            <Label label={getLabel('userEmail')}>{user.client_email}</Label>
        </div>
    </div></>)
}

const UserDetail = () => {

    const { id } = useParams();
    const users = useSelector(selectUsers);
    const [getLabel] = useLabels();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const [user, setUser] = useState({});
    const currentUser = useSelector(selectUser);
    
    const navigate = useNavigate();
    const pageName = getLabel('userTitlePage', {username: user?.username || id})

    useEffect(() => {
        if (!users || users.length === 0) {
            loadUsers()
        }
    }, [])

    useEffect(() => {
        if (users && users.length > 0) {
            setUser(users.find((dbUser) => {
                return dbUser.id === Number(id)
            }) || {});
        }
    }, [users])

    const loadUsers = async () => {
        const currentUsers = await getUsers(axiosPrivate, id, null);
        dispatch(setUsers(currentUsers))
    }

    const removeUser = async () => {
        const response = await deleteUser(user?.username, axiosPrivate);
        const requestPayload = {
            ...(id && {id: Number(id)}),
            ...(user?.username && {username: user.username})
        }
        if (response && !response.error) {
            dispatch(removeUsers(requestPayload));
            navigate('/wineries');
        }
    
    }

    const renderNavMenu = () => {
        const navClickHandler = () => {
            navigate('/wineries')
        }
        return (
            <div className="primary_container_menu">
                <div className="primary_container_row">
                <Button
                    arrowDirection="left"
                    onClick={navClickHandler}
                />
                <h2>{pageName}</h2>
                </div>
                <div className="primary_container_row">
                    <CTA onClick={removeUser}>{getLabel('deleteUser')}</CTA>
                    {currentUser?.userName === user?.username &&
                        <NavLink
                            to={`/users/${id}/update`}
                        >
                            <CTA>{getLabel('updateUser')}</CTA>
                        </NavLink>
                    }
                </div>    
            </div>
        )
    }

    const renderNextOperation = () => {
        return (<div>
            <div className="primary_container_menu">
                <h4>{getLabel('user_nextOperations')}</h4>
                <NavLink to={'/programming/new'}>
                    <CTA>{getLabel('programOperation')}</CTA>
                </NavLink>
            </div>
        </div>)
    }

    return (
        <div className="primary_container">
            <SecondaryBar
                breadCrumb={[
                    getLabel('usersNavLink'),
                    getLabel('userTitlePage', {username: ''})
                ]}
            />
            {renderNavMenu()}
            <UserInfo user={user}/>
            {renderNextOperation()}
        </div>
    )
}

export default UserDetail